import { unitMapping } from 'lib/style';

type Props = {
  size?: keyof typeof unitMapping;
  className?: string;
};

export const BasketballGuard = ({ size = 2, className }: Props) => (
  <svg
    className={className}
    style={{ width: unitMapping[size], height: unitMapping[size] }}
    viewBox="0 0 215 215"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M207.556 183.48L188.199 202.837L50.1602 64.7982L102.427 12.5312L110.655 20.7587L118.882 50.9261L156.363 66.4669L164.59 58.2394L180.131 60.9819L181.266 93.1553H149.695L164.695 110.065H182.434C185.112 133.261 192.283 155.762 203.596 176.293L207.556 183.48ZM3 93.0977H45.7685L61.621 108.95H18.8525L3 93.0977ZM35.7617 129.03H81.701L98.6103 145.939H52.671L35.7617 129.03ZM80.1495 166.019H118.689L134.542 181.871H96.002L80.1495 166.019Z"
      fill="white"
    />
  </svg>
);
