import { unitMapping } from 'lib/style';

type Props = {
  size?: keyof typeof unitMapping;
  className?: string;
};

export const BasketballForward = ({ size = 2, className }: Props) => (
  <svg
    className={className}
    style={{ width: unitMapping[size], height: unitMapping[size] }}
    viewBox="0 0 215 215"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.5 33C53.5 52 95.9999 57.5 128 57.5C169.5 57.5 193 89.4999 193 146C193 191.2 205.333 209.5 211.5 213C189.333 207.5 156 190.1 156 140.5C156 109.054 127.134 98.4856 96.7267 87.3529C67.4534 76.6353 36.7515 65.3947 29 34.5C29.0493 34.446 29.351 34.1469 29.672 33.8285C30.0374 33.4662 30.4278 33.0791 30.5 33ZM23.5 86C44.1111 97.7782 72.1414 104.506 98.8004 108.264C110.454 112.942 119.509 117.091 126.151 122.148C133.001 127.362 136 132.491 136 140.5C136 143.052 136.075 145.545 136.222 147.982C100.931 143.434 56.9811 132.657 21.5 89.9998L23.5 86ZM46.5187 152.025C82.4057 163.11 114.37 164.48 138.075 162.655C140.204 173.454 143.887 182.91 148.717 191.112C111.273 189.701 60.7984 181.663 45.0189 154.525C45.4998 154 46.0184 153.025 46.5187 152.025Z"
      fill="white"
    />
  </svg>
);
