import { useCallback } from 'react';

import { NotificationCategoryInput } from '__generated__/globalTypes';
import {
  NotificationGroup,
  notificationCategoryTypes,
  notificationGroups,
} from 'lib/notifications';

export const useGetNotificationCategories = () => {
  return useCallback(
    (groups: NotificationGroup[]): NotificationCategoryInput[] =>
      groups
        .flatMap(group =>
          notificationGroups[group].map(category => ({
            name: category,
            type: notificationCategoryTypes[category],
          }))
        )
        .filter(({ type }) => type !== 'ManagerProgressionNotification'),
    []
  );
};
