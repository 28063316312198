import { type TypedDocumentNode, gql } from '@apollo/client';
import { ComponentProps, useState } from 'react';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { Dropdown } from '@sorare/core/src/atoms/dropdowns/Dropdown';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyS } from '@sorare/core/src/atoms/typography';
import { ANY_SPORT_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY } from '@sorare/core/src/constants/__generated__/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { isType } from '@sorare/core/src/gql';
import { generateSportPath } from '@sorare/core/src/lib/routing/generateSportPath';

import { AnyRewardConfig } from '../AnyRewardConfig';
import { LazyBoxOverviewDialog } from '../LazyBoxOverviewDialog';
import type {
  ClickableAnyRewardConfig_anyRewardConfigInterface,
  ClickableAnyRewardConfig_so5League,
} from './__generated__/index.graphql';

const DropdownLabel = styled.div`
  width: 100%;
  height: 100%;
`;
const DropdownContent = styled.div`
  padding: var(--intermediate-unit) var(--double-unit);
`;

type Props = {
  anyRewardConfig: ClickableAnyRewardConfig_anyRewardConfigInterface;
  so5League?: ClickableAnyRewardConfig_so5League;
} & Pick<ComponentProps<typeof AnyRewardConfig>, 'rarity' | 'size' | 'variant'>;

export const ClickableAnyRewardConfig = ({
  anyRewardConfig,
  so5League,
  ...anyRewardConfigProps
}: Props) => {
  const { sport } = useSportContext();
  const [openBoxOverview, setOpenBoxOverview] = useState(false);

  const rewardOverview = (
    <AnyRewardConfig
      anyRewardConfig={anyRewardConfig}
      {...anyRewardConfigProps}
    />
  );

  if (isType(anyRewardConfig, 'ProbabilisticBundleRewardConfig')) {
    return (
      <>
        <ButtonBase onClick={() => setOpenBoxOverview(true)}>
          {rewardOverview}
        </ButtonBase>
        <LazyBoxOverviewDialog
          open={openBoxOverview}
          onClose={() => setOpenBoxOverview(false)}
          boxConfigId={anyRewardConfig.probabilisticBundleConfig.id}
          so5League={so5League}
        />
      </>
    );
  }

  if (isType(anyRewardConfig, 'DeliverableItemRewardConfig')) {
    return (
      <Dropdown
        triggerOnHover
        gap={8}
        label={props => (
          <ButtonBase {...props}>
            <DropdownLabel>{rewardOverview}</DropdownLabel>
          </ButtonBase>
        )}
      >
        <DropdownContent>
          <Vertical gap={0}>
            <BodyS>{anyRewardConfig.deliverableItem.name}</BodyS>
          </Vertical>
        </DropdownContent>
      </Dropdown>
    );
  }

  if (
    isType(anyRewardConfig, 'CardRewardConfig') &&
    so5League &&
    anyRewardConfig.quality
  ) {
    return (
      <Link
        to={generateSportPath(ANY_SPORT_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY, {
          params: {
            rarity: anyRewardConfig.rarity,
            quality: anyRewardConfig.quality,
            league: so5League.slug,
            fixture: so5League.so5Fixture.slug,
          },
          sport,
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        {rewardOverview}
      </Link>
    );
  }

  return rewardOverview;
};

ClickableAnyRewardConfig.fragments = {
  anyRewardConfigInterface: gql`
    fragment ClickableAnyRewardConfig_anyRewardConfigInterface on AnyRewardConfigInterface {
      id
      ... on CardRewardConfig {
        id
        quality: qualityTyped
        rarity
      }
      ... on ProbabilisticBundleRewardConfig {
        id
        probabilisticBundleConfig {
          id
        }
      }
      ... on DeliverableItemRewardConfig {
        id
        deliverableItem {
          slug
          name
        }
      }
      ...AnyRewardConfig_anyRewardConfigInterface
    }
    ${AnyRewardConfig.fragments.anyRewardConfigInterface}
  ` as TypedDocumentNode<ClickableAnyRewardConfig_anyRewardConfigInterface>,
  so5League: gql`
    fragment ClickableAnyRewardConfig_so5League on So5League {
      slug
      so5Fixture {
        slug
      }
      ...LazyBoxOverviewDialog_so5League
    }
    ${LazyBoxOverviewDialog.fragments.so5League}
  ` as TypedDocumentNode<ClickableAnyRewardConfig_so5League>,
};
