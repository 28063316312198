import { TypedDocumentNode, gql } from '@apollo/client';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import {
  LinkBox,
  LinkOther,
  LinkOverlay,
} from '@sorare/core/src/atoms/navigation/Box';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { ScarcityAvatar } from '@sorare/core/src/atoms/ui/ScarcityAvatar';
import { PlayerGameScoreScore } from '@sorare/core/src/components/scoring/PlayerGameScoreScore';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

import { DumbNotification } from 'components/activity/DumbNotification';
import { PlayerGameScoreDrawer } from 'components/stats/PlayerGameScoreDrawer';
import useComposeTeamAutosave from 'hooks/compose-team/useComposeTeamAutosave';
import { getComposeTeamRoute } from 'lib/so5';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { MissedLineupNotification_missedLineupNotification } from './__generated__/index.graphql';

const PlusIcon = styled(FontAwesomeIcon)`
  opacity: 0.5;
`;
const PlayerButton = styled(ButtonBase)`
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
  font: var(--t-12);
  padding-top: var(--unit);
  padding-bottom: var(--half-unit);
`;

const Avatar = styled(ScarcityAvatar)`
  width: var(--quadruple-unit);
`;
const PlayerGameScores = styled(Horizontal)`
  align-items: start;
`;

type Props = CommonNotificationProps & {
  notification: MissedLineupNotification_missedLineupNotification;
};

export const MissedLineupNotification = ({ notification, ...rest }: Props) => {
  const { missedLineup, name, sport, read, createdAt } = notification;

  const { currentUser } = useCurrentUserContext();
  const navigate = useNavigate();
  const composeSessionId = useMemo(
    () =>
      `${currentUser?.slug}-${missedLineup?.so5Leaderboard?.slug}-${new Date().getTime()}`,
    [currentUser?.slug, missedLineup?.so5Leaderboard?.slug]
  );
  const { addItemsToAutoSave } = useComposeTeamAutosave(
    missedLineup?.so5Leaderboard?.slug || '',
    composeSessionId
  );

  const [playerGameScoreId, setPlayerGameScoreId] = useState<string>();

  const appearances = missedLineup?.so5Leaderboard?.rules?.appearances?.map(
    (rule, i) =>
      missedLineup?.appearances.find(appearance => appearance.index === i) ||
      rule
  );

  if (!missedLineup?.so5Leaderboard || !appearances?.length) {
    return null;
  }

  const createTeam = () => {
    if (missedLineup.so5Leaderboard?.slug) {
      addItemsToAutoSave(
        appearances
          .filter(appearance => 'id' in appearance)
          .map(({ index, id }) => ({
            position: index,
            benchObjectId: id,
          }))
      );
      navigate(
        getComposeTeamRoute({
          leaderboardSlug: missedLineup.so5Leaderboard.slug,
          sport: Sport.FOOTBALL,
          eventType: 'classic',
        })
      );
    }
  };

  return (
    <>
      <LinkBox>
        <DumbNotification
          name={name}
          title={
            <LinkOverlay
              as={ButtonBase}
              className="text-left"
              onClick={createTeam}
            >
              <FormattedMessage
                id="Notification.MissedLineupNotification.title"
                defaultMessage="Your benched players shined last Game Week 🔥 Play them in {leagueName} next time!"
                values={{
                  leagueName:
                    missedLineup.so5Leaderboard.so5League.shortDisplayName,
                }}
              />
            </LinkOverlay>
          }
          createdAt={createdAt}
          sport={sport}
          read={read}
          avatarUrl={missedLineup.so5Leaderboard.so5League.iconUrl}
          {...rest}
        >
          <PlayerGameScores>
            {appearances.map(appearance => {
              if (!('id' in appearance)) {
                return (
                  <PlayerButton as="div" key="+">
                    <Vertical gap={0.5} center>
                      <Avatar />
                      <LabelM>
                        <PlusIcon icon={faCirclePlus} />
                      </LabelM>
                    </Vertical>
                  </PlayerButton>
                );
              }

              const { anyCard, eligiblePlayerGameScores } = appearance;
              const playerGameScore = eligiblePlayerGameScores[0];

              return (
                <LinkOther as="div" key={playerGameScore.id}>
                  <PlayerButton
                    onClick={e => {
                      setPlayerGameScoreId(playerGameScore.id);
                      e.stopPropagation();
                    }}
                  >
                    <Vertical gap={0.5}>
                      {anyCard && (
                        <Avatar
                          name={anyCard.anyPlayer.displayName}
                          avatarUrl={anyCard.avatarUrl}
                        />
                      )}
                      <PlayerGameScoreScore
                        playerGameScore={playerGameScore}
                        shape="TEXT"
                      />
                    </Vertical>
                  </PlayerButton>
                </LinkOther>
              );
            })}
          </PlayerGameScores>
        </DumbNotification>
      </LinkBox>
      {playerGameScoreId && (
        <PlayerGameScoreDrawer
          open
          onClose={() => setPlayerGameScoreId(undefined)}
          playerGameScoreId={playerGameScoreId}
          side="left"
        />
      )}
    </>
  );
};

MissedLineupNotification.fragments = {
  missedLineupNotification: gql`
    fragment MissedLineupNotification_missedLineupNotification on MissedLineupNotification {
      id
      ...Notification_notificationInterface
      missedLineup {
        slug
        so5Leaderboard {
          slug
          title
          rules {
            id
            appearances {
              name
            }
          }
          so5League {
            slug
            iconUrl
            shortDisplayName
          }
        }
        appearances {
          id
          index
          position
          anyCard {
            slug
            rarity: rarityTyped
            avatarUrl: pictureUrl(derivative: "avatar")
            anyPlayer {
              slug
              displayName
            }
          }
          eligiblePlayerGameScores {
            id
            anyPlayerGameStats {
              id
              ... on PlayerGameStats {
                id
                fieldStatus
                gameStarted
              }
            }
            ...PlayerGameScoreScore_playerGameScoreInterface
          }
        }
      }
    }
    ${commonNotificationInterfaceFragment}
    ${PlayerGameScoreScore.fragments.playerGameScoreInterface}
  ` as TypedDocumentNode<MissedLineupNotification_missedLineupNotification>,
};
