export type Errors = { path: string[] | null; message: string }[] | null;

export function getErrorFrom(errors: Errors, id: string) {
  if (!errors) {
    return '';
  }
  return (
    errors
      ?.filter(({ path }) => path?.[1].toLowerCase() === id.toLowerCase())
      .map(({ message }) => message)[0] || ''
  );
}
