import { getRoutesFromFiles } from '@sorare/routing';

/*
 * Prefer dynamic over static import into this file
 * because static ones will end up in the main bundle
 */

export const routeObjects = getRoutesFromFiles(
  import.meta.glob('./**/(page|layout).tsx'),
  '../../packages/shared-pages/src/pages'
);
