import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { So5LeaderboardSeasonality } from '@sorare/core/src/__generated__/globalTypes';

import { seasonalityMessages } from 'lib/so5';

const Wrapper = styled.span`
  &.inSeason {
    background: linear-gradient(
      84deg,
      #f8d3da 0%,
      #b3a9f4 28.32%,
      #fbe9fb 54.01%,
      #4f94fd 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  color: var(--c-nd-600);
`;

type Props = {
  seasonality: Nullable<So5LeaderboardSeasonality>;
  label: Nullable<string>;
};

export const SeasonalityLabel = ({ seasonality, label }: Props) => {
  if (!seasonality) {
    return null;
  }
  return (
    <Wrapper
      className={classNames({
        inSeason: seasonality === So5LeaderboardSeasonality.IN_SEASON,
      })}
    >
      {label || <FormattedMessage {...seasonalityMessages[seasonality]} />}
    </Wrapper>
  );
};
