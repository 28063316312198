import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { LabelM, Title6 } from '@sorare/core/src/atoms/typography';
import { getHumanReadableSerialNumber } from '@sorare/core/src/lib/cards';

import { TokenNameCancelSalePopin_anyCard } from './__generated__/index.graphql';

const TokenInfo = styled.div`
  display: flex;
  color: var(--c-nd-600);
`;

interface Props {
  card: TokenNameCancelSalePopin_anyCard;
}

export const TokenNameCancelSalePopin = ({ card }: Props) => {
  const { anyPlayer: player, sportSeason } = card;

  return (
    <>
      <Title6>{player.displayName}</Title6>
      <TokenInfo>
        <LabelM>{sportSeason.name}</LabelM>
        &nbsp;-&nbsp;
        <LabelM>{getHumanReadableSerialNumber(card)}</LabelM>
      </TokenInfo>
    </>
  );
};

TokenNameCancelSalePopin.fragments = {
  anyCard: gql`
    fragment TokenNameCancelSalePopin_anyCard on AnyCardInterface {
      slug
      anyPlayer {
        slug
        displayName
      }
      rarityTyped
      sportSeason {
        name
      }
      ...getHumanReadableSerialNumber_anyCard
    }
    ${getHumanReadableSerialNumber.fragments.anyCard}
  ` as TypedDocumentNode<TokenNameCancelSalePopin_anyCard>,
};

export default TokenNameCancelSalePopin;
