import { ShopItemType } from '__generated__/globalTypes';

export const MERCHANDISE_TYPES = [
  ShopItemType.JERSEY,
  ShopItemType.RAFFLE,
  ShopItemType.DELIVERABLE_WITH_CUSTOM_VARIANT,
  ShopItemType.DELIVERABLE_WITH_NO_VARIANT,
] as const;

export const COSMETICS_TYPES = [
  ShopItemType.ACHIEVEMENT_BADGE,
  ShopItemType.BACKGROUND,
  ShopItemType.BANNER,
  ShopItemType.CHALLENGES_BADGE,
  ShopItemType.CLUB_BADGE,
  ShopItemType.COLLECTION_BADGE,
  ShopItemType.COUNTRY_BADGE,
  ShopItemType.EVENT_BADGE,
  ShopItemType.LOGO,
  ShopItemType.MANAGER_PROGRESSION_BADGE,
  ShopItemType.RIVALS_SQUAD_LOGO,
  ShopItemType.SHIELD,
  ShopItemType.SHOP_BADGE,
] as const;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NOT_MERCHANDISE_FILTERABLE_TYPES = [
  ShopItemType.ACHIEVEMENT_BADGE,
  ShopItemType.CLUB_BADGE,
  ShopItemType.BACKGROUND,
  ShopItemType.EXTRA_SWAP,
  ShopItemType.EXTRA_TEAMS_CAP,
  ShopItemType.LEVEL_UP,
  ShopItemType.APPEARANCE_BOOST,
  ShopItemType.COMMON_CARD_SWAP,
] as const;

type NotMerchandiseFilterableType =
  (typeof NOT_MERCHANDISE_FILTERABLE_TYPES)[number];

export const ALL_FILTERABLE_TYPES = [
  ShopItemType.ACHIEVEMENT_BADGE,
  ShopItemType.CLUB_BADGE,
  ShopItemType.BACKGROUND,
  ShopItemType.EXTRA_SWAP,
  ShopItemType.EXTRA_TEAMS_CAP,
  ShopItemType.LEVEL_UP,
  ShopItemType.APPEARANCE_BOOST,
  ShopItemType.COMMON_CARD_SWAP,
  ...MERCHANDISE_TYPES,
];

export const MERCHANDISE_TYPE = 'MERCHANDISE' as const;

export type FilterableType =
  | NotMerchandiseFilterableType
  | typeof MERCHANDISE_TYPE;
