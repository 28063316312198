import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { SilencedError } from '@sorare/error-boundary';

import { AvailableLocale } from '@sorare/core/src/contexts/intl';

import { API_ENDPOINT } from 'constants/config';

export type Locale = 'en-us' | 'es' | 'fr' | 'it';

export interface Article {
  locale: string;
  body: string;
  updated_at: string;
  id: number;
  name: string;
  position: number;
  title: string;
  url: string;
  section_id: number;
}

export interface Section {
  category_id: number;
  description: string;
  id: number;
  locale: string;
  name: string;
  position: number;
  url: string;
  html_url: string;
}

export interface Category {
  description: string;
  id: number;
  locale: string;
  name: string;
  position: number;
  url: string;
  html_url: string;
  sections: Section[];
}

interface Categories {
  categories: Category[];
}

interface Sections {
  sections: Section[];
}

interface Articles {
  articles: Article[];
}

export const ZENDESK_LOCALES: Record<AvailableLocale, Locale> = {
  ar: 'en-us',
  'de-DE': 'en-us',
  'en-US': 'en-us',
  'en-GB': 'en-us',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'it-IT': 'it',
  'ru-RU': 'en-us',
  'tr-TR': 'en-us',
} as const;

const CACHE: Record<string, any> = {};

const fetchCached = async <T>(
  url: string
): Promise<
  { data: T; error: undefined } | { data: undefined; error: Error }
> => {
  if (url in CACHE) {
    return { data: CACHE[url], error: undefined };
  }
  return fetch(url)
    .then(async res => res.json())
    .then(data => {
      CACHE[url] = data;
      return { data, error: undefined };
    })
    .catch(e => {
      return { error: e, data: undefined };
    });
};

export const useZendeskLocale = () => {
  const intl = useIntl();
  const locale = intl.locale as AvailableLocale;

  return ZENDESK_LOCALES[locale] || 'en-us';
};

export const useCategories = () => {
  const locale = useZendeskLocale();
  const [error, setError] = useState<Error | undefined>(undefined);
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    Promise.all([
      fetchCached<Categories>(
        `${API_ENDPOINT}/${locale}/categories.json?per_page=100`
      ),
      fetchCached<Sections>(
        `${API_ENDPOINT}/${locale}/sections.json?per_page=100`
      ),
    ]).then(
      ([
        { data: dataCategories, error: errorCategories },
        { data: dataSections, error: errorSections },
      ]) => {
        if (errorCategories) {
          setError(errorCategories);
          return;
        }
        if (errorSections) {
          setError(errorSections);
          return;
        }

        setCategories(
          dataCategories.categories
            .sort((a, b) => a.position - b.position)
            .map(category => ({
              ...category,
              sections: dataSections.sections
                .filter(section => section.category_id === category.id)
                .sort((a, b) => a.position - b.position),
            }))
        );
      }
    );
  }, [locale]);

  if (error) {
    throw new SilencedError(`Zendesk categories not found`, error);
  }

  return categories;
};

export const useCategory = (categoryId: number) => {
  const categories = useCategories();

  return categories.find(category => category.id === categoryId);
};

export const useSection = (sectionIdStr: string | undefined) => {
  const sectionId = sectionIdStr && parseInt(sectionIdStr, 10);
  const categories = useCategories();
  const category = categories.find(
    c => !!c.sections.find(s => s.id === sectionId)
  );
  const section = category?.sections.find(s => s.id === sectionId);

  return { section, category };
};

export const useArticles = (sectionId: number) => {
  const locale = useZendeskLocale();
  const [error, setError] = useState<Error | undefined>(undefined);
  const [articles, setArticles] = useState<Article[]>([]);

  useEffect(() => {
    fetchCached<Articles>(
      `${API_ENDPOINT}/${locale}/sections/${sectionId}/articles.json?per_page=100`
    ).then(({ data, error: err }) => {
      if (err) {
        setError(err);
      } else {
        setArticles(data.articles);
      }
    });
  }, [locale, sectionId]);

  if (error) {
    throw new SilencedError(
      `Zendesk articles for section ${sectionId} not found`,
      error
    );
  }

  return articles;
};

export const useArticle = (articleIdStr: string | undefined) => {
  const locale = useZendeskLocale();
  const [article, setArticle] = useState<Article>();
  const [error, setError] = useState<Error | undefined>(undefined);
  const articleId = articleIdStr && parseInt(articleIdStr, 10);
  const categories = useCategories();

  useEffect(() => {
    fetchCached<{ article: Article }>(
      `${API_ENDPOINT}/${locale}/articles/${articleId}.json`
    ).then(({ data, error: err }) => {
      if (err) {
        setError(err);
      } else {
        setArticle(data.article);
      }
    });
  }, [locale, articleId, articleIdStr]);

  const category = categories.find(c =>
    c.sections.find(s => s.id === article?.section_id)
  );

  const section = category?.sections.find(s => s.id === article?.section_id);

  if (error) {
    throw new SilencedError(
      `Zendesk article ${articleIdStr} not found.`,
      error
    );
  }

  return { article, category, section };
};
