import { MessageDescriptor } from 'react-intl';

import { navLabels } from '@sorare/core/src/lib/glossary';

export enum MySorarePage {
  NEW = 'new',
  AUCTIONS = 'auctions',
  PRIMARY_OFFERS = 'primary_offers',
  PURCHASES = 'purchases',
  SALES = 'sales',
  OFFERS_RECEIVED = 'offers_received',
  OFFERS_SENT = 'offers_sent',
  FOLLOWS = 'follows',
  PLAYERS_NOTIFICATIONS = 'players_notifications',
  TRANSACTIONS = 'transactions',
}

export const DEFAULT_PAGE_ID: MySorarePage = MySorarePage.NEW;

export const TITLES: { [key in MySorarePage]: MessageDescriptor } = {
  new: navLabels.new,
  auctions: navLabels.myAuctions,
  primary_offers: navLabels.myInstantBuys,
  sales: navLabels.myListings,
  purchases: navLabels.myPurchases,
  offers_received: navLabels.myOffersReceived,
  offers_sent: navLabels.myOffersSent,
  follows: navLabels.myFollows,
  players_notifications: navLabels.myPlayerNotifications,
  transactions: navLabels.myTransactions,
};
