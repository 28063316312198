import { TypedDocumentNode, gql } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import playerPlaceholder from '@sorare/core/src/assets/players/placeholder.png';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { DisplayS, LabelL } from '@sorare/core/src/atoms/typography';
import { Chip } from '@sorare/core/src/atoms/ui/Chip';
import { Card } from '@sorare/core/src/components/card/Card';
import { ANY_SPORT_PLAYERS_SLUG } from '@sorare/core/src/constants/__generated__/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { isType } from '@sorare/core/src/gql';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import {
  getPlayerInjuryColor,
  getPlayerInjuryMessage,
} from '@sorare/core/src/lib/baseball';
import { playerUnavailability } from '@sorare/core/src/lib/glossary';
import { anyPositionShortNames } from '@sorare/core/src/lib/players';
import { Color } from '@sorare/core/src/style/types';

import {
  PlayerProperties_anyCard,
  PlayerProperties_anyPlayer,
} from './__generated__/index.graphql';

const ImageWrapper = styled.div`
  width: 100px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: var(--intermediate-unit);
  justify-content: space-between;
`;

const PlayerImageWrapper = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(
      180deg,
      rgba(var(--c-rgb-black), 0) 0%,
      var(--c-black) 100%
    );
  }
`;

const PlayerImage = styled.img`
  width: 100%;
  aspect-ratio: 1;
`;

const WrapHorizontal = styled(Horizontal)`
  align-items: end;
  flex-wrap: wrap;
`;

const StyledChip = styled(Chip)`
  --rgb-color: 240, 206, 29;
  background: linear-gradient(
    -30deg,
    rgba(var(--rgb-color), 0.16) 50%,
    var(--c-nd-100) 50%
  );
  border: 1px solid rgba(var(--rgb-color), 0.3);

  &.suspension {
    --rgb-color: var(--c-rgb-red-600);
  }
`;

const PlayerName = styled(DisplayS)`
  word-break: break-word;
`;
const ClubAvatar = styled.img`
  width: var(--intermediate-unit);
  height: var(--intermediate-unit);
  object-fit: contain;
  object-position: center;
`;

type Props = {
  player: PlayerProperties_anyPlayer;
  card?: PlayerProperties_anyCard;
  useCardPositions?: boolean;
  usePlayerPicture?: boolean;
};

export const PlayerProperties = ({
  player,
  card,
  usePlayerPicture,
  useCardPositions,
}: Props) => {
  const {
    displayName,
    anyPositions,
    squaredPictureUrl: playerPictureUrl,
  } = player;
  const { generateSportContextPath } = useSportContext();
  const { isIosApp } = useIsMobileApp();

  let availability: MessageDescriptor = playerUnavailability.active;
  let color: Color = 'var(--c-score-high)';

  const isInjured = player.activeInjuries.length > 0;
  const isSuspended = player.activeSuspensions.length > 0;

  if (isType(player, 'BaseballPlayer') && player.activeInjuries.length) {
    availability = getPlayerInjuryMessage(player.activeInjuries[0].status, {
      long: true,
    });
    color = `var(--c-${getPlayerInjuryColor(player.activeInjuries[0].status)}-600)`;
  } else if (isInjured) {
    availability = playerUnavailability.injuryTitle;
    color = 'var(--c-yellow-400)';
  } else if (isSuspended) {
    availability = playerUnavailability.suspendedTitle;
    color = 'var(--c-red-600)';
  }

  return (
    <SBHorizontal>
      <Vertical>
        <LabelL color={color}>
          <FormattedMessage {...availability} />
        </LabelL>
        <Wrapper>
          <Vertical>
            <PlayerName>
              {isIosApp ? (
                displayName
              ) : (
                <Link
                  to={generateSportContextPath(ANY_SPORT_PLAYERS_SLUG, {
                    params: { slug: player.slug },
                    sport: player.sport,
                  })}
                >
                  {displayName}
                </Link>
              )}
            </PlayerName>
            <WrapHorizontal gap={0.5}>
              {(useCardPositions && card
                ? card.anyPositions
                : anyPositions
              ).map(position => (
                <span key={position}>
                  <Chip>
                    <FormattedMessage {...anyPositionShortNames[position]} />
                  </Chip>
                </span>
              ))}
              <span>
                <Chip>
                  <ClubAvatar
                    alt=""
                    src={player.activeClub?.pictureUrl || ''}
                  />
                  {player.activeClub?.code}
                </Chip>
              </span>
              {(isInjured || isSuspended) && (
                <StyledChip
                  color="yellow"
                  className={classNames({
                    suspension: player.activeSuspensions.length > 0,
                  })}
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color={
                      player.activeInjuries.length > 0
                        ? 'var(--c-yellow-400)'
                        : 'var(--c-red-600)'
                    }
                  />
                </StyledChip>
              )}
            </WrapHorizontal>
          </Vertical>
        </Wrapper>
      </Vertical>
      <ImageWrapper>
        {!usePlayerPicture && card ? (
          <Card card={card} />
        ) : (
          <PlayerImageWrapper>
            <PlayerImage src={playerPictureUrl || playerPlaceholder} alt="" />
          </PlayerImageWrapper>
        )}
      </ImageWrapper>
    </SBHorizontal>
  );
};

PlayerProperties.fragments = {
  anyPlayer: gql`
    fragment PlayerProperties_anyPlayer on AnyPlayerInterface {
      slug
      displayName
      sport
      activeInjuries {
        id
        status
      }
      activeSuspensions {
        id
      }
      activeClub {
        slug
        code
        pictureUrl
      }
      squaredPictureUrl
      anyPositions
    }
  ` as TypedDocumentNode<PlayerProperties_anyPlayer>,
  anyCard: gql`
    fragment PlayerProperties_anyCard on AnyCardInterface {
      slug
      anyPositions
      ...Card_anyCard
    }
    ${Card.fragments.anyCard}
  ` as TypedDocumentNode<PlayerProperties_anyCard>,
};
