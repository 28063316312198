import type { TypedDocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';
import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyM, BodyS } from '@sorare/core/src/atoms/typography';
import { statLabels } from '@sorare/core/src/lib/scoring';

import type { ScoringMatrixRules_so5Leaderboard } from './__generated__/index.graphql';

const StyledBodyM = styled(BodyM)`
  .minified & {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const Points = styled.span`
  color: var(--c-nd-400);
  font: var(--t-label-sm);
`;
const CustomPoints = styled.span`
  font: var(--t-label-md);
  font-weight: var(--t-bold);
`;
const Green = styled(CustomPoints)`
  color: var(--c-rivals-win);
`;
const Red = styled(CustomPoints)`
  color: var(--c-red-600);
`;

const CapitalizeFirstLetter = styled.span`
  text-transform: capitalize;
`;

type Props = {
  so5Leaderboard: ScoringMatrixRules_so5Leaderboard;
  minified?: boolean;
};

export const ScoringMatrixRules = ({ minified, so5Leaderboard }: Props) => {
  const statCustomScorings =
    so5Leaderboard.so5League?.scoringMatrix?.statCustomScorings || [];

  const scoringChanges = statCustomScorings.filter(
    stat => stat.points !== stat.customPoints
  );

  if (!scoringChanges.length) {
    return null;
  }

  return (
    <Vertical gap={0.5} className={classNames({ minified })}>
      <Horizontal>
        <FontAwesomeIcon icon={faArrowsRotate} />
        <StyledBodyM as="p" bold>
          <FormattedMessage
            id="Rules.ScoringMatrix.scoringChanges"
            defaultMessage="Scoring Changes"
          />
        </StyledBodyM>
      </Horizontal>
      {statCustomScorings.map(stat => {
        if (stat.points === stat.customPoints) {
          return null;
        }
        const morePoints = stat.customPoints > stat.points;
        const ColorsCustomPoints = morePoints ? Green : Red;

        const label = statLabels[stat.stat as keyof typeof statLabels];
        return (
          <BodyS key={stat.stat} as="p" color="var(--c-nd-600">
            <CapitalizeFirstLetter>
              {label ? <FormattedMessage {...label} /> : stat.stat}
            </CapitalizeFirstLetter>
            :{' '}
            <Points>
              <s>
                <FormattedNumber value={stat.points} signDisplay="always" />
              </s>
            </Points>{' '}
            <ColorsCustomPoints>
              <FormattedNumber value={stat.customPoints} signDisplay="always" />
            </ColorsCustomPoints>
          </BodyS>
        );
      })}
    </Vertical>
  );
};

ScoringMatrixRules.fragments = {
  so5Leaderboard: gql`
    fragment ScoringMatrixRules_so5Leaderboard on So5Leaderboard {
      slug
      so5League {
        slug
        scoringMatrix {
          id
          statCustomScorings {
            position
            stat
            points
            customPoints
          }
        }
      }
    }
  ` as TypedDocumentNode<ScoringMatrixRules_so5Leaderboard>,
};
