import { Navigate, generatePath } from 'react-router-dom';

import { So5FixtureEvent } from '@sorare/core/src/__generated__/globalTypes';
import { MLB_PLAY_EVENTTYPE } from '@sorare/core/src/constants/__generated__/routes';

const LeaderboardPage = () => {
  return (
    <Navigate
      to={generatePath(MLB_PLAY_EVENTTYPE, {
        eventType: So5FixtureEvent.CLASSIC.toLowerCase(),
      })}
    />
  );
};

export default LeaderboardPage;
