import { defineMessages } from 'react-intl';

import { CustomRewardExperience } from '__generated__/globalTypes';
import { glossary } from 'lib/glossary';
import { ExperienceType } from 'lib/rewards';

export const messages = defineMessages<ExperienceType>({
  [CustomRewardExperience.JERSEY]: glossary.jerseys,
  [CustomRewardExperience.TICKET]: glossary.tickets,
  [CustomRewardExperience.EVENT]: glossary.events,
  [CustomRewardExperience.MERCH]: glossary.merch,
});
