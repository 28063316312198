import { InputHTMLAttributes } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { IconDefinition } from 'atoms/icons';
import { turnIconIntoBackground } from 'lib/pictures';

const Root = styled.input`
  flex: 1;
  min-width: 0;
  width: auto;
  font: var(--t-16);
  line-height: calc(5 * var(--unit));
  appearance: none;
  border: none;
  border-radius: var(--unit);
  background-color: var(--c-nd-50);
  background-image: var(--icon);
  background-repeat: no-repeat;
  background-size: var(--double-unit) var(--double-unit);
  background-position: var(--intermediate-unit) center;
  padding: 0 var(--unit) 0 var(--paddingLeft);
`;

export type Props = {
  icon?: IconDefinition;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = ({ icon, ...props }: Props) => {
  return (
    <Root
      style={
        {
          '--icon': icon ? `url(${turnIconIntoBackground(icon)})` : 'none',
          '--paddingLeft': icon ? `var(--quadruple-unit)` : `var(--unit)`,
        } as CSSProperties
      }
      {...props}
    />
  );
};
