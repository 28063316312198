import { BoundingBox } from './types';

export const drawImage = async ({
  ctx,
  imageUrl,
  boundingBox,
  globalCompositeOperation = 'source-over',
  globalAlpha = 1,
}: {
  ctx: CanvasRenderingContext2D;
  imageUrl: string;
  boundingBox: BoundingBox;
  globalCompositeOperation?: GlobalCompositeOperation;
  globalAlpha?: number;
}) => {
  const WIDTH = ctx.canvas.width;
  const HEIGHT = ctx.canvas.height;

  const width = boundingBox.width * WIDTH;
  const height = boundingBox.height * HEIGHT;

  const x = boundingBox.left * WIDTH;
  const y = boundingBox.top * HEIGHT;

  const image = new Image();
  image.src = imageUrl;
  await image.decode();
  ctx.save();
  ctx.globalCompositeOperation = globalCompositeOperation;
  ctx.globalAlpha = globalAlpha;
  ctx.drawImage(image, x, y, width, height);
  ctx.restore();
};
