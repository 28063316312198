import { TypedDocumentNode, gql } from '@apollo/client';

import { isType } from '@sorare/core/src/gql';

import { ClickableAnyRewardConfig } from 'components/rewards/ClickableAnyRewardConfig';
import { Variant } from 'components/rewards/RewardOverview/types';

import { So5RankingRewards_so5Ranking } from './__generated__/index.graphql';

type Props = { so5Ranking: So5RankingRewards_so5Ranking; variant: Variant };

export const So5RankingRewards = ({ so5Ranking, variant }: Props) => {
  const { eligibleOrSo5Rewards, so5Lineup } = so5Ranking;

  const rewardConfigs = eligibleOrSo5Rewards.flatMap(so5RewardOrConfig =>
    isType(so5RewardOrConfig, 'So5RewardConfig')
      ? so5RewardOrConfig.rewardConfigs
      : so5RewardOrConfig.rewards.map(reward => reward.config)
  );

  return rewardConfigs.map((rewardConfig, i) => (
    <ClickableAnyRewardConfig
      // eslint-disable-next-line react/no-array-index-key
      key={i}
      anyRewardConfig={rewardConfig}
      rarity={so5Lineup.so5Leaderboard?.mainRarityType || undefined}
      variant={variant}
      so5League={so5Lineup.so5Leaderboard?.so5League}
    />
  ));
};

So5RankingRewards.fragments = {
  so5Ranking: gql`
    fragment So5RankingRewards_so5Ranking on So5Ranking {
      id
      eligibleOrSo5Rewards {
        ... on So5RewardConfig {
          score
          ranks
          rankPct
          rewardConfigs {
            id
            ...ClickableAnyRewardConfig_anyRewardConfigInterface
          }
        }
        ... on So5Reward {
          slug
          rewards {
            id
            config {
              id
              ...ClickableAnyRewardConfig_anyRewardConfigInterface
            }
          }
        }
      }
      so5Lineup {
        id
        so5Leaderboard {
          slug
          mainRarityType
          so5League {
            slug
            ...ClickableAnyRewardConfig_so5League
          }
        }
      }
    }
    ${ClickableAnyRewardConfig.fragments.anyRewardConfigInterface}
    ${ClickableAnyRewardConfig.fragments.so5League}
  ` as TypedDocumentNode<So5RankingRewards_so5Ranking>,
};
