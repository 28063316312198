import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import styled from 'styled-components';

import { So5RuleValidationState } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { BodyM, BodyS } from '@sorare/core/src/atoms/typography';
import { Color } from '@sorare/core/src/style/types';

import { FormatRule } from 'components/so5/Rules/types';

const StyledBodyM = styled(BodyM)`
  .minified & {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const colorPerState: Record<So5RuleValidationState, Color> = {
  [So5RuleValidationState.VALID]: 'var(--c-rivals-win)',
  [So5RuleValidationState.INVALID]: 'var(--c-yellow-400)',
  [So5RuleValidationState.VALIDATABLE]: 'var(--c-nd-600)',
};

type Props = {
  rule: FormatRule;
  minified?: boolean;
};

export const ComposeRule = ({ rule, minified }: Props) => {
  return (
    <Vertical key={rule.id} gap={0.5} className={classNames({ minified })}>
      <SBHorizontal>
        <Horizontal>
          {rule.icon}
          <StyledBodyM as="p" bold>
            {rule.label}
          </StyledBodyM>
        </Horizontal>
        {rule.validationState?.state && (
          <FontAwesomeIcon
            icon={
              rule.validationState.state === So5RuleValidationState.INVALID
                ? faExclamationCircle
                : faCheckCircle
            }
            color={colorPerState[rule.validationState.state]}
          />
        )}
      </SBHorizontal>
      {!minified && rule.description && (
        <BodyS as="p" color="var(--c-nd-600">
          {rule.description}
        </BodyS>
      )}
      {!minified &&
        rule.validationState?.state === So5RuleValidationState.INVALID &&
        rule.validationState.message && (
          <BodyS as="p" color="var(--c-yellow-400)">
            {rule.validationState.message}
          </BodyS>
        )}
    </Vertical>
  );
};
