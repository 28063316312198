import { forwardRef } from 'react';
import { useKey } from 'react-use';
import styled, { css } from 'styled-components';

import { fadeIn } from 'atoms/animations/keyframes';
import { theme } from 'style/theme';

const BackdropWrapper = styled.div<{ blur?: boolean }>`
  position: fixed;
  z-index: ${theme.zIndex.backdrop};
  inset: 0;
  background: rgba(var(--c-rgb-black), 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.25s ease-in-out forwards;
  ${({ blur }) =>
    blur
      ? css`
          backdrop-filter: blur(4px);
        `
      : ''};
`;

type Props = {
  onClick?: () => void;
  blur?: boolean;
  children?: React.ReactNode;
  className?: string;
};

export const Backdrop = forwardRef<HTMLDivElement, Props>(
  ({ onClick, blur = false, children, className }, ref) => {
    useKey('Escape', onClick);

    return (
      <BackdropWrapper
        blur={blur}
        onClick={onClick}
        as={onClick ? 'button' : 'div'}
        className={className}
        ref={ref}
      >
        {children}
      </BackdropWrapper>
    );
  }
);

Backdrop.displayName = 'Backdrop';
