import { Fragment, ReactNode } from 'react';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { Vertical } from 'atoms/layout/flex';
import { BodyM, BodyS } from 'atoms/typography';
import { Country } from 'components/seo/Player/Infos/Country';
import { PlayerAvatar } from 'components/seo/PlayerAvatar';
import { Row } from 'components/seo/ui';

const StyledBodyS = styled(BodyS)`
  display: flex;
  gap: var(--half-unit);
`;
const OverflowHiddenVertical = styled(Vertical)`
  overflow: hidden;
`;
const EllipsisBodyM = styled(BodyM)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export type Props<T extends string> = {
  stats: T[];
  player: {
    slug: string;
    displayName: string;
    pictureUrl: string | null;
    age: number;
    to: string;
    country: {
      code: string;
      threeLetterCode: string | null;
    };
    stats: Record<T, ReactNode>;
  };
};

export const SquadPlayer = <T extends string>({ player, stats }: Props<T>) => {
  return (
    <Row
      style={{
        gridTemplateColumns: `auto 1fr repeat(${stats.length}, calc(6 * var(--unit)))`,
      }}
    >
      <Link to={player.to}>
        <PlayerAvatar src={player.pictureUrl} alt={player.displayName} />
      </Link>
      <OverflowHiddenVertical gap={0}>
        <EllipsisBodyM as="p">
          <Link to={player.to}> {player.displayName}</Link>
        </EllipsisBodyM>
        <StyledBodyS as="p" color="var(--c-nd-600)">
          <span>{player.age}</span>
          •
          <Country size={1.5} {...player.country} />
        </StyledBodyS>
      </OverflowHiddenVertical>
      {stats.map(stat => (
        <Fragment key={stat}>
          {['string', 'number'].includes(typeof player.stats[stat]) ? (
            <BodyM as="p">{player.stats[stat]}</BodyM>
          ) : (
            player.stats[stat]
          )}
        </Fragment>
      ))}
    </Row>
  );
};
