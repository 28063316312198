import { ParamParseKey, generatePath, matchPath } from 'react-router-dom';

import { Sport } from '__generated__/globalTypes';
import {
  ANY_SPORT_CLUB_SHOP,
  ANY_SPORT_MARKET_SECONDARY,
  ANY_SPORT_PLAYERS_SLUG,
  ANY_SPORT_PLAYERS_SLUG_CARDS,
  ANY_SPORT_PLAY_INVENTORY,
  ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
  ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE_SEARCH,
  ANY_SPORT_REWARDS_ESSENCE_QUALITY_RARITY,
  ANY_SPORT_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY,
  ANY_SPORT_TEAMS_SLUG,
  FOOTBALL_CLUBS_SLUG,
  FOOTBALL_CLUB_SHOP,
  FOOTBALL_MARKET_INSTANT_BUY,
  FOOTBALL_MARKET_MANAGER_SALES,
  FOOTBALL_MARKET_NEW_SIGNINGS,
  FOOTBALL_PLAY,
  FOOTBALL_PLAYERS_SLUG,
  FOOTBALL_PLAYERS_SLUG_CARDS,
  FOOTBALL_PLAY_INVENTORY,
  FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
  FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE_SEARCH,
  FOOTBALL_PLAY_PRO_COMPOSE_LEADERBOARD,
  FOOTBALL_PLAY_PRO_COMPOSE_LEADERBOARD_LINEUP_LINEUP,
  FOOTBALL_PLAY_PRO_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM,
  FOOTBALL_PLAY_PRO_FIXTURE,
  FOOTBALL_PLAY_PRO_FIXTURE_COMPETITION_CONTENDER_CONTENDER,
  FOOTBALL_PLAY_PRO_FIXTURE_ENTER,
  FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION,
  FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK,
  FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES,
  FOOTBALL_PLAY_PRO_FIXTURE_MATCH_CENTER,
  FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL,
  FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER,
  FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS,
  FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER,
  FOOTBALL_PLAY_PRO_LINEUPS_ID,
  FOOTBALL_PLAY_PRO_MY_LINEUPS_ID,
  FOOTBALL_PLAY_REWARDS_FIXTURE,
  FOOTBALL_PLAY_RIVALS,
  FOOTBALL_REWARDS_ESSENCE_QUALITY_RARITY,
  FOOTBALL_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY,
  FOOTBALL_SCOUTING_CENTER_LEADERBOARDGROUP,
  MLB_CLUB_SHOP,
  MLB_CLUB_SHOP_MY_ITEMS,
  MLB_MARKET,
  MLB_MARKET_INSTANT_BUY,
  MLB_MARKET_PRIMARY,
  MLB_MARKET_SECONDARY,
  MLB_PLAY,
  MLB_PLAYERS_SLUG,
  MLB_PLAYERS_SLUG_CARDS,
  MLB_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD,
  MLB_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_LINEUP_LINEUP,
  MLB_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM,
  MLB_PLAY_EVENTTYPE_FIXTURE,
  MLB_PLAY_EVENTTYPE_FIXTURE_COMPETITION_CONTENDER_CONTENDER,
  MLB_PLAY_EVENTTYPE_FIXTURE_ENTER,
  MLB_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION,
  MLB_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK,
  MLB_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES,
  MLB_PLAY_EVENTTYPE_FIXTURE_GAME_CENTER,
  MLB_PLAY_EVENTTYPE_FIXTURE_REWARDS,
  MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL,
  MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER,
  MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS,
  MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER,
  MLB_PLAY_EVENTTYPE_LINEUPS_ID,
  MLB_PLAY_PRO_FIXTURE_CHASE_USERGROUP_USER,
  MLB_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY,
  MLB_TEAMS_SLUG,
  NBA_MARKET_INSTANT_BUY,
  NBA_MARKET_SECONDARY,
  NBA_PLAY,
  NBA_PLAYERS_SLUG,
  NBA_PLAYERS_SLUG_CARDS,
  NBA_PLAY_COMMON_MARKET_ADD_CARD,
  NBA_PLAY_COMMON_MARKET_DROP_CARD,
  NBA_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD,
  NBA_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_LINEUP_LINEUP,
  NBA_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM,
  NBA_PLAY_EVENTTYPE_FIXTURE,
  NBA_PLAY_EVENTTYPE_FIXTURE_COMPETITION_CONTENDER_CONTENDER,
  NBA_PLAY_EVENTTYPE_FIXTURE_ENTER,
  NBA_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION,
  NBA_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK,
  NBA_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES,
  NBA_PLAY_EVENTTYPE_FIXTURE_GAME_CENTER,
  NBA_PLAY_EVENTTYPE_FIXTURE_REWARDS,
  NBA_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL,
  NBA_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER,
  NBA_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS,
  NBA_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER,
  NBA_PLAY_EVENTTYPE_LINEUPS_ID,
  NBA_PLAY_INVENTORY,
  NBA_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
  NBA_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE_SEARCH,
  NBA_REWARDS_ESSENCE_QUALITY_RARITY,
  NBA_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY,
  NBA_SCOUTING_CENTER,
  NBA_TEAMS_SLUG,
} from 'constants/__generated__/routes';
import {
  ANY_SPORT_CLUB_SHOP_INVENTORY,
  ANY_SPORT_COMMON_MARKET_ADD_CARD,
  ANY_SPORT_COMMON_MARKET_DROP_CARD,
  ANY_SPORT_DRAFT,
  ANY_SPORT_MARKET_INSTANT_BUY,
  ANY_SPORT_MARKET_NEW_SIGNINGS,
  ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD,
  ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_LINEUP_LINEUP,
  ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM,
  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE,
  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_COMPETITION_CONTENDER_CONTENDER,
  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER,
  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION,
  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK,
  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES,
  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_MATCH_CENTER,
  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_REWARDS,
  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_USERGROUP_USER,
  ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL,
  ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER,
  ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS,
  ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER,
  ANY_SPORT_PLAY_EVENTTYPE_LINEUPS_ID,
  ANY_SPORT_PLAY_EVENTTYPE_MY_LINEUPS_LINEUP,
  ANY_SPORT_SCOUTING_CENTER_LEADERBOARDGROUP,
  FOOTBALL_PATH,
  MLB_PATH,
  NBA_PATH,
  NBA_PRIMARY_MARKET,
} from 'constants/routes';

const appsPaths = {
  [Sport.FOOTBALL]: FOOTBALL_PATH,
  [Sport.BASEBALL]: MLB_PATH,
  [Sport.NBA]: NBA_PATH,
};

/**
 * Rewrite paths that are different between sports
 * should be temporary and removed when paths are cross-sport consistent
 */
const UNMATCHED_CROSS_SPORT_PATHS = {
  [ANY_SPORT_MARKET_NEW_SIGNINGS]: {
    [Sport.FOOTBALL]: FOOTBALL_MARKET_NEW_SIGNINGS,
    [Sport.BASEBALL]: MLB_MARKET_PRIMARY,
    [Sport.NBA]: NBA_PRIMARY_MARKET,
  },
  [ANY_SPORT_MARKET_INSTANT_BUY]: {
    [Sport.FOOTBALL]: FOOTBALL_MARKET_INSTANT_BUY,
    [Sport.BASEBALL]: MLB_MARKET_INSTANT_BUY,
    [Sport.NBA]: NBA_MARKET_INSTANT_BUY,
  },
  [ANY_SPORT_MARKET_SECONDARY]: {
    [Sport.FOOTBALL]: FOOTBALL_MARKET_MANAGER_SALES,
    [Sport.BASEBALL]: MLB_MARKET_SECONDARY,
    [Sport.NBA]: NBA_MARKET_SECONDARY,
  },
  [ANY_SPORT_DRAFT]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_RIVALS,
    [Sport.BASEBALL]: MLB_PLAY,
    [Sport.NBA]: NBA_PLAY,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_FIXTURE]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_PRO_FIXTURE,
    [Sport.BASEBALL]: MLB_PLAY_EVENTTYPE_FIXTURE,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_FIXTURE,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_PRO_FIXTURE_ENTER,
    [Sport.BASEBALL]: MLB_PLAY_EVENTTYPE_FIXTURE_ENTER,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_FIXTURE_ENTER,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION,
    [Sport.BASEBALL]: MLB_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK]: {
    [Sport.FOOTBALL]:
      FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK,
    [Sport.BASEBALL]:
      MLB_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES]: {
    [Sport.FOOTBALL]:
      FOOTBALL_PLAY_PRO_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES,
    [Sport.BASEBALL]:
      MLB_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES,
    [Sport.NBA]:
      NBA_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK_GAMES,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_MY_LINEUPS_LINEUP]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_PRO_MY_LINEUPS_ID,
    [Sport.BASEBALL]: MLB_PLAY_EVENTTYPE_LINEUPS_ID,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_LINEUPS_ID,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_USERGROUP_USER]: {
    // TODO: PROF-255 => Update when peyton urls on NBA
    [Sport.NBA]: NBA_PLAY,
    [Sport.FOOTBALL]: FOOTBALL_PLAY,
    [Sport.BASEBALL]: MLB_PLAY_PRO_FIXTURE_CHASE_USERGROUP_USER,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_COMPETITION_CONTENDER_CONTENDER]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_PRO_FIXTURE_COMPETITION_CONTENDER_CONTENDER,
    [Sport.BASEBALL]:
      MLB_PLAY_EVENTTYPE_FIXTURE_COMPETITION_CONTENDER_CONTENDER,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_FIXTURE_COMPETITION_CONTENDER_CONTENDER,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL,
    [Sport.BASEBALL]: MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER]: {
    [Sport.FOOTBALL]:
      FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER,
    [Sport.BASEBALL]:
      MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER,
    [Sport.NBA]:
      NBA_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL_CONTENDER,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS,
    [Sport.BASEBALL]: MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER]: {
    [Sport.FOOTBALL]:
      FOOTBALL_PLAY_PRO_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER,
    [Sport.BASEBALL]:
      MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_RANKINGS_CONTENDER,
  },
  [ANY_SPORT_PLAYERS_SLUG]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAYERS_SLUG,
    [Sport.BASEBALL]: MLB_PLAYERS_SLUG,
    [Sport.NBA]: NBA_PLAYERS_SLUG,
  },
  [ANY_SPORT_PLAYERS_SLUG_CARDS]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAYERS_SLUG_CARDS,
    [Sport.BASEBALL]: MLB_PLAYERS_SLUG_CARDS,
    [Sport.NBA]: NBA_PLAYERS_SLUG_CARDS,
  },
  [ANY_SPORT_TEAMS_SLUG]: {
    [Sport.FOOTBALL]: FOOTBALL_CLUBS_SLUG,
    [Sport.BASEBALL]: MLB_TEAMS_SLUG,
    [Sport.NBA]: NBA_TEAMS_SLUG,
  },
  [ANY_SPORT_CLUB_SHOP]: {
    [Sport.NBA]: NBA_PLAY,
    [Sport.BASEBALL]: MLB_CLUB_SHOP,
    [Sport.FOOTBALL]: FOOTBALL_CLUB_SHOP,
  },
  [ANY_SPORT_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY]: {
    [Sport.FOOTBALL]: FOOTBALL_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY,
    [Sport.BASEBALL]: MLB_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY,
    [Sport.NBA]: NBA_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY,
  },
  [ANY_SPORT_REWARDS_ESSENCE_QUALITY_RARITY]: {
    [Sport.FOOTBALL]: FOOTBALL_REWARDS_ESSENCE_QUALITY_RARITY,
    [Sport.BASEBALL]: MLB_PLAY,
    [Sport.NBA]: NBA_REWARDS_ESSENCE_QUALITY_RARITY,
  },
  [ANY_SPORT_CLUB_SHOP_INVENTORY]: {
    [Sport.FOOTBALL]: generatePath(FOOTBALL_CLUB_SHOP, { tab: 'inventory' }),
    [Sport.BASEBALL]: MLB_CLUB_SHOP_MY_ITEMS,
    [Sport.NBA]: NBA_PLAY,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_LINEUPS_ID]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_PRO_LINEUPS_ID,
    [Sport.BASEBALL]: MLB_PLAY_EVENTTYPE_LINEUPS_ID,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_LINEUPS_ID,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_REWARDS]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_REWARDS_FIXTURE,
    [Sport.BASEBALL]: MLB_PLAY_EVENTTYPE_FIXTURE_REWARDS,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_FIXTURE_REWARDS,
  },
  [ANY_SPORT_PLAY_INVENTORY]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_INVENTORY,
    [Sport.NBA]: NBA_PLAY_INVENTORY,
    // TODO: US-377 => Update when in season lobby urls on MLB
    [Sport.BASEBALL]: MLB_PLAY,
  },
  [ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
    [Sport.NBA]: NBA_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
    // TODO: US-377 => Update when in season lobby urls on MLB
    [Sport.BASEBALL]: MLB_PLAY,
  },
  [ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE_SEARCH]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE_SEARCH,
    [Sport.NBA]: NBA_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE_SEARCH,
    // TODO: US-377 => Update when in season lobby urls on MLB
    [Sport.BASEBALL]: MLB_PLAY,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_MATCH_CENTER]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_PRO_FIXTURE_MATCH_CENTER,
    [Sport.BASEBALL]: MLB_PLAY_EVENTTYPE_FIXTURE_GAME_CENTER,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_FIXTURE_GAME_CENTER,
  },
  [ANY_SPORT_COMMON_MARKET_DROP_CARD]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY,
    [Sport.BASEBALL]: MLB_PLAY,
    [Sport.NBA]: NBA_PLAY_COMMON_MARKET_DROP_CARD,
  },
  [ANY_SPORT_COMMON_MARKET_ADD_CARD]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY,
    [Sport.BASEBALL]: MLB_PLAY,
    [Sport.NBA]: NBA_PLAY_COMMON_MARKET_ADD_CARD,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_PRO_COMPOSE_LEADERBOARD,
    [Sport.BASEBALL]: MLB_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_LINEUP_LINEUP]: {
    [Sport.FOOTBALL]: FOOTBALL_PLAY_PRO_COMPOSE_LEADERBOARD_LINEUP_LINEUP,
    [Sport.BASEBALL]: MLB_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_LINEUP_LINEUP,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_LINEUP_LINEUP,
  },
  [ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM]: {
    [Sport.FOOTBALL]:
      FOOTBALL_PLAY_PRO_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM,
    [Sport.BASEBALL]:
      MLB_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM,
    [Sport.NBA]: NBA_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM,
  },
  [ANY_SPORT_SCOUTING_CENTER_LEADERBOARDGROUP]: {
    [Sport.FOOTBALL]: FOOTBALL_SCOUTING_CENTER_LEADERBOARDGROUP,
    [Sport.BASEBALL]: MLB_MARKET,
    [Sport.NBA]: NBA_SCOUTING_CENTER,
  },
} as const;

type UnmachedCrossSportPaths = typeof UNMATCHED_CROSS_SPORT_PATHS;
type UnmachedCrossSportPath<Path extends keyof UnmachedCrossSportPaths> =
  UnmachedCrossSportPaths[Path];
type ParseParamKeysFromUnmatchedPaths<Entry> = Entry extends {
  [Sport.FOOTBALL]: infer A extends string;
  [Sport.BASEBALL]: infer B extends string;
  [Sport.NBA]: infer C extends string;
}
  ? ParamParseKey<A | B | C>
  : never;

export type PathParams<Path extends string> =
  Path extends keyof typeof UNMATCHED_CROSS_SPORT_PATHS
    ? {
        [key in ParseParamKeysFromUnmatchedPaths<
          UnmachedCrossSportPath<Path>
        >]: string;
      }
    : {
        [key in ParamParseKey<Path>]: string;
      };

const isUnmatchedCrossSportPath = (
  path: string
): path is keyof typeof UNMATCHED_CROSS_SPORT_PATHS =>
  Object.keys(UNMATCHED_CROSS_SPORT_PATHS).includes(path);

export const generateSportPath = <Path extends string>(
  path: Path,
  {
    params,
    sport,
  }: {
    params?: PathParams<Path>;
    sport?: Sport;
  }
) => {
  if (Object.values(appsPaths).some(appPath => path.startsWith(appPath))) {
    return generatePath(path, params);
  }
  if (isUnmatchedCrossSportPath(path)) {
    return generatePath(
      UNMATCHED_CROSS_SPORT_PATHS[path][sport || Sport.FOOTBALL],
      params
    );
  }
  return generatePath(
    `${sport ? appsPaths[sport] : ''}${path as string}`,
    params
  );
};

export const matchAnySportPath = (
  pattern: string,
  pathname: string,
  sport?: Sport
) => {
  if (isUnmatchedCrossSportPath(pattern) && sport) {
    return matchPath(UNMATCHED_CROSS_SPORT_PATHS[pattern][sport], pathname);
  }

  return matchPath(
    pattern,
    Object.values(appsPaths).reduce(
      (acc, path) => acc.replace(new RegExp(path, 'g'), ''),
      pathname
    )
  );
};
