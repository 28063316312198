import { ReactNode, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Sport } from '__generated__/globalTypes';
import useLocalStorage, { STORAGE } from 'hooks/useLocalStorage';
import {
  PathParams,
  generateSportPath,
  matchAnySportPath,
} from 'lib/routing/generateSportPath';
import { sportFromPath } from 'lib/routing/sportFromPath';

import SportContextProvider from '.';

interface Props {
  children: ReactNode;
  sport?: Sport;
}

const SportProvider = ({ children, sport: defaultSport }: Props) => {
  const { pathname } = useLocation();
  const [sportFromStorage] = useLocalStorage(STORAGE.sport, undefined);
  const [sport, setSport] = useState<Sport>(
    defaultSport || sportFromPath(pathname, sportFromStorage)
  );

  const value = useMemo(
    () => ({
      sport,
      setSport,
      // wrap the `generateSportPath` to handle the default sport
      generateSportContextPath: <Path extends string>(
        path: Path,
        {
          params,
          sport: s = sport,
        }: {
          params?: PathParams<Path>;
          sport?: Sport;
        } = {}
      ) => generateSportPath(path, { params, sport: s }),
      // eslint-disable-next-line @typescript-eslint/no-shadow
      matchAnySportPath: (pattern: string, pathname: string, s?: Sport) =>
        matchAnySportPath(pattern, pathname, s || sport),
    }),
    [sport]
  );

  return <SportContextProvider value={value}>{children}</SportContextProvider>;
};

export default SportProvider;
