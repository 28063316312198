import { TypedDocumentNode, gql } from '@apollo/client';
import { faInfoCircle, faWarning } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { DivisionChange } from '@sorare/core/src/__generated__/globalTypes';
import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { Dropdown } from '@sorare/core/src/atoms/dropdowns/Dropdown';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LinkOther } from '@sorare/core/src/atoms/navigation/Box';
import { BodyS } from '@sorare/core/src/atoms/typography';
import { Ellipsis } from '@sorare/core/src/atoms/typography/Ellipsis';
import TimeLeft from '@sorare/core/src/contexts/ticker/TimeLeft';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { fantasy } from '@sorare/core/src/lib/glossary';

import { LineupRewardsDistances } from 'components/lineup/LineupRewardsDistances';
import { So5RankingRewards } from 'components/ranking/So5RankingRewards';
import { Variant } from 'components/rewards/RewardOverview/types';
import { hasGamesStarted, isFixtureOpened } from 'lib/so5';

import { LiveLineupFooter_so5LeaderboardContender } from './__generated__/index.graphql';

const messages = defineMessages({
  scoreToTarget: {
    id: 'Lineup.LiveLineupFooter.scoreToTarget',
    defaultMessage: '<b>{score}</b> to target',
  },
  scoreToClosestReward: {
    id: 'Lineup.LiveLineupFooter.scoreToClosestReward',
    defaultMessage: '<b>{score}</b> to closest reward',
  },
});

const StyledBold = styled.b`
  color: var(--c-white);
`;

type Props = {
  so5LeaderboardContender: LiveLineupFooter_so5LeaderboardContender;
};

export const LiveLineupFooter = ({ so5LeaderboardContender }: Props) => {
  const { so5Leaderboard, so5Lineup } = so5LeaderboardContender;
  const isUpcoming = isFixtureOpened(so5Leaderboard.so5Fixture);
  if (isUpcoming || !so5Lineup) {
    return null;
  }

  const ranking = so5Lineup.so5Rankings.at(0);
  const hasLineupGamesStarted = hasGamesStarted(so5Lineup);

  const lineupDistances = ranking && (
    <Dropdown
      triggerOnHover
      horizontalGap={-8}
      label={props => (
        <IconButton
          {...props}
          icon={faInfoCircle}
          color="transparent"
          smaller
        />
      )}
    >
      {({ closeDropdown }) => (
        <LineupRewardsDistances
          so5RankingId={idFromObject(ranking.id)}
          onClose={closeDropdown}
        />
      )}
    </Dropdown>
  );

  let content: ReactNode = null;
  if (so5Lineup.cancelledAt) {
    content = (
      <>
        <FontAwesomeIcon icon={faWarning} color="var(--c-yellow-400)" />
        <Ellipsis>
          <BodyS>
            <FormattedMessage
              id="Lineup.LiveLineupFooter.cancelledDueTo"
              defaultMessage="Cancelled due to card listing or transfer"
            />
          </BodyS>
        </Ellipsis>
      </>
    );
  } else if (!hasLineupGamesStarted) {
    content = (
      <>
        {lineupDistances}
        <BodyS color="var(--c-nd-600)">
          <FormattedMessage
            id="Lineup.LiveLineupFooter.timeLeftToNextMatch"
            defaultMessage="<b>{timeLeft}</b> to next match"
            values={{
              b: (chunks: ReactNode[]) => {
                return <StyledBold>{chunks}</StyledBold>;
              },
              timeLeft: <TimeLeft time={so5Lineup.anyEarliestGame!.date} />,
            }}
          />
        </BodyS>
      </>
    );
  } else if (
    ranking?.eligibleDivisionChange === DivisionChange.RELEGATED &&
    so5Leaderboard.divisionChangeConfiguration?.lastStableSo5Ranking
  ) {
    content = (
      <>
        {lineupDistances}
        <BodyS color="var(--c-nd-600)">
          <FormattedMessage
            id="Lineup.LiveLineupFooter.scoreToSafety"
            defaultMessage="<b>{score}</b> to safety"
            values={{
              b: (chunks: ReactNode[]) => {
                return <StyledBold>{chunks}</StyledBold>;
              },
              score: (
                <FormattedMessage
                  {...fantasy.pointsWithoutFormat}
                  values={{
                    b: StyledBold,
                    score: Math.ceil(
                      so5Leaderboard.divisionChangeConfiguration
                        .lastStableSo5Ranking.score - ranking.score
                    ),
                  }}
                />
              ),
            }}
          />
        </BodyS>
      </>
    );
  } else if (
    ranking?.eligibleOrSo5Rewards &&
    ranking.eligibleOrSo5Rewards.length > 0
  ) {
    content = (
      <>
        {lineupDistances}
        <LinkOther as="div">
          <Horizontal gap={0.5}>
            <So5RankingRewards variant={Variant.INLINE} so5Ranking={ranking} />
          </Horizontal>
        </LinkOther>
      </>
    );
  } else if (ranking?.nextEligibleRewards) {
    const target = ranking.nextEligibleRewards.eligibleRewards.find(
      rewardConfig => rewardConfig.target
    )?.target;

    content = (
      <>
        {target ? (
          <img src={target.pictureUrl} alt="" width="12" height="12" />
        ) : (
          lineupDistances
        )}
        <BodyS color="var(--c-nd-600)">
          <FormattedMessage
            {...(target
              ? messages.scoreToTarget
              : messages.scoreToClosestReward)}
            values={{
              b: (chunks: ReactNode[]) => {
                return <StyledBold>{chunks}</StyledBold>;
              },
              score: (
                <FormattedMessage
                  {...fantasy.pointsWithoutFormat}
                  values={{
                    score: Math.ceil(
                      ranking.nextEligibleRewards.score - ranking.score
                    ),
                  }}
                />
              ),
            }}
          />
        </BodyS>
      </>
    );
  }

  return <Horizontal>{content}</Horizontal>;
};

LiveLineupFooter.fragments = {
  so5LeaderboardContender: gql`
    fragment LiveLineupFooter_so5LeaderboardContender on So5LeaderboardContender {
      slug
      so5Leaderboard {
        slug
        divisionChangeConfiguration {
          id
          lastStableSo5Ranking {
            id
            score
          }
        }
        so5Fixture {
          slug
          ...isFixtureOpened_so5Fixture
        }
      }
      so5Lineup {
        id
        cancelledAt
        so5Rankings {
          id
          score
          eligibleDivisionChange
          nextEligibleRewards {
            score
            eligibleRewards {
              target {
                slug
                pictureUrl
              }
            }
          }
          eligibleOrSo5Rewards {
            ... on So5RewardConfig {
              ranks
            }
            ... on So5Reward {
              slug
            }
          }
          ...So5RankingRewards_so5Ranking
        }
        anyEarliestGame {
          id
          date
        }
        ...hasGamesStarted_so5Lineup
      }
    }
    ${isFixtureOpened.fragments.so5Fixture}
    ${hasGamesStarted.fragments.so5Lineup}
    ${So5RankingRewards.fragments.so5Ranking}
  ` as TypedDocumentNode<LiveLineupFooter_so5LeaderboardContender>,
};
