import { TypedDocumentNode, gql } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { useConfigContext } from '@sorare/core/src/contexts/config';
import { useRedirectToAfterSignIn } from '@sorare/core/src/contexts/currentUser/RedirectAfterSignIn';
import { currentUser as currentUserFragment } from '@sorare/core/src/contexts/currentUser/queries';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';
import useQueryString from '@sorare/core/src/hooks/useQueryString';

import {
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables,
} from './__generated__/index.graphql';

const confirmEmailMutation = gql`
  mutation ConfirmEmailMutation($input: confirmEmailInput!, $sport: Sport!) {
    confirmEmail(input: $input) {
      currentUser {
        slug
        email
        ...CurrentUserProvider_currentUser
      }
      errors {
        message
        code
      }
    }
  }
  ${currentUserFragment}
` as TypedDocumentNode<ConfirmEmailMutation, ConfirmEmailMutationVariables>;

export const ConfirmEmail = () => {
  const { updateQuery } = useConfigContext();
  const navigate = useNavigate();
  const token = useQueryString('token');
  const { sport } = useSportContext();
  const [confirm, { loading, called }] = useMutation(confirmEmailMutation, {
    showErrorsWithSnackNotification: true,
  });
  const getRedirectTo = useRedirectToAfterSignIn();

  useEffect(() => {
    if (!loading && token && !called) {
      confirm({
        variables: { input: { token }, sport },
      }).then(({ data }) => {
        const user = data?.confirmEmail?.currentUser;
        if (user) {
          updateQuery(user);
        }
        navigate(getRedirectTo(user), { replace: true });
      });
    }
  }, [
    loading,
    called,
    confirm,
    token,
    sport,
    updateQuery,
    getRedirectTo,
    navigate,
  ]);

  return <LoadingIndicator fullScreen />;
};

export default ConfirmEmail;
