import { TypedDocumentNode, gql } from '@apollo/client';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { fantasy, glossary, rivals } from 'lib/glossary';
import { isType } from 'lib/gql';
import { COSMETICS_TYPES } from 'types/shopItems';

import { useProbabilisticBundleConfigSummary_ProbabilisticBundleConfig } from './__generated__/useProbabilisticBundleConfigSummary.graphql';

type Reward =
  | 'card'
  | 'cardShards'
  | 'conversionCredit'
  | 'deliverable'
  | 'shopItem'
  | 'arenaTicket';

type RewardObject = {
  odd: number;
  upTo?: boolean;
  name: string;
  type: Reward;
};

type CardReward = RewardObject & {
  type: 'card';
};
type CardShardsReward = RewardObject & {
  type: 'cardShards';
};
type ConversionCreditReward = RewardObject & {
  type: 'conversionCredit';
};
type DeliverableReward = RewardObject & {
  type: 'deliverable';
  deliverableTypename:
    | 'ExperienceDeliverableItem'
    | 'JerseyDeliverableItem'
    | 'TicketsDeliverableItem'
    | 'WearableDeliverableItem';
  signed: boolean;
};
type ShopItemReward = RewardObject & {
  type: 'shopItem';
};
type ArenaTicketReward = RewardObject & {
  type: 'arenaTicket';
};

export type PossibleReward =
  | CardReward
  | CardShardsReward
  | ConversionCreditReward
  | DeliverableReward
  | ShopItemReward
  | ArenaTicketReward;

export const mergePossibleRewards = (
  a: PossibleReward[],
  b: PossibleReward[]
) => {
  const result: PossibleReward[] = [...a];
  b.forEach(reward => {
    const matchingReward = result.find(r => reward.name === r.name);
    if (matchingReward) {
      if (reward.odd !== matchingReward.odd) {
        matchingReward.upTo = true;
        matchingReward.odd = Math.max(reward.odd, matchingReward.odd);
      }
    } else {
      result.push(reward);
    }
  });
  return result;
};

// summing odds can fail otherwise, cf 0.07+ 0.05
const sumOdds = (a: number, b: number) => (a * 100 + b * 100) / 100;

export const useProbabilisticBundleConfigSummary = (
  boxConfig: useProbabilisticBundleConfigSummary_ProbabilisticBundleConfig
): PossibleReward[] => {
  const { formatMessage } = useIntl();
  const { slots } = boxConfig;

  const result = useMemo(() => {
    const summary: Record<string, PossibleReward> = {};
    slots.forEach(({ probableConfigs }) =>
      probableConfigs.forEach(
        ({
          arenaTickets,
          cards,
          rewardShopItems,
          deliverableItems,
          conversionCredit,
          cardShards,
          probability,
        }) => {
          if (cards.length) {
            const name = formatMessage(glossary.cards);
            if (summary[name] === undefined) {
              summary[name] = {
                odd: probability,
                type: 'card',
                name,
              };
            } else {
              summary[name].odd = sumOdds(summary[name].odd ?? 0, probability);
            }
          }
          if (arenaTickets > 0) {
            const name = formatMessage(rivals.arenaTickets);
            if (summary[name] === undefined) {
              summary[name] = {
                odd: probability,
                type: 'arenaTicket',
                name,
              };
            } else {
              summary[name].odd = sumOdds(summary[name].odd ?? 0, probability);
            }
          }
          deliverableItems.forEach(({ deliverableItem }) => {
            const { name } = deliverableItem;
            if (summary[name] === undefined) {
              summary[name] = {
                odd: probability,
                type: 'deliverable',
                signed:
                  isType(deliverableItem, 'JerseyDeliverableItem') &&
                  deliverableItem.signed,
                deliverableTypename: deliverableItem.__typename,
                name,
              };
            } else {
              summary[name].odd = sumOdds(summary[name].odd ?? 0, probability);
            }
          });
          if (conversionCredit) {
            const name = formatMessage(fantasy.credits);
            if (summary[name] === undefined) {
              summary[name] = {
                odd: probability,
                type: 'conversionCredit',
                name,
              };
            } else {
              summary[name].odd = sumOdds(summary[name].odd ?? 0, probability);
            }
          }
          rewardShopItems.forEach(({ shopItem }) => {
            const name = COSMETICS_TYPES.includes(
              shopItem.position as (typeof COSMETICS_TYPES)[number]
            )
              ? formatMessage({
                  id: 'useProbabilisticBundleConfigSummary.cosmetic',
                  defaultMessage: 'cosmetics',
                })
              : shopItem.name;

            if (summary[name] === undefined) {
              summary[name] = {
                odd: probability,
                type: 'shopItem',
                name,
              };
            } else {
              summary[name].odd = sumOdds(summary[name].odd ?? 0, probability);
            }
          });
          if (cardShards.length) {
            const name = formatMessage(fantasy.essenceShort);
            if (summary[name] === undefined) {
              summary[name] = {
                odd: probability,
                type: 'cardShards',
                name,
              };
            } else {
              summary[name].odd = sumOdds(summary[name].odd ?? 0, probability);
            }
          }
        }
      )
    );
    return summary;
  }, [formatMessage, slots]);

  return (
    Object.keys(result)
      .map(name => ({ ...result[name], name }))
      // sort by less likely to most likely
      .sort((a, b) => a.odd - b.odd)
  );
};

useProbabilisticBundleConfigSummary.fragments = {
  ProbabilisticBundleConfig: gql`
    fragment useProbabilisticBundleConfigSummary_ProbabilisticBundleConfig on ProbabilisticBundleConfig {
      id
      slots {
        probableConfigs {
          probability
          arenaTickets
          deliverableItems {
            quantity
            deliverableItem {
              slug
              name
              ... on JerseyDeliverableItem {
                slug
                signed
              }
            }
          }
          cards {
            quality
          }
          rewardShopItems {
            quantity
            shopItem {
              id
              position
              name
            }
          }
          conversionCredit {
            id
            maxDiscount {
              usd
            }
            percentageDiscount
          }
          cardShards {
            id
            quantity
          }
        }
      }
    }
  ` as TypedDocumentNode<useProbabilisticBundleConfigSummary_ProbabilisticBundleConfig>,
};
