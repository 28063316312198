import { TypedDocumentNode, gql } from '@apollo/client';

import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import { BoxOverviewDialog } from '../BoxOverviewDialog';
import {
  LazyBoxOverviewDialogQuery,
  LazyBoxOverviewDialogQueryVariables,
  LazyBoxOverviewDialog_so5League,
} from './__generated__/index.graphql';

const LAZY_BOX_OVERVIEW_DIALOG_QUERY = gql`
  query LazyBoxOverviewDialogQuery($boxConfigId: ID!) {
    probabilisticBundleConfig(id: $boxConfigId) {
      id
      ...BoxOverviewDialog_probabilisticBundleConfig
    }
  }
  ${BoxOverviewDialog.fragments.probabilisticBundleConfig}
` as TypedDocumentNode<
  LazyBoxOverviewDialogQuery,
  LazyBoxOverviewDialogQueryVariables
>;

type Props = {
  boxConfigId: string;
  so5League?: LazyBoxOverviewDialog_so5League;
  onClose: () => void;
  open: boolean;
};

export const LazyBoxOverviewDialog = ({
  boxConfigId,
  so5League,
  open,
  onClose,
}: Props) => {
  const { data } = useQuery(LAZY_BOX_OVERVIEW_DIALOG_QUERY, {
    variables: { boxConfigId },
    skip: !open,
  });

  const boxConfig = data?.probabilisticBundleConfig;
  if (!boxConfig) {
    return null;
  }

  return (
    <BoxOverviewDialog
      open={open}
      onClose={onClose}
      boxConfig={boxConfig}
      so5League={so5League}
    />
  );
};

LazyBoxOverviewDialog.fragments = {
  so5League: gql`
    fragment LazyBoxOverviewDialog_so5League on So5League {
      slug
      ...BoxOverviewDialog_so5League
    }
    ${BoxOverviewDialog.fragments.so5League}
  ` as TypedDocumentNode<LazyBoxOverviewDialog_so5League>,
};
