import { TypedDocumentNode, gql } from '@apollo/client';

import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useGenerateDialogPath } from '@sorare/core/src/hooks/navigation/useGenerateDialogPath';

import { DumbNotification } from 'components/activity/DumbNotification';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { AnnouncementNotification_announcementNotification } from './__generated__/index.graphql';

type Props = CommonNotificationProps & {
  notification: AnnouncementNotification_announcementNotification;
};

export const AnnouncementNotification = ({ notification, ...rest }: Props) => {
  const {
    createdAt,
    name,
    announcement: { id, title, url },
    sport,
    read,
  } = notification;
  const generateDialogPath = useGenerateDialogPath();

  return (
    <DumbNotification
      name={name}
      title={title}
      link={url || generateDialogPath(DialogKey.news, idFromObject(id))}
      createdAt={createdAt}
      sport={sport}
      read={read}
      {...rest}
    />
  );
};

AnnouncementNotification.fragments = {
  announcementNotification: gql`
    fragment AnnouncementNotification_announcementNotification on AnnouncementNotification {
      id
      ...Notification_notificationInterface
      announcement {
        id
        title
        url
      }
    }
    ${commonNotificationInterfaceFragment}
  ` as TypedDocumentNode<AnnouncementNotification_announcementNotification>,
};
