import { LOCALE_STORAGE_KEY } from 'constants/intl';
import useLocalStorage from 'hooks/useLocalStorage';
import {
  ALLOWED_LOCALES,
  AllowedLocales,
  PREFIXES,
  getPrefix,
  matchPrefix,
} from 'i18n/useTranslations';
import detectBrowserLanguage from 'lib/detectBrowserLanguage';

const DEFAULT_LOCALE: AllowedLocales = 'en-US';
const getAllowedLocales = (locale: string | null) =>
  ALLOWED_LOCALES.includes(locale || '') ? (locale as AllowedLocales) : null;

/**
 * Long term, this should rely on a cookie value instead of localstorage and
 * be moved to the frontend-worker.
 *
 * This redirects to the active language
 * according to the following algo:
 *
 * 1. If user preference is set use that
 * 2. If the locale is set use that
 * 3. Fallback to first browser locale that is supported
 * 4. Fallback to english
 */
export const useBasenameLocale = () => {
  // eslint-disable-next-line no-restricted-properties
  const { pathname, search, hash } = window.location;

  const [savedLocale, setLocale] = useLocalStorage<string | null>(
    LOCALE_STORAGE_KEY,
    null
  );
  const safeSavedLocale = getAllowedLocales(savedLocale);

  let localeFromUrl: string | null = null;
  const prefix = pathname.split('/')[1];
  const basename = `/${prefix}`;
  let safeBasename = '';
  if (prefix.length && matchPrefix(basename)) {
    localeFromUrl = PREFIXES[basename];
    safeBasename = basename;
  }
  let hasSearchParam = false;
  if (!localeFromUrl) {
    localeFromUrl = new URLSearchParams(search).get('lang');
    hasSearchParam = !!localeFromUrl;
  }
  const safeLocaleFromUrl = getAllowedLocales(localeFromUrl);

  const browserLocale = detectBrowserLanguage();
  const safeBrowserLocale = getAllowedLocales(browserLocale);

  const safeLocale =
    safeSavedLocale || safeLocaleFromUrl || safeBrowserLocale || DEFAULT_LOCALE;

  const safePrefix = getPrefix(safeLocale);

  if (safeBasename !== safePrefix || hasSearchParam) {
    let newSearch = search;
    if (hasSearchParam) {
      const searchParams = new URLSearchParams(search);
      searchParams.delete('lang');
      newSearch = `?${searchParams.toString()}`;
    }

    let newPathname = pathname;
    if (safeBasename !== safePrefix) {
      newPathname = pathname.replace(safeBasename, getPrefix(safeLocale));
    }

    // eslint-disable-next-line no-restricted-properties
    window.history.replaceState(null, '', `${newPathname}${newSearch}${hash}`);
  }

  return {
    locale: safeLocale,
    setLocale,
    basename: safePrefix,
  };
};
