import { unitMapping } from 'lib/style';

type Props = {
  className?: string;
  size?: keyof typeof unitMapping;
  title?: string;
};

export const MLBBall = ({ className, size = 2, title }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill="currentColor"
    className={className}
    style={{
      width: unitMapping[size],
      height: unitMapping[size],
    }}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M1.10261 10.2789C0.817174 8.52283 1.11283 6.66096 2.07177 5.00002C3.03071 3.3391 4.4953 2.15212 6.15882 1.52127C6.07798 2.91203 5.71859 4.68354 4.66983 6.50005C3.62108 8.31653 2.26662 9.51352 1.10261 10.2789Z"
      fill="currentColor"
    />
    <path
      d="M9.8232 16.9581C8.51662 17.0944 7.16689 16.9109 5.89695 16.3746C5.5928 16.2462 5.29323 16.0975 4.99993 15.9282C3.738 15.1996 2.74966 14.1792 2.07095 13.0013C2.07053 13.0006 2.07012 12.9999 2.06972 12.9991C1.93253 12.7609 1.80801 12.5162 1.69645 12.2662C1.73741 12.2418 1.77866 12.2169 1.82017 12.1916C3.26452 11.3103 5.04964 9.8422 6.40188 7.50004C7.75413 5.15789 8.13299 2.87787 8.17402 1.1864C8.1752 1.13776 8.1761 1.08959 8.17674 1.04191C8.63465 0.994163 9.09786 0.985688 9.56109 1.01791C9.56296 1.01802 9.56484 1.01814 9.56672 1.01827C10.7399 1.10071 11.9129 1.4442 12.9999 2.07178C13.2932 2.24112 13.5718 2.42623 13.8351 2.62542C13.9555 2.7165 14.0727 2.81052 14.1866 2.90733C15.1135 3.69449 15.8268 4.66562 16.3035 5.7339C16.2625 5.75829 16.2213 5.78314 16.1798 5.80848C14.7354 6.68975 12.9503 8.15786 11.5981 10.5C10.2458 12.8422 9.86695 15.1222 9.82592 16.8137C9.82474 16.8623 9.82384 16.9105 9.8232 16.9581Z"
      fill="currentColor"
    />
    <path
      d="M11.8411 16.4788C13.5047 15.8479 14.9692 14.661 15.9282 13C16.8871 11.3391 17.1828 9.47723 16.8973 7.72115C15.7333 8.48654 14.3789 9.68353 13.3301 11.5C12.2814 13.3165 11.922 15.088 11.8411 16.4788Z"
      fill="currentColor"
    />
  </svg>
);
