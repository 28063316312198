import { TypedDocumentNode, gql } from '@apollo/client';

import { So5LeaderboardType } from '@sorare/core/src/__generated__/globalTypes';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import {
  useIsUserProEligibleQuery,
  useIsUserProEligibleQueryVariables,
} from './__generated__/useIsUserProEligible.graphql';
import { useIsUserDailyPickupEligible } from './useIsUserDailyPickupEligible';

const query = gql`
  query useIsUserProEligibleQuery {
    currentUser {
      slug
      id
      nbaUserProfile {
        id
        tournamentEligibilities {
          eligible
          so5LeaderboardType
        }
      }
    }
  }
` as TypedDocumentNode<
  useIsUserProEligibleQuery,
  useIsUserProEligibleQueryVariables
>;

export const useIsUserProEligible = () => {
  const { data, loading } = useQuery(query);
  const { isDailyPickupEligible } = useIsUserDailyPickupEligible();

  const nbaUserProfile = data?.currentUser?.nbaUserProfile;

  const isProTipoffEligible = nbaUserProfile?.tournamentEligibilities.some(
    eligiblity =>
      eligiblity.so5LeaderboardType === So5LeaderboardType.NBA_LIMITED_TIP_OF &&
      eligiblity.eligible
  );

  const isProEligible =
    isProTipoffEligible || (!isDailyPickupEligible && !isProTipoffEligible);

  return { isProEligible, loading };
};
