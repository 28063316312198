import { Outlet } from 'react-router-dom';

import { NotFound, getRoutesFromFiles } from '@sorare/routing';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import SportSwitcher from '@sorare/core/src/contexts/sport/SportSwitcher';
import { lazy } from '@sorare/core/src/lib/retry';

/*
 * Prefer dynamic over static import into this file
 * because static ones will end up in the main bundle
 */
const MLBMarketSubscriptions = lazy(
  async () => import('routing/MLBMarketSubscriptions'),
  'MLBMarketSubscriptions'
);

export const routeObjects = [
  {
    element: (
      <>
        <SportSwitcher sport={Sport.BASEBALL} />
        <MLBMarketSubscriptions />
        <Outlet />
      </>
    ),
    children: [
      ...getRoutesFromFiles(
        import.meta.glob('./**/(page|layout).tsx'),
        '../../packages/baseball/src/pages'
      ),
      { path: '*', element: <NotFound /> },
    ],
  },
];
