import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  // eslint-disable-next-line no-restricted-imports
  useQuery as apolloUseQuery,
} from '@apollo/client';
import { DocumentNode, IntrospectionQuery, buildClientSchema } from 'graphql';
import { print } from 'graphql/language/printer';

import { IS_TEST_RUNNER } from 'config';
import { useWalletContext } from 'contexts/wallet';
import useLogOut from 'hooks/auth/useLogOut';

// Outside local dev environment, queries are optimized and transformed to stored operations
// Meaning the query used in production are very different from the ones used in development
// Preprocessing the query before sending it to the server allows us to use the same query in both environments
let usePreprocessedQuery = (query: TypedDocumentNode<any, any>) => query;

if (process.env.NODE_ENV === 'development' && !IS_TEST_RUNNER) {
  // HACK https://github.com/dotansimha/graphql-code-generator/discussions/8784
  process.hrtime = process.hrtime || (() => [0, 0]);
  import('@graphql-tools/relay-operation-optimizer').then(
    ({ optimizeDocuments }) => {
      // HACK use import.meta.glob to avoid loading this file in typescript
      import.meta
        .glob<Record<'default', unknown>>(
          '../../../federation-graphql-schema.json',
          { eager: false }
        )
        ['../../../federation-graphql-schema.json']()
        .then(({ default: schemaJson }) => {
          const schema = schemaJson as unknown as IntrospectionQuery;
          const clientSchema = buildClientSchema(schema);
          const optimizedQueries: Record<string, DocumentNode> = {};

          usePreprocessedQuery = (query: TypedDocumentNode) => {
            const s = print(query);

            if (!optimizedQueries[s]) {
              // eslint-disable-next-line prefer-destructuring
              optimizedQueries[s] = optimizeDocuments(clientSchema, [query])[0];
            }

            return optimizedQueries[s];
          };
        });
    }
  );
}

let logoutTimeout: ReturnType<typeof setTimeout> | undefined;

interface AdditionalOptions {
  logOutOnErrorCode?: number;
  skipCacheAndNetworkLoadingOverride?: boolean;
}

export const useQuery = <TData, TVariables extends OperationVariables>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
  {
    logOutOnErrorCode,
    skipCacheAndNetworkLoadingOverride,
  }: AdditionalOptions = {
    logOutOnErrorCode: 422,
  }
): QueryResult<TData, TVariables> => {
  const applogOut = useLogOut();

  const q = usePreprocessedQuery(query);

  const apolloOptions: QueryHookOptions<TData, TVariables> = {
    fetchPolicy: 'cache-and-network',
    ...options,
  };

  // Work-around the pollInterval to keep firing even when skip is set
  // https://github.com/apollographql/react-apollo/issues/2127#issuecomment-607844722
  if (apolloOptions.skip && apolloOptions.pollInterval) {
    apolloOptions.pollInterval = 0;
  }

  const res = apolloUseQuery(q, apolloOptions);

  // When using `cache-and-network` policy, `loading` will be `true`
  // even in case of cache hit.
  // This ensure that should `loading` be set, nothing is in the cache.
  // Hack inspired from https://github.com/apollographql/apollo-client/issues/8669
  if (
    apolloOptions.fetchPolicy === 'cache-and-network' &&
    res.loading &&
    res.data &&
    !skipCacheAndNetworkLoadingOverride
  ) {
    res.loading = false;
  }

  const walletCtx = useWalletContext();

  const logOut = () => {
    (async () => {
      try {
        if (walletCtx) {
          await walletCtx.logOut();
        } else {
          await applogOut();
        }
      } finally {
        logoutTimeout = undefined;
      }
    })();
  };

  if (res.error) {
    if (res.error.networkError) {
      if ((res.error.networkError as any).statusCode === logOutOnErrorCode) {
        // Allow only one logout at a time to avoid infinite loop
        if (!logoutTimeout) {
          // logout asynchronously to avoid running into "Store reset while query was in flight" error
          logoutTimeout = setTimeout(logOut, 1000);
        }

        return res;
      }
      if (!(res.error.networkError as any).statusCode) {
        throw res.error;
      }
      throw res.error.networkError;
    }
  }

  return res;
};
