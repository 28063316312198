import { unitMapping } from 'lib/style';

type Props = {
  size?: keyof typeof unitMapping;
  className?: string;
};

export const BasketballCenter = ({ size = 2, className }: Props) => (
  <svg
    className={className}
    style={{ width: unitMapping[size], height: unitMapping[size] }}
    viewBox="0 0 215 217"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M109.473 60.0501L74.3846 77.5943V112.364C74.3846 124.522 80.6783 135.814 91.0189 142.208L109.473 153.619L127.927 142.208C138.268 135.814 144.561 124.522 144.561 112.364V77.5943L109.473 60.0501Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M191.343 41.1253L109.47 0.188965L27.5974 41.1253V116.264C27.5974 145.645 42.8071 172.933 67.797 188.386L109.47 214.155L151.143 188.386C176.133 172.933 191.343 145.645 191.343 116.264V41.1253ZM50.9896 116.264V55.5825L109.47 26.3422L167.951 55.5825V116.264C167.951 137.54 156.937 157.3 138.841 168.49L109.47 186.652L80.0996 168.49C62.0035 157.3 50.9896 137.54 50.9896 116.264Z"
      fill="white"
    />
  </svg>
);
