import { useIntl } from 'react-intl';

import { CardIcon } from 'components/stats/CardIcon';
import { Stats, statLabels } from 'lib/scoring';
import { unitMapping } from 'lib/style';

import goal_assist from './assets/assist.svg';
import clean_sheet from './assets/clean_sheet.svg';
import clean_sheet_60 from './assets/clean_sheet_gk.svg';
import clearance_off_line from './assets/clearance_off_the_line.svg';
import error_lead_to_goal from './assets/error_leading_to_goal.svg';
import goals from './assets/goal.svg';
import goals_conceded from './assets/goal_conceded.svg';
import last_man_tackle from './assets/last_man_tackle.svg';
import mins_played from './assets/mins_played.svg';
import penalty_conceded from './assets/penalty_conceded.svg';
import penalty_save from './assets/penalty_saved.svg';
import assist_penalty_won from './assets/penalty_scored.svg';
import set_piece_taken from './assets/set_piece_taken.svg';
import three_goals_conceded from './assets/three_goals_conceded.svg';

const IMAGES: Record<Exclude<Stats, 'red_card' | 'yellow_card'>, string> = {
  penalty_save,
  penalty_conceded,
  own_goals: goals_conceded,
  three_goals_conceded,
  goals,
  assist_penalty_won,
  clean_sheet_60,
  goal_assist,
  clearance_off_line,
  error_lead_to_goal,
  last_man_tackle,
  set_piece_taken,
  mins_played,
  clean_sheet,
};

type Props = {
  statKey: Stats;
  className?: string;
  size?: keyof typeof unitMapping;
};
export const StatIcon = ({ statKey, className, size = 2 }: Props) => {
  const { formatMessage } = useIntl();

  if (!(statKey in statLabels)) {
    return null;
  }

  const title = formatMessage(statLabels[statKey as keyof typeof statLabels]);

  if (statKey === 'yellow_card') {
    return <CardIcon className={className} color="yellow" size={size} />;
  }
  if (statKey === 'red_card') {
    return <CardIcon className={className} color="red" size={size} />;
  }

  const image = IMAGES[statKey];

  if (!image) {
    return null;
  }

  return (
    <img
      className={className}
      width={unitMapping[size]}
      height={unitMapping[size]}
      title={title}
      src={image}
      alt=""
    />
  );
};
