import { useEffect } from 'react';

import { Sport } from '__generated__/globalTypes';
import useLocalStorage, { STORAGE } from 'hooks/useLocalStorage';

import { useSportContext } from '.';

type Props = {
  sport: Sport;
};

export const SportSwitcher = ({ sport }: Props) => {
  const { sport: currentSport, setSport } = useSportContext();
  const [, setSportStorage] = useLocalStorage<Sport | undefined>(
    STORAGE.sport,
    undefined
  );
  useEffect(() => {
    if (sport !== currentSport) {
      setSport(sport);
      setSportStorage(sport);
    }
  });

  return null;
};

export default SportSwitcher;
