import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { DivisionChange } from '@sorare/core/src/__generated__/globalTypes';
import { Timer } from '@sorare/core/src/atoms/icons/Timer';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelL } from '@sorare/core/src/atoms/typography';
import { fantasy } from '@sorare/core/src/lib/glossary';

import { hasGamesStarted } from 'lib/so5';

import { DumbStatus } from './DumbStatus';
import { Status_so5Ranking } from './__generated__/index.graphql';
import { messages } from './messages';

const IconContainer = styled(Vertical).attrs({ center: true })`
  width: var(--triple-unit);
  height: var(--triple-unit);
`;

type Props = {
  ranking: Nullable<Status_so5Ranking>;
  withLabel?: boolean;
  showNeutral?: boolean;
  projectedStatus?: boolean;
};

export const Status = ({
  ranking,
  withLabel,
  showNeutral,
  projectedStatus,
}: Props) => {
  const status = ranking?.eligibleDivisionChange;
  const hasStarted = ranking ? hasGamesStarted(ranking.so5Lineup) : true;
  const { formatMessage } = useIntl();

  if (!status) {
    return null;
  }

  const statusMessage = projectedStatus
    ? messages.projectedToDiv
    : messages[status];

  const projectedDivisionDelta = status === DivisionChange.PROMOTED ? -1 : +1;
  const projectedDivision =
    (ranking?.so5Leaderboard.division || 0) +
    (status === DivisionChange.STABLE ? 0 : projectedDivisionDelta);

  return (
    <Horizontal gap={0.5}>
      {withLabel && (
        <LabelL as="p" bold>
          {status ? (
            <FormattedMessage
              {...(hasStarted ? statusMessage : fantasy.viewSchedule)}
              values={{ division: projectedDivision }}
            />
          ) : (
            <>&nbsp;</>
          )}
        </LabelL>
      )}
      {status && (
        <>
          {hasStarted ? (
            (projectedStatus ||
              showNeutral ||
              status !== DivisionChange.STABLE) && (
              <IconContainer>
                <DumbStatus
                  title={formatMessage(statusMessage)}
                  divisionChange={status}
                />
              </IconContainer>
            )
          ) : (
            <IconContainer>
              <Timer size={2.5} fill="var(--c-brand-300)" />
            </IconContainer>
          )}
        </>
      )}
    </Horizontal>
  );
};

Status.fragments = {
  so5Ranking: gql`
    fragment Status_so5Ranking on So5Ranking {
      id
      eligibleDivisionChange
      so5Lineup {
        id
        ...hasGamesStarted_so5Lineup
      }
      so5Leaderboard {
        slug
        division
      }
    }

    ${hasGamesStarted.fragments.so5Lineup}
  ` as TypedDocumentNode<Status_so5Ranking>,
};
