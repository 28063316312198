import { TypedDocumentNode, gql } from '@apollo/client';

import { useWalletContext } from 'contexts/wallet';
import { EthMigrationError } from 'errors';
import useMutation from 'hooks/graphql/useMutation';
import useBankWithdrawal from 'hooks/users/useBankWithdrawal';
import { generateDealId } from 'lib/deal';

import {
  CreateEthMigrationMutation,
  CreateEthMigrationMutationVariables,
} from './__generated__/useCreateEthMigration.graphql';

const CREATE_ETH_MIGRATION_MUTATION = gql`
  mutation CreateEthMigrationMutation($input: createEthMigrationInput!) {
    createEthMigration(input: $input) {
      currentUser {
        id
        slug
        ethMigration {
          id
        }
      }
      errors {
        path
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  CreateEthMigrationMutation,
  CreateEthMigrationMutationVariables
>;

export const useCreateEthMigration = () => {
  const { signEthMigration } = useWalletContext();
  const [mutate] = useMutation(CREATE_ETH_MIGRATION_MUTATION);
  const { bankBalance, availableBalance, ethMigration } = useBankWithdrawal();

  return async () => {
    if (!bankBalance || !availableBalance) return;
    const amount =
      bankBalance > availableBalance ? availableBalance : bankBalance;

    if (ethMigration) return;
    if (amount === 0n) return;

    const nonce = generateDealId();
    const signature = await signEthMigration(nonce, amount);
    if (!signature) {
      throw new EthMigrationError('unable to fetch migration signature');
    }
    await mutate({
      variables: { input: { nonce, weiAmount: amount, signature } },
    });
  };
};
