import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import useLocalStorage, {
  STORAGE,
} from '@sorare/core/src/hooks/useLocalStorage';

export type LineupStyle = 'inline' | 'spread';

export const usePreferredLineupStyle = (): [
  LineupStyle,
  (value: LineupStyle) => void,
] => {
  const { sport } = useSportContext();

  const [lineupStyle, setLineupStyle] = useLocalStorage<
    Record<Sport, LineupStyle>
  >(STORAGE.sportLineupStyle, {
    [Sport.FOOTBALL]: 'spread',
    [Sport.NBA]: 'spread',
    [Sport.BASEBALL]: 'spread',
  });

  const setSportLineupStyle = (style: LineupStyle) => {
    setLineupStyle({ ...lineupStyle, [sport]: style });
  };

  return [lineupStyle[sport], setSportLineupStyle];
};
