import { unitMapping } from 'lib/style';

type Props = {
  size?: keyof typeof unitMapping;
  className?: string;
  hollow?: boolean;
};

export const Shoe = ({ size = 2, className, hollow }: Props) => {
  return (
    <svg
      className={className}
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.419511 8.12026L2.95873 1.59961L4.15286 2.06462L5.91209 4.86449L8.15244 4.2514L8.14992 4.24263L9.0327 3.96489L11.7521 5.02385L11.6635 5.04838L13.4883 7.68522L11.6401 8.39509L12.2635 10.018L14.5064 9.15648L16.4701 11.9941L15.6665 14.0576L13.855 13.3522L13.5652 14.0966L12.0646 14.7561L11.4051 13.2555L11.695 12.5111L7.26754 10.787L6.97765 11.5314L5.47708 12.1908L4.81765 10.6903L5.10754 9.94583L4.02699 9.52505L3.73703 10.2697L2.23646 10.9291L1.57702 9.42853L1.86699 8.68392L0.419511 8.12026Z"
        fill={hollow ? 'url(#shoe-hollow-gradient)' : 'currentColor'}
      />
      <defs>
        <linearGradient
          id="shoe-hollow-gradient"
          x1="0.5"
          y1="7.75"
          x2="9.22771"
          y2="6.81853"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8D3DA" />
          <stop offset="0.283178" stopColor="#B3A9F4" />
          <stop offset="0.5401" stopColor="#FBE9FB" />
          <stop offset="1" stopColor="#4F94FD" />
        </linearGradient>
      </defs>
    </svg>
  );
};
