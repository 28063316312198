import { TypedDocumentNode, gql } from '@apollo/client';
import { useMemo } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { TokenOfferRejectionReason } from '@sorare/core/src/__generated__/globalTypes';
import { Bold } from '@sorare/core/src/atoms/typography/Bold';
import {
  MY_SORARE_OFFERS_RECEIVED_ID,
  MY_SORARE_OFFERS_SENT_ID,
} from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { isType } from '@sorare/core/src/lib/gql';

import { DumbNotification } from 'components/activity/DumbNotification';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { OfferNotification_offerNotification } from './__generated__/index.graphql';

type Props = CommonNotificationProps & {
  notification: OfferNotification_offerNotification;
};

type OfferNotification_tokenOffer_User = (
  | OfferNotification_offerNotification['tokenOffer']['receiver']
  | OfferNotification_offerNotification['tokenOffer']['sender']
) & {
  __typename: 'User';
};

const messages = defineMessages({
  offer_received: {
    id: 'Activity.Notifications.offerReceived',
    defaultMessage: 'You received an offer from <b>{nickname}</b>',
  },
  offer_accepted: {
    id: 'Activity.Notifications.offerAccepted',
    defaultMessage: '<b>{nickname}</b> has accepted your offer',
  },
  offer_rejected: {
    id: 'Activity.Notifications.offerRejected',
    defaultMessage: '<b>{nickname}</b> has rejected your offer',
  },
  offer_cancelled: {
    id: 'Activity.Notifications.offerCancelled',
    defaultMessage:
      'Your offer has been canceled: <b>{nickname}</b> accepted another offer',
  },
  offer_withdrawn: {
    id: 'Activity.Notifications.offerWithdrawn',
    defaultMessage: 'Offer from <b>{nickname}</b> was cancelled',
  },
  offer_countered: {
    id: 'Activity.Notifications.offerCountered',
    defaultMessage: '<b>{nickname}</b> has sent you a counter offer',
  },
});

const rejectedOfferWithReasonMessages = defineMessages({
  [TokenOfferRejectionReason.OFFER_TOO_LOW]: {
    id: 'rejectedOfferWithReasonMessages.OFFER_TOO_LOW',
    defaultMessage:
      '<b>{nickname}</b> has rejected your offer because it’s too low',
  },
  [TokenOfferRejectionReason.NOT_SELLING]: {
    id: 'rejectedOfferWithReasonMessages.NOT_SELLING',
    defaultMessage:
      '<b>{nickname}</b> doesn’t want to sell and rejected your offer',
  },
  [TokenOfferRejectionReason.ONLY_CASH]: {
    id: 'rejectedOfferWithReasonMessages.ONLY_CASH',
    defaultMessage:
      '<b>{nickname}</b> only wants money and rejected your offer.',
  },
  [TokenOfferRejectionReason.ADD_CASH]: {
    id: 'rejectedOfferWithReasonMessages.ADD_CASH',
    defaultMessage:
      '<b>{nickname}</b> has rejected your offer because they want you to add more money.',
  },
  [TokenOfferRejectionReason.IN_A_LINEUP]: {
    id: 'rejectedOfferWithReasonMessages.IN_A_LINEUP',
    defaultMessage:
      '<b>{nickname}</b> has rejected your offer because they currently play the card in a lineup.',
  },
  [TokenOfferRejectionReason.CARD_NOT_WANTED]: {
    id: 'rejectedOfferWithReasonMessages.CARD_NOT_WANTED',
    defaultMessage:
      '<b>{nickname}</b> rejected your offer because they do not want to swap your card(s).',
  },
});

export const OfferNotification = ({ notification, ...rest }: Props) => {
  const { isMe } = useCurrentUserContext();

  const { name, createdAt, sport, tokenOffer, read } = notification;

  const link = useMemo(() => {
    if (isType(tokenOffer.sender, 'User') && isMe(tokenOffer.sender)) {
      return generatePath(MY_SORARE_OFFERS_SENT_ID, {
        id: idFromObject(tokenOffer.id),
      });
    }
    return generatePath(MY_SORARE_OFFERS_RECEIVED_ID, {
      id: idFromObject(tokenOffer.id),
    });
  }, [isMe, tokenOffer]);

  let user;
  switch (name) {
    case 'offer_accepted':
    case 'offer_rejected':
    case 'offer_cancelled':
      user = tokenOffer.receiver as OfferNotification_tokenOffer_User;
      break;
    case 'offer_received':
    case 'offer_countered':
    case 'offer_withdrawn':
      user = tokenOffer.sender as OfferNotification_tokenOffer_User;
      break;
    default:
  }

  const title =
    (name === 'offer_rejected' &&
      tokenOffer?.rejectionReason &&
      rejectedOfferWithReasonMessages[tokenOffer.rejectionReason]) ||
    messages[name as keyof typeof messages];

  return (
    <DumbNotification
      name={name}
      title={
        <>
          {title && (
            <FormattedMessage
              {...title}
              values={{ b: Bold, nickname: user?.nickname || '' }}
            />
          )}
        </>
      }
      userAvatar={user}
      link={link}
      createdAt={createdAt}
      sport={sport}
      read={read}
      {...rest}
    />
  );
};

OfferNotification.fragments = {
  offerNotification: gql`
    fragment OfferNotification_offerNotification on OfferNotification {
      id
      ...Notification_notificationInterface
      sport
      tokenOffer {
        id
        status
        sender {
          ... on User {
            slug
            nickname
            ...DumbNotification_userAvatar
          }
        }
        rejectionReason
        receiver {
          ... on User {
            slug
            nickname
            ...DumbNotification_userAvatar
          }
        }
      }
    }
    ${commonNotificationInterfaceFragment}
    ${DumbNotification.fragments.avatarUser}
  ` as TypedDocumentNode<OfferNotification_offerNotification>,
};
