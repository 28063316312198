import { Outlet } from 'react-router-dom';

/*
 * Prefer dynamic over static import into this file
 * because static ones will end up in the main bundle
 */
import { NotFound, getRoutesFromFiles } from '@sorare/routing';

export const routeObjects = [
  {
    element: <Outlet />,
    children: [
      ...getRoutesFromFiles(
        import.meta.glob('./**/(page|layout).tsx'),
        '../../packages/blog/src/pages'
      ),
      { path: '*', element: <NotFound /> },
    ],
  },
];
