import { unitMapping } from '@sorare/core/src/lib/style';
import { HexColor } from '@sorare/core/src/style/types';

export const TargetIcon = ({
  color,
  size = 2,
}: {
  color: HexColor;
  size?: keyof typeof unitMapping;
}) => {
  return (
    <svg
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 11.8a4.8 4.8 0 100-9.6 4.8 4.8 0 000 9.6zm0-1.9a2.9 2.9 0 100-5.8 2.9 2.9 0 000 5.8z"
        />
        <path d="M7.801 3.8V.6h-1.6v3.2h1.6zM.6 6.201h3.2v1.6H.6v-1.6zM13.4 6.201h-3.2v1.6h3.2v-1.6zM7.801 13.4v-3.2h-1.6v3.2h1.6z" />
      </g>
    </svg>
  );
};
