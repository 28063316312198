import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';

import { Errors, getErrorFrom } from 'components/so5/Rules/formatRules';
import { FormatRule } from 'components/so5/Rules/types';

import { ComposeRule } from './ComposeRule';
import { ScoringMatrixRules } from './ScoringMatrixRules';
import type { ComposeRules_so5RuleFeedback } from './__generated__/index.graphql';
import { ComposeRules_so5Leaderboard } from './__generated__/index.graphql';

const Icon = styled.img`
  object-fit: contain;
`;

type Props = {
  so5Leaderboard: ComposeRules_so5Leaderboard;
  minified?: boolean;
  errors?: Errors;
  rulesFeedbacks?: ComposeRules_so5RuleFeedback[];
};

export const ComposeRules = ({
  so5Leaderboard,
  minified,
  errors = [],
  rulesFeedbacks = [],
}: Props) => {
  const { displayedTypedRules = [] } = so5Leaderboard;
  const normalizedRules: FormatRule[] = displayedTypedRules.flatMap(
    ({ __typename, key, descriptions }) => {
      const ruleFeedback = rulesFeedbacks.find(
        ({ ruleName }) => ruleName === key
      );

      return (
        descriptions?.map((description, i) => ({
          id: `${__typename}-${i}`,
          icon: description.icon && (
            <Icon src={description.icon} alt="" width={16} height={16} />
          ),
          label: description.title,
          description: (
            <Vertical as="span" gap={0}>
              {description.items.map((item, j) => (
                // eslint-disable-next-line react/no-array-index-key
                <Horizontal key={j} as="span">
                  {item.icon && (
                    <Icon src={item.icon} alt="" width={16} height={16} />
                  )}
                  {item.title}
                </Horizontal>
              ))}
            </Vertical>
          ),
          validationState: {
            message:
              getErrorFrom(errors, key) || ruleFeedback?.message || undefined,
            state: ruleFeedback?.state,
          },
        })) || []
      );
    }
  );

  return (
    <Vertical gap={2} className={classNames({ minified })}>
      {normalizedRules.map(rule => (
        <ComposeRule key={rule.id} rule={rule} minified={minified} />
      ))}
      <ScoringMatrixRules so5Leaderboard={so5Leaderboard} minified={minified} />
    </Vertical>
  );
};

ComposeRules.fragments = {
  so5Leaderboard: gql`
    fragment ComposeRules_so5Leaderboard on So5Leaderboard {
      slug
      displayedTypedRules {
        key
        descriptions {
          icon
          title
          items {
            icon
            title
          }
        }
      }
      ...ScoringMatrixRules_so5Leaderboard
    }
    ${ScoringMatrixRules.fragments.so5Leaderboard}
  ` as TypedDocumentNode<ComposeRules_so5Leaderboard>,
  so5RuleFeedback: gql`
    fragment ComposeRules_so5RuleFeedback on So5RuleFeedback {
      ruleName
      state
      message
    }
  ` as TypedDocumentNode<ComposeRules_so5RuleFeedback>,
};
