import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineXL, LabelXS } from '@sorare/core/src/atoms/typography';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { fantasy, glossary } from '@sorare/core/src/lib/glossary';

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  text-align: center;

  hr {
    display: block;
    width: 1px;
    background: var(--c-nd-300);
    content: '';
    border: none;
    margin: 0;
  }
`;
const StyledHorizontal = styled(Horizontal).attrs({ center: true, gap: 0 })`
  & > *:first-child {
    margin-right: var(--half-unit);
  }
`;
const Exposant = styled(LabelXS).attrs({
  bold: true,
  uppercase: true,
})`
  color: var(--c-nd-600);
  margin-top: calc(-1 * var(--unit));
`;
const Sup = styled(LabelXS).attrs({
  bold: true,
})`
  align-self: flex-start;
  margin-top: var(--half-unit);
`;

type Props = {
  showRankingAndScore?: boolean;
  ranking?: { rankingRatio: number | null; ranking: number | null };
  score?: number;
  onClick?: () => void;
  className?: string;
};

export const LineupRankingDumb = ({
  showRankingAndScore,
  ranking,
  score,
  onClick,
  className,
}: Props) => {
  const {
    flags: { useTopRankingRatio = false },
  } = useFeatureFlags();

  return (
    <Root
      className={className}
      onClick={onClick}
      as={onClick ? ButtonBase : undefined}
    >
      {showRankingAndScore && ranking?.rankingRatio && useTopRankingRatio ? (
        <HeadlineXL>
          <StyledHorizontal>
            <FormattedMessage
              id="LineupRanking.top"
              defaultMessage="<label>Top</label>{rankingRatio}<sup>%</sup>"
              values={{
                rankingRatio: ranking.rankingRatio,
                label: c => (
                  <LabelXS bold uppercase color="var(--c-nd-600)">
                    {c}
                  </LabelXS>
                ),
                sup: c => <Sup color="var(--c-nd-600)">{c}</Sup>,
              }}
            />
          </StyledHorizontal>
        </HeadlineXL>
      ) : (
        <HeadlineXL>
          <Horizontal center gap={0.5}>
            {showRankingAndScore && ranking ? (
              <FormattedMessage
                {...glossary.ordinalStyled}
                values={{
                  ordinal: ranking.ranking,
                  sup: c => <Exposant>{c}</Exposant>,
                }}
              />
            ) : (
              <>
                -<Exposant>%</Exposant>
              </>
            )}
          </Horizontal>
        </HeadlineXL>
      )}
      <hr />
      <HeadlineXL>
        <Horizontal center gap={0.5}>
          <FormattedMessage
            {...fantasy.ordinalPoints}
            values={{
              score: showRankingAndScore ? Math.floor(score || 0) : '-',
              sup: c => <Exposant>{c}</Exposant>,
            }}
          />
        </Horizontal>
      </HeadlineXL>
    </Root>
  );
};
