import { useTypedParams } from '@sorare/routing';

import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';

import { Lineups } from 'components/rivals/matches/Lineups';
import { useFetchGameStatus } from 'pages/play/rivals/matches/$date/$slug/useFetchGameStatus';

import { PageParams } from './__generated__/routeParams';

const RivalsMyLineupsGameSlugLineupsPage = () => {
  const { gameSlug } = useTypedParams<PageParams>();

  const { loading, status } = useFetchGameStatus(gameSlug);

  if (loading && !status) {
    return <LoadingIndicator />;
  }

  return <Lineups rivalsGameSlug={gameSlug} />;
};

export default RivalsMyLineupsGameSlugLineupsPage;
