import { TypedDocumentNode, gql } from '@apollo/client';
import { ComponentProps } from 'react';

import { BonusChip } from '../BonusChip';
import { CardBonus_anyCard } from './__generated__/index.graphql';

type Props = {
  card: CardBonus_anyCard;
  withTransferMalus?: boolean;
} & Pick<ComponentProps<typeof BonusChip>, 'size'>;

export const CardBonus = ({ card, withTransferMalus, ...chipProps }: Props) => {
  return (
    <BonusChip
      bonus={Number(card.power)}
      powerBreakdown={card.powerBreakdown}
      transferMalus={
        withTransferMalus ? Number(card.powerMalusAfterTransfer) : 0
      }
      {...chipProps}
    />
  );
};

CardBonus.fragments = {
  anyCard: gql`
    fragment CardBonus_anyCard on AnyCardInterface {
      slug
      power
      powerMalusAfterTransfer
      powerBreakdown {
        ...BonusChip_powerBreakdown
      }
    }
    ${BonusChip.fragments.powerBreakdown}
  ` as TypedDocumentNode<CardBonus_anyCard>,
};

export default CardBonus;
