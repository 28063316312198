import { generatePath } from 'react-router-dom';

import { HELP_ARTICLE, HELP_CATEGORY, HELP_SECTION } from 'constants/routes';
import { Category, Section } from 'hooks/zendesk';

import { slugify } from './slug';

export const sectionPath = (section: Section) =>
  generatePath(HELP_SECTION, {
    sectionId: String(section.id),
    slug: slugify(section.name),
  });

export const articlePath = ({
  id,
  title,
}: {
  id: string | number;
  title: string;
}) =>
  generatePath(HELP_ARTICLE, {
    articleId: String(id),
    slug: slugify(title),
  });

export const categoryPath = (category: Category) =>
  generatePath(HELP_CATEGORY, {
    categoryId: String(category.id),
    slug: slugify(category.name),
  });
