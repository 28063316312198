export const InlineLineupIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5476 9H18.2857C17.9964 9 17.7619 9.22386 17.7619 9.5V14.5C17.7619 14.7761 17.9964 15 18.2857 15H18.5476C18.8369 15 19.0714 14.7761 19.0714 14.5V9.5C19.0714 9.22386 18.8369 9 18.5476 9Z"
        fill="currentColor"
      />
      <path
        d="M15.4047 9H15.1428C14.8535 9 14.619 9.22386 14.619 9.5V14.5C14.619 14.7761 14.8535 15 15.1428 15H15.4047C15.694 15 15.9285 14.7761 15.9285 14.5V9.5C15.9285 9.22386 15.694 9 15.4047 9Z"
        fill="currentColor"
      />
      <path
        d="M12.2619 9H12C11.7107 9 11.4762 9.22386 11.4762 9.5V14.5C11.4762 14.7761 11.7107 15 12 15H12.2619C12.5512 15 12.7857 14.7761 12.7857 14.5V9.5C12.7857 9.22386 12.5512 9 12.2619 9Z"
        fill="currentColor"
      />
      <path
        d="M9.11903 9H8.85712C8.56783 9 8.33331 9.22386 8.33331 9.5V14.5C8.33331 14.7761 8.56783 15 8.85712 15H9.11903C9.40832 15 9.64284 14.7761 9.64284 14.5V9.5C9.64284 9.22386 9.40832 9 9.11903 9Z"
        fill="currentColor"
      />
      <path
        d="M5.97621 9H5.7143C5.42501 9 5.19049 9.22386 5.19049 9.5V14.5C5.19049 14.7761 5.42501 15 5.7143 15H5.97621C6.2655 15 6.50001 14.7761 6.50001 14.5V9.5C6.50001 9.22386 6.2655 9 5.97621 9Z"
        fill="currentColor"
      />
      <path
        d="M18.8095 5.5H5.19047C3.16543 5.5 1.5238 7.067 1.5238 9V15C1.5238 16.933 3.16543 18.5 5.19047 18.5H18.8095C20.8346 18.5 22.4762 16.933 22.4762 15V9C22.4762 7.067 20.8346 5.5 18.8095 5.5Z"
        stroke="currentColor"
      />
    </svg>
  );
};
