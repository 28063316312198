import { TypedDocumentNode, gql } from '@apollo/client';

import {
  CardQuality,
  CustomRewardExperience,
  PaymentCurrency,
  Rarity,
  Rates,
  ShopItemType,
  So5LeaderboardType,
} from '__generated__/globalTypes';
import { FRONTEND_ASSET_HOST } from 'constants/assets';
import { Selection, TreeSchema } from 'routing/Tree';

import type { getAnyRewardConfigRarity_anyRewardConfigInterface } from './__generated__/rewards.graphql';
import {
  getTreeSchemaFromSo5Fixture_so5Fixture,
  getTreeSelection_so5League,
  parseRankingRewards_so5RewardConfig,
  rewardsOverviewToSimpleRewards_rewardsOverview,
  taskRewardsToSimpleRewards_managerTaskRewardInterface,
} from './__generated__/rewards.graphql';
import { scarcityNames } from './cards';
import { isType, withFragments } from './gql';
import MonetaryAmount, { monetaryAmountFragment } from './monetaryAmount';
import { qualityNames } from './players';
import { sortByRarity } from './scarcity';

export const getUnclaimedRewards = (so5Fixture: {
  mySo5Rewards: { aasmState: string }[];
}) => {
  return so5Fixture.mySo5Rewards.filter(r => r.aasmState === 'ready');
};

export type Experiences = { type?: CustomRewardExperience[] };

export type ExperienceType = CustomRewardExperience;

export type RewardExperience = {
  type: CustomRewardExperience;
  description: string;
  count: number;
};

export type TotalRewards = {
  prizePool: number;
  conversionCreditReward?: number;
  prizePoolCurrency: PaymentCurrency;
  cards: Json;
  experiences?: RewardExperience[] | null;
  coins?: number;
};

type CardsPerRarity = {
  [key in Rarity]?: number;
};

export interface CardsInRewards extends CardsPerRarity {
  rarities?: Rarity[];
}

export type CardReward = {
  rarity: Rarity;
  nb: number;
};

export const getCardRewards = (cards: CardsInRewards): CardReward[] => {
  if (!cards) {
    return [];
  }
  const result: CardReward[] = [];

  const raritiesFromKeys = Object.keys(cards).filter(
    k => k !== 'rarities'
  ) as Rarity[];

  const cardRarities = raritiesFromKeys.length
    ? raritiesFromKeys
    : (cards.rarities as Rarity[]);

  if (!cardRarities) {
    return result;
  }

  cardRarities.forEach(rarity => {
    result.push({ rarity, nb: cards[rarity] || 0 });
  });

  return result;
};

interface So5RewardWithCoin {
  __typename: 'So5Reward';
  coinAmount: number;
}

export const mergeCoinRewards = <T extends So5RewardWithCoin>(rewards: T[]) => {
  if (!rewards.length) {
    return [];
  }

  let totalCoinAmount = 0;
  const otherRewards: T[] = [];
  rewards.forEach(r => {
    if (r.coinAmount > 0) {
      totalCoinAmount += r.coinAmount;
    } else {
      otherRewards.push(r);
    }
  });

  const syntheticReward = { ...rewards[0], coinAmount: totalCoinAmount };

  return totalCoinAmount > 0
    ? [...otherRewards, syntheticReward]
    : otherRewards;
};

export const boxIconUrl = (rarity: Rarity) =>
  `${FRONTEND_ASSET_HOST}/football/probabilistic_bundles/rarities/${rarity}/icon.png`;

export const shardsIconUrl = (rarity: Rarity) =>
  `${FRONTEND_ASSET_HOST}/football/shards/${rarity}.png`;

export enum RewardType {
  CARD = 'CARD',
  CASH = 'CASH',
  PROBABILISTIC_BUNDLE = 'PROBABILISTIC_BUNDLE',
  COIN = 'COIN',
  CREDIT = 'CREDIT',
  ARENA_TICKET = 'ARENA_TICKET',
  SHARD = 'SHARD',
  LEVEL_UP = 'LEVEL_UP',
}

export type RewardWithExperiencesType = CustomRewardExperience | RewardType;

type CommonSimpleReward = {
  rarity: Rarity | null;
  quality?: CardQuality | null;
  count: number;
  poolSize?: number;
  sharedPool?: boolean;
  title?: Nullable<string>;
  description?: Nullable<string>;
};

export type BoxSimpleReward = CommonSimpleReward & {
  type: RewardType.PROBABILISTIC_BUNDLE;
  boxConfig?: {
    id: string;
    stars: number | null;
  };
};

type OtherSimpleReward = CommonSimpleReward & {
  type: Exclude<RewardWithExperiencesType, RewardType.PROBABILISTIC_BUNDLE>;
};

export type SimpleReward = BoxSimpleReward | OtherSimpleReward;

type FlattenRewards = {
  cash?: SimpleReward | null;
  coins?: SimpleReward | null;
  credits?: SimpleReward | null;
  cards?: SimpleReward[] | null;
  boxes?: SimpleReward[] | null;
  jerseys?: SimpleReward[] | null;
  experiences?: SimpleReward[] | null;
  merch?: SimpleReward[] | null;
  tickets?: SimpleReward[] | null;
  arenaTickets?: SimpleReward | null;
  shards?: SimpleReward[] | null;
};

export const flattenRewardsToSimpleRewards = (
  flattenRewards: FlattenRewards
): SimpleReward[] => {
  return [
    ...(flattenRewards.experiences || []),
    flattenRewards.cash?.count &&
      flattenRewards.cash?.count >= 3500 &&
      flattenRewards.cash,
    ...(flattenRewards.jerseys || []),
    ...(flattenRewards.tickets || []),
    ...(flattenRewards.cards || []),
    flattenRewards.cash?.count &&
      flattenRewards.cash?.count < 3500 &&
      flattenRewards.cash,
    flattenRewards.credits,
    ...(flattenRewards.merch || []),
    ...(flattenRewards.boxes || []),
    ...(flattenRewards.shards || []),
    flattenRewards.arenaTickets,
    flattenRewards.coins,
  ].filter(Boolean);
};

export const rewardsOverviewToSimpleRewards = withFragments(
  ({
    rewardsOverview,
    rarity,
    onePerCategoryOnly,
  }: {
    rewardsOverview: rewardsOverviewToSimpleRewards_rewardsOverview;
    rarity: Rarity | null;
    onePerCategoryOnly?: boolean;
  }): SimpleReward[] => {
    const cash =
      rewardsOverview.prizePool > 0
        ? {
            type: RewardType.CASH,
            count: rewardsOverview.prizePool,
            rarity,
          }
        : null;

    const cardRewards = getCardRewards(rewardsOverview.cards as CardsInRewards);
    const cards = sortByRarity(
      cardRewards.map(cardReward => ({
        type: RewardType.CARD,
        count: cardReward.nb,
        rarity: cardReward.rarity,
      })),
      cardReward => cardReward.rarity
    ).reverse();

    const boxes = sortByRarity(
      rewardsOverview.probabilisticBundles.rarities.map(
        ({ rarity: boxRarity, count }) => ({
          type: RewardType.PROBABILISTIC_BUNDLE,
          count,
          rarity: boxRarity,
        })
      ),
      pb => pb.rarity
    );

    const jerseys =
      rewardsOverview.deliverableItems.jersey > 0
        ? {
            type: CustomRewardExperience.JERSEY,
            count: rewardsOverview.deliverableItems.jersey,
            rarity,
          }
        : null;

    const experiences =
      rewardsOverview.deliverableItems.experience > 0
        ? {
            type: CustomRewardExperience.EVENT,
            count: rewardsOverview.deliverableItems.experience,
            rarity,
          }
        : null;

    const tickets =
      rewardsOverview.deliverableItems.tickets > 0
        ? {
            type: CustomRewardExperience.TICKET,
            count: rewardsOverview.deliverableItems.tickets,
            rarity,
          }
        : null;

    const merch =
      rewardsOverview.deliverableItems.wearable > 0
        ? {
            type: CustomRewardExperience.MERCH,
            count: rewardsOverview.deliverableItems.wearable,
            rarity,
          }
        : null;

    return flattenRewardsToSimpleRewards({
      cash,
      cards: onePerCategoryOnly ? cards.slice(0, 1) : cards,
      boxes: onePerCategoryOnly ? boxes.slice(0, 1) : boxes,
      jerseys: jerseys ? [jerseys] : null,
      tickets: tickets ? [tickets] : null,
      experiences: experiences ? [experiences] : null,
      merch: merch ? [merch] : null,
    });
  },
  {
    rewardsOverview: gql`
      fragment rewardsOverviewToSimpleRewards_rewardsOverview on RewardsOverview {
        cards
        probabilisticBundles {
          rarities {
            rarity
            count
          }
        }
        prizePool
        deliverableItems {
          jersey
          experience
          tickets
          wearable
        }
      }
    ` as TypedDocumentNode<rewardsOverviewToSimpleRewards_rewardsOverview>,
  }
);

export type WithRewardRange<T> = T & {
  startRank?: number | null;
  startPct?: number | null;
  endRank?: number | null;
  endPct?: number | null;
};

export const parseRankingRewards = withFragments(
  <T extends parseRankingRewards_so5RewardConfig>(
    rewardConfigs: T[]
  ): WithRewardRange<T>[] => {
    if (!rewardConfigs) return [];

    const rangeRewards = rewardConfigs.reduce((sum, cur) => {
      const prev = sum[sum.length - 1] || undefined;

      let rewardRange: WithRewardRange<T>;
      if (!prev) {
        rewardRange = {
          startRank: 1,
          endRank: cur.ranks || null,
          startPct: null,
          endPct: cur.rankPct || null,
          ...cur,
        };
      } else if (cur.ranks) {
        const startRank = prev.endRank ? prev.endRank + 1 : null;
        const endRank = prev.endRank ? prev.endRank + cur.ranks : null;

        rewardRange = {
          startRank,
          endRank,
          startPct: null,
          endPct: null,
          ...cur,
        };
      } else if (cur.rankPct) {
        const startRank = prev.endRank ? prev.endRank + 1 : null;
        const startPct = prev.endPct || null;

        const endPctBasedOnRank = prev.endRank ? cur.rankPct : null;
        const endPct = prev.endPct ? cur.rankPct : endPctBasedOnRank;

        rewardRange = {
          startRank,
          endRank: null,
          startPct,
          endPct,
          ...cur,
        };
      } else {
        return sum;
      }

      sum.push(rewardRange);
      return sum;
    }, [] as WithRewardRange<T>[]);

    return rangeRewards;
  },
  {
    so5RewardConfig: gql`
      fragment parseRankingRewards_so5RewardConfig on So5RewardConfig {
        ranks
        rankPct
      }
    ` as TypedDocumentNode<parseRankingRewards_so5RewardConfig>,
  }
);

export const getAnyRewardConfigRarity = withFragments(
  (
    anyRewardConfig: getAnyRewardConfigRarity_anyRewardConfigInterface
  ): Rarity | null => {
    let rarity = null;
    if (
      isType(anyRewardConfig, 'CardRewardConfig') ||
      isType(anyRewardConfig, 'CardShardRewardConfig')
    ) {
      rarity = anyRewardConfig.rarity;
    } else if (isType(anyRewardConfig, 'ProbabilisticBundleRewardConfig')) {
      rarity = anyRewardConfig.probabilisticBundleConfig.rarity;
    }

    return rarity;
  },
  {
    anyRewardConfigInterface: gql`
      fragment getAnyRewardConfigRarity_anyRewardConfigInterface on AnyRewardConfigInterface {
        id
        ... on CardRewardConfig {
          id
          rarity
        }
        ... on CardShardRewardConfig {
          id
          rarity
        }
        ... on ProbabilisticBundleRewardConfig {
          id
          probabilisticBundleConfig {
            id
            rarity
          }
        }
      }
    ` as TypedDocumentNode<getAnyRewardConfigRarity_anyRewardConfigInterface>,
  }
);

export const getTreeSchemaFromSo5Fixture = withFragments(
  (so5Fixture: getTreeSchemaFromSo5Fixture_so5Fixture) => {
    const schema = so5Fixture.so5Leagues
      .filter(so5League =>
        so5League.so5Leaderboards.every(
          so5Leaderboard =>
            so5Leaderboard.so5TournamentType.so5LeaderboardType !==
            So5LeaderboardType.SPECIAL_TRAINING_CENTER
        )
      )
      .reduce<{
        [key: string]: { [key: string]: string[] };
      }>((sum, cur) => {
        const cardRewards = cur.so5Leaderboards
          .flatMap(so5Leaderboard => [
            ...(so5Leaderboard.rewardsConfig.ranking || []),
            ...(so5Leaderboard.rewardsConfig.conditional || []),
          ])
          .flatMap(r => r.cards)
          .filter(Boolean);

        sum[cur.displayName] = Object.values(CardQuality).reduce(
          (acc, quality) => {
            const rarities = sortByRarity([
              ...new Set(
                cardRewards
                  .filter(rewards => rewards.quality === quality)
                  .map(rewards => rewards.rarity)
              ),
            ]).reverse();

            if (!rarities.length) {
              return acc;
            }

            return {
              ...acc,
              [qualityNames[quality]]: rarities.map(
                rarity => scarcityNames[rarity]
              ),
            };
          },
          {}
        );

        return sum;
      }, {});

    return schema;
  },
  {
    so5Fixture: gql`
      fragment getTreeSchemaFromSo5Fixture_so5Fixture on So5Fixture {
        slug
        so5Leagues {
          slug
          displayName
          so5Leaderboards {
            slug
            so5TournamentType {
              id
              so5LeaderboardType
            }
            rewardsConfig {
              ranking {
                cards {
                  rarity
                  quality
                }
              }
              conditional {
                cards {
                  rarity
                  quality
                }
              }
            }
          }
        }
      }
    ` as TypedDocumentNode<getTreeSchemaFromSo5Fixture_so5Fixture>,
  }
);

export const getTreeSelection = withFragments(
  ({
    schema,
    so5Leagues,
    league,
    quality,
    rarity,
  }: {
    schema: TreeSchema;
    so5Leagues: getTreeSelection_so5League[];
    league: string | undefined;
    quality: string | undefined;
    rarity: string | undefined;
  }): Selection => {
    const so5League = so5Leagues.find(l => l.slug === league);

    const leagueKey = so5League?.displayName || Object.keys(schema)[0];
    const qualityKey =
      quality && quality.toUpperCase() in qualityNames
        ? qualityNames[quality.toUpperCase() as keyof typeof qualityNames]
        : undefined;
    const rarityKey = rarity ? scarcityNames[rarity.toLowerCase()] : undefined;

    return [leagueKey, qualityKey, rarityKey];
  },
  {
    so5League: gql`
      fragment getTreeSelection_so5League on So5League {
        slug
        displayName
      }
    ` as TypedDocumentNode<getTreeSelection_so5League>,
  }
);

export const taskRewardsToSimpleRewards = withFragments(
  (
    taskReward: taskRewardsToSimpleRewards_managerTaskRewardInterface,
    rates: Rates
  ): SimpleReward | null => {
    if (isType(taskReward, 'ProbabilisticBundleConfigReward')) {
      const probableConfig = taskReward.probabilisticBundleConfig.slots
        .at(0)
        ?.probableConfigs.at(0);
      const shards = probableConfig?.cardShards.at(0);
      const conversionCredit = probableConfig?.conversionCredit;
      const levelUpShopItem = probableConfig?.rewardShopItems
        .filter(item => item.shopItem.position === ShopItemType.LEVEL_UP)
        .at(0);
      if (shards) {
        return {
          type: RewardType.SHARD,
          count: shards.quantity,
          rarity: shards.rarity,
        };
      }
      if (conversionCredit) {
        return {
          type: RewardType.CREDIT,
          count:
            new MonetaryAmount(conversionCredit.maxDiscount).inCurrencies(rates)
              .usd / 100,
          rarity: Rarity.limited,
        };
      }
      if (levelUpShopItem) {
        return {
          type: RewardType.LEVEL_UP,
          count: levelUpShopItem.quantity,
          rarity: Rarity.limited,
        };
      }
    }
    return null;
  },
  {
    managerTaskRewardInterface: gql`
      fragment taskRewardsToSimpleRewards_managerTaskRewardInterface on ManagerTaskRewardInterface {
        ... on ProbabilisticBundleConfigReward {
          probabilisticBundleConfig {
            id
            slots {
              probableConfigs {
                cardShards {
                  id
                  quantity
                  rarity
                }
                conversionCredit {
                  id
                  percentageDiscount
                  maxDiscount {
                    ...MonetaryAmountFragment_monetaryAmount
                  }
                }
                rewardShopItems {
                  quantity
                  shopItem {
                    id
                    position
                  }
                }
              }
            }
          }
        }
      }
      ${monetaryAmountFragment}
    ` as TypedDocumentNode<taskRewardsToSimpleRewards_managerTaskRewardInterface>,
  }
);
