import { useState } from 'react';

import { FRONTEND_ASSET_HOST } from 'constants/assets';
import { toLanguageCode } from 'lib/i18n';

import { DEBUG_I18N, REVISION } from '../config';

export const PREFIXES = {
  '': 'en-US',
  '/en-gb': 'en-GB',
  '/de': 'de-DE',
  '/es': 'es-ES',
  '/fr': 'fr-FR',
  '/it': 'it-IT',
  '/ru': 'ru-RU',
  '/tr': 'tr-TR',
  '/ar': 'ar',
} as const;

const PREFIX_KEYS = Object.keys(PREFIXES) as (keyof typeof PREFIXES)[];

export const getPrefix = (locale: string) => {
  return (
    PREFIX_KEYS.find(key => PREFIXES[key] === locale) ||
    PREFIX_KEYS.find(key => PREFIXES[key].startsWith(locale)) ||
    ''
  );
};

const PREFIX_RE = new RegExp(`(${PREFIX_KEYS.filter(Boolean).join('|')})(/|$)`);

export const stripPrefix = (url: string) => {
  return url.replace(PREFIX_RE, '/');
};

type URL_PREFIX = keyof typeof PREFIXES;

export function matchPrefix(prefix: string): prefix is URL_PREFIX {
  return Object.prototype.hasOwnProperty.call(PREFIXES, prefix);
}

export const AVAILABLE_LOCALES = Object.values(PREFIXES);
const AVAILABLE_LANGUAGES = AVAILABLE_LOCALES.map(toLanguageCode);
export const ALLOWED_LOCALES = AVAILABLE_LANGUAGES.concat(AVAILABLE_LOCALES);

type AvailableLocale = (typeof AVAILABLE_LOCALES)[number];

export type AvailableLanguage =
  | 'en'
  | 'de'
  | 'es'
  | 'fr'
  | 'it'
  | 'ru'
  | 'tr'
  | 'ar';
export type AllowedLocales = AvailableLocale | AvailableLanguage;

let DEV_TRANSLATIONS: Record<string, { default: Record<string, string> }> = {};
if (import.meta.env.DEV) {
  // To get the translations run the `yarn i18n:lokalise:download` script
  DEV_TRANSLATIONS = import.meta.glob('./translations/*.json', { eager: true });
}

export default (locale: AllowedLocales, testMode = false) => {
  const formattedLocaleFromProps = locale.split('-')[0];
  const [formattedLocale, setFormattedLocale] = useState(
    formattedLocaleFromProps
  );
  const [translations, setTranslations] = useState<Record<string, string>>({});
  const [loaded, setLoaded] = useState<true | false | 'loading'>(false);

  const loadLiveTranslations = async (revision: string) => {
    setLoaded('loading');
    const response = await fetch(
      `${FRONTEND_ASSET_HOST}/i18n/${revision}/${formattedLocale}.json.gz`
    );
    let json: Record<string, string> = await response.json();
    if (DEBUG_I18N) {
      json = Object.fromEntries(
        Object.entries(json).map(([k, v]) => [k, `${v} [${k}]`])
      );
    }
    setTranslations(existingTranslations => ({
      ...existingTranslations,
      ...json,
    }));
    setLoaded(true);
  };

  if (formattedLocale !== formattedLocaleFromProps && loaded) {
    setFormattedLocale(formattedLocaleFromProps);
    setLoaded(false);
  }

  if (!loaded) {
    if (!testMode) {
      if (REVISION === 'development') {
        if (formattedLocale === 'en') {
          // for english translations, just rely on what's in the code
          setLoaded(true);
        } else if (process.env.MASTER_COMMIT_REF) {
          // otherwise load the associated master translations
          loadLiveTranslations(process.env.MASTER_COMMIT_REF);
        }
      } else {
        loadLiveTranslations(REVISION);
      }
    } else {
      if (formattedLocale !== 'en') {
        // In test mode access translations locally
        const key = `./translations/${formattedLocale}.json`;

        if (Object.keys(DEV_TRANSLATIONS).includes(key)) {
          const data = DEV_TRANSLATIONS[key];
          setTranslations(data.default);
        }
      }
      setLoaded(true);
    }
  }
  return { loaded: loaded === true, translations };
};
