import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  Position,
  StatCategory,
} from '@sorare/core/src/__generated__/globalTypes';
import { Select } from '@sorare/core/src/atoms/inputs/Select';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyM } from '@sorare/core/src/atoms/typography';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { isType } from '@sorare/core/src/gql';
import { groupBy } from '@sorare/core/src/lib/arrays';
import { fantasy } from '@sorare/core/src/lib/glossary';

import InlineGame from 'components/stats/InlineGame';
import {
  isGameFinished,
  isGameLive,
  isGameScheduled,
  isGameStarted,
} from 'lib/so5';

import { Category } from './Category';
import { PlayerGameScoreStats_playerGameScoreInterface } from './__generated__/index.graphql';
import { Cell, Row, Table } from './ui';
import {
  CustomStatCategory,
  DECISIVE_STAT_CATEGORY,
  SORTED_STAT_CATEGORIES,
  isCategoryDecisive,
} from './utils';

const DefaultCell = styled(Cell)`
  background-color: transparent;
`;
const Root = styled(Vertical)`
  overflow-x: auto;
  overflow-y: hidden;

  & > :first-child {
    margin-left: auto;
  }
`;

const TotalScore = ({
  score,
  isDecisive,
}: {
  score: Nullable<number>;
  isDecisive?: boolean;
}) => {
  const { formatNumber } = useIntlContext();
  const greenTreshold = isDecisive ? 25 : 0;

  const scoreColor =
    (score || 0) > greenTreshold
      ? 'var(--c-score-high)'
      : 'var(--c-score-veryLow)';
  const color =
    score && score !== greenTreshold ? scoreColor : 'var(--c-nd-600)';

  return (
    <BodyM as="p" bold color={color}>
      {score ? (
        <FormattedMessage
          {...fantasy.pointsWithStyledScoreWithoutFormat}
          values={{
            score: formatNumber(Number(score.toFixed(2))),
            span: (...chunks) => <span>{chunks}</span>,
          }}
        />
      ) : (
        '-'
      )}
    </BodyM>
  );
};

type Props = {
  playerGameScores: PlayerGameScoreStats_playerGameScoreInterface[];
};

export const PlayerGameScoreStats = ({ playerGameScores }: Props) => {
  const sortedPlayerGameScores = [...playerGameScores].sort(
    (a, b) => a.anyGame.date.getTime() - b.anyGame.date.getTime()
  );

  const liveGamePGS = playerGameScores.find(pGS =>
    isGameLive(pGS.anyGame.statusTyped)
  );
  const bestGamePGS = playerGameScores
    .filter(pgs => isGameFinished(pgs.anyGame.statusTyped))
    .sort((a, b) => b.score - a.score)[0];

  const [playerGameScore, setPlayerGameScore] = useState(
    liveGamePGS ?? bestGamePGS ?? sortedPlayerGameScores[0]
  );

  if (
    playerGameScores.length === 1 &&
    !isGameStarted(playerGameScores[0].anyGame.statusTyped)
  ) {
    return null;
  }

  const allStats = playerGameScore.detailedScore;

  const categories = groupBy(allStats, statScore =>
    isCategoryDecisive(statScore.category)
      ? DECISIVE_STAT_CATEGORY
      : statScore.category
  );
  const sortedCategories = SORTED_STAT_CATEGORIES.filter(category =>
    Object.keys(categories).includes(category)
  );
  const isGoalkeeper = playerGameScore.position === Position.Goalkeeper;
  const filteredCategories = sortedCategories.filter(category => {
    if (isGoalkeeper) {
      return (
        category !== StatCategory.ATTACKING &&
        category !== StatCategory.DEFENDING
      );
    }
    return category !== StatCategory.GOALKEEPING;
  });

  const notDecisiveCategories: CustomStatCategory[] = filteredCategories.filter(
    category => category !== DECISIVE_STAT_CATEGORY
  );

  const hasDecisiveScore =
    (isType(playerGameScore, 'PlayerGameScore') ||
      isType(playerGameScore, 'So5Score')) &&
    playerGameScore.decisiveScore;

  const decisiveScore =
    isType(playerGameScore, 'PlayerGameScore') ||
    isType(playerGameScore, 'So5Score')
      ? playerGameScore.decisiveScore?.totalScore
      : null;

  const allAroundScore = playerGameScore.detailedScore
    .filter(statScore =>
      notDecisiveCategories.includes(statScore.category as CustomStatCategory)
    )
    .reduce((acc, statScore) => acc + statScore.totalScore, 0);

  return (
    <Root gap={2}>
      {sortedPlayerGameScores.length > 1 && (
        <Select
          small
          menuLateralAlignment="right"
          options={sortedPlayerGameScores.map(pgs => ({
            value: pgs.id,
            label: (
              <InlineGame
                disableTooltip
                inline
                game={pgs.anyGame}
                withGameStatus
                size="sm"
              />
            ),
          }))}
          value={{
            value: playerGameScore.id,
            label: (
              <InlineGame
                inline
                disableTooltip
                withGameStatus
                game={playerGameScore.anyGame}
                size="sm"
              />
            ),
          }}
          onChange={obj => {
            if (obj) {
              const selectedPlayerGameScore = sortedPlayerGameScores.find(
                ({ id }) => obj.value === id
              );
              if (selectedPlayerGameScore) {
                setPlayerGameScore(selectedPlayerGameScore);
              }
            }
          }}
        />
      )}
      {hasDecisiveScore && (
        <>
          <Table>
            <Row className="section">
              <DefaultCell>
                <BodyM bold>
                  <FormattedMessage {...fantasy.decisiveScore} />
                </BodyM>
              </DefaultCell>
              <DefaultCell key={playerGameScore.id}>
                <TotalScore
                  score={
                    isGameScheduled(playerGameScore.anyGame.statusTyped)
                      ? null
                      : decisiveScore
                  }
                  isDecisive
                />
              </DefaultCell>
            </Row>
          </Table>
          {(categories[DECISIVE_STAT_CATEGORY]?.length ?? 0) > 0 && (
            <Table className="categories">
              <Category
                category={DECISIVE_STAT_CATEGORY}
                playerGameScores={[playerGameScore]}
                stats={categories[DECISIVE_STAT_CATEGORY] || []}
              />
            </Table>
          )}
        </>
      )}
      {hasDecisiveScore && (
        <Table>
          <Row className="section">
            <DefaultCell>
              <BodyM bold>
                <FormattedMessage {...fantasy.allAroundScore} />
              </BodyM>
            </DefaultCell>
            <DefaultCell key={playerGameScore.id}>
              <TotalScore score={allAroundScore} />
            </DefaultCell>
          </Row>
        </Table>
      )}
      <Table className="categories">
        {notDecisiveCategories.map(category => (
          <Category
            key={category}
            category={category}
            playerGameScores={[playerGameScore]}
            stats={categories[category] || []}
            defaultExpanded={Object.keys(notDecisiveCategories).length === 1}
          />
        ))}
      </Table>
    </Root>
  );
};

PlayerGameScoreStats.fragments = {
  playerGameScoreInterface: gql`
    fragment PlayerGameScoreStats_playerGameScoreInterface on PlayerGameScoreInterface {
      id
      score
      position: positionTyped
      anyGame {
        id
        statusTyped
        homeScore
        homeTeam {
          slug
          name
          pictureUrl
        }
        awayScore
        awayTeam {
          slug
          name
          pictureUrl
        }
        ...InlineGame_game
      }
      detailedScore {
        ...Category_statScore
      }
      ... on PlayerGameScore {
        id
        decisiveScore {
          totalScore
        }
      }
      ... on So5Score {
        id
        decisiveScore {
          totalScore
        }
      }
      ...Category_playerGameScoreInterface
    }
    ${Category.fragments.playerGameScoreInterface}
    ${Category.fragments.statScore}
    ${InlineGame.fragments.game}
  ` as TypedDocumentNode<PlayerGameScoreStats_playerGameScoreInterface>,
};
