import { createContext, useContext } from 'react';

import {
  Currency,
  EnabledWallet,
  PrivateFiatWalletAccount,
  Rarity,
  Sport,
} from '__generated__/globalTypes';
import { Currency as FiatCurrency } from 'lib/fiat';

import { CurrentUserProvider_userSportProfile } from './__generated__/queries.graphql';
import {
  CurrentUserQuery_currentUser,
  CurrentUserQuery_currentUser_userSettings,
} from './types';

type UserSettingsWithoutInitialCardDrop = Omit<
  CurrentUserQuery_currentUser_userSettings,
  'initialCardDrop'
>;

export type CurrentUser = Omit<CurrentUserQuery_currentUser, 'userSettings'> & {
  userSettings: UserSettingsWithoutInitialCardDrop;
};

export type CurrentUserFiatWalletAccountDepositBankAccount =
  (CurrentUserQuery_currentUser['myAccounts'][number]['accountable'] & {
    __typename: 'PrivateFiatWalletAccount';
  })['depositBankAccount'];

export type CurrentUserFiatWalletAccount = Pick<
  PrivateFiatWalletAccount,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'dob'
  | 'nationalityCode'
  | 'availableBalance'
  | 'state'
  | 'totalBalance'
  | 'countryOfResidenceCode'
  | 'kycStatus'
  | 'kycRefusedReason'
> & {
  depositBankAccount: CurrentUserFiatWalletAccountDepositBankAccount;
} & {
  publicInfo: Pick<PrivateFiatWalletAccount['publicInfo'], 'currency'>;
};

interface CurrentUserContext {
  currentUser: CurrentUser | null | undefined;
  onboarded: Record<Sport, boolean>;
  fiatCurrency: FiatCurrency;
  currency: Currency;
  displayEth: boolean;
  refetch: () => Promise<any>;
  blockchainCardsCount: number;
  rarities: Rarity[];
  fiatWalletAccountable: CurrentUserFiatWalletAccount | null;
  walletPreferences: {
    enabledWallets?: EnabledWallet[];
    showEthWallet: boolean;
    showFiatWallet: boolean;
    onlyShowFiatCurrency: boolean;
    hasMigratedAndSetupWallets: boolean;
  };
  emailConfirmationNeeded: boolean;
  currentDeviceConfirmationNeeded: boolean;
  sportProfileBySport: Record<
    Sport,
    Nullable<CurrentUserProvider_userSportProfile>
  >;
  isMe: (arg?: { slug?: string } | string | null) => boolean;
}

export const currentUserContext = createContext<CurrentUserContext | null>(
  null
);

export const useCurrentUserContext = () => useContext(currentUserContext)!;

export default currentUserContext.Provider;
