import { useIntl } from 'react-intl';

import { FootballRivalsOutcome } from '@sorare/core/src/__generated__/globalTypes';
import { fantasy } from '@sorare/core/src/lib/glossary';
import { HexColorOnly, RGBColor } from '@sorare/core/src/style/types';

export const useOutcomeConfig = (
  outcome?: FootballRivalsOutcome | null
): {
  color: RGBColor;
  hexColor: HexColorOnly;
  text: string;
  points: number;
} => {
  const { formatMessage } = useIntl();

  const config = {
    [FootballRivalsOutcome.WIN]: {
      color: 'var(--c-rgb-score-high)' as RGBColor,
      hexColor: '#33ce78' as HexColorOnly,
      text: formatMessage(fantasy.victory),
      points: +3,
    },
    [FootballRivalsOutcome.LOSE]: {
      color: 'var(--c-rgb-rivals-loss)' as RGBColor,
      hexColor: '#ff4e22' as HexColorOnly,
      text: formatMessage(fantasy.defeat),
      points: -2,
    },
    [FootballRivalsOutcome.DRAW]: {
      color: 'var(--c-rgb-rivals-friendlies)' as RGBColor,
      hexColor: '#00fff7' as HexColorOnly,
      text: formatMessage(fantasy.draw),
      points: 0,
    },
  };

  return config[outcome || FootballRivalsOutcome.WIN];
};
