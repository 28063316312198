import { DivisionChange } from '@sorare/core/src/__generated__/globalTypes';
import { Promotion } from '@sorare/core/src/atoms/icons/Promotion';
import { Relegation } from '@sorare/core/src/atoms/icons/Relegation';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelL, LabelM, LabelS } from '@sorare/core/src/atoms/typography';
import { Diamond } from '@sorare/core/src/components/rivals/branded/Diamond';

const colors = {
  [DivisionChange.PROMOTED]: 'var(--c-rivals-friendlies)',
  [DivisionChange.RELEGATED]: 'var(--c-rivals-loss)',
  [DivisionChange.STABLE]: 'var(--c-rivals-arena)',
} as const;

type Props = {
  state: DivisionChange;
  ranking?: number;
};
export const RankDiamond = ({ state, ranking }: Props) => {
  return (
    <Diamond color={colors[state]} reverse>
      <Vertical gap={0} center>
        <LabelS as="span" color="var(--c-nd-600)" bold uppercase>
          Rank
        </LabelS>
        {ranking !== undefined ? (
          <LabelM bold>
            <Horizontal gap={0} center>
              {state === DivisionChange.PROMOTED && (
                <Promotion fill="var(--c-rivals-friendlies)" />
              )}
              {state === DivisionChange.RELEGATED && <Relegation />}
              {ranking}
            </Horizontal>
          </LabelM>
        ) : (
          <LabelL bold>-</LabelL>
        )}
      </Vertical>
    </Diamond>
  );
};
