export const SpreadLineupIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M16.8 3.5H7.19999C5.72723 3.5 4.53333 4.61929 4.53333 6V18C4.53333 19.3807 5.72723 20.5 7.19999 20.5H16.8C18.2728 20.5 19.4667 19.3807 19.4667 18V6C19.4667 4.61929 18.2728 3.5 16.8 3.5Z"
          stroke="currentColor"
        />
        <path
          d="M13.8667 7H13.6C13.3054 7 13.0667 7.22386 13.0667 7.5V10.5C13.0667 10.7761 13.3054 11 13.6 11H13.8667C14.1612 11 14.4 10.7761 14.4 10.5V7.5C14.4 7.22386 14.1612 7 13.8667 7Z"
          fill="currentColor"
        />
        <path
          d="M10.6666 7H10.4C10.1054 7 9.86664 7.22386 9.86664 7.5V10.5C9.86664 10.7761 10.1054 11 10.4 11H10.6666C10.9612 11 11.2 10.7761 11.2 10.5V7.5C11.2 7.22386 10.9612 7 10.6666 7Z"
          fill="currentColor"
        />
        <path
          d="M15.4667 13H15.2C14.9055 13 14.6667 13.2239 14.6667 13.5V16.5C14.6667 16.7761 14.9055 17 15.2 17H15.4667C15.7612 17 16 16.7761 16 16.5V13.5C16 13.2239 15.7612 13 15.4667 13Z"
          fill="currentColor"
        />
        <path
          d="M12.2667 13.5H12C11.7055 13.5 11.4667 13.7239 11.4667 14V17C11.4667 17.2761 11.7055 17.5 12 17.5H12.2667C12.5612 17.5 12.8 17.2761 12.8 17V14C12.8 13.7239 12.5612 13.5 12.2667 13.5Z"
          fill="currentColor"
        />
        <path
          d="M9.06666 13H8.8C8.50544 13 8.26666 13.2239 8.26666 13.5V16.5C8.26666 16.7761 8.50544 17 8.8 17H9.06666C9.36121 17 9.6 16.7761 9.6 16.5V13.5C9.6 13.2239 9.36121 13 9.06666 13Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
