import { gql } from '@apollo/client';
import type { TypedDocumentNode } from '@apollo/client';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { AmountWithConversion } from '@sorare/core/src/components/buyActions/AmountWithConversion';
import { Since } from '@sorare/core/src/contexts/intl/Since';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import {
  ItemImgContainer,
  ItemInfosContainer,
} from 'components/ItemPreview/ui';
import CardDescription from 'components/token/CardDescription';
import FlexCard from 'components/token/FlexCard';

import { ItemRoot } from '../ui';
import type { MySorarePrimaryOffer_primaryOffer } from './__generated__/index.graphql';

type Props = {
  primaryOffer: MySorarePrimaryOffer_primaryOffer;
};

export const PrimaryOffer = ({ primaryOffer }: Props) => {
  const card = primaryOffer.anyCards[0];

  return (
    <ItemRoot>
      <ItemImgContainer>
        <FlexCard card={card} withLink />
      </ItemImgContainer>
      <ItemInfosContainer>
        <CardDescription
          card={card}
          Details={LabelM}
          detailsColor="var(--c-nd-600)"
        />
        <Horizontal>
          <AmountWithConversion monetaryAmount={primaryOffer.price!} />
          ·
          <Since date={primaryOffer.acceptedAt!} />
        </Horizontal>
      </ItemInfosContainer>
    </ItemRoot>
  );
};

PrimaryOffer.fragments = {
  primaryOffer: gql`
    fragment MySorarePrimaryOffer_primaryOffer on TokenPrimaryOffer {
      id
      acceptedAt
      price {
        ...MonetaryAmountFragment_monetaryAmount
      }
      anyCards {
        slug
        ...CardDescription_anyCard
        ...FlexCard_anyCard
      }
    }
    ${CardDescription.fragments.anyCard}
    ${FlexCard.fragments.anyCard}
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<MySorarePrimaryOffer_primaryOffer>,
};
