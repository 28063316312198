import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { HexColor } from 'style/types';

const Root = styled.div`
  --color: var(--c-rivals-reputation);

  width: calc(10 * var(--unit));
  height: calc(10 * var(--unit));
  filter: drop-shadow(0px 0px 50px var(--color));
  &.noFilter {
    filter: unset;
  }
`;
const DiamondWrapper = styled.div`
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
  background-color: var(--color);
`;
const Content = styled(Horizontal).attrs({ center: true })<{
  width: number;
  height: number;
}>`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  background: rgba(var(--c-rgb-black), 0.9);

  ${({ width, height }) => {
    return `
      clip-path: path(
        'm ${width * 0.98125} ${height * 0.4975} l ${width * -0.25} ${
          height * 0.215
        } v ${height * -0.0625} l ${width * -0.23375} ${height * 0.33125} l ${
          width * -0.48125
        } ${height * -0.48125} l ${width * 0.48375} ${height * -0.48375} l ${
          width * 0.24125
        } ${height * 0.34875} v ${height * -0.06125} z'
      );
      .reverse & {
        clip-path: path(
          'm ${width * 0.01875} ${height * 0.4975} l ${width * 0.25} ${
            height * 0.215
          } v ${height * -0.0625} l ${width * 0.23375} ${height * 0.33125} l ${
            width * 0.48125
          } ${height * -0.48125} l ${width * -0.48375} ${height * -0.48375} l ${
            width * -0.24125
          } ${height * 0.34875} v ${height * -0.06125} z'
        );
      }
    `;
  }}
`;

type Props = {
  width?: number;
  height?: number;
  color?: HexColor;
  reverse?: boolean;
  className?: string;
  noFilter?: boolean;
};
export const Diamond = ({
  width = 80,
  height = 80,
  color = 'var(--c-rivals-reputation)',
  children,
  reverse,
  className,
  noFilter,
}: PropsWithChildren<Props>) => {
  return (
    <Root
      style={
        {
          '--color': color,
          width,
          height,
        } as CSSProperties
      }
      className={classNames({ reverse: !!reverse, noFilter }, className)}
    >
      <DiamondWrapper>
        <Content width={width} height={height}>
          {children}
        </Content>
      </DiamondWrapper>
    </Root>
  );
};
