import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';

export const ItemRoot = styled(Horizontal).attrs({ gap: 1.5 })`
  @container (min-width: 800px) {
    flex: 1 0 calc(50% - var(--unit));
    max-width: calc(50% - var(--unit));
  }
`;
