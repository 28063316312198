import { TypedDocumentNode, gql } from '@apollo/client';

import {
  Position,
  So5LeaderboardSeasonality,
} from '@sorare/core/src/__generated__/globalTypes';
import { groupBy } from '@sorare/core/src/lib/arrays';

import { PositionCardCount } from 'components/card/PositionCardCount';

import { EligibleCards_so5LeaderboardGroupInterface } from './__generated__/index.graphql';

const positions = [
  Position.Goalkeeper,
  Position.Defender,
  Position.Midfielder,
  Position.Forward,
] as const;

type Props = {
  so5LeaderboardGroupInterface: EligibleCards_so5LeaderboardGroupInterface;
  seasonality: So5LeaderboardSeasonality;
};

export const EligibleCards = ({
  so5LeaderboardGroupInterface,
  seasonality,
}: Props) => {
  const eligibleCardsCountBySeasonality = groupBy(
    so5LeaderboardGroupInterface.eligibleCardsCountByPosition,
    count => count.seasonality
  );

  const eligibleCardsCountBySeasonalityByPosition = Object.fromEntries(
    Object.entries(eligibleCardsCountBySeasonality).map(
      ([_seasonality, counts]) => {
        const countsByPosition = Object.fromEntries(
          counts.map(count => [count.position, count])
        );

        return [_seasonality, countsByPosition];
      }
    )
  );

  const seasonalityCountsByPosition =
    eligibleCardsCountBySeasonalityByPosition[seasonality];

  return positions.map(position => {
    const countObject = seasonalityCountsByPosition?.[position];

    return (
      <PositionCardCount
        key={position}
        count={
          countObject ? countObject.totalCount - countObject.usedCardsCount : 0
        }
        position={position}
        seasonality={seasonality}
      />
    );
  });
};

EligibleCards.fragments = {
  so5LeaderboardGroupInterface: gql`
    fragment EligibleCards_so5LeaderboardGroupInterface on So5LeaderboardGroupInterface {
      slug
      eligibleCardsCountByPosition {
        slug
        position
        seasonality
        totalCount
        usedCardsCount
      }
    }
  ` as TypedDocumentNode<EligibleCards_so5LeaderboardGroupInterface>,
};
