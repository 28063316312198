import { ApolloQueryResult } from '@apollo/client';
import { createContext, useContext } from 'react';

import { Currency, CurrencyCode } from 'lib/fiat';

import {
  ConfigQuery,
  ConfigQuery_currentLocation,
  ConfigQuery_currentUser,
  ConfigQuery_exchangeRate,
} from './types';

export interface AlgoliaCardIndexes {
  // cards indices
  'Cards New': string;
  'Cards Highest Average Score': string;
  'Cards Highest Price': string;
  'Cards Lowest Price': string;
  'Cards Player Name': string;

  // blockchain cards indices
  'Ending Soon': string;
  'Newly Listed': string;
  'Highest Price': string;
  'Lowest Price': string;
  'Highest Average Score': string;
  'Best Value': string;
  'Popular Player': string;

  // On sale cards indices
  'Cards On Sale Ending Soon': string;
  'Cards On Sale Newly Listed': string;
  'Cards On Sale Highest Price': string;
  'Cards On Sale Lowest Price': string;
  'Cards On Sale Highest Average Score': string;
  'Cards On Sale Best Value': string;
  'Cards On Sale Popular Player': string;
}

export interface AlgoliaIndexes extends AlgoliaCardIndexes {
  User: string;
  Player: string;
  Club: string;
  League: string;
  Country: string;
  'National Team': string;
  Competition: string;
}

export type AlgoliaIndex = keyof AlgoliaIndexes;

export type AlgoliaCardIndexesName = keyof AlgoliaCardIndexes;

export type PrintablePlayerSortName =
  | 'ASC-L5'
  | 'DESC-L5'
  | 'ASC-L10'
  | 'DESC-L10'
  | 'ASC-L15'
  | 'DESC-L15'
  | 'ASC-L15AA'
  | 'DESC-L15AA'
  | 'ASC-L15D'
  | 'DESC-L15D'
  | 'ASC-season_avg'
  | 'DESC-season_avg'
  | 'ASC-name_for_sorting'
  | 'DESC-name_for_sorting'
  | 'ASC-next_classic_fixture_projected_score'
  | 'DESC-next_classic_fixture_projected_score'
  | 'ASC-next_daily_fixture_projected_score'
  | 'DESC-next_daily_fixture_projected_score'
  | 'ASC-points'
  | 'DESC-points'
  | 'ASC-assists'
  | 'DESC-assists'
  | 'ASC-rebounds'
  | 'DESC-rebounds'
  | 'ASC-blocks'
  | 'DESC-blocks'
  | 'ASC-double_double'
  | 'DESC-double_double'
  | 'ASC-average_mins_played'
  | 'DESC-average_mins_played'
  | 'ASC-total_mins_played'
  | 'DESC-total_mins_played'
  | 'ASC-steals'
  | 'DESC-steals'
  | 'ASC-three_points_made'
  | 'DESC-three_points_made'
  | 'ASC-triple_double'
  | 'DESC-triple_double'
  | 'ASC-turnovers'
  | 'DESC-turnovers'
  | 'ASC-total_set_piece_taken'
  | 'DESC-total_set_piece_taken'
  | 'ASC-total_goals'
  | 'DESC-total_goals'
  | 'ASC-total_goal_assist'
  | 'DESC-total_goal_assist'
  | 'ASC-total_assist_penalty_won'
  | 'DESC-total_assist_penalty_won'
  | 'ASC-total_last_man_tackle'
  | 'DESC-total_last_man_tackle'
  | 'ASC-total_clean_sheet'
  | 'DESC-total_clean_sheet'
  | 'ASC-total_yellow_card'
  | 'DESC-total_yellow_card'
  | 'ASC-total_red_card'
  | 'DESC-total_red_card'
  | 'ASC-total_error_lead_to_goal'
  | 'DESC-total_error_lead_to_goal'
  | 'ASC-total_penalty_conceded'
  | 'DESC-total_penalty_conceded'
  | 'ASC-total_three_goals_conceded'
  | 'DESC-total_three_goals_conceded'
  | 'ASC-average_set_piece_taken'
  | 'DESC-average_set_piece_taken'
  | 'ASC-average_goals'
  | 'DESC-average_goals'
  | 'ASC-average_goal_assist'
  | 'DESC-average_goal_assist'
  | 'ASC-average_assist_penalty_won'
  | 'DESC-average_assist_penalty_won'
  | 'ASC-average_last_man_tackle'
  | 'DESC-average_last_man_tackle'
  | 'ASC-average_clean_sheet'
  | 'DESC-average_clean_sheet'
  | 'ASC-average_yellow_card'
  | 'DESC-average_yellow_card'
  | 'ASC-average_red_card'
  | 'DESC-average_red_card'
  | 'ASC-average_error_lead_to_goal'
  | 'DESC-average_error_lead_to_goal'
  | 'ASC-average_penalty_conceded'
  | 'DESC-average_penalty_conceded'
  | 'ASC-average_three_goals_conceded'
  | 'DESC-average_three_goals_conceded'
  | 'ASC-average_three_points_made'
  | 'ASC-floor_prices_in_season_limited'
  | 'DESC-floor_prices_in_season_limited'
  | 'ASC-floor_prices_in_season_rare'
  | 'DESC-floor_prices_in_season_rare'
  | 'ASC-floor_prices_in_season_super_rare'
  | 'DESC-floor_prices_in_season_super_rare'
  | 'ASC-floor_prices_in_season_unique'
  | 'DESC-floor_prices_in_season_unique'
  | 'ASC-floor_prices_all_seasons_limited'
  | 'DESC-floor_prices_all_seasons_limited'
  | 'ASC-floor_prices_all_seasons_rare'
  | 'DESC-floor_prices_all_seasons_rare'
  | 'ASC-floor_prices_all_seasons_super_rare'
  | 'DESC-floor_prices_all_seasons_super_rare'
  | 'ASC-floor_prices_all_seasons_unique'
  | 'DESC-floor_prices_all_seasons_unique'
  | 'ASC-ib_prices_limited'
  | 'DESC-ib_prices_limited'
  | `ASC-starter_odds_basis_points`
  | `DESC-starter_odds_basis_points`
  | 'ASC-last_thirty_days_subscription_count'
  | 'DESC-last_thirty_days_subscription_count';

export type PrintableI18nPlayerSortName =
  | 'DESC-L10'
  | 'ASC-player_name'
  | 'DESC-player_name';

// TODO(sylvain): Need to figure a way to support dynamic fields
export type ShortSortName = string;

export type AlgoliaCardIndexesNames = AlgoliaCardIndexesName[];

export interface ConfigContext {
  algoliaIndexes: AlgoliaIndexes;
  algoliaCardIndexes: AlgoliaCardIndexes;
  algoliaApplicationId: string;
  algoliaSearchApiKey: string;
  giphyApiKey: string;
  ethereumNetworkId: string;
  ethereumEndpoint: string;
  sorareTokensAddress: EthereumAddress;
  baseballTokensAddress: EthereumAddress;
  nbaTokensAddress: EthereumAddress;
  sorareCardsAddress: EthereumAddress;
  footballNationalSeriesTokensAddress: EthereumAddress;
  sorareEncryptionKey: string;
  sponsorAccountAddress: string;
  migratorAddress?: string | null;
  bankAddress: string;
  ethAssetType: string | null;
  ethQuantum: bigint;
  starkExchangeAddress: EthereumAddress;
  relayAddress: string;
  so5: {
    teamsPlayingNextClassicGameWeeks: ConfigQuery['config']['so5']['teamsPlayingNextClassicGameWeeks'];
    teamsPlayingNextDailyGameWeeks: ConfigQuery['config']['so5']['teamsPlayingNextDailyGameWeeks'];
    so5Competitions: ConfigQuery['so5']['so5Competitions'];
    inSeasonName: string;
    allSeasonsName: string;
  };
  currentUser: ConfigQuery_currentUser | null;
  refetch: () => Promise<ApolloQueryResult<ConfigQuery>>;
  updateQuery: (user: ConfigQuery_currentUser) => void;
  exchangeRate: ConfigQuery_exchangeRate & {
    getEthRate: (to: CurrencyCode) => number;
  };
  marketFeeRateBasisPoints: number;
  marketFeePercentagePoints: bigint;
  checkoutFeesBasisPoints: string;
  idealDepositFeesAmountMinor: number;
  mangopayVisaFeesBasisPoints: string;
  mangopayAmexFeesBasisPoints: string;
  paypalDepositFeesBasisPoints: number;
  mangopayApplePayDepositFeesBasisPoints: number;
  stripeCreditCardFeesBasisPoints: string;
  defaultFiatCurrency: Currency;
  minimumReceiveWeiAmount: bigint;
  currentLocation: ConfigQuery_currentLocation;
  marketplacePromotionalEvents: ConfigQuery['config']['marketplacePromotionalEvents'];
  promotionalEvents: ConfigQuery['tokens']['promotionalEvents'];
  counts: ConfigQuery['config']['counts'];
}

export const configContext = createContext<ConfigContext | null>(null);

export const useConfigContext = () => useContext(configContext)!;

export default configContext.Provider;
