import { defineMessages } from 'react-intl';

import { DivisionChange } from '@sorare/core/src/__generated__/globalTypes';

export const messages = defineMessages({
  [DivisionChange.PROMOTED]: {
    id: 'managerTeam.Status.up',
    defaultMessage: 'Promoted',
  },
  [DivisionChange.RELEGATED]: {
    id: 'managerTeam.Status.down',
    defaultMessage: 'Relegated',
  },
  [DivisionChange.STABLE]: {
    id: 'managerTeam.Status.neutral',
    defaultMessage: 'Safe',
  },
  projectedToDiv: {
    id: 'managerTeam.Status.projDiv',
    defaultMessage: 'Proj. Div {division}',
  },
});
