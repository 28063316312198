import styled from 'styled-components';

import Dialog from '@sorare/core/src/components/dialog';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import MatchView from 'components/MatchView';

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--double-unit);
  height: 100%;
  padding: var(--unit);

  @media ${laptopAndAbove} {
    min-width: var(--layout-dialog-width);
  }
`;

type Props = {
  gameId?: string;
  onClose: () => void;
  highlightedPlayerSlugs?: string[];
  open?: boolean;
};
const MatchViewDialog = ({
  gameId,
  onClose,
  highlightedPlayerSlugs,
  open,
}: Props) => {
  const { up: isLaptop } = useScreenSize('laptop');

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose} hideHeader>
      <DialogContainer>
        {open && gameId && (
          <MatchView
            desktop={isLaptop}
            id={gameId}
            onClose={onClose}
            highlightedPlayerSlugs={highlightedPlayerSlugs}
          />
        )}
      </DialogContainer>
    </Dialog>
  );
};

export default MatchViewDialog;
