import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { To } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { SBHorizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LinkBox, LinkOverlay } from '@sorare/core/src/atoms/navigation/Box';
import { LabelS, LabelXS } from '@sorare/core/src/atoms/typography';
import { glossary } from '@sorare/core/src/lib/glossary';

import emptyAppearance from 'assets/empty_appearance.svg';
import { So5AppearanceScore } from 'components/appearance/So5AppearanceScore';
import { LeaderboardInlineInfo } from 'components/leaderboard/LeaderboardInlineInfo';
import { Badge } from 'components/managerTeam/Badge';
import { LiveLineupFooter } from 'layouts/LineupLayout/LiveLineupFooter';

import { CompactContender_so5LeaderboardContender } from './__generated__/index.graphql';

const StyledLinkBox = styled(LinkBox)`
  button${LinkOverlay} {
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    button${LinkOverlay} {
      opacity: 0.5;
    }
  }
`;
const StyledLinkOverlay = styled(LinkOverlay)`
  button& {
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.5;
    }
  }
`;
const Root = styled(Vertical)`
  width: 100%;
  background: var(--c-nd-50);
  border-radius: var(--double-unit);
`;
const Content = styled(Vertical)`
  padding: var(--intermediate-unit) var(--double-unit) 0;
`;
const Row = styled(SBHorizontal)`
  width: 100%;
  align-items: start;

  > *:first-child {
    align-self: center;
  }
`;
const Avatar = styled.img`
  border-radius: 50%;
  object-fit: cover;
`;
const Footer = styled.div`
  display: flex;
  padding: var(--unit) var(--double-unit);
  min-height: calc(6 * var(--unit));
  background: var(--c-nd-50);
  border-radius: 0 0 var(--double-unit) var(--double-unit);
`;

type Props = {
  so5LeaderboardContender: CompactContender_so5LeaderboardContender;
  to?: To;
  onClick?: () => void;
};

export const CompactContender = ({
  so5LeaderboardContender,
  to,
  onClick,
}: Props) => {
  const { so5Leaderboard, so5Lineup, managerTeam } = so5LeaderboardContender;
  const { so5Rankings = [] } = so5Lineup || {};
  const [ranking] = so5Rankings;

  return (
    <StyledLinkBox>
      <Root gap={1.5}>
        <Content gap={1.5}>
          <LeaderboardInlineInfo so5Leaderboard={so5Leaderboard}>
            <LabelXS bold color="var(--c-nd-700)">
              {'- '}
              <FormattedMessage
                {...glossary.ordinal}
                values={{ ordinal: ranking.ranking }}
              />
            </LabelXS>
          </LeaderboardInlineInfo>
          <StyledLinkOverlay
            to={to}
            as={onClick || to ? ButtonBase : 'span'}
            onClick={onClick}
          >
            <Row>
              <Badge
                size={5}
                managerTeam={managerTeam}
                leaderboard={so5Leaderboard}
                validLineup={!!so5Lineup?.confirmable}
                ranking={ranking}
              />
              {so5Lineup?.so5Appearances.map((so5Appearance, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Vertical key={i} center gap={0.5}>
                  <Avatar
                    src={so5Appearance?.avatarUrl || emptyAppearance}
                    alt=""
                    width="32"
                    height="32"
                  />
                  <LabelS bold>
                    <So5AppearanceScore
                      shape="TEXT"
                      so5Appearance={so5Appearance}
                      hideReviewing
                      hideClock
                    />
                  </LabelS>
                </Vertical>
              ))}
            </Row>
          </StyledLinkOverlay>
        </Content>
        <Footer>
          <LiveLineupFooter so5LeaderboardContender={so5LeaderboardContender} />
        </Footer>
      </Root>
    </StyledLinkBox>
  );
};

CompactContender.fragments = {
  so5LeaderboardContender: gql`
    fragment CompactContender_so5LeaderboardContender on So5LeaderboardContender {
      slug
      managerTeam {
        id
        ...ManagerTeamBadge_managerTeam
      }
      so5Leaderboard {
        slug
        ...ManagerTeamBadge_so5Leaderboard
        ...LeaderboardInlineInfo_so5Leaderboard
      }
      so5Lineup {
        id
        confirmable
        so5Rankings {
          id
          ranking
          ...ManagerTeamBadge_so5Ranking
        }
        so5Appearances {
          id
          avatarUrl: pictureUrl(derivative: "avatar")
          ...So5AppearanceScore_so5Appearance
        }
      }
      ...LiveLineupFooter_so5LeaderboardContender
    }
    ${Badge.fragments.managerTeam}
    ${Badge.fragments.so5Leaderboard}
    ${Badge.fragments.so5Ranking}
    ${So5AppearanceScore.fragments.so5Appearance}
    ${LeaderboardInlineInfo.fragments.so5Leaderboard}
    ${LiveLineupFooter.fragments.so5LeaderboardContender}
  ` as TypedDocumentNode<CompactContender_so5LeaderboardContender>,
};
