import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ScoreStatus, Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import Dialog from '@sorare/core/src/components/dialog';
import { PlayerGameScoreScore } from '@sorare/core/src/components/scoring/PlayerGameScoreScore';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { fantasy } from '@sorare/core/src/lib/glossary';
import { breakpoints, tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { PrimaryBuyForAPlayer } from 'components/primaryBuy/PrimaryBuyForAPlayer';
import { BriefGame } from 'components/so5/BriefGame';
import { isGameStarted } from 'lib/so5';

import { BriefPlayerScore } from '../BriefPlayerScore';
import MatchViewDialog from '../MatchViewDialog';
import { PlayerGameScoreStats } from '../PlayerGameScoreStats';
import {
  PlayerGameScoreDialogQuery,
  PlayerGameScoreDialogQueryVariables,
} from './__generated__/index.graphql';

export const PLAYER_GAME_SCORE_DIALOG_QUERY = gql`
  query PlayerGameScoreDialogQuery($id: ID!) {
    so5 {
      playerGameScore(id: $id) {
        id
        scoreStatus
        anyPlayer {
          slug
          avatarPictureUrl
          ...BriefPlayerScore_anyPlayerInterface
        }
        anyGame {
          id
          ...BriefGame_anyGameInterface
        }
        anyPlayerGameStats {
          id
          anyTeam {
            slug
            ...BriefGame_teamInterface
          }
        }
        ...BriefPlayerScore_playerGameScoreInterface
        ...PlayerGameScoreScore_playerGameScoreInterface
        ...PlayerGameScoreStats_playerGameScoreInterface
      }
    }
  }
  ${BriefGame.fragments.anyGameInterface}
  ${BriefGame.fragments.teamInterface}
  ${BriefPlayerScore.fragments.anyPlayerInterface}
  ${BriefPlayerScore.fragments.playerGameScoreInterface}
  ${PlayerGameScoreScore.fragments.playerGameScoreInterface}
  ${PlayerGameScoreStats.fragments.playerGameScoreInterface}
` as TypedDocumentNode<
  PlayerGameScoreDialogQuery,
  PlayerGameScoreDialogQueryVariables
>;

const Root = styled(Vertical)`
  padding: var(--double-unit);
  @media ${tabletAndAbove} {
    min-width: ${breakpoints.mobile}px;
  }
`;

type Props = {
  open: boolean;
  onClose: () => void;
  playerGameScoreId: string;
  showUpsell: boolean;
  inDrawer?: boolean;
};

export const PlayerGameScoreDialog = ({
  onClose,
  open,
  playerGameScoreId,
  showUpsell,
  inDrawer,
}: Props) => {
  const { sport } = useSportContext();
  const [openMatchView, setOpenMatchView] = useState(false);
  const { data, loading } = useQuery(PLAYER_GAME_SCORE_DIALOG_QUERY, {
    variables: { id: idFromObject(playerGameScoreId)! },
    skip: !open,
  });
  const { playerGameScore } = data?.so5 || {};

  const onClick =
    sport === Sport.FOOTBALL && !inDrawer
      ? () => setOpenMatchView(true)
      : undefined;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={
        <Horizontal center>
          <FormattedMessage {...fantasy.playerPerformance} />
        </Horizontal>
      }
    >
      <>
        {loading && <LoadingIndicator />}
        {playerGameScore && (
          <Root gap={5}>
            <BriefPlayerScore
              pictureUrl={playerGameScore.anyPlayer.avatarPictureUrl}
              anyPlayer={playerGameScore.anyPlayer}
              playerGameScores={[playerGameScore]}
            >
              <PlayerGameScoreScore playerGameScore={playerGameScore} />
            </BriefPlayerScore>
            <BriefGame
              game={playerGameScore.anyGame}
              team={playerGameScore.anyPlayerGameStats.anyTeam}
              onClick={onClick}
            />
            {showUpsell && (
              <PrimaryBuyForAPlayer
                playerSlug={playerGameScore.anyPlayer.slug}
              />
            )}
            {playerGameScore.scoreStatus !== ScoreStatus.DID_NOT_PLAY &&
              isGameStarted(playerGameScore.anyGame.status) && (
                <PlayerGameScoreStats playerGameScores={[playerGameScore]} />
              )}
            <MatchViewDialog
              open={!!openMatchView}
              onClose={() => setOpenMatchView(false)}
              gameId={playerGameScore.anyGame.id}
            />
          </Root>
        )}
      </>
    </Dialog>
  );
};
