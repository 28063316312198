import { TypedDocumentNode, gql } from '@apollo/client';
import { MessageDescriptor } from 'react-intl';

import { Rarity, So5LeaderboardType } from '__generated__/globalTypes';
import { FRONTEND_ASSET_HOST } from 'constants/assets';

import {
  formatLineupDisplayName_so5Lineup,
  teamPictureUrl_teamInterface,
} from './__generated__/so5.graphql';
import { TotalAveragePlayerScore } from './averagePlayerScore';
import { cardAttributes } from './glossary';
import { isType, withFragments } from './gql';

export const formatLineupDisplayName = withFragments(
  (so5Lineup: formatLineupDisplayName_so5Lineup) => {
    const { name, so5Leaderboard } = so5Lineup;
    const { displayName, trainingCenter } = so5Leaderboard || {};

    if (
      so5Leaderboard?.so5TournamentType.so5LeaderboardType ===
      So5LeaderboardType.SPECIAL_WEEKLY
    ) {
      return so5Leaderboard.so5LeaderboardGroup.displayName;
    }

    return [displayName, trainingCenter && name].filter(Boolean).join(' ');
  },
  {
    so5Lineup: gql`
      fragment formatLineupDisplayName_so5Lineup on So5Lineup {
        id
        name
        so5Leaderboard {
          slug
          displayName
          trainingCenter
          so5TournamentType {
            id
            so5LeaderboardType
          }
          so5LeaderboardGroup {
            slug
            displayName
          }
        }
      }
    ` as TypedDocumentNode<formatLineupDisplayName_so5Lineup>,
  }
);

export enum PlayerScoreStatus {
  NO_GAME = 'no_game',
  DID_NOT_PLAY = 'did_not_play',
  REVIEWING = 'reviewing',
  PENDING = 'pending',
}

export enum FixtureState {
  opened = 'opened',
  started = 'started',
  closed = 'closed',
}

export const isRarityStr = (rarity: Nullable<string>): rarity is Rarity =>
  !rarity || rarity in Rarity;

export const modeTooltipMessages: Record<
  TotalAveragePlayerScore,
  MessageDescriptor
> = {
  LAST_FIVE_SO5_AVERAGE_SCORE: cardAttributes.L5Score,
  LAST_FIFTEEN_SO5_AVERAGE_SCORE: cardAttributes.L15Score,
  LAST_TEN_PLAYED_SO5_AVERAGE_SCORE: cardAttributes.L10Score,
  SEASON_AVERAGE_SCORE: cardAttributes.seasonAverageScore,
};

export const teamPictureUrl = withFragments(
  (team: teamPictureUrl_teamInterface) => {
    if (isType(team, 'Club')) {
      return team.pictureUrl;
    }

    return `${FRONTEND_ASSET_HOST}/flags/${team.country.code}.svg`;
  },
  {
    teamInterface: gql`
      fragment teamPictureUrl_teamInterface on TeamInterface {
        slug
        pictureUrl
        ... on NationalTeam {
          slug
          country {
            slug
            code
          }
        }
      }
    ` as TypedDocumentNode<teamPictureUrl_teamInterface>,
  }
);
