import { TypedDocumentNode, gql } from '@apollo/client';

import { getHumanReadableSerialNumber } from 'lib/cards';

import { TokenMetas_anyCard } from './__generated__/index.graphql';

interface Props {
  card: TokenMetas_anyCard;
  separator?: string;
}

export const TokenMetas = ({ card, separator }: Props) => {
  return (
    <>
      {[card.sportSeason.name, separator, getHumanReadableSerialNumber(card)]}
    </>
  );
};

TokenMetas.fragments = {
  anyCard: gql`
    fragment TokenMetas_anyCard on AnyCardInterface {
      slug
      sportSeason {
        name
      }
      ...getHumanReadableSerialNumber_anyCard
    }
    ${getHumanReadableSerialNumber.fragments.anyCard}
  ` as TypedDocumentNode<TokenMetas_anyCard>,
};
