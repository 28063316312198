import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { DivisionChange } from '@sorare/core/src/__generated__/globalTypes';
import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyS, LabelS, LabelXS } from '@sorare/core/src/atoms/typography';
import { Ellipsis } from '@sorare/core/src/atoms/typography/Ellipsis';
import { Avatar } from '@sorare/core/src/components/user/Avatar';
import { fantasy } from '@sorare/core/src/lib/glossary';

import { DumbStatus } from 'components/managerTeam/Status/DumbStatus';
import { getCurrentSportClubName } from 'lib/getCurrentSportClubName';

import { RankRewardsRanking_so5Ranking } from './__generated__/index.graphql';

const Root = styled.div`
  height: calc(6 * var(--unit));
  display: grid;
  grid-template-columns:
    calc(8 * var(--unit)) calc(4 * var(--unit)) 1fr
    calc(13 * var(--unit));
  gap: var(--double-unit);
  align-items: center;
  &.hasDivisionChange {
    border-left: var(--unit) solid var(--c-nd-400);

    &.PROMOTED {
      border-color: var(--c-rivals-win);
    }
    &.RELEGATED {
      border-color: var(--c-rivals-loss);
    }
  }

  &.isMyRanking {
    background-color: rgba(var(--c-rgb-white), 0.07);
  }

  & > * {
    &:first-child {
      text-align: center;
    }
    &:last-child {
      padding-right: var(--intermediate-unit);
      justify-content: end;
    }
  }
`;
const StyledButtonBase = styled(ButtonBase)`
  width: 100%;
  text-align: left;
`;
const AvatarContainer = styled(Horizontal).attrs({ gap: 0, center: true })`
  width: var(--quadruple-unit);
  height: var(--quadruple-unit);
  border-radius: var(--quadruple-unit);
  overflow: hidden;
  background-color: var(--c-nd-400);

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Infos = styled(Vertical).attrs({ gap: 0 })`
  overflow: hidden;
`;

type Props = {
  so5Ranking: RankRewardsRanking_so5Ranking;
  isMyRanking?: boolean;
  onClick?: () => void;
};

export const Ranking = ({ so5Ranking, isMyRanking, onClick }: Props) => {
  const { user } = so5Ranking.so5Lineup;

  return (
    <Root
      key={so5Ranking.id}
      as={onClick ? StyledButtonBase : undefined}
      onClick={onClick}
      className={classNames(so5Ranking.eligibleDivisionChange, {
        hasDivisionChange: !!so5Ranking.eligibleDivisionChange,
        isMyRanking,
      })}
    >
      <BodyS as="p" bold>
        <FormattedNumber value={so5Ranking.ranking ?? 0} />
      </BodyS>
      <AvatarContainer>
        <Avatar user={user} />
      </AvatarContainer>
      <Infos gap={0}>
        <BodyS as="p" bold>
          <Ellipsis>{getCurrentSportClubName(user)}</Ellipsis>
        </BodyS>
        <LabelS as="p">
          <Ellipsis>{user.nickname}</Ellipsis>
        </LabelS>
      </Infos>
      <Horizontal>
        {so5Ranking.eligibleDivisionChange &&
          so5Ranking.eligibleDivisionChange !== DivisionChange.STABLE && (
            <DumbStatus divisionChange={so5Ranking.eligibleDivisionChange} />
          )}
        <LabelXS as="p">
          <Horizontal gap={0.5} as="span">
            <FormattedMessage
              {...fantasy.pointsWithStyledScore}
              values={{
                score: so5Ranking.score,
                span: (...chunks) => (
                  <BodyS as="span" bold>
                    {chunks}
                  </BodyS>
                ),
              }}
            />
          </Horizontal>
        </LabelXS>
      </Horizontal>
    </Root>
  );
};

Ranking.fragments = {
  so5Ranking: gql`
    fragment RankRewardsRanking_so5Ranking on So5Ranking {
      id
      eligibleDivisionChange
      ranking
      score
      so5Lineup {
        id
        name
        user {
          slug
          nickname
          ...Avatar_publicUserInfoInterface
          ...getCurrentSportClubName_PublicUserInfoInterface
        }
      }
    }
    ${Avatar.fragments.publicUserInfoInterface}
    ${getCurrentSportClubName.fragments.PublicUserInfoInterface}
  ` as TypedDocumentNode<RankRewardsRanking_so5Ranking>,
};
