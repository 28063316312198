import { type TypedDocumentNode, gql } from '@apollo/client';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FormattedNumber, useIntl } from 'react-intl';
import styled from 'styled-components';

import { FiatCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { useConfigContext } from '@sorare/core/src/contexts/config';
import { isType } from '@sorare/core/src/gql';
import { range } from '@sorare/core/src/lib/arrays';
import MonetaryAmount from '@sorare/core/src/lib/monetaryAmount';
import { qualityNames } from '@sorare/core/src/lib/players';

import { Variant } from 'components/rewards/RewardOverview/types';

import type { AnyRewardConfigText_anyRewardConfigInterface } from './__generated__/index.graphql';

const Stars = styled(Horizontal).attrs({ as: 'span', gap: 0 })`
  height: 14px;
  gap: 1px;
`;

type Props = {
  anyRewardConfig: AnyRewardConfigText_anyRewardConfigInterface;
  variant: Variant;
};

export const AnyRewardConfigText = ({ anyRewardConfig, variant }: Props) => {
  const { exchangeRate } = useConfigContext();
  const { formatNumber } = useIntl();

  if (isType(anyRewardConfig, 'CardRewardConfig') && anyRewardConfig.quality) {
    return qualityNames[anyRewardConfig.quality];
  }

  if (
    isType(anyRewardConfig, 'ProbabilisticBundleRewardConfig') &&
    anyRewardConfig.probabilisticBundleConfig.stars
  ) {
    if (variant === Variant.INLINE) {
      return `${anyRewardConfig.probabilisticBundleConfig.stars} Star`;
    }

    return (
      <Stars>
        {range(anyRewardConfig.probabilisticBundleConfig.stars).map(i => (
          <FontAwesomeIcon
            key={i}
            icon={faStar}
            color="var(--c-yellow-400)"
            width={8}
          />
        ))}
      </Stars>
    );
  }

  if (
    isType(anyRewardConfig, 'MonetaryRewardConfig') ||
    isType(anyRewardConfig, 'ConversionCreditRewardConfig')
  ) {
    const amount = isType(anyRewardConfig, 'MonetaryRewardConfig')
      ? anyRewardConfig.monetaryAmount
      : anyRewardConfig.maxDiscount;

    const usdAmount =
      new MonetaryAmount(amount).inCurrencies(exchangeRate.ethRates).usd / 100;

    return (
      <FormattedNumber
        value={usdAmount}
        style="currency"
        currency={FiatCurrency.USD}
        currencyDisplay="narrowSymbol"
        maximumFractionDigits={0}
      />
    );
  }

  let count = 1;
  if (isType(anyRewardConfig, 'CardShardRewardConfig')) {
    count = anyRewardConfig.quantity;
  } else if (
    isType(anyRewardConfig, 'CoinRewardConfig') ||
    isType(anyRewardConfig, 'InGameCurrencyRewardConfig')
  ) {
    count = anyRewardConfig.amount;
  }

  const formattedCount = formatNumber(count, {
    maximumFractionDigits: 1,
    notation: 'compact',
    compactDisplay: 'short',
  });

  return `x${formattedCount}`;
};

AnyRewardConfigText.fragments = {
  anyRewardConfigInterface: gql`
    fragment AnyRewardConfigText_anyRewardConfigInterface on AnyRewardConfigInterface {
      id
      ... on CardRewardConfig {
        id
        quality: qualityTyped
      }
      ... on CardShardRewardConfig {
        id
        quantity
      }
      ... on CoinRewardConfig {
        id
        amount
      }
      ... on InGameCurrencyRewardConfig {
        id
        amount
      }
      ... on ConversionCreditRewardConfig {
        id
        maxDiscount {
          referenceCurrency
          eur
          gbp
          usd
          wei
        }
      }
      ... on MonetaryRewardConfig {
        id
        monetaryAmount: amount {
          referenceCurrency
          eur
          gbp
          usd
          wei
        }
      }
      ... on ProbabilisticBundleRewardConfig {
        id
        probabilisticBundleConfig {
          id
          stars
        }
      }
    }
  ` as TypedDocumentNode<AnyRewardConfigText_anyRewardConfigInterface>,
};
