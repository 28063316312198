import { Suspense, useEffect, useRef } from 'react';
import { Outlet, useHref, useLocation } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import { Backdrop } from '@sorare/core/src/atoms/loader/Backdrop';
import Dialog from '@sorare/core/src/components/dialog';
import { UnblockWallet } from '@sorare/core/src/components/fiatWallet/UnblockWallet';
import {
  DialogKey,
  WithDialogs,
} from '@sorare/core/src/components/navigation/WithDialogs';
import { SidetabHandler as TypeformSidetabHandler } from '@sorare/core/src/components/typeform/SidetabHandler';
import { DepositAcceptedDialog } from '@sorare/core/src/components/wallet/BankEthAccounting/AddFundsToFiatWallet/DepositAcceptedDialog';
import { IS_PRERENDER } from '@sorare/core/src/config';
import { StyleSheetManager } from '@sorare/core/src/contexts/StyleSheetManager';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import {
  HrefLang,
  IntlProvider,
} from '@sorare/core/src/contexts/intl/Provider';
import { useCloseDialog } from '@sorare/core/src/hooks/navigation/useCloseDialog';
import { useBasenameLocale } from '@sorare/core/src/hooks/useBasenameLocale';
import useFeatureFlags, {
  useLDClient,
} from '@sorare/core/src/hooks/useFeatureFlags';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { setCurrentBackgroundColor } from '@sorare/core/src/lib/currentBackgroundColor';
import { lazy } from '@sorare/core/src/lib/retry';
import { Maintenance } from '@sorare/core/src/routing/Maintenance';

import { SpecificNewsDialogContent } from '@sorare/gameplay/src/components/activity/SpecificNews';

import AppProviders from 'AppProviders';
import { LazyBlockchainProviders } from 'components/BlockChainProviders/lazy';
import { MobileAppInit } from 'components/MobileAppInit';

const AnyCardPage = lazy(async () => import('components/AnyCardPage'));
const CreditClaimedDialog = lazy(
  async () =>
    import('@sorare/core/src/components/conversionCredit/CreditClaimedDialog')
);
const AuctionDialog = lazy(
  async () =>
    import(
      '@sorare/marketplace/src/components/buyActions/BidField/BidPaymentFlow'
    )
);

const VerifyPhoneNumber = lazy(
  async () => import('@sorare/core/src/components/user/VerifyPhoneNumber')
);

const LoanWelcomeScreen = lazy(
  async () => import('@sorare/marketplace/src/components/loan/WelcomeScreen')
);

function getOrCreateMeta(name: string): HTMLMetaElement {
  let meta = document.querySelector<HTMLMetaElement>(`meta[name=${name}]`);
  if (!meta) {
    meta = document.createElement('meta');
    meta.name = name;
    document.head.appendChild(meta);
  }
  return meta;
}

const PrerenderMeta = () => {
  // eslint-disable-next-line no-restricted-properties
  const initial = useRef(window.location.pathname);
  const location = useLocation();

  const href = useHref(location.pathname);

  useEffect(() => {
    if (href !== initial.current) {
      // https://docs.prerender.io/docs/11-best-practices
      const statusMeta = getOrCreateMeta('prerender-status-code');
      statusMeta.content = '301';
      const locationMeta = getOrCreateMeta('prerender-header');
      locationMeta.content = `Location: ${href}`;
    }
  }, [href]);
  return null;
};

export const InnerAppLayout = () => {
  const { currentUser } = useCurrentUserContext();
  const { isIosApp } = useIsMobileApp();
  const closeDialog = useCloseDialog();

  return (
    <WithDialogs
      dialogs={[
        {
          name: DialogKey.news,
          element: (
            <Suspense fallback={<Backdrop />}>
              <Dialog
                open
                maxWidth="sm"
                onClose={() => closeDialog(DialogKey.news)}
                hideHeader
              >
                <SpecificNewsDialogContent />
              </Dialog>
            </Suspense>
          ),
        },
        {
          name: DialogKey.paypalTransactionId,
          element: (
            <Suspense fallback={<Backdrop />}>
              <DepositAcceptedDialog />
            </Suspense>
          ),
        },
        {
          name: DialogKey.claimedConversionCredit,
          element: (
            <Suspense fallback={<Backdrop />}>
              <CreditClaimedDialog />
            </Suspense>
          ),
        },
        {
          name: DialogKey.card,
          element: (
            <Dialog
              open
              fullScreen
              hideHeader
              onClose={() => closeDialog(DialogKey.card)}
            >
              <Suspense fallback={<Backdrop />}>
                <AnyCardPage />
              </Suspense>
            </Dialog>
          ),
        },
        {
          name: DialogKey.auction,
          element: (
            <Suspense fallback={<Backdrop />}>
              <AuctionDialog />
            </Suspense>
          ),
        },
        {
          name: DialogKey.unblockWallet,
          element: (
            <Suspense fallback={<Backdrop />}>
              <UnblockWallet />
            </Suspense>
          ),
        },
        {
          name: DialogKey.verifyPhone,
          element: (
            <Suspense fallback={<Backdrop />}>
              <VerifyPhoneNumber
                onCancel={() => closeDialog(DialogKey.verifyPhone)}
              />
            </Suspense>
          ),
        },
        {
          name: DialogKey.loanWelcomeScreen,
          element: (
            <Suspense fallback={<Backdrop />}>
              <LoanWelcomeScreen />
            </Suspense>
          ),
        },
      ]}
    >
      {currentUser && !isIosApp ? (
        <LazyBlockchainProviders>
          <TypeformSidetabHandler />
          <Outlet />
        </LazyBlockchainProviders>
      ) : (
        <Outlet />
      )}
    </WithDialogs>
  );
};

const GlobalBackground = createGlobalStyle`
  body {
    color: var(--c-white);
    ${setCurrentBackgroundColor('var(--c-black)')}
  }
  `;

export const OuterAppLayout = () => {
  const { locale, setLocale } = useBasenameLocale();
  const client = useLDClient();
  const {
    flags: { useMaintenance },
  } = useFeatureFlags();

  if (!client) {
    return null;
  }

  if (useMaintenance?.maintenance) {
    return (
      <IntlProvider locale={locale} setLocale={setLocale}>
        <Maintenance
          startDate={new Date(useMaintenance.start_date)}
          endDate={new Date(useMaintenance.end_date)}
        />
      </IntlProvider>
    );
  }

  return (
    <>
      <GlobalBackground />
      <MobileAppInit />
      <HrefLang />
      {IS_PRERENDER && <PrerenderMeta />}
      <IntlProvider locale={locale} setLocale={setLocale}>
        <StyleSheetManager>
          <AppProviders>
            <Outlet />
          </AppProviders>
        </StyleSheetManager>
      </IntlProvider>
    </>
  );
};
