import { Outlet } from 'react-router-dom';

import { getRoutesFromFiles } from '@sorare/routing';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Dialog } from '@sorare/core/src/components/dialog';
import {
  DialogKey,
  WithDialogs,
} from '@sorare/core/src/components/navigation/WithDialogs';
import SportSwitcher from '@sorare/core/src/contexts/sport/SportSwitcher';
import { lazy } from '@sorare/core/src/lib/retry';
import { RequireAuth } from '@sorare/core/src/routing/RequireAuth';

/*
 * Prefer dynamic over static import into this file
 * because static ones will end up in the main bundle
 */
const BundledAuctionPage = lazy(
  async () => import('components/auction/BundledAuctionPage')
);

const DeliverableItemOrderFormRoutedDialog = lazy(
  async () =>
    import(
      '@sorare/gameplay/src/components/rewards/deliverableItem/DeliverableItemOrderFormRoutedDialog'
    )
);

export const WithDialogLayout = () => {
  return (
    <WithDialogs
      dialogs={[
        {
          name: DialogKey.deliveryItemOrder,
          element: (
            <RequireAuth redirectTo="signup">
              <DeliverableItemOrderFormRoutedDialog />
            </RequireAuth>
          ),
        },
        {
          name: DialogKey.bundledAuction,
          element: (
            <Dialog open fullScreen>
              <BundledAuctionPage />
            </Dialog>
          ),
        },
      ]}
    >
      <SportSwitcher sport={Sport.FOOTBALL} />
      <Outlet />
    </WithDialogs>
  );
};

export const routeObjects = [
  {
    element: <WithDialogLayout />,
    children: [
      ...getRoutesFromFiles(
        import.meta.glob('./**/(page|layout).tsx'),
        '../../packages/football/src/pages'
      ),
    ],
  },
];
