import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import { Vertical } from 'atoms/layout/flex';
import { BodyM, HeadlineS, LabelM } from 'atoms/typography';

const StyledVertical = styled(Vertical)`
  padding: var(--double-unit);
  background: var(--c-nd-50);
  border-radius: var(--double-unit);
`;

export type Props = {
  displayName: string;
  items: { question: string; answer: string }[];
};

export const MoreAbout = ({ displayName, items }: Props) => {
  return (
    <>
      <Helmet
        script={[
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'FAQPage',
              mainEntity: items.map(({ question, answer }) => ({
                '@type': 'Question',
                name: question,
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: answer,
                },
              })),
            }),
          },
        ]}
      />
      <FullWidthContainer>
        <Vertical gap={1.5}>
          <HeadlineS as="h2">
            <FormattedMessage
              id="Seo.MoreAbout.moreAbout"
              defaultMessage="More about {displayName}"
              values={{ displayName }}
            />
          </HeadlineS>
          <StyledVertical gap={1.5}>
            {items.map(({ question, answer }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Vertical key={index} gap={0}>
                <LabelM as="h3" color="var(--c-nd-600)">
                  {question}
                </LabelM>
                <BodyM as="p">{answer}</BodyM>
              </Vertical>
            ))}
          </StyledVertical>
        </Vertical>
      </FullWidthContainer>
    </>
  );
};
