import { useIntl } from 'react-intl';

import { DivisionChange } from '@sorare/core/src/__generated__/globalTypes';
import { Neutral } from '@sorare/core/src/atoms/icons/Neutral';
import { Promotion } from '@sorare/core/src/atoms/icons/Promotion';
import { Relegation } from '@sorare/core/src/atoms/icons/Relegation';

import { messages } from '../messages';

export const icons = {
  [DivisionChange.PROMOTED]: Promotion,
  [DivisionChange.RELEGATED]: Relegation,
  [DivisionChange.STABLE]: Neutral,
};

type Props = {
  divisionChange: DivisionChange;
  title?: string;
};

export const DumbStatus = ({ divisionChange, title }: Props) => {
  const { formatMessage } = useIntl();

  const Component = icons[divisionChange];

  return <Component title={title || formatMessage(messages[divisionChange])} />;
};
