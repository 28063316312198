import { unitMapping } from 'lib/style';

type Props = {
  size?: keyof typeof unitMapping;
  className?: string;
  gradient?: 'purple';
};

export const Shards = ({ size = 2, className, gradient }: Props) => {
  let fill = 'currentColor';
  if (gradient) {
    fill = `url(#essence-gradient-${gradient})`;
  }

  return (
    <svg
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 36"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8175 4.07802L13.2278 0.637207L12.272 3.57872L8.91913 2.4893L7.67986 6.30338L8.57917 6.59558L2.23587 26.1182L1.35585 25.8323L0.116577 29.6464L17.6093 35.3301L18.8486 31.516L17.8356 31.1869L24.1789 11.6642L25.1726 11.9871L26.4118 8.17302L22.8618 7.01953L23.8175 4.07802ZM19.3792 14.0468L11.0617 11.3443L7.21493 23.1836L16.4105 23.1836L19.3792 14.0468Z"
        fill={fill}
      />
      <defs>
        <linearGradient
          id="essence-gradient-purple"
          x1="23.9207"
          y1="39.6667"
          x2="7.01138"
          y2="22.5093"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0974" />
          <stop offset="1" stopColor="#AD00FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
