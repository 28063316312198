import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Rarity } from '__generated__/globalTypes';
import { Button } from 'atoms/buttons/Button';
import { SorareLogo } from 'atoms/icons/SorareLogo';
import { ConditionalWrapper } from 'atoms/layout/ConditionalWrapper';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { BodyM, HeadlineXL } from 'atoms/typography';
import { ScarcityBackground } from 'components/card/ScarcityBackground';
import { ANY_SPORT_MY_CARDS_BOXES } from 'constants/__generated__/routes';
import { useSportContext } from 'contexts/sport';
import { isType } from 'gql';
import { scarcityNames } from 'lib/cards';
import { generateSportPath } from 'lib/routing/generateSportPath';
import { tabletAndAbove } from 'style/mediaQuery';

import { CardReward } from '../CardReward';
import { CardShardsReward } from '../CardShardsReward';
import { ProbabilisticBundle } from '../ProbabilisticBundle';
import { actionsAnimation, textAnimation } from '../ui';
import { CongratsScreen_anyReward } from './__generated__/index.graphql';

const Root = styled(Vertical)`
  position: relative;
  height: 100%;
  text-align: center;
  justify-content: flex-start;
  color: var(--c-white);
  margin: 0 auto;
  @media ${tabletAndAbove} {
    justify-content: center;
  }
`;
const LogoCtn = styled(Horizontal)`
  ${textAnimation};
`;

const Title = styled(HeadlineXL)`
  ${textAnimation};
  width: 100%;
  text-shadow: 0px 0px 40px rgba(255, 255, 255, 0.58);
  .showAdditionalRewards & {
    animation: none;
    transition: transform 0.5s;
    transform: translateX(-100%);
    padding: 0 var(--quadruple-unit);
  }
`;

const Subtitle = styled(BodyM)`
  width: 100%;
  ${textAnimation}
  .showAdditionalRewards & {
    animation: none;
    transition: transform 0.6s;
    transform: translateX(-100%);
    padding: 0 var(--quadruple-unit);
  }
`;

const SlideShow = styled(Horizontal)`
  width: 100%;
  flex-wrap: nowrap;
  align-items: stretch;
`;

const CardSlideShow = styled(Horizontal)`
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding-block: 24px;
  @media ${tabletAndAbove} {
    .isBundle & {
      height: 415px;
    }
  }
`;

const MainColumn = styled(Vertical)`
  width: 50%;
  .showAdditionalRewards.isBundle & {
    width: 0;
    opacity: 0;
    transition: all 0.3s;
    overflow: hidden;
  }
`;

const Column = styled(Vertical)`
  width: 50%;
  .isBundle & {
    width: calc(30% - var(--unit));
  }
`;

const Grid = styled(Horizontal)`
  width: 0;
  .showAdditionalRewards & {
    width: 100%;
  }
  flex-wrap: wrap;
  justify-content: center;
`;
const NoShrinkColumn = styled(Vertical)`
  width: 100%;
  flex-shrink: 0;
  transition: all 0.5s;
`;

const Reward = styled(Horizontal).attrs({ gap: 0, center: true })`
  width: 0;
  flex-grow: 0;
  flex-shrink: 0;
  transition: all 0.5s;
  align-self: stretch;

  .showAdditionalRewards & {
    width: 50%;
  }
  .showAdditionalRewards.isBundle & {
    width: calc(33% - var(--unit));
  }
`;

const Actions = styled(Vertical)`
  flex-grow: 1;
  max-width: 340px;
  width: 100%;
  gap: var(--double-unit);
  justify-content: center;
  ${actionsAnimation};
`;
const ActionsCtn = styled(Vertical)`
  height: 88px;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

export type Props = {
  main: ReactNode;
  additionalRewards?: Nullable<CongratsScreen_anyReward[]>;
  actions?: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
  rarityBackground: Rarity;
  christmasBackground?: boolean;
  isBundle: boolean;
  onClose?: () => void;
};

export const CongratsScreen = ({
  main,
  actions: actionsProp,
  additionalRewards,
  title,
  subtitle,
  rarityBackground,
  christmasBackground,
  isBundle,
  onClose,
}: Props) => {
  const { sport } = useSportContext();
  const hasAdditionalRewards = (additionalRewards?.length || 0) > 0;
  const additionalReward = additionalRewards?.[0];
  const [showAdditionalRewards, setShowAdditionalRewards] = useState(false);

  const hasBoxReward =
    hasAdditionalRewards &&
    !isBundle &&
    isType(additionalReward, 'ProbabilisticBundleReward');

  const actions =
    actionsProp || hasBoxReward ? (
      <>
        {actionsProp}
        {hasBoxReward && (
          <Button
            size="medium"
            color="transparent"
            fullWidth
            to={generateSportPath(ANY_SPORT_MY_CARDS_BOXES, {
              sport,
            })}
            onClick={onClose}
          >
            <FormattedMessage
              id="ClubShop.ItemPreviewDialog.Cta.Inventory"
              defaultMessage="Go to inventory"
            />
          </Button>
        )}
      </>
    ) : null;

  return (
    <>
      <ScarcityBackground
        rarity={rarityBackground}
        christmas={christmasBackground}
      />
      <Root
        gap={1.5}
        center
        className={classNames({ isBundle, showAdditionalRewards })}
      >
        <LogoCtn center>
          <SorareLogo />
        </LogoCtn>
        <SlideShow gap={0}>
          <NoShrinkColumn center>
            <Title brand>{title}</Title>
            <Subtitle bold>{subtitle}</Subtitle>
          </NoShrinkColumn>
          {hasAdditionalRewards && (
            <NoShrinkColumn center>
              <Title brand>
                <FormattedMessage
                  id="buyTokenConfirmation.rewards.title"
                  defaultMessage="There's{br}More"
                  values={{ br: <br /> }}
                />
              </Title>
              <Subtitle bold>
                {!isBundle && isType(additionalReward, 'AnyCardReward') && (
                  <FormattedMessage
                    id="buyTokenConfirmation.buy.cardReward.subtitle"
                    defaultMessage="2 for 1, {playerDisplayName} is also joining your team!"
                    values={{
                      playerDisplayName:
                        additionalReward.card.anyPlayer.displayName,
                    }}
                  />
                )}
                {!isBundle && isType(additionalReward, 'CardShardsReward') && (
                  <FormattedMessage
                    id="buyTokenConfirmation.buy.cardShardReward.subtitle2"
                    defaultMessage="You also received {quantity} {rarity} essence {quantity, plural, one {capsule} other {capsules}}!"
                    values={{
                      quantity: additionalReward.quantity,
                      rarity: scarcityNames[additionalReward.rarity],
                    }}
                  />
                )}
                {!isBundle &&
                  isType(additionalReward, 'ProbabilisticBundleReward') && (
                    <FormattedMessage
                      id="buyTokenConfirmation.buy.probabilisticBundle.subtitle"
                      defaultMessage="You also received a {name}!"
                      values={{
                        name: additionalReward.probabilisticBundle.config.name,
                      }}
                    />
                  )}
              </Subtitle>
            </NoShrinkColumn>
          )}
        </SlideShow>
        <CardSlideShow gap={0}>
          <MainColumn center>{main}</MainColumn>
          <ConditionalWrapper wrap={isBundle} Wrapper={Grid}>
            {showAdditionalRewards &&
              additionalRewards?.map(r => {
                if (isType(r, 'CardShardsReward'))
                  return (
                    <Reward key={r.id}>
                      <CardShardsReward reward={r} />
                    </Reward>
                  );

                if (isType(r, 'AnyCardReward'))
                  return (
                    <Column key={r.id}>
                      <CardReward reward={r} />
                    </Column>
                  );

                if (isType(r, 'ProbabilisticBundleReward'))
                  return (
                    <Reward key={r.id}>
                      <ProbabilisticBundle
                        withEnterAnimation
                        probabilisticBundleReward={r}
                      />
                    </Reward>
                  );
                return null;
              })}
          </ConditionalWrapper>
        </CardSlideShow>
        <ActionsCtn>
          {hasAdditionalRewards && !showAdditionalRewards ? (
            <Actions key="1">
              <Button
                size="medium"
                color="secondary"
                onClick={() => setShowAdditionalRewards(!showAdditionalRewards)}
              >
                <FormattedMessage
                  id="buyTokenConfirmation.buy.showRewards"
                  defaultMessage="And..."
                />
              </Button>
            </Actions>
          ) : (
            actions && <Actions key="2">{actions}</Actions>
          )}
        </ActionsCtn>
      </Root>
    </>
  );
};

CongratsScreen.fragments = {
  anyReward: gql`
    fragment CongratsScreen_anyReward on AnyRewardInterface {
      id
      ... on AnyCardReward {
        id
        ...CardReward_anyCardReward
      }
      ... on CardShardsReward {
        id
        ...CardShardsReward_cardShardsReward
      }
      ... on ProbabilisticBundleReward {
        id
        ...ProbabilisticBundle_probabilisticBundleReward
      }
    }
    ${CardShardsReward.fragments.cardShardsReward}
    ${ProbabilisticBundle.fragments.probabilisticBundleReward}
    ${CardReward.fragments.anyCardReward}
  ` as TypedDocumentNode<CongratsScreen_anyReward>,
};
