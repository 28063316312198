import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import jersey from '@sorare/core/src/assets/icons/jerseys.png';
import { ScarcityIcon } from '@sorare/core/src/atoms/icons/ScarcityIcon';
import { ArenaTicket } from '@sorare/core/src/atoms/icons/rivals/branded/ArenaTicket';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyM } from '@sorare/core/src/atoms/typography';
import { Chip } from '@sorare/core/src/atoms/ui/Chip';
import coins from '@sorare/core/src/components/conversionCredit/assets/coins.png';
import {
  EssenceRewardCount,
  RewardCount,
} from '@sorare/core/src/components/rewards/RewardCount';
import { ANY_SPORT_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY } from '@sorare/core/src/constants/__generated__/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { isType } from '@sorare/core/src/gql';
import { scarcityNames } from '@sorare/core/src/lib/cards';
import { rivals } from '@sorare/core/src/lib/glossary';
import { qualityNames } from '@sorare/core/src/lib/players';
import { shardsIconUrl } from '@sorare/core/src/lib/rewards';

import { Probability } from '../Probability';
import {
  BoxOdds_probabilisticBundleConfig,
  BoxOdds_so5League,
} from './__generated__/index.graphql';

const List = styled(Vertical)`
  margin: 0;
  padding: 0;
`;
const Line = styled(Horizontal)`
  border-radius: var(--unit);
  padding: var(--double-unit);
  background: var(--c-nd-50);
`;
const RewardImage = styled.img`
  width: var(--double-unit);
`;
const Right = styled.div`
  margin-left: auto;
`;

export const messages = defineMessages({
  discountCredits: {
    id: 'ConversionCreditTinyBanner.discountCredits',
    defaultMessage: '{maxDiscount} credits',
  },
  discountCreditsUpTo: {
    id: 'ConversionCreditTinyBanner.discountCreditsUpTo',
    defaultMessage: '{percentageDiscount}% credits (up to { maxDiscount })',
  },
});

type Props = {
  boxConfig: BoxOdds_probabilisticBundleConfig;
  so5League?: BoxOdds_so5League;
};

export const BoxOdds = ({ boxConfig, so5League }: Props) => {
  const { formatMessage, formatNumber } = useIntl();
  const { generateSportContextPath } = useSportContext();
  const { slots } = boxConfig;

  const normalizedSlots: {
    to?: string;
    icon: ReactNode;
    label: ReactNode;
    chip?: ReactNode;
    probability: number;
  }[] = slots.flatMap(({ probableConfigs }) => {
    return probableConfigs.flatMap(
      ({
        arenaTickets,
        probability,
        cards,
        rewardShopItems,
        deliverableItems,
        conversionCredit,
        cardShards,
      }) => {
        return [
          ...cards.map(({ quality, rarity }) => {
            const isLink = !!quality && !!so5League;

            return {
              to: isLink
                ? generateSportContextPath(
                    ANY_SPORT_REWARDS_FIXTURE_LEAGUE_QUALITY_RARITY,
                    {
                      params: {
                        fixture: so5League.so5Fixture.slug,
                        league: so5League.slug,
                        rarity,
                        quality: quality.toLowerCase(),
                      },
                    }
                  )
                : undefined,
              icon: <ScarcityIcon scarcity={rarity} />,
              label: scarcityNames[rarity],
              chip: quality && <Chip>{qualityNames[quality]}</Chip>,
              probability,
            };
          }),
          ...rewardShopItems.map(({ shopItem, quantity }) => {
            return {
              icon: <RewardImage src={shopItem.pictureUrl} alt="" width={16} />,
              label: (
                <RewardCount quantity={quantity} rewardName={shopItem.name} />
              ),
              probability,
            };
          }),
          arenaTickets > 0 && {
            icon: <ArenaTicket width={16} />,
            label: (
              <RewardCount
                quantity={arenaTickets}
                rewardName={formatMessage(rivals.arenaTicket)}
              />
            ),
            probability,
          },
          ...deliverableItems.map(({ quantity, deliverableItem }) => {
            return {
              icon: isType(deliverableItem, 'JerseyDeliverableItem') && (
                <RewardImage src={jersey} alt="" width={16} />
              ),
              label: (
                <RewardCount
                  quantity={quantity}
                  rewardName={deliverableItem.name}
                />
              ),
              probability,
            };
          }),
          conversionCredit && {
            icon: <img src={coins} alt="" width={16} />,
            label: (
              <FormattedMessage
                {...messages.discountCreditsUpTo}
                values={{
                  percentageDiscount: conversionCredit.percentageDiscount * 100,
                  maxDiscount: formatNumber(
                    +(conversionCredit.maxDiscount?.usd || 0) / 100,
                    {
                      style: 'currency',
                      currency: 'USD',
                    }
                  ),
                }}
              />
            ),
            probability,
          },
          ...cardShards.map(shard => {
            return {
              icon: <img src={shardsIconUrl(shard.rarity)} alt="" width={16} />,
              label: (
                <EssenceRewardCount
                  quantity={shard.quantity}
                  rarity={shard.rarity}
                />
              ),
              probability,
            };
          }),
        ].filter(Boolean);
      }
    );
  });

  return (
    <List as="ul">
      {normalizedSlots.map((slot, index) => (
        <Line
          as={slot.to ? Link : 'li'}
          to={slot.to}
          target="_blank"
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          {slot.icon}
          <BodyM as="h3" bold>
            {slot.label}
          </BodyM>

          {slot.chip}
          <Right>
            <Probability probability={slot.probability} />
          </Right>
        </Line>
      ))}
    </List>
  );
};

BoxOdds.fragments = {
  probabilisticBundleConfig: gql`
    fragment BoxOdds_probabilisticBundleConfig on ProbabilisticBundleConfig {
      id
      slots {
        probableConfigs {
          arenaTickets
          probability
          deliverableItems {
            quantity
            deliverableItem {
              slug
              id
              name
            }
          }
          cards {
            quality
            rarity
          }
          rewardShopItems {
            quantity
            shopItem {
              id
              pictureUrl
              name
            }
          }
          conversionCredit {
            id
            maxDiscount {
              usd
            }
            percentageDiscount
          }
          cardShards {
            id
            quantity
            rarity
          }
        }
      }
    }
  ` as TypedDocumentNode<BoxOdds_probabilisticBundleConfig>,
  so5League: gql`
    fragment BoxOdds_so5League on So5League {
      slug
      so5Fixture {
        slug
      }
    }
  ` as TypedDocumentNode<BoxOdds_so5League>,
};
