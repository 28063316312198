import { TypedDocumentNode, gql } from '@apollo/client';

import gameWeeksUrl from 'components/card/Overlay/Stats/assets/stats/game_weeks.svg';
import highestScoreUrl from 'components/card/Overlay/Stats/assets/stats/highest_score.svg';
import rewardsUrl from 'components/card/Overlay/Stats/assets/stats/rewards.svg';
import { drawHightlightedStat } from 'components/card/Overlay/Stats/drawHighlightedStat';
import { drawOwner } from 'components/card/Overlay/Stats/drawOwner';
import { drawPlayerName } from 'components/card/Overlay/Stats/drawPlayerName';
import { drawStat } from 'components/card/Overlay/Stats/drawStat';
import { loadTypekitFonts } from 'lib/fonts';
import { withFragments } from 'lib/gql';

import { footballStatsOverlay2024_AnyCardInterface } from './__generated__/index.graphql';

export const footballStatsOverlay2024 = withFragments(
  (card: footballStatsOverlay2024_AnyCardInterface) => {
    if (!card.cardStats) {
      return undefined;
    }
    return async (image: ImageBitmap) => {
      const canvas = document.createElement('canvas');
      const WIDTH = image.width;
      const HEIGHT = image.height;

      // not all card backs are the same size
      const scale = WIDTH / 771;

      canvas.width = WIDTH;
      canvas.height = HEIGHT;

      const so5RewardsCount = card.cardStats?.so5RewardsCount ?? 0;
      const bestScore = Math.floor(
        card.cardStats?.bestSo5Appearance?.score ?? 0
      );

      const ctx = canvas.getContext('2d')!;
      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      // draw texture
      ctx.drawImage(image, 0, 0);

      const promises: Promise<void>[] = [];
      // load rigid square font
      await loadTypekitFonts('vxh4mak');

      drawPlayerName({
        ctx,
        y: HEIGHT * 0.3,
        x: WIDTH / 2,
        card,
        scale,
        fontName: 'rigid-square',
      });

      promises.push(
        drawOwner({
          ctx,
          width: WIDTH,
          card,
          y: 513 * scale,
          scale,
          fontName: 'rigid-square',
        })
      );
      const highlightedStat = card.cardStats?.highlightedStats[0];

      //  Game weeks
      promises.push(
        drawStat({
          ctx,
          value: card.cardStats?.so5LineupsCount ?? 0,
          label: 'GAME WEEKS',
          iconUrl: gameWeeksUrl,
          x: 83 * scale,
          y: 838 * scale,
          fontName: 'rigid-square',
          scale,
        })
      );

      // Highest score
      promises.push(
        drawStat({
          ctx,
          value: bestScore,
          label: 'HIGHEST SCORE',
          iconUrl: highestScoreUrl,
          x: 285 * scale,
          y: 727 * scale,
          fontName: 'rigid-square',
          scale,
        })
      );

      // Rewards
      promises.push(
        drawStat({
          ctx,
          value: so5RewardsCount,
          label: 'REWARDS',
          iconUrl: rewardsUrl,
          x: 484 * scale,
          y: 838 * scale,
          scale,
          fontName: 'rigid-square',
        })
      );

      // highlighted stat
      if (highlightedStat) {
        promises.push(
          drawHightlightedStat({
            ctx,
            card,
            x: 247 * scale,
            y: 1007 * scale,
            fontName: 'rigid-square',
            scale,
          })
        );
      }
      await Promise.all(promises);

      return createImageBitmap(canvas);
    };
  },
  {
    AnyCardInterface: gql`
      fragment footballStatsOverlay2024_AnyCardInterface on AnyCardInterface {
        slug

        cardStats {
          id
          so5LineupsCount
          so5RewardsCount
          bestSo5Appearance {
            id
            score
          }
        }
        ...drawOwner_AnyCardInterface
        ...drawPlayerName_AnyCardInterface
        ...drawHightlightedStat_AnyCardInterface
      }
      ${drawOwner.fragments.AnyCardInterface}
      ${drawPlayerName.fragments.AnyCardInterface}
      ${drawHightlightedStat.fragments.AnyCardInterface}
    ` as TypedDocumentNode<footballStatsOverlay2024_AnyCardInterface>,
  }
);
