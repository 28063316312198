import { TypedDocumentNode, gql } from '@apollo/client';

import { BlueprintRevealStatus } from '__generated__/globalTypes';
import { loadTypekitFonts } from 'lib/fonts';
import { withFragments } from 'lib/gql';

import type {
  celebrationNormalOverlay_AnyCardInterface,
  celebrationOverlay_AnyCardInterface,
} from './__generated__/celebration.graphql';
import celebrationLockedUrl from './assets/celebration_locked.png';
import celebrationUnlockedUrl from './assets/celebration_unlocked.png';
import { drawImage } from './utils/drawImage';
import { getBoundingBox } from './utils/getBoundingBox';

export const celebrationOverlay = withFragments(
  (card: celebrationOverlay_AnyCardInterface) => {
    const { blueprint } = card;
    if (!blueprint) {
      return undefined;
    }
    return async (image: ImageBitmap) => {
      const canvas = document.createElement('canvas');
      const WIDTH = image.width;
      const HEIGHT = image.height;

      canvas.width = WIDTH;
      canvas.height = HEIGHT;

      const state = blueprint.revealStatus;

      const ctx = canvas.getContext('2d')!;
      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      // draw texture
      ctx.drawImage(image, 0, 0);

      const promises: Promise<void>[] = [];

      const revealable = [
        BlueprintRevealStatus.REVEALABLE,
        BlueprintRevealStatus.REVEALED,
      ].includes(state);

      promises.push(
        drawImage({
          ctx,
          imageUrl: revealable ? celebrationUnlockedUrl : celebrationLockedUrl,
          boundingBox: getBoundingBox({
            width: 899,
            height: 638,
            containerWidth: 1211,
            containerHeight: 1916,
            top: 600,
          }),
          globalCompositeOperation: 'overlay',
        })
      );

      // load rigid square font
      if (revealable) {
        promises.push(
          (async () => {
            await loadTypekitFonts('vxh4mak');
            ctx.globalCompositeOperation = 'overlay';
            // ctx.globalAlpha = 0.5;
            ctx.font = `700 80px 'rigid-square'`;
            ctx.fillStyle = '#fff';
            ctx.textAlign = 'center';
            ctx.fillText(
              'TIME TO',
              canvas.width / 2,
              (canvas.height * 1300) / 1916
            );
            ctx.fillText(
              'CELEBRATE',
              canvas.width / 2,
              (canvas.height * 1380) / 1916
            );
          })()
        );
      }

      await Promise.all(promises);

      return createImageBitmap(canvas);
    };
  },
  {
    AnyCardInterface: gql`
      fragment celebrationOverlay_AnyCardInterface on AnyCardInterface {
        slug
        blueprint {
          id
          revealStatus
        }
      }
    ` as TypedDocumentNode<celebrationOverlay_AnyCardInterface>,
  }
);

export const celebrationNormalOverlay = withFragments(
  (card: celebrationOverlay_AnyCardInterface) => {
    const { blueprint } = card;
    const state = blueprint?.revealStatus;
    const revealable =
      !!state &&
      [
        BlueprintRevealStatus.REVEALABLE,
        BlueprintRevealStatus.REVEALED,
      ].includes(state);

    if (!blueprint || revealable) {
      return undefined;
    }

    return async (image: ImageBitmap) => {
      const canvas = document.createElement('canvas');
      const WIDTH = image.width;
      const HEIGHT = image.height;

      canvas.width = WIDTH;
      canvas.height = HEIGHT;

      const ctx = canvas.getContext('2d')!;
      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      // draw texture
      ctx.drawImage(image, 0, 0);

      const promises: Promise<void>[] = [];

      promises.push(
        (async () => {
          // load rigid square font
          await loadTypekitFonts('vxh4mak');
          ctx.globalCompositeOperation = 'overlay';
          ctx.font = `700 70px rigid-square`;
          ctx.fillStyle = '#956ffe';
          ctx.textAlign = 'center';
          ctx.fillText(
            'PERFORMANCE',
            canvas.width / 2,
            (canvas.height * 1300) / 1916
          );
          ctx.fillText(
            'SPARKS CELEBRATION',
            canvas.width / 2,
            (canvas.height * 1380) / 1916
          );
        })()
      );

      await Promise.all(promises);

      return createImageBitmap(canvas);
    };
  },
  {
    AnyCardInterface: gql`
      fragment celebrationNormalOverlay_AnyCardInterface on AnyCardInterface {
        slug
        blueprint {
          id
          revealStatus
        }
      }
    ` as TypedDocumentNode<celebrationNormalOverlay_AnyCardInterface>,
  }
);
