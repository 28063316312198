import { BoundingBox } from './types';

export const getBoundingBox = ({
  width,
  height,
  top,
  left,
  containerWidth,
  containerHeight,
}: {
  width: number;
  height: number;
  top: number;
  left?: number;
  containerWidth: number;
  containerHeight: number;
}): BoundingBox => ({
  top: top / containerHeight,
  left:
    // if left is not defined, center the content
    (left !== undefined ? left : (containerWidth - width) / 2) / containerWidth,
  width: width / containerWidth,
  height: height / containerHeight,
});
