import { useEffect, useSyncExternalStore } from 'react';

import { local as localStorage } from 'lib/storage';

export const STORAGE = {
  showOnlyGamesWithLinedUpPlayers: 'showOnlyGamesWithLinedUpPlayers',
  sortingOrderSchedule: 'sortingOrderSchedule',
  includeCommonsSchedule: 'includeCommonsSchedule',
  mlbGameViewStats: 'mlbGameViewStats',
  savedHistory: 'savedHistory',
  rivalsSortBy: 'rivalsSortBy',
  trackingConsent: 'trackingConsent',
  mlbMonthlyChaseLastPreferredScarcity: 'mlbMonthlyChaseLastPreferredScarcity',
  useRedDotNotification: 'useRedDotNotification',
  useBaseballRedDotNotification: 'useBaseballRedDotNotification',
  useNbaRedDotNotification: 'useNbaRedDotNotification',
  sportLineupStyle: 'sportLineupStyle',
  mlbOffseasonCountdownReminder: 'mlbOffseasonCountdownReminder',
  sport: 'sport',
} as const;

let callbacks: (() => void)[] = [];
const emitChange = () => {
  for (const callback of callbacks) {
    callback();
  }
};
const subscribe = (callback: () => void) => {
  callbacks = [...callbacks, callback];
  window.addEventListener('storage', callback);
  return () => {
    callbacks = callbacks.filter(l => l !== callback);
    window.removeEventListener('storage', callback);
  };
};

export default <T>(
  key: string,
  initialValue: T,
  serializer: (value: T | null) => string = JSON.stringify,
  parser: (value: string) => T = JSON.parse
) => {
  const parseSnapshot = (value: string | null) => {
    try {
      return value ? parser(value) : null;
    } catch {
      return null;
    }
  };
  const getSnapshot = () => {
    return localStorage.getItem(key);
  };
  const store = useSyncExternalStore(subscribe, getSnapshot);

  const setStore = (value: (T | null) | ((previousValue: T) => T)) => {
    const currentValue = parseSnapshot(store);
    const serializedValue = serializer(
      value instanceof Function ? value(currentValue || initialValue) : value
    );
    localStorage.setItem(key, serializedValue);
    emitChange();
  };
  const shouldInitialize = store === null && initialValue;

  useEffect(() => {
    if (shouldInitialize) {
      localStorage.setItem(key, serializer(initialValue));
    }
  }, [shouldInitialize, initialValue, key, serializer]);

  return [parseSnapshot(store) ?? initialValue, setStore] as const;
};
