import { PrintablePlayerSortName } from '@sorare/core/src/contexts/config';

export const SORTS: PrintablePlayerSortName[] = [
  'DESC-L15',
  'ASC-L15',
  'ASC-L5',
  'DESC-L5',
  'DESC-L15AA',
  'ASC-L15AA',
  'DESC-L15D',
  'ASC-L15D',
  'ASC-name_for_sorting',
  'DESC-name_for_sorting',
  'ASC-average_mins_played',
  'DESC-average_mins_played',
  'ASC-average_set_piece_taken',
  'DESC-average_set_piece_taken',
  'ASC-average_goals',
  'DESC-average_goals',
  'ASC-average_goal_assist',
  'DESC-average_goal_assist',
  'ASC-average_assist_penalty_won',
  'DESC-average_assist_penalty_won',
  'ASC-average_last_man_tackle',
  'DESC-average_last_man_tackle',
  'ASC-average_clean_sheet',
  'DESC-average_clean_sheet',
  'ASC-average_yellow_card',
  'DESC-average_yellow_card',
  'ASC-average_red_card',
  'DESC-average_red_card',
  'ASC-average_error_lead_to_goal',
  'DESC-average_error_lead_to_goal',
  'ASC-average_penalty_conceded',
  'DESC-average_penalty_conceded',
  'ASC-average_three_goals_conceded',
  'DESC-average_three_goals_conceded',
  'ASC-total_mins_played',
  'DESC-total_mins_played',
  'ASC-total_set_piece_taken',
  'DESC-total_set_piece_taken',
  'ASC-total_goals',
  'DESC-total_goals',
  'ASC-total_goal_assist',
  'DESC-total_goal_assist',
  'ASC-total_assist_penalty_won',
  'DESC-total_assist_penalty_won',
  'ASC-total_last_man_tackle',
  'DESC-total_last_man_tackle',
  'ASC-total_clean_sheet',
  'DESC-total_clean_sheet',
  'ASC-total_yellow_card',
  'DESC-total_yellow_card',
  'ASC-total_red_card',
  'DESC-total_red_card',
  'ASC-total_error_lead_to_goal',
  'DESC-total_error_lead_to_goal',
  'ASC-total_penalty_conceded',
  'DESC-total_penalty_conceded',
  'ASC-total_three_goals_conceded',
  'DESC-total_three_goals_conceded',
  'ASC-floor_prices_in_season_limited',
  'DESC-floor_prices_in_season_limited',
  'ASC-floor_prices_in_season_rare',
  'DESC-floor_prices_in_season_rare',
  'ASC-floor_prices_in_season_super_rare',
  'DESC-floor_prices_in_season_super_rare',
  'ASC-floor_prices_in_season_unique',
  'DESC-floor_prices_in_season_unique',
  'ASC-floor_prices_all_seasons_limited',
  'DESC-floor_prices_all_seasons_limited',
  'ASC-floor_prices_all_seasons_rare',
  'DESC-floor_prices_all_seasons_rare',
  'ASC-floor_prices_all_seasons_super_rare',
  'DESC-floor_prices_all_seasons_super_rare',
  'ASC-floor_prices_all_seasons_unique',
  'DESC-floor_prices_all_seasons_unique',
  'ASC-ib_prices_limited',
  'DESC-ib_prices_limited',
  'ASC-last_thirty_days_subscription_count',
  'DESC-last_thirty_days_subscription_count',
  'ASC-starter_odds_basis_points',
  'DESC-starter_odds_basis_points',
];
