import { type TypedDocumentNode, gql } from '@apollo/client';
import styled, { css } from 'styled-components';

import {
  InGameCurrency,
  Rarity,
} from '@sorare/core/src/__generated__/globalTypes';
import { Fiat } from '@sorare/core/src/atoms/icons/Fiat';
import { NewScarcityIcon } from '@sorare/core/src/atoms/icons/ScarcityIcon';
import { FRONTEND_ASSET_HOST } from '@sorare/core/src/constants/assets';
import { shardsIconUrl } from '@sorare/core/src/lib/rewards';
import { toSnakeCase } from '@sorare/core/src/lib/toSnakeCase';

import type { AnyRewardConfigImage_anyRewardConfigInterface } from './__generated__/index.graphql';

const StyledImg = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
`;
const forcedComponentStyle = css`
  width: 90% !important;
  height: unset !important;
  aspect-ratio: 1/1;
`;
const StyledFiat = styled(Fiat)`
  ${forcedComponentStyle}
`;
const StyledCard = styled(NewScarcityIcon)`
  ${forcedComponentStyle}
`;

type Props = {
  anyRewardConfig: AnyRewardConfigImage_anyRewardConfigInterface;
  rarity?: Rarity;
};

const getRewardImage = (
  anyRewardConfig: AnyRewardConfigImage_anyRewardConfigInterface,
  rarity?: Rarity
) => {
  switch (anyRewardConfig.__typename) {
    case 'CoinRewardConfig':
      return 'coin';
    case 'InGameCurrencyRewardConfig':
      if (anyRewardConfig.currency === InGameCurrency.OLD_CLUB_SHOP_COIN)
        return 'coin';
      return '';
    case 'ConversionCreditRewardConfig':
      return 'credit';
    case 'ProbabilisticBundleRewardConfig':
      return `box-${toSnakeCase(rarity || Rarity.common)}`;
    case 'ShopItemRewardConfig':
      return `level-up-${toSnakeCase(rarity || Rarity.limited)}`;
    case 'DeliverableItemRewardConfig':
      return {
        ExperienceDeliverableItem: '',
        JerseyDeliverableItem: 'jersey',
        TicketsDeliverableItem: 'tickets',
        WearableDeliverableItem: 'merch',
      }[anyRewardConfig.deliverableItem.__typename];
    default:
      return '';
  }
};

const REWARDS_ASSETS_URL = `${FRONTEND_ASSET_HOST}/football/rewards`;

export const AnyRewardConfigImage = ({ anyRewardConfig, rarity }: Props) => {
  switch (anyRewardConfig.__typename) {
    case 'MonetaryRewardConfig':
      return <StyledFiat />;
    case 'CardRewardConfig':
      return <StyledCard rarity={rarity || Rarity.common} />;
    case 'CardShardRewardConfig':
      return <StyledImg src={shardsIconUrl(rarity || Rarity.common)} alt="" />;
    default:
      return (
        <StyledImg
          src={`${REWARDS_ASSETS_URL}/icons/${getRewardImage(anyRewardConfig, rarity)}.png`}
          alt=""
        />
      );
  }
};

AnyRewardConfigImage.fragments = {
  anyRewardConfigInterface: gql`
    fragment AnyRewardConfigImage_anyRewardConfigInterface on AnyRewardConfigInterface {
      id
      ... on InGameCurrencyRewardConfig {
        id
        currency
      }
      ... on CardRewardConfig {
        id
        rarity
      }
      ... on CardShardRewardConfig {
        id
        rarity
      }
      ... on ShopItemRewardConfig {
        id
        shopItem {
          id
          position
        }
      }
      ... on DeliverableItemRewardConfig {
        id
        deliverableItem {
          slug
        }
      }
    }
  ` as TypedDocumentNode<AnyRewardConfigImage_anyRewardConfigInterface>,
};
