import classNames from 'classnames';
import { InputHTMLAttributes, ReactNode, RefObject } from 'react';
import styled from 'styled-components';

import { laptopAndAbove } from 'style/mediaQuery';

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  border-radius: var(--triple-unit);
  display: inline-flex;
  gap: var(--half-unit);
  align-items: center;
  position: relative;
  padding: var(--unit);
  --input-border-color: transparent;
  --input-bg-color: var(--c-nd-50);
  color: var(--c-white);
  border: 1px solid var(--input-border-color);
  background: var(--input-bg-color);

  @media ${laptopAndAbove} {
    gap: var(--unit);
    padding: var(--unit) var(--double-unit);
  }
  &:not(.disabled) {
    &:hover {
      --input-bg-color: var(--c-nd-100);
      --input-border-color: var(--c-nd-50);
    }
  }
  &.disabled {
    --input-bg-color: var(--c-nd-50);
    color: var(--c-nd-500);
  }
  &:focus-within {
    & p {
      opacity: 1;
    }
  }
`;
const Input = styled.input`
  resize: none;
  width: 100%;
  font: inherit;
  color: currentColor;
  border: none;
  letter-spacing: inherit;
  background-color: transparent;
  height: inherit;
  &::placeholder {
    color: currentColor;

    text-overflow: ellipsis;
    opacity: 0.6;
  }
  &:placeholder-shown {
    text-overflow: ellipsis;
  }
`;

type Props = {
  className?: string;
  id?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  inputRef?: RefObject<HTMLInputElement>;
} & Partial<InputHTMLAttributes<HTMLInputElement>>;

export const TextInput = ({
  className,
  id,
  startAdornment,
  endAdornment,
  inputRef,
  ...rest
}: Props) => {
  return (
    <Wrapper className={classNames(className, { disabled: rest.disabled })}>
      {startAdornment}
      <Input id={id} {...rest} ref={inputRef} />
      {endAdornment}
    </Wrapper>
  );
};
