import { useId } from 'react';
import styled from 'styled-components';

import { LabelM } from 'atoms/typography';
import { StrokedText } from 'atoms/typography/StrokedText';

const Root = styled.div`
  position: relative;
`;
const CountWrapper = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
`;

type Props = {
  count?: number;
  hideCount?: boolean;
} & React.JSX.IntrinsicElements['svg'];
export const Reputation = ({ count = 1, hideCount, ...rest }: Props) => {
  const filterId = useId();
  const effectId = useId();

  return (
    <Root>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 27"
        fill="none"
        {...rest}
      >
        <g filter={`url(#${filterId})`}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.8668 2.0349L14.8431 2.03308L6.66399 13.7767L11.405 13.7522L8.12612 23.9651L9.15086 23.9669L17.33 12.2233L12.5879 12.2478L15.8668 2.0349Z"
            fill="#F1FF00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.3048 1L17.2814 1.00529L14.0056 11.2084L19.3138 11.1809L9.68911 25L6.71153 24.9947L9.98728 14.7916L4.68018 14.8191L14.3048 1ZM11.405 13.7522L8.12612 23.9651L9.15086 23.9669L17.33 12.2233L12.5879 12.2478L15.8668 2.0349L14.8431 2.03308L6.66399 13.7767L11.405 13.7522Z"
            fill="#F1FF00"
          />
          <path
            d="M14.3057 0.500001L14.0441 0.499536L13.8945 0.714241L4.26988 14.5334L3.71915 15.3241L4.68277 15.3191L9.30047 15.2952L6.23546 24.8419L6.02625 25.4935L6.71064 25.4947L9.68822 25.5L9.94987 25.5005L10.0994 25.2858L19.7241 11.4666L20.2748 10.6759L19.3112 10.6809L14.6925 10.7048L17.7575 1.15813L17.9667 0.506507L17.2823 0.505291L14.3057 0.500001Z"
            stroke="#101010"
            strokeLinecap="square"
          />
        </g>
        <defs>
          <filter
            id={filterId}
            x="2.75806"
            y="-0.000976562"
            width="18.4778"
            height="26.8591"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.857143" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result={effectId} />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2={effectId}
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      {!hideCount && (
        <CountWrapper>
          <StrokedText yOffset={1} strokeWidth={2}>
            <LabelM color="var(--c-white)" bold>
              {count}
            </LabelM>
          </StrokedText>
        </CountWrapper>
      )}
    </Root>
  );
};
