import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, defineMessages } from 'react-intl';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Bold } from '@sorare/core/src/atoms/typography/Bold';
import { ANY_SPORT_CARDS_SLUG } from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { getName } from '@sorare/core/src/lib/cards';
import { getSportOrSharedFromSport } from '@sorare/core/src/lib/sports';

import { DumbNotification } from 'components/activity/DumbNotification';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { CardNotification_cardNotification } from './__generated__/index.graphql';

type Props = CommonNotificationProps & {
  notification: CardNotification_cardNotification;
};

const messages = defineMessages({
  card_withdrawal_started: {
    id: 'Activity.Notifications.cardWithdrawalStarted',
    defaultMessage: 'Your card <b>{card}</b> is transferring to Ethereum',
  },
  card_withdrawn: {
    id: 'Activity.Notifications.cardWithdrawn',
    defaultMessage: 'Your card <b>{card}</b> has been transferred to Ethereum',
  },
  card_revealable: {
    id: 'Activity.Notifications.card_revealable',
    defaultMessage: 'Your card <b>{card}</b> is now revealable',
  },
});

export const CardNotification = ({ notification, ...rest }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { generateSportContextPath } = useSportContext();

  const { name, createdAt, anyCard: card, sport, read } = notification;

  if (!Object.keys(messages).includes(name) && !card) return null;

  const link = card?.slug
    ? generateSportContextPath(ANY_SPORT_CARDS_SLUG, {
        params: {
          slug: card.slug,
        },
        sport: card.sport || Sport.FOOTBALL,
      })
    : '';

  const title = messages[name as keyof typeof messages];

  return (
    <DumbNotification
      name={name}
      title={
        title && (
          <FormattedMessage
            {...title}
            values={{ b: Bold, card: card && getName(card) }}
          />
        )
      }
      userAvatar={currentUser}
      sport={getSportOrSharedFromSport(card?.sport) || sport}
      createdAt={createdAt}
      cardPicture={card}
      link={link}
      read={read}
      {...rest}
    />
  );
};

CardNotification.fragments = {
  cardNotification: gql`
    fragment CardNotification_cardNotification on CardNotification {
      id
      ...Notification_notificationInterface
      anyCard {
        slug
        sport
        ...getName_anyCard
        ...DumbNotification_cardPicture
      }
    }
    ${getName.fragments.anyCard}
    ${commonNotificationInterfaceFragment}
    ${DumbNotification.fragments.cardPicture}
  ` as TypedDocumentNode<CardNotification_cardNotification>,
};
