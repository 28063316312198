import { getValue } from 'components/PersistsQueryStringParameters/storage';
import { STORE_URL } from 'constants/externalLinks';

interface Options {
  invitationToken?: string | null;
  impactId?: string | null;
  referrer?: string | null;
  googleClickId?: string | null;
  facebookClickId?: string | null;
  twitterClickId?: string | null;
  tikTokClickId?: string | null;
  redditClickId?: string | null;
}

export const useOneLinkUrl = (
  href: string,
  {
    invitationToken,
    impactId,
    referrer,
    googleClickId,
    facebookClickId,
    twitterClickId,
    tikTokClickId,
    redditClickId,
  }: Options
) => {
  const url = new URL(href);
  if (invitationToken) {
    url.searchParams.append('deep_link_sub1', invitationToken);
  }
  if (impactId) {
    url.searchParams.append('deep_link_sub2', impactId);
  }
  if (referrer) {
    url.searchParams.append('deep_link_sub3', `referrer:${referrer}`);
  }
  if (googleClickId) {
    url.searchParams.append('deep_link_sub3', `gclid:${googleClickId}`);
  }
  if (facebookClickId) {
    url.searchParams.append('deep_link_sub3', `fbclid:${facebookClickId}`);
  }
  if (twitterClickId) {
    url.searchParams.append('deep_link_sub3', `twclid:${twitterClickId}`);
  }
  if (tikTokClickId) {
    url.searchParams.append('deep_link_sub3', `ttclid:${tikTokClickId}`);
  }
  if (redditClickId) {
    url.searchParams.append('deep_link_sub3', `rdt_cid:${redditClickId}`);
  }

  return url.href;
};

export const useStoreUrl = () => {
  const referrer = getValue('referrer');
  const impactId = getValue('irclickid');
  const googleClickId = getValue('gclid');
  const facebookClickId = getValue('fbclid');
  const twitterClickId = getValue('twclid');
  const tikTokClickId = getValue('ttclid');
  const redditClickId = getValue('rdt_cid');
  const invitationToken = getValue('invitation_token');

  return useOneLinkUrl(STORE_URL, {
    invitationToken,
    impactId,
    googleClickId,
    facebookClickId,
    twitterClickId,
    tikTokClickId,
    redditClickId,
    referrer,
  });
};
