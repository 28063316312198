import { TypedDocumentNode, gql } from '@apollo/client';

import { PositionIcon } from '@sorare/core/src/components/lineup/PositionIcon';

import { AppearanceRuleIcon_appearanceRule } from './__generated__/index.graphql';

type Props = { appearanceRule: AppearanceRuleIcon_appearanceRule };

export const AppearanceRuleIcon = ({ appearanceRule }: Props) => {
  const position =
    appearanceRule.positions.length === 1 ? appearanceRule.positions[0] : null;

  return <PositionIcon position={position} />;
};

AppearanceRuleIcon.fragments = {
  appearanceRule: gql`
    fragment AppearanceRuleIcon_appearanceRule on AppearanceRule {
      name
      positions
    }
  ` as TypedDocumentNode<AppearanceRuleIcon_appearanceRule>,
};
