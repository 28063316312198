import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { LiveDot } from '@sorare/core/src/components/live/LiveDot';
import { isType } from '@sorare/core/src/gql';
import { glossary } from '@sorare/core/src/lib/glossary';
import { teamPictureUrl } from '@sorare/core/src/lib/so5';

import { isGameFinished, isGameLive, isGameScheduled } from 'lib/so5';

import { InlineGame_game } from './__generated__/index.graphql';

const TeamLogo = styled.img`
  width: 1.2em;
  height: 1.2em;
  object-fit: contain;
  border-radius: var(--half-unit);
  .sm & {
    height: 1.6em;
  }
  .md & {
    height: 2em;
  }
`;
const ReversibleHorizontal = styled(Horizontal)`
  &.reversed {
    flex-direction: row-reverse;
  }
`;
const Game = styled(ReversibleHorizontal)`
  border-radius: var(--half-unit);
  min-height: var(--double-and-a-half-unit);
  padding: var(--half-unit);
  font: var(--t-label-s);
  white-space: nowrap;
  background: var(--c-nd-50);
  &.sm {
    font: var(--t-label-m);
  }
  &.md {
    font: var(--t-label-l);
    padding: var(--unit);
  }
  &.inline {
    padding: 0;
    vertical-align: middle;
    background: none;
    min-height: auto;
  }
`;
const TooltipContent = styled(Vertical)`
  color: var(--c-nd-600);
  font: var(--t-label-s);
`;

const NBAQuarterIndicator = styled(Horizontal)`
  padding-right: var(--double-unit);
  justify-content: flex-end;
  font: var(--t-label-s);
  font-weight: var(--t-bold);
  color: var(--c-nd-600);
`;

const Won = styled.span`
  color: var(--c-white);
  font-weight: var(--t-bold);
`;

const Lost = styled.span`
  color: var(--c-nd-600);
`;

type Props = {
  game: InlineGame_game | null;
  size?: 'xs' | 'sm' | 'md';
  disableTooltip?: boolean;
  inline?: boolean;
  withTeamCode?: boolean;
  withGameStatus?: boolean;
};
export const InlineGame = ({
  game,
  size,
  disableTooltip,
  inline,
  withTeamCode,
  withGameStatus,
}: Props) => {
  if (!game?.homeTeam || !game.awayTeam) {
    return null;
  }

  const isFutureGame = isGameScheduled(game.statusTyped);
  const isLiveGame = isGameLive(game.statusTyped);
  const isPastGame = isGameFinished(game.statusTyped);

  const reverseTeamOrder =
    isType(game, 'GameOfBaseball') || isType(game, 'GameOfBasketball');

  const homeTeamPictureUrl = game.homeTeam
    ? teamPictureUrl(game.homeTeam)
    : null;
  const awayTeamPictureUrl = game.awayTeam
    ? teamPictureUrl(game.awayTeam)
    : null;

  const homeScore = game.homeScore ?? '';
  const awayScore = game.awayScore ?? '';

  const HomeScore = homeScore >= awayScore ? Won : Lost;
  const AwayScore = homeScore <= awayScore ? Won : Lost;

  return (
    <Tooltip
      disableFocusListener={disableTooltip}
      title={
        <TooltipContent center>
          <ReversibleHorizontal
            className={classNames({ reversed: reverseTeamOrder })}
          >
            <span>{game.homeTeam.code}</span>
            {homeTeamPictureUrl && <TeamLogo src={homeTeamPictureUrl} alt="" />}
            {isFutureGame ? (
              <Lost>-</Lost>
            ) : (
              <>
                <HomeScore>{homeScore}</HomeScore> <Lost>-</Lost>
                <AwayScore>{awayScore}</AwayScore>
              </>
            )}
            {awayTeamPictureUrl && <TeamLogo src={awayTeamPictureUrl} alt="" />}
            <span>{game.awayTeam.code}</span>
          </ReversibleHorizontal>
          {game.date && (
            <FormattedDate
              value={game.date}
              month="short"
              day="numeric"
              hour="2-digit"
              minute="2-digit"
            />
          )}
        </TooltipContent>
      }
    >
      <Game
        gap={size === 'md' ? 2 : 0.5}
        className={classNames(size, { reversed: reverseTeamOrder, inline })}
      >
        {homeTeamPictureUrl ? (
          <>
            {withTeamCode && <span>{game.homeTeam.code}</span>}
            <TeamLogo src={homeTeamPictureUrl} alt="" />
          </>
        ) : (
          <span>{game.homeTeam.code}</span>
        )}
        {isFutureGame ? (
          <Lost>-</Lost>
        ) : (
          <>
            <HomeScore>{homeScore}</HomeScore> <Lost>-</Lost>
            <AwayScore>{awayScore}</AwayScore>
          </>
        )}
        {awayTeamPictureUrl ? (
          <>
            <TeamLogo src={awayTeamPictureUrl} alt="" />
            {withTeamCode && <span>{game.awayTeam.code}</span>}
          </>
        ) : (
          <span>{game.awayTeam.code}</span>
        )}
        {withGameStatus && isType(game, 'GameOfBasketball') && (
          <NBAQuarterIndicator gap={0.5}>
            {isLiveGame && <LiveDot animate />}{' '}
            {isPastGame ? (
              <FormattedMessage id="GameInfos.final" defaultMessage="Final" />
            ) : (
              <>
                {isFutureGame ? (
                  <>
                    {game.date && (
                      <FormattedDate
                        value={game.date}
                        month="numeric"
                        day="numeric"
                      />
                    )}
                  </>
                ) : (
                  <>
                    {game.isHalftime ? (
                      <FormattedMessage
                        id="Game.status.halftime"
                        defaultMessage="Half"
                      />
                    ) : (
                      <FormattedMessage
                        {...glossary.ordinal}
                        values={{ ordinal: game.quarter }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </NBAQuarterIndicator>
        )}
      </Game>
    </Tooltip>
  );
};

InlineGame.fragments = {
  game: gql`
    fragment InlineGame_game on AnyGameInterface {
      id
      date
      statusTyped
      homeScore
      awayScore
      awayTeam {
        slug
        code
        shortName
        ...teamPictureUrl_teamInterface
      }
      homeTeam {
        slug
        code
        shortName
        ...teamPictureUrl_teamInterface
      }
      ... on GameOfBasketball {
        id
        isHalftime
        quarter
      }
    }
    ${teamPictureUrl.fragments.teamInterface}
  ` as TypedDocumentNode<InlineGame_game>,
};

export default InlineGame;
