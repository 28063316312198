import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import { Vertical } from 'atoms/layout/flex';
import { BodyM, HeadlineS, LabelS } from 'atoms/typography';
import { Row } from 'components/seo/ui';
import { glossary } from 'lib/glossary';

import { SquadPlayer, Props as SquadPlayerProps } from './SquadPlayer';

const StyledLabelS = styled(LabelS)`
  text-transform: uppercase;
`;

type Props<T extends string> = {
  displayName: string;
  stats: SquadPlayerProps<T>['stats'];
  headers: Record<T, ReactNode>;
  groups: {
    label?: string;
    players: SquadPlayerProps<T>['player'][];
  }[];
};

export const Squad = <T extends string>({
  displayName,
  groups,
  headers,
  stats,
}: Props<T>) => {
  return (
    <FullWidthContainer>
      <Vertical gap={1.5}>
        <HeadlineS as="h1">
          <FormattedMessage
            id="Seo.Team.Squad.teamSquad"
            defaultMessage="{team} players"
            values={{ team: displayName }}
          />
        </HeadlineS>
        <Vertical gap={2}>
          <Row
            style={{
              gridTemplateColumns: `auto 1fr repeat(${stats.length}, calc(6 * var(--unit)))`,
            }}
          >
            <StyledLabelS as="p" color="var(--c-nd-600)" bold>
              <FormattedMessage {...glossary.name} />
            </StyledLabelS>
            <span />
            {stats.map(stat => (
              <LabelS as="p" key={stat} color="var(--c-nd-600)" bold>
                {headers[stat]}
              </LabelS>
            ))}
          </Row>
          {groups.map(group => (
            <Vertical gap={1.5} key={group.label}>
              {!!group.label && (
                <BodyM as="h2" color="var(--c-nd-600)">
                  {group.label}
                </BodyM>
              )}
              <Vertical gap={3}>
                {group.players.map(player => (
                  <SquadPlayer
                    key={player.slug}
                    player={player}
                    stats={stats}
                  />
                ))}
              </Vertical>
            </Vertical>
          ))}
        </Vertical>
      </Vertical>
    </FullWidthContainer>
  );
};
