import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import styled from 'styled-components';

import { ResponsiveImg, ValidWidths } from 'atoms/ui/ResponsiveImg';

import { CardAvatar_anyCard } from './__generated__/index.graphql';

type Props = {
  card: CardAvatar_anyCard;
  className?: string;
  maxWidth?: ValidWidths;
  noTitle?: boolean;
};

const CardWrapper = styled.div`
  position: relative;
  aspect-ratio: 1;
  max-width: 250px;
  width: 100%;
  overflow: hidden;

  border-radius: 50%;
  padding: 1px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -1px;
    border-radius: inherit;
    overflow: hidden;
  }
  &.limited:before {
    background: var(--c-gradient-limited);
  }
  &.rare:before {
    background: var(--c-gradient-rare);
  }
  &.super_rare:before {
    background: var(--c-gradient-superRare);
  }
  &.unique:before {
    background: var(--c-gradient-unique);
  }
`;

const CardAvatarImage = styled(ResponsiveImg)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  clip-path: border-box;
  .rounded & {
    clip-path: circle(50%);
  }
`;

export const CardAvatar = ({
  card,
  className = '',
  maxWidth = 320,
  noTitle,
}: Props) => {
  return (
    <CardWrapper
      title={noTitle ? undefined : card.anyPlayer.displayName}
      className={classNames(className, card.rarityTyped)}
    >
      <CardAvatarImage
        cropWidth={maxWidth}
        src={card.avatarUrl!}
        alt={card.anyPlayer.displayName}
      />
    </CardWrapper>
  );
};

CardAvatar.fragments = {
  anyCard: gql`
    fragment CardAvatar_anyCard on AnyCardInterface {
      slug
      rarityTyped
      avatarUrl: pictureUrl(derivative: "avatar")
      anyPlayer {
        slug
        displayName
      }
    }
  ` as TypedDocumentNode<CardAvatar_anyCard>,
};
