import { Reputation } from '@sorare/core/src/atoms/icons/rivals/branded/Reputation';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelL, LabelM, LabelS } from '@sorare/core/src/atoms/typography';
import { Diamond } from '@sorare/core/src/components/rivals/branded/Diamond';

type Props = {
  points?: number;
};
export const RepDiamond = ({ points }: Props) => {
  return (
    <Diamond color="var(--c-rivals-reputation)">
      <Vertical gap={0} center>
        <LabelS as="span" color="var(--c-nd-600)" bold uppercase>
          Rep
        </LabelS>
        {points !== undefined ? (
          <LabelM bold>
            <Horizontal gap={0} center>
              {points}
              <Reputation height={10} hideCount />
            </Horizontal>
          </LabelM>
        ) : (
          <LabelL bold>-</LabelL>
        )}
      </Vertical>
    </Diamond>
  );
};
