import { TypedDocumentNode, gql } from '@apollo/client';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import {
  ANY_SPORT_CARDS_SLUG,
  ANY_SPORT_MY_CARDS_ESSENCE_TYPE,
} from '@sorare/core/src/constants/__generated__/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { getSportFromSportOrShared } from '@sorare/core/src/lib/sports';

import { DumbNotification } from 'components/activity/DumbNotification';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { AutoClaimNotification_autoClaimNotification } from './__generated__/index.graphql';

type Props = CommonNotificationProps & {
  notification: AutoClaimNotification_autoClaimNotification;
};

export const AutoClaimNotification = ({ notification, ...rest }: Props) => {
  const { generateSportContextPath } = useSportContext();

  const {
    createdAt,
    title,
    sport,
    read,
    name,
    anyCard,
    cardShardRewardConfig,
  } = notification;

  const cardLink = anyCard?.slug
    ? generateSportContextPath(ANY_SPORT_CARDS_SLUG, {
        params: {
          slug: anyCard.slug,
        },
        sport: anyCard.sport ?? Sport.FOOTBALL,
      })
    : undefined;
  const shardLink = cardShardRewardConfig?.rarity
    ? generateSportContextPath(ANY_SPORT_MY_CARDS_ESSENCE_TYPE, {
        params: {
          type: cardShardRewardConfig.rarity,
        },
        sport: getSportFromSportOrShared(sport) ?? Sport.FOOTBALL,
      })
    : undefined;

  return (
    <DumbNotification
      title={title}
      createdAt={createdAt}
      sport={sport}
      read={read}
      name={name}
      cardPicture={anyCard}
      link={cardLink || shardLink}
      {...rest}
    />
  );
};

AutoClaimNotification.fragments = {
  autoClaimNotification: gql`
    fragment AutoClaimNotification_autoClaimNotification on AutoClaimNotification {
      id
      ...Notification_notificationInterface
      title
      anyCard {
        slug
        sport
        ...DumbNotification_cardPicture
      }
      cardShardRewardConfig {
        id
        rarity
      }
    }
    ${commonNotificationInterfaceFragment}
    ${DumbNotification.fragments.cardPicture}
  ` as TypedDocumentNode<AutoClaimNotification_autoClaimNotification>,
};
