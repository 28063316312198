import { TypedDocumentNode, gql } from '@apollo/client';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { withFragments } from '@sorare/core/src/gql';

import { getCurrentSportClubName_PublicUserInfoInterface } from './__generated__/getCurrentSportClubName.graphql';

export const getCurrentSportClubName = withFragments(
  (user: getCurrentSportClubName_PublicUserInfoInterface) => {
    const { sport } = useSportContext();

    if (sport === Sport.BASEBALL) {
      return user.baseballUserProfile?.clubName;
    }
    if (sport === Sport.NBA) {
      return user.nbaUserProfile?.clubName;
    }
    return user.footballUserProfile?.clubName;
  },
  {
    PublicUserInfoInterface: gql`
      fragment getCurrentSportClubName_PublicUserInfoInterface on PublicUserInfoInterface {
        slug
        baseballUserProfile {
          id
          clubName
        }
        footballUserProfile {
          id
          clubName
        }
        nbaUserProfile {
          id
          clubName
        }
      }
    ` as TypedDocumentNode<getCurrentSportClubName_PublicUserInfoInterface>,
  }
);
