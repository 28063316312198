import { ComponentProps } from 'react';

import { Position } from '__generated__/globalTypes';
import { BasketballCenter } from 'assets/icons/BasketballCenter';
import { BasketballForward } from 'assets/icons/BasketballForward';
import { BasketballGuard } from 'assets/icons/BasketballGuard';
import { DefaultPosition } from 'assets/icons/DefaultPosition';
import { Glove } from 'assets/icons/Glove';
import { Jersey } from 'assets/icons/Jersey';
import { Shield } from 'assets/icons/Shield';
import { Shoe } from 'assets/icons/Shoe';

export const iconPerPosition: Record<
  | Position.Goalkeeper
  | Position.Defender
  | Position.Midfielder
  | Position.Forward
  | Position.basketball_center
  | Position.basketball_forward
  | Position.basketball_guard,
  | typeof Glove
  | typeof Shield
  | typeof Jersey
  | typeof Shoe
  | typeof BasketballCenter
  | typeof BasketballForward
  | typeof BasketballGuard
> = {
  // FOOTBALL
  [Position.Goalkeeper]: Glove,
  [Position.Defender]: Shield,
  [Position.Midfielder]: Jersey,
  [Position.Forward]: Shoe,

  // NBA
  [Position.basketball_center]: BasketballCenter,
  [Position.basketball_forward]: BasketballForward,
  [Position.basketball_guard]: BasketballGuard,
};

type Props = { position: Nullable<Position> } & ComponentProps<typeof Glove> &
  ComponentProps<typeof Shield> &
  ComponentProps<typeof Shoe> &
  ComponentProps<typeof Jersey> &
  ComponentProps<typeof BasketballCenter> &
  ComponentProps<typeof BasketballForward> &
  ComponentProps<typeof BasketballGuard> &
  ComponentProps<typeof DefaultPosition>;

export const PositionIcon = ({ position, size, hollow }: Props) => {
  const Icon =
    position && position in iconPerPosition
      ? iconPerPosition[position as keyof typeof iconPerPosition]
      : DefaultPosition;

  return <Icon size={size} hollow={hollow} />;
};
