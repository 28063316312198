import { useContext } from 'react';

import {
  FeatureFlagsContextType,
  FlagSet,
  featureFlagsContext,
} from 'contexts/featureFlags';

const noFlags: FeatureFlagsContextType = {
  flags: {},
  untrackedFlags: {},
  identify: undefined,
};

export default <
  T extends FlagSet = Record<string, any>,
>(): FeatureFlagsContextType<T> => {
  return useContext(featureFlagsContext) || noFlags;
};

export { useLDClient } from 'launchdarkly-react-client-sdk';
