import { unitMapping } from 'lib/style';

type Props = {
  size?: keyof typeof unitMapping;
  className?: string;
  hollow?: boolean;
};

export const Shield = ({ size = 2, className, hollow }: Props) => {
  return (
    <svg
      className={className}
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.14579 5.32051L5.53457 6.62612V9.21365C5.53457 10.1184 6.00294 10.9587 6.77247 11.4346L8.14579 12.2838L9.51911 11.4346C10.2886 10.9587 10.757 10.1184 10.757 9.21365V6.62612L8.14579 5.32051Z"
        fill={hollow ? 'url(#shield-hollow-gradient)' : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2384 3.91215L8.14559 0.865723L2.05273 3.91215V9.50386C2.05273 11.6904 3.18462 13.7211 5.04433 14.8711L8.14559 16.7888L11.2468 14.8711C13.1066 13.7211 14.2384 11.6904 14.2384 9.50386V3.91215ZM3.79355 9.50386V4.98803L8.14559 2.81201L12.4976 4.98803V9.50386C12.4976 11.0872 11.678 12.5577 10.3313 13.3905L8.14559 14.742L5.95988 13.3905C4.61319 12.5577 3.79355 11.0872 3.79355 9.50386Z"
        fill={hollow ? 'url(#shield-hollow-gradient)' : 'currentColor'}
      />
      <defs>
        <linearGradient
          id="shield-hollow-gradient"
          x1="0.5"
          y1="7.75"
          x2="9.22771"
          y2="6.81853"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8D3DA" />
          <stop offset="0.283178" stopColor="#B3A9F4" />
          <stop offset="0.5401" stopColor="#FBE9FB" />
          <stop offset="1" stopColor="#4F94FD" />
        </linearGradient>
      </defs>
    </svg>
  );
};
