import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import {
  ManagerTeamBadgeIcon_managerTeam,
  ManagerTeamBadgeIcon_so5Leaderboard,
} from './__generated__/index.graphql';

const Img = styled.img`
  width: var(--size);
  height: var(--size);
  object-fit: contain;
`;

type Props = {
  leaderboard?: Nullable<ManagerTeamBadgeIcon_so5Leaderboard>;
  managerTeam?: Nullable<ManagerTeamBadgeIcon_managerTeam>;
};

export const BadgeIcon = ({ leaderboard, managerTeam }: Props) => {
  return (
    <Img
      src={
        leaderboard?.iconUrl ||
        managerTeam?.activeDivisionIconUrl ||
        `https://frontend-assets.sorare.com/football/manager_team/${
          leaderboard?.mainRarityType || 'academy'
        }/icon.png`
      }
      alt=""
    />
  );
};

BadgeIcon.fragments = {
  so5Leaderboard: gql`
    fragment ManagerTeamBadgeIcon_so5Leaderboard on So5Leaderboard {
      slug
      mainRarityType
      iconUrl
    }
  ` as TypedDocumentNode<ManagerTeamBadgeIcon_so5Leaderboard>,
  managerTeam: gql`
    fragment ManagerTeamBadgeIcon_managerTeam on ManagerTeam {
      id
      activeDivisionIconUrl
    }
  ` as TypedDocumentNode<ManagerTeamBadgeIcon_managerTeam>,
};

export default BadgeIcon;
