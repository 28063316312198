import styled from 'styled-components';

import { BodyS } from '@sorare/core/src/atoms/typography';

const ImageArea = styled.div`
  width: 100%;
  aspect-ratio: 373/217;
  position: relative;
  isolation: isolate;
`;
const Summary = styled(BodyS)`
  position: absolute;
  top: var(--double-unit);
  left: var(--double-unit);
  padding: 2px var(--unit);
  border-radius: var(--intermediate-unit);
  backdrop-filter: blur(4px);
  background-color: var(--c-nd-50);
`;
const Picture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Header = styled.img`
  margin: auto;
  position: absolute;
  inset: auto 0 0;
  max-width: 295px;
`;
export const LeagueImage = ({
  summary,
  pictureUrl,
  headerUrl,
}: {
  summary?: string | null;
  pictureUrl: string;
  headerUrl: string;
}) => {
  return (
    <ImageArea>
      {summary && <Summary as="p">{summary}</Summary>}
      <Picture src={pictureUrl} alt="" />
      <Header src={headerUrl} alt="" />
    </ImageArea>
  );
};
