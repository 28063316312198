import { TypedDocumentNode, gql } from '@apollo/client';

import { So5LeaderboardType } from '@sorare/core/src/__generated__/globalTypes';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import {
  useIsUserDailyPickupEligibleQuery,
  useIsUserDailyPickupEligibleQueryVariables,
} from './__generated__/useIsUserDailyPickupEligible.graphql';

const query = gql`
  query useIsUserDailyPickupEligibleQuery {
    currentUser {
      slug
      id
      nbaUserProfile {
        id
        tournamentEligibilities {
          eligible
          so5LeaderboardType
        }
      }
    }
  }
` as TypedDocumentNode<
  useIsUserDailyPickupEligibleQuery,
  useIsUserDailyPickupEligibleQueryVariables
>;

export const useIsUserDailyPickupEligible = () => {
  const { data, loading } = useQuery(query);

  const nbaUserProfile = data?.currentUser?.nbaUserProfile;

  const isDailyPickupEligible = nbaUserProfile?.tournamentEligibilities.some(
    eligiblity =>
      eligiblity.so5LeaderboardType ===
        So5LeaderboardType.NBA_COMMON_DAILY_PICKUP && eligiblity.eligible
  );

  return { isDailyPickupEligible, loading };
};
