import { TypedDocumentNode, gql } from '@apollo/client';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { LiveDot } from '@sorare/core/src/components/live/LiveDot';
import { unitMapping } from '@sorare/core/src/lib/style';

import { BadgeIcon } from 'components/managerTeam/BadgeIcon';
import { Status } from 'components/managerTeam/Status';

import {
  ManagerTeamBadge_managerTeam,
  ManagerTeamBadge_so5Leaderboard,
  ManagerTeamBadge_so5Ranking,
} from './__generated__/index.graphql';
import validated from './assets/validated.svg';

const Root = styled.div`
  display: inline-flex;
  position: relative;
  border-radius: unset;
`;
const Dot = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  border: 2px solid var(--c-nd-50);
  border-radius: 50%;
  line-height: 0;
`;

const StatusWrapper = styled.div`
  position: absolute;
  right: calc(-1 * var(--half-unit));
  top: calc(-1 * var(--half-unit));
  width: var(--status-icon-size);
  height: var(--status-icon-size);
  * {
    max-width: 100%;
    max-height: 100%;
  }
`;
type Props = {
  hasLiveGames?: boolean;
  managerTeam?: Nullable<ManagerTeamBadge_managerTeam>;
  ranking?: ManagerTeamBadge_so5Ranking;
  leaderboard: Nullable<ManagerTeamBadge_so5Leaderboard>;
  validLineup: boolean;
  fixtureStarted?: boolean;
  size?: keyof typeof unitMapping;
  statusIconSize?: keyof typeof unitMapping;
};
export const Badge = ({
  hasLiveGames,
  managerTeam,
  ranking,
  leaderboard,
  validLineup,
  fixtureStarted,
  size = 5,
  statusIconSize = 2,
}: Props) => {
  let statusIcon: ReactNode;
  if (managerTeam?.hidden) {
    statusIcon = <FontAwesomeIcon icon={faEyeSlash} />;
  } else if (validLineup) {
    statusIcon = <img src={validated} alt="" />;
  }

  return (
    <Root
      style={
        {
          '--size': unitMapping[size],
          '--status-icon-size': unitMapping[statusIconSize],
        } as CSSProperties
      }
    >
      {hasLiveGames && (
        <Dot>
          <LiveDot animate />
        </Dot>
      )}
      <BadgeIcon leaderboard={leaderboard} managerTeam={managerTeam} />
      {ranking || fixtureStarted ? (
        <StatusWrapper>
          <Status ranking={ranking} />
        </StatusWrapper>
      ) : (
        <StatusWrapper>{statusIcon}</StatusWrapper>
      )}
    </Root>
  );
};

Badge.fragments = {
  so5Leaderboard: gql`
    fragment ManagerTeamBadge_so5Leaderboard on So5Leaderboard {
      slug
      ...ManagerTeamBadgeIcon_so5Leaderboard
    }
    ${BadgeIcon.fragments.so5Leaderboard}
  ` as TypedDocumentNode<ManagerTeamBadge_so5Leaderboard>,
  so5Ranking: gql`
    fragment ManagerTeamBadge_so5Ranking on So5Ranking {
      id
      ...Status_so5Ranking
    }
    ${Status.fragments.so5Ranking}
  ` as TypedDocumentNode<ManagerTeamBadge_so5Ranking>,
  managerTeam: gql`
    fragment ManagerTeamBadge_managerTeam on ManagerTeam {
      id
      hidden
      ...ManagerTeamBadgeIcon_managerTeam
    }
    ${BadgeIcon.fragments.managerTeam}
  ` as TypedDocumentNode<ManagerTeamBadge_managerTeam>,
};
