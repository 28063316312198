import { Color, HexColorOnly } from 'style/types';

type Input = (HexColorOnly | Rgb | RgbString)[];

type Item = Url | HTMLImageElement;

type Output = (HexColorOnly | Rgb)[];

type Rgb = [r: number, g: number, b: number];
export type RgbString = `${number}, ${number}, ${number}`;

type Url = string;

type ColorFormat = HexColorOnly | RgbString | Rgb;

export const isHexColor = (c: string): c is HexColorOnly => {
  return c.startsWith('#');
};

export const ensureHexColor = (c: string): HexColorOnly => {
  return isHexColor(c) ? c : '#fff';
};

const getHexFromRGB = (rgb: Rgb): HexColorOnly =>
  `#${rgb
    .map(val => {
      const hex = val.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('')}`;

const getRGBFromHex = (hex: HexColorOnly): Rgb => {
  let strippedHex = hex.replace('#', '');

  if (strippedHex.length === 3) {
    strippedHex = strippedHex
      .split('')
      .map(char => char + char)
      .join('');
  }

  const r = parseInt(strippedHex.substring(0, 2), 16);
  const g = parseInt(strippedHex.substring(2, 4), 16);
  const b = parseInt(strippedHex.substring(4, 6), 16);

  return [r, g, b];
};

export const colorToRgb = (color: ColorFormat): Rgb => {
  if (Array.isArray(color)) {
    return color;
  }
  if (isHexColor(color)) {
    return getRGBFromHex(color);
  }
  return color.split(/,\s*/).map(Number) as Rgb;
};

export const getRgbColorFromHex = (hex: ColorFormat): RgbString => {
  const [r, g, b] = colorToRgb(hex);
  return `${r}, ${g}, ${b}`;
};

export const isLightColor = (color: ColorFormat) => {
  const [r, g, b] = colorToRgb(color);

  // Based on https://stackoverflow.com/a/3943023 There are more precise (and more complex) ways to calculate this: https://stackoverflow.com/a/56678483
  if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
    return true;
  }
  return false;
};

export const getTextColorFromBackgroundColor = (
  bgColor: ColorFormat
): Color => {
  if (isLightColor(bgColor)) {
    return 'var(--c-black)';
  }
  return 'var(--c-white)';
};

const getSrc = (item: Item): string =>
  typeof item === 'string' ? item : item.src;

type Args = {
  amount: number;
  format: string;
  group: number;
  sample: number;
};
const getArgs = ({
  amount = 3,
  format = 'array',
  group = 20,
  sample = 10,
} = {}): Args => ({ amount, format, group, sample });

const group = (number: number, grouping: number): number => {
  const grouped = Math.round(number / grouping) * grouping;

  return Math.min(grouped, 255);
};

const format = (input: Input, args: Args): Output => {
  const list = input.map(val => {
    const rgb = Array.isArray(val) ? val : (val.split(',').map(Number) as Rgb);
    return args.format === 'hex' ? getHexFromRGB(rgb) : rgb;
  });

  return list;
};

const getImageData = async (src: Url): Promise<Uint8ClampedArray> =>
  new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    const img = new Image();

    img.onload = () => {
      try {
        canvas.height = img.height;
        canvas.width = img.width;
        context.drawImage(img, 0, 0);

        const { data } = context.getImageData(0, 0, img.width, img.height);

        resolve(data);
      } catch {
        reject(Error('Image loading failed.'));
      }
    };
    img.onerror = () => reject(Error('Image loading failed.'));
    img.crossOrigin = '';
    img.src = src;
  });

const getAverage = (data: Uint8ClampedArray, args: Args): Output => {
  const gap = 4 * args.sample;
  const amount = data.length / gap;
  const rgb = { r: 0, g: 0, b: 0 };

  for (let i = 0; i < data.length; i += gap) {
    rgb.r += data[i];
    rgb.g += data[i + 1];
    rgb.b += data[i + 2];
  }

  return format(
    [
      [
        Math.round(rgb.r / amount),
        Math.round(rgb.g / amount),
        Math.round(rgb.b / amount),
      ],
    ],
    args
  );
};

const getProminent = (data: Uint8ClampedArray, args: Args): Output => {
  const gap = 4 * args.sample;
  const colors: { [key: RgbString]: number } = {};

  for (let i = 0; i < data.length; i += gap) {
    const rgb = [
      group(data[i], args.group),
      group(data[i + 1], args.group),
      group(data[i + 2], args.group),
    ].join(', ') as RgbString;

    colors[rgb] = colors[rgb] ? colors[rgb] + 1 : 1;
  }
  const sortedColors = Object.entries(colors).sort(
    ([, valA], [, valB]) => valB - valA
  ) as [RgbString, number][];
  const prominentColors = sortedColors
    .slice(0, args.amount)
    .map(([rgb]) => rgb);
  return format(prominentColors, args);
};

const process = async (
  handler: (data: Uint8ClampedArray, args: Args) => Output,
  item: Item,
  args?: Partial<Args>
): Promise<Output> => handler(await getImageData(getSrc(item)), getArgs(args));

export const getAverageColor = async (item: Item, args?: Partial<Args>) =>
  process(getAverage, item, args);

export const getProminentColor = async (item: Item, args?: Partial<Args>) =>
  process(getProminent, item, args);
