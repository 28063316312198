import { useNavigate } from 'react-router-dom';

import { DialogKey } from 'components/navigation/WithDialogs';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useGenerateDialogPath } from 'hooks/navigation/useGenerateDialogPath';
import useQueryString from 'hooks/useQueryString';

export default () => {
  const { currentUser } = useCurrentUserContext();
  const generateDialogPath = useGenerateDialogPath();

  const action = useQueryString('action');
  const dialogOpen = useQueryString(DialogKey.verifyPhone);

  const navigate = useNavigate();

  if (
    action !== 'verifyPhone' ||
    !currentUser ||
    currentUser.phoneNumberVerified ||
    dialogOpen
  ) {
    return;
  }

  navigate(generateDialogPath(DialogKey.verifyPhone, '1'), { replace: true });
};
