export const defaultTypePolicies = {
  Account: { keyFields: ["id"] },
  ActionReward: { keyFields: ["id"] },
  ActivitiesInterface: { keyFields: ["id"] },
  AirdropPromotionalEvent: { keyFields: ["slug"] },
  Announcement: { keyFields: ["id"] },
  AnnouncementNotification: { keyFields: ["id"] },
  AnonymousUser: { keyFields: ["id"] },
  AnyCardInterface: { keyFields: ["slug"] },
  AnyCardReward: { keyFields: ["id"] },
  AnyContestantInterface: { keyFields: ["id"] },
  AnyConversionCreditReward: { keyFields: ["id"] },
  AnyGameInterface: { keyFields: ["id"] },
  AnyPlayerGameStatsInterface: { keyFields: ["id"] },
  AnyPlayerInterface: { keyFields: ["slug"] },
  AnyRewardConfigInterface: { keyFields: ["id"] },
  AnyRewardInterface: { keyFields: ["id"] },
  AppearanceBoostShopItem: { keyFields: ["id"] },
  AuctionDrop: { keyFields: ["id"] },
  AuctionNotification: { keyFields: ["id"] },
  AuctionReminder: { keyFields: ["id"] },
  AuthorizationRequest: { keyFields: ["id"] },
  AutoClaimNotification: { keyFields: ["id"] },
  BankAccountInterface: { keyFields: ["id"] },
  Banner: { keyFields: ["id"] },
  BannersSet: { keyFields: ["id"] },
  BaseballCard: { keyFields: ["slug"] },
  BaseballContestantType: { keyFields: ["id"] },
  BaseballPlayer: { keyFields: ["slug"] },
  BaseballPlayerGameScore: { keyFields: ["id"] },
  BaseballUserSportProfile: { keyFields: ["id"] },
  BasketballContestantType: { keyFields: ["id"] },
  BasketballPlayerGameScore: { keyFields: ["id"] },
  Blueprint: { keyFields: ["id"] },
  CaBankAccount: { keyFields: ["id"] },
  Card: { keyFields: ["slug"] },
  CardBoost: { keyFields: ["id"] },
  CardCollection: { keyFields: ["slug"] },
  CardCollectionCard: { keyFields: ["id"] },
  CardCollectionNotification: { keyFields: ["id"] },
  CardCollectionSlot: { keyFields: ["id"] },
  CardNotification: { keyFields: ["id"] },
  CardPack: { keyFields: ["slug"] },
  CardPackGroup: { keyFields: ["id"] },
  CardRewardConfig: { keyFields: ["id"] },
  CardSample: { keyFields: ["id"] },
  CardShardRewardConfig: { keyFields: ["id"] },
  CardShardsChest: { keyFields: ["id"] },
  CardShardsReward: { keyFields: ["id"] },
  CardStats: { keyFields: ["id"] },
  CardSubscription: { keyFields: ["slug"] },
  CheckoutCreditCardAuthorizationRequest: { keyFields: ["id"] },
  Club: { keyFields: ["slug"] },
  CoinReward: { keyFields: ["id"] },
  CoinRewardConfig: { keyFields: ["id"] },
  CommonAccount: { keyFields: ["id"] },
  CommonCardSwapShopItem: { keyFields: ["id"] },
  CommonDraftCampaign: { keyFields: ["slug"] },
  CommonDraftSwap: { keyFields: ["id"] },
  CommonPlayer: { keyFields: ["id"] },
  Competition: { keyFields: ["slug"] },
  CompletablePromotionalEvent: { keyFields: ["slug"] },
  CompletablePromotionalEventInterface: { keyFields: ["slug"] },
  ComposeTeamBenchCard: { keyFields: ["id"] },
  ComposeTeamBenchCommonPlayer: { keyFields: ["id"] },
  ComposeTeamBenchObjectInterface: { keyFields: ["id"] },
  Config: { keyFields: ["id"] },
  ConnectedOAuth: { keyFields: ["id"] },
  ContentTileInterface: { keyFields: ["id"] },
  Contestant: { keyFields: ["id"] },
  Contract: { keyFields: ["id"] },
  ConversionCredit: { keyFields: ["id"] },
  ConversionCreditCampaign: { keyFields: ["id"] },
  ConversionCreditRewardConfig: { keyFields: ["id"] },
  Country: { keyFields: ["slug"] },
  CurrentUser: { keyFields: ["slug"] },
  CustomBanner: { keyFields: ["id"] },
  DataPartner: { keyFields: ["slug"] },
  DecisiveActionNotification: { keyFields: ["id"] },
  DecisivePurchaseNotification: { keyFields: ["id"] },
  Deck: { keyFields: ["slug"] },
  DeliverableItemInterface: { keyFields: ["slug"] },
  DeliverableItemOrder: { keyFields: ["id"] },
  DeliverableItemReward: { keyFields: ["id"] },
  DeliverableItemRewardConfig: { keyFields: ["id"] },
  DeliverableWithCustomVariantShopItem: { keyFields: ["id"] },
  DeliverableWithNoVariantShopItem: { keyFields: ["id"] },
  DepositPromotionalEvent: { keyFields: ["slug"] },
  DivisionChangeConfiguration: { keyFields: ["id"] },
  DraftablePlayer: { keyFields: ["id"] },
  EligibilityRules: { keyFields: ["id"] },
  EmailSubscription: { keyFields: ["slug"] },
  EmailSubscriptionPreferences: { keyFields: ["slug"] },
  EmoteSentActivity: { keyFields: ["id"] },
  EthMigration: { keyFields: ["id"] },
  EthereumAccount: { keyFields: ["id"] },
  EthereumTransaction: { keyFields: ["id"] },
  ExchangeRate: { keyFields: ["id"] },
  ExperienceDeliverableItem: { keyFields: ["slug"] },
  ExternalDepositNotification: { keyFields: ["id"] },
  ExtraSwapShopItem: { keyFields: ["id"] },
  FastWithdrawal: { keyFields: ["id"] },
  FastWithdrawalWithRates: { keyFields: ["id"] },
  FiatWalletAccount: { keyFields: ["id"] },
  FirstRivalsLineupSubmittedActivity: { keyFields: ["id"] },
  FootballContestantType: { keyFields: ["id"] },
  FootballManagerTask: { keyFields: ["id"] },
  FootballManagerTaskContentTile: { keyFields: ["id"] },
  FootballRivalLeaderboardInterface: { keyFields: ["id"] },
  FootballRivalLeaderboardRankingInterface: { keyFields: ["id"] },
  FootballRivalsAggregatedArenaStory: { keyFields: ["id"] },
  FootballRivalsAppearance: { keyFields: ["id"] },
  FootballRivalsArenaRequest: { keyFields: ["id"] },
  FootballRivalsChallenge: { keyFields: ["id"] },
  FootballRivalsChallengeContentTile: { keyFields: ["id"] },
  FootballRivalsChallenger: { keyFields: ["id"] },
  FootballRivalsConfig: { keyFields: ["id"] },
  FootballRivalsContestant: { keyFields: ["id"] },
  FootballRivalsCurrentManager: { keyFields: ["id"] },
  FootballRivalsDivisionLeaderboard: { keyFields: ["id"] },
  FootballRivalsDivisionLeaderboardDivisionConfig: { keyFields: ["id"] },
  FootballRivalsDivisionRanking: { keyFields: ["id"] },
  FootballRivalsDraftableCard: { keyFields: ["id"] },
  FootballRivalsDraftableObjectInterface: { keyFields: ["id"] },
  FootballRivalsDraftablePlayer: { keyFields: ["id"] },
  FootballRivalsEloGroup: { keyFields: ["id"] },
  FootballRivalsGame: { keyFields: ["slug"] },
  FootballRivalsGameContentTile: { keyFields: ["id"] },
  FootballRivalsGameNotification: { keyFields: ["id"] },
  FootballRivalsInvite: { keyFields: ["id"] },
  FootballRivalsLineup: { keyFields: ["id"] },
  FootballRivalsLineupTactic: { keyFields: ["slug"] },
  FootballRivalsManager: { keyFields: ["id"] },
  FootballRivalsManagerNotification: { keyFields: ["id"] },
  FootballRivalsManagerSquadRanking: { keyFields: ["id"] },
  FootballRivalsPrivateRanking: { keyFields: ["id"] },
  FootballRivalsPromotionRelegationStory: { keyFields: ["id"] },
  FootballRivalsPromotionRelegationStoryInterface: { keyFields: ["id"] },
  FootballRivalsRivalry: { keyFields: ["id"] },
  FootballRivalsRoot: { keyFields: ["id"] },
  FootballRivalsSeason: { keyFields: ["slug"] },
  FootballRivalsSeasonSquad: { keyFields: ["id"] },
  FootballRivalsSquad: { keyFields: ["slug"] },
  FootballRivalsSquadMembershipChallenge: { keyFields: ["id"] },
  FootballRivalsSquadMembershipsLeaderboard: { keyFields: ["id"] },
  FootballRivalsSquadMembershipsLeaderboardRanking: { keyFields: ["id"] },
  FootballRivalsSquadPromotionRelegationStory: { keyFields: ["id"] },
  FootballRivalsSquadsLeaderboard: { keyFields: ["id"] },
  FootballRivalsSquadsLeaderboardRanking: { keyFields: ["id"] },
  FootballRivalsSuggestedManager: { keyFields: ["id"] },
  FootballRoot: { keyFields: ["id"] },
  FootballSo5NoCardRouteRoot: { keyFields: ["id"] },
  FootballUserSportProfile: { keyFields: ["id"] },
  FullWithdrawalRequest: { keyFields: ["id"] },
  Game: { keyFields: ["id"] },
  GameOfBaseball: { keyFields: ["id"] },
  GameOfBasketball: { keyFields: ["id"] },
  GbBankAccount: { keyFields: ["id"] },
  GiphySentActivity: { keyFields: ["id"] },
  HeroBanner: { keyFields: ["id"] },
  HeroBannersSet: { keyFields: ["id"] },
  IbanBankAccount: { keyFields: ["id"] },
  InGameCurrencyNotification: { keyFields: ["id"] },
  InGameCurrencyReward: { keyFields: ["id"] },
  InGameCurrencyRewardConfig: { keyFields: ["id"] },
  Injury: { keyFields: ["id"] },
  JerseyDeliverableItem: { keyFields: ["slug"] },
  JerseyShopItem: { keyFields: ["id"] },
  KycRequestNotification: { keyFields: ["id"] },
  LevelUpShopItem: { keyFields: ["id"] },
  LeveledUpCard: { keyFields: ["id"] },
  LoanableRoster: { keyFields: ["id"] },
  LobbyTile: { keyFields: ["id"] },
  LoomAccount: { keyFields: ["id"] },
  ManagerProgression: { keyFields: ["id"] },
  ManagerProgressionCategory: { keyFields: ["id"] },
  ManagerProgressionMission: { keyFields: ["id"] },
  ManagerProgressionMissionContentTile: { keyFields: ["id"] },
  ManagerProgressionNotification: { keyFields: ["id"] },
  ManagerProgressionTask: { keyFields: ["id"] },
  ManagerTaskInterface: { keyFields: ["id"] },
  ManagerTeam: { keyFields: ["id"] },
  MangopayApplePayAuthorizationRequest: { keyFields: ["id"] },
  MangopayCreditCardAuthorizationRequest: { keyFields: ["id"] },
  MangopayKycDocument: { keyFields: ["id"] },
  MangopayKycRequest: { keyFields: ["id"] },
  MangopayRoot: { keyFields: ["id"] },
  MangopayUser: { keyFields: ["id"] },
  MangopayWithdrawal: { keyFields: ["id"] },
  MarketPromotionalEvent: { keyFields: ["slug"] },
  MarketRoot: { keyFields: ["id"] },
  MarketingNotification: { keyFields: ["id"] },
  MarketplacePromotionalEvent: { keyFields: ["id"] },
  MarketplacePromotionalEvents: { keyFields: ["id"] },
  Membership: { keyFields: ["id"] },
  MissedAppearance: { keyFields: ["id"] },
  MissedLineup: { keyFields: ["slug"] },
  MissedLineupNotification: { keyFields: ["id"] },
  MonetaryReward: { keyFields: ["id"] },
  MonetaryRewardConfig: { keyFields: ["id"] },
  NBACard: { keyFields: ["slug"] },
  NBAPlayer: { keyFields: ["slug"] },
  NBAUserSportProfile: { keyFields: ["id"] },
  NationalTeam: { keyFields: ["slug"] },
  NoCardRoute: { keyFields: ["id"] },
  Node: { keyFields: ["id"] },
  NotificationInterface: { keyFields: ["id"] },
  OAuthAccessToken: { keyFields: ["id"] },
  OAuthApplication: { keyFields: ["id"] },
  OfferNotification: { keyFields: ["id"] },
  OnboardingCompetition: { keyFields: ["slug"] },
  OtherBankAccount: { keyFields: ["id"] },
  Owner: { keyFields: ["id"] },
  OwnerInterface: { keyFields: ["id"] },
  OwnerWithRates: { keyFields: ["id"] },
  Payment: { keyFields: ["id"] },
  PaymentMethod: { keyFields: ["id"] },
  PaypalAuthorizationRequest: { keyFields: ["id"] },
  PendingDeposit: { keyFields: ["id"] },
  PickablePlayer: { keyFields: ["id"] },
  Player: { keyFields: ["slug"] },
  PlayerGameScore: { keyFields: ["id"] },
  PlayerGameScoreInterface: { keyFields: ["id"] },
  PlayerGameStats: { keyFields: ["id"] },
  PlayerGameStatsBaseball: { keyFields: ["id"] },
  PlayerGameStatsBasketball: { keyFields: ["id"] },
  PlayerRecommendation: { keyFields: ["id"] },
  PlayerWithSupply: { keyFields: ["slug"] },
  PoolKeyShardsCount: { keyFields: ["slug"] },
  PreRegistrationData: { keyFields: ["id"] },
  PrivateAccount: { keyFields: ["id"] },
  PrivateEmptyAccount: { keyFields: ["id"] },
  PrivateFiatWalletAccount: { keyFields: ["id"] },
  PrivateKeyRecoveryOption: { keyFields: ["id"] },
  ProbabilisticBundle: { keyFields: ["id"] },
  ProbabilisticBundleConfig: { keyFields: ["id"] },
  ProbabilisticBundleOpeningDisclaimer: { keyFields: ["slug"] },
  ProbabilisticBundleReward: { keyFields: ["id"] },
  ProbabilisticBundleRewardConfig: { keyFields: ["id"] },
  ProbabilisticBundleSlotArenaTicketItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotCardItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotCardShardItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotConversionCreditItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotDeliverableItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotItemInterface: { keyFields: ["id"] },
  ProbabilisticBundleSlotRewardShopItem: { keyFields: ["id"] },
  ProbablePitchersForGameWeek: { keyFields: ["slug"] },
  PromotionalEventCompletion: { keyFields: ["id"] },
  PromotionalEventInterface: { keyFields: ["slug"] },
  PublicUserInfoInterface: { keyFields: ["slug"] },
  RaffleShopItem: { keyFields: ["id"] },
  Referral: { keyFields: ["id"] },
  ReferralLeaderboard: { keyFields: ["id"] },
  ReferralLeaderboardRanking: { keyFields: ["id"] },
  ReferralProgramConfig: { keyFields: ["id"] },
  ReferralReward: { keyFields: ["id"] },
  ReferralRewardNotification: { keyFields: ["id"] },
  ReferralSpecialEvent: { keyFields: ["id"] },
  ReferralSpecialEventChallenge: { keyFields: ["id"] },
  ReferralTile: { keyFields: ["id"] },
  ReminderNotification: { keyFields: ["id"] },
  ResponsiveBanner: { keyFields: ["id"] },
  ResponsiveBannersSet: { keyFields: ["id"] },
  RevealableCardCampaign: { keyFields: ["slug"] },
  RevealedCardsSummary: { keyFields: ["id"] },
  RewardCard: { keyFields: ["id"] },
  RivalsGameEndedActivity: { keyFields: ["id"] },
  RivalsGameStartedActivity: { keyFields: ["id"] },
  RivalsSquadMembership: { keyFields: ["id"] },
  RivalsUserGroup: { keyFields: ["slug"] },
  RivalsUserGroupMembership: { keyFields: ["id"] },
  RivalsUserGroupTournament: { keyFields: ["id"] },
  SaleNotification: { keyFields: ["id"] },
  ScoringMatrix: { keyFields: ["id"] },
  Season: { keyFields: ["id"] },
  SeasonPicture: { keyFields: ["id"] },
  ShopItem: { keyFields: ["id"] },
  ShopItemInterface: { keyFields: ["id"] },
  ShopItemNotification: { keyFields: ["id"] },
  ShopItemReward: { keyFields: ["id"] },
  ShopItemRewardConfig: { keyFields: ["id"] },
  SkinShopItem: { keyFields: ["id"] },
  So5Appearance: { keyFields: ["id"] },
  So5AppearanceInterface: { keyFields: ["id"] },
  So5Campaign: { keyFields: ["slug"] },
  So5Competition: { keyFields: ["slug"] },
  So5Config: { keyFields: ["id"] },
  So5Fixture: { keyFields: ["slug"] },
  So5FixturePreparing: { keyFields: ["slug"] },
  So5FixturePublicInterface: { keyFields: ["slug"] },
  So5Leaderboard: { keyFields: ["slug"] },
  So5LeaderboardContender: { keyFields: ["slug"] },
  So5LeaderboardGroup: { keyFields: ["slug"] },
  So5LeaderboardGroupInterface: { keyFields: ["slug"] },
  So5League: { keyFields: ["slug"] },
  So5LeagueTrack: { keyFields: ["slug"] },
  So5Lineup: { keyFields: ["id"] },
  So5LineupImport: { keyFields: ["id"] },
  So5LineupImportsBatch: { keyFields: ["id"] },
  So5LineupNotification: { keyFields: ["id"] },
  So5NoCardDraftableAppearance: { keyFields: ["id"] },
  So5NoCardLineup: { keyFields: ["id"] },
  So5OnboardingPromotionalEvent: { keyFields: ["id"] },
  So5Ranking: { keyFields: ["id"] },
  So5Reward: { keyFields: ["slug"] },
  So5RewardTargetConfig: { keyFields: ["slug"] },
  So5Root: { keyFields: ["id"] },
  So5Rules: { keyFields: ["id"] },
  So5Score: { keyFields: ["id"] },
  So5Tournament: { keyFields: ["id"] },
  So5UserGroup: { keyFields: ["slug"] },
  So5UserGroupMembership: { keyFields: ["id"] },
  So5UserGroupNotification: { keyFields: ["id"] },
  So5UserGroupTournament: { keyFields: ["id"] },
  SpecialEditionPromotionalEvent: { keyFields: ["slug"] },
  SpecialWeeklyBanner: { keyFields: ["slug"] },
  StageGroup: { keyFields: ["id"] },
  StarkwareAccount: { keyFields: ["id"] },
  StarkwarePrivateAccount: { keyFields: ["id"] },
  StarkwareWithdrawal: { keyFields: ["id"] },
  StartingLineupRevealedActivity: { keyFields: ["id"] },
  Stats: { keyFields: ["id"] },
  Story: { keyFields: ["id"] },
  StoryInterface: { keyFields: ["id"] },
  StripeCreditCardAuthorizationRequest: { keyFields: ["id"] },
  Suspension: { keyFields: ["id"] },
  SwappablePlayer: { keyFields: ["id"] },
  TeamInterface: { keyFields: ["slug"] },
  TeamsPlayingNextGameWeek: { keyFields: ["slug"] },
  TicketsDeliverableItem: { keyFields: ["slug"] },
  TokenAuction: { keyFields: ["id"] },
  TokenBid: { keyFields: ["id"] },
  TokenMonetaryReward: { keyFields: ["id"] },
  TokenMyBid: { keyFields: ["id"] },
  TokenOffer: { keyFields: ["id"] },
  TokenOfferSide: { keyFields: ["id"] },
  TokenOwner: { keyFields: ["id"] },
  TokenPrice: { keyFields: ["id"] },
  TokenPrimaryOffer: { keyFields: ["id"] },
  TokenWithdrawal: { keyFields: ["id"] },
  TournamentCalendarStage: { keyFields: ["id"] },
  UsBankAccount: { keyFields: ["id"] },
  User: { keyFields: ["slug"] },
  UserAccountEntry: { keyFields: ["id"] },
  UserAward: { keyFields: ["slug"] },
  UserAwardNotification: { keyFields: ["id"] },
  UserCardCollection: { keyFields: ["id"] },
  UserCardCollectionSlot: { keyFields: ["slug"] },
  UserCardsCount: { keyFields: ["slug"] },
  UserDevice: { keyFields: ["id"] },
  UserDeviceEvent: { keyFields: ["id"] },
  UserFlag: { keyFields: ["id"] },
  UserGroupCreatedActivity: { keyFields: ["id"] },
  UserGroupInterface: { keyFields: ["slug"] },
  UserGroupMemberJoinedActivity: { keyFields: ["id"] },
  UserGroupMembershipInterface: { keyFields: ["id"] },
  UserGroupRankedActivity: { keyFields: ["id"] },
  UserOwner: { keyFields: ["id"] },
  UserProfile: { keyFields: ["id"] },
  UserProfileBadge: { keyFields: ["slug"] },
  UserRankingInterface: { keyFields: ["id"] },
  UserRelation: { keyFields: ["id"] },
  UserRelationNotification: { keyFields: ["id"] },
  UserSettings: { keyFields: ["id"] },
  UserShopItem: { keyFields: ["id"] },
  UserSource: { keyFields: ["id"] },
  UserSportProfileInterface: { keyFields: ["id"] },
  UserSportStatistic: { keyFields: ["id"] },
  UserWallet: { keyFields: ["id"] },
  UserWithSubscriptionSlug: { keyFields: ["slug"] },
  VerificationSession: { keyFields: ["id"] },
  WearableDeliverableItem: { keyFields: ["slug"] },
  WithSubscriptionsInterface: { keyFields: ["slug"] },
  Withdrawal: { keyFields: ["id"] },
  WithdrawalWithRates: { keyFields: ["id"] },
};
