import { defineMessages } from 'react-intl';

export const metadatas = {
  default: defineMessages({
    title: {
      id: 'Meta.common.default.title',
      defaultMessage: 'Sorare: Own Your Game',
    },
    description: {
      id: 'Meta.common.default.description',
      defaultMessage:
        "Collect, play and win officially licensed digital cards featuring the world's best global football, MLB and NBA players.",
    },
  }),
  landing: defineMessages({
    title: {
      id: 'Meta.common.landing.title',
      defaultMessage:
        'Play Sorare Fantasy Football, NBA, MLB w/tradable player cards',
    },
    description: {
      id: 'Meta.common.landing.description',
      defaultMessage:
        'Play Sorare’s Fantasy Football, MLB & NBA games. Collect, buy, sell and compete with tradable digital player cards to win rewards.',
    },
  }),
  card: defineMessages({
    title: {
      id: 'Meta.common.Card.title',
      defaultMessage: '{scarcity} Card of {display_name} – {season}',
    },
    description: {
      id: 'Meta.common.Card.description',
      defaultMessage:
        'Collect, trade and play with {display_name} Sorare cards available in different scarcities and Own Your Game',
    },
  }),
  player: defineMessages({
    title: {
      id: 'Meta.common.player.title',
      defaultMessage: '{display_name} – Player Profile',
    },
    description: {
      id: 'Meta.common.player.description',
      defaultMessage:
        'Find all the statistics of {display_name}. Collect and trade officially licensed digital cards of your favourite player on Sorare',
    },
  }),
  playerCard: defineMessages({
    title: {
      id: 'Meta.common.player.card.title',
      defaultMessage: '{display_name} Cards – Collect and Trade',
    },
    description: {
      id: 'Meta.common.player.card.description',
      defaultMessage:
        'Find all the officially licensed digital cards of {display_name}: Limited, Rare, Super Rare, Unique. Only on Sorare.',
    },
  }),
  userGallery: defineMessages({
    title: {
      id: 'Meta.common.userGallery.title',
      defaultMessage: '{username}’s Gallery and Profile',
    },
    description: {
      id: 'Meta.common.userGallery.description',
      defaultMessage: '{username} Sorare cards gallery and Profile',
    },
  }),
  managerHome: defineMessages({
    title: {
      id: 'Meta.common.managerHome.title',
      defaultMessage: 'Manager Home',
    },
    description: {
      id: 'Meta.common.managerHome.description',
      defaultMessage: '{username} Sorare Manager Home',
    },
  }),
  myGallery: defineMessages({
    title: {
      id: 'Meta.common.myGallery.title',
      defaultMessage: 'My Gallery',
    },
    description: {
      id: 'Meta.common.myGallery.description',
      defaultMessage: '{username} Sorare Cards gallery',
    },
  }),
  press: defineMessages({
    title: {
      id: 'Meta.common.press.title',
      defaultMessage: 'Press articles about us',
    },
  }),
  licensedPartners: defineMessages({
    title: {
      id: 'Meta.common.licensedPartners.title',
      defaultMessage: '300+ licensed clubs',
    },
    description: {
      id: 'Meta.common.licensedPartners.description',
      defaultMessage:
        'We have partnered with the best sports leagues and clubs in the world to bring our next-level fantasy sports game to fans across the globe',
    },
  }),
  coverage: defineMessages({
    title: {
      id: 'Meta.common.coverage.title',
      defaultMessage: 'Covered Competitions',
    },
    description: {
      id: 'Meta.common.coverage.description',
      defaultMessage:
        'We cover dozens of competitions every week, allowing you to follow the performances of your players around the world',
    },
  }),
  collection: defineMessages({
    title: {
      id: 'Collection.title',
      defaultMessage: "{username}'s collection: {collectionName}",
    },
  }),
};
