/**
 * Recreate the infamous html <marquee /> component with super power.
 * User can scroll the content in either direction with an infinite loop.
 * Whenever the user hovers the content, the animation slows down and
 * whenever the user scroll in either direction, the autoplay will continue
 * in that direction.
 *
 * Used this component for decoration only, it is not meant to replace accessible carousel
 * with arrow navigation.
 */
import classNames from 'classnames';
import { PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';
import { usePrevious, useScroll } from 'react-use';
import styled, { CSSProperties, keyframes } from 'styled-components';

import { fadeIn } from 'atoms/animations/keyframes';
import { range } from 'lib/arrays';
import { unitMapping } from 'lib/style';

const marquee = keyframes`
  from {
    transform: translateX(calc(-1 * var(--itemWidth) / 2));
  }
  to {
    transform: translateX(calc(var(--itemWidth) / 2));
  }
`;

const Root = styled.div`
  --animation: ${marquee} linear infinite;
  --dur: var(--duration, 100s);
  --speed: 0.5;
  --itemWidth: 100% / var(--items);
  position: relative;
  overflow: auto;
  opacity: 0;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &.revelead {
    animation: 0.3s ${fadeIn} ease forwards;
  }
  &.stretch {
    &,
    * {
      height: 100%;
    }
  }
`;

const Inner = styled.div`
  display: flex;
  gap: var(--gap);
  width: max-content;
`;

const Item = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
`;

/**
 * Since we can't use animation-composition, we need to nest element using
 * the same animation to either reverse or slow down the animation
 * without the glitch that would occure if we used 4 differents animations.
 *
 * We could have also combined 2 different animations if we could use 2 different prop doing the same thing,
 * but we can't use translate-x either because of browser support.
 */
const LeftToRight = styled.div`
  animation: var(--animation) var(--dur);
  width: fit-content;
  .reverse &,
  &:hover:not(.reverse &) {
    animation-play-state: paused;
  }
`;
const LeftToRightSlow = styled.div`
  animation: var(--animation) calc(var(--dur) / var(--speed)) paused;
  width: fit-content;
  &:hover:not(.reverse &) {
    animation-play-state: running;
  }
`;
const RightToLeft = styled.div`
  animation: var(--animation) var(--dur) reverse paused;
  width: fit-content;
  .reverse & {
    &:not(:hover) {
      animation-play-state: running;
    }
  }
`;
const RightToLeftSlow = styled.div`
  animation: var(--animation) calc(var(--dur) / var(--speed)) reverse paused;
  width: fit-content;
  .reverse & {
    &:hover {
      animation-play-state: running;
    }
  }
`;

type Props = {
  gap?: keyof typeof unitMapping;
  duration?: string;
  direction?: 'normal' | 'reverse';
  stretch?: boolean;
};
export const Marquee = ({
  children,
  gap = 2,
  duration,
  direction,
  stretch,
}: PropsWithChildren<Props>) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const firstItemRef = useRef<HTMLDivElement>(null);
  const { x } = useScroll(scrollRef);
  const prevX = usePrevious(x) || 0;
  const reverse = prevX ? prevX < x : direction === 'reverse';
  const [items, setItems] = useState(2);
  const [revelead, setRevealed] = useState(false);

  useLayoutEffect(() => {
    /** This early return is for testing reasons... */
    if (!scrollRef.current?.offsetWidth) return;

    /**
     * How many Item fit n times in the container, multiplied by 2 for the infinite loop.
     * We need to take into account the scroll that can be at most half the size of an Item on each side.
     * So we need to take the container's width and adding half an items's width so the animation can start
     * at the furthest scroll position in either normal or reverse mode.
     */
    const itemWidth = firstItemRef.current!.clientWidth;
    const numberOfItems = Math.ceil(
      ((scrollRef.current.offsetWidth + itemWidth / 2) / itemWidth) * 2
    );
    if (numberOfItems && numberOfItems < Infinity) {
      /** We always want it to be centered properly */
      if (numberOfItems % 2 === 0) {
        setItems(numberOfItems + 1);
      } else {
        setItems(numberOfItems);
      }
    }
  }, []);

  useLayoutEffect(() => {
    /** We intialize the scroll at 50% */
    const itemWidth = firstItemRef.current!.clientWidth;
    scrollRef.current!.scrollLeft =
      (itemWidth * items - scrollRef.current!.offsetWidth) / 2;
    setRevealed(true);
  }, [items]);

  return (
    <Root
      ref={scrollRef}
      onScroll={e => {
        /**
         * This create the infinite loop when you scroll. Whenever you reach 50% + 1 items's width
         * you go back to 0 and whenever you reach 50% - 1 items's width you go back to 50% + 1 items's width.
         */
        const itemWidth = firstItemRef.current!.clientWidth;
        const midScroll =
          (itemWidth * items - scrollRef.current!.offsetWidth) / 2;
        const maxScroll = midScroll + itemWidth / 2;
        const minScroll = midScroll - itemWidth / 2;
        const { scrollLeft } = e.currentTarget;
        if (scrollLeft >= maxScroll) {
          e.currentTarget.scrollLeft = minScroll + 1;
        } else if (scrollLeft <= minScroll) {
          e.currentTarget.scrollLeft = maxScroll - 1;
        }
      }}
      style={
        {
          '--gap': unitMapping[gap],
          '--duration': duration,
          '--items': items,
        } as CSSProperties
      }
      className={classNames({ reverse, revelead, stretch })}
    >
      <LeftToRight>
        <LeftToRightSlow>
          <RightToLeft>
            <RightToLeftSlow>
              <Inner>
                <Item ref={firstItemRef}>{children}</Item>
                {range(Math.max(1, items - 1)).map(i => (
                  <Item key={i} aria-hidden="true">
                    {children}
                  </Item>
                ))}
              </Inner>
            </RightToLeftSlow>
          </RightToLeft>
        </LeftToRightSlow>
      </LeftToRight>
    </Root>
  );
};
