import { TypedDocumentNode, gql } from '@apollo/client';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { ReactNode, useMemo } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import styled from 'styled-components';

import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { LabelL } from '@sorare/core/src/atoms/typography';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { fantasy } from '@sorare/core/src/lib/glossary';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import { ClickableAnyRewardConfig } from 'components/rewards/ClickableAnyRewardConfig';
import { Variant } from 'components/rewards/RewardOverview/types';

import {
  LineupRewardsDistancesQuery,
  LineupRewardsDistancesQueryVariables,
} from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 0 })`
  background: var(--c-nd-50);
`;

const StyledSBHorizontal = styled(SBHorizontal)`
  padding-right: var(--unit);
`;

const StyledIconButton = styled(IconButton)`
  @media ${laptopAndAbove} {
    display: none;
  }
`;

const Loader = styled(Vertical).attrs({ gap: 0 })`
  min-height: 300px;
`;

const RewardConfig = styled(SBHorizontal).attrs({ gap: 2 })`
  border-top: 1px solid var(--c-nd-100);
  padding: var(--half-unit) var(--unit);
  font: var(--t-12);

  &.eligible {
    background-color: rgba(var(--c-rgb-brand-600), 0.1);
  }
`;

const DistanceAndRank = styled(Vertical).attrs({ gap: 0 })``;

const ColoredDistance = styled.div<{ delta: number }>`
  color: ${props =>
    props.delta > 0 ? 'var(--c-static-green-600)' : 'var(--c-static-red-300)'};
`;

const PercentageOfGamesPlayed = styled(LabelL).attrs({ as: 'div', bold: true })`
  padding: var(--double-unit);
`;

const LINEUP_REWARDS_DISTANCES_QUERY = gql`
  query LineupRewardsDistancesQuery($so5RankingId: ID!) {
    so5 {
      so5Ranking(id: $so5RankingId) {
        id
        score
        ranking
        so5Leaderboard {
          slug
          mainRarityType
          so5LeaderboardGroup {
            slug
            percentageOfGamesPlayed
          }
          so5League {
            slug
            ...ClickableAnyRewardConfig_so5League
          }
          rewardsConfig {
            ranking {
              fromRank
              toRank
              toSo5Ranking {
                id
                score
              }
              rewardConfigs {
                id
                ...ClickableAnyRewardConfig_anyRewardConfigInterface
              }
            }
            conditional {
              score
              rewardConfigs {
                id
                ...ClickableAnyRewardConfig_anyRewardConfigInterface
              }
            }
          }
        }
      }
    }
  }
  ${ClickableAnyRewardConfig.fragments.anyRewardConfigInterface}
  ${ClickableAnyRewardConfig.fragments.so5League}
` as TypedDocumentNode<
  LineupRewardsDistancesQuery,
  LineupRewardsDistancesQueryVariables
>;

type RankingRewardConfig = NonNullable<
  LineupRewardsDistancesQuery['so5']['so5Ranking']['so5Leaderboard']['rewardsConfig']['ranking']
>[number];
type ConditionalRewardConfig = NonNullable<
  LineupRewardsDistancesQuery['so5']['so5Ranking']['so5Leaderboard']['rewardsConfig']['conditional']
>[number];

const isRankingConfig = (
  config: RankingRewardConfig | ConditionalRewardConfig
): config is RankingRewardConfig => {
  return 'fromRank' in config;
};

type Props = {
  so5RankingId: string;
  onClose?: () => void;
};
export const LineupRewardsDistances = ({ so5RankingId, onClose }: Props) => {
  const { formatMessage } = useIntl();
  const { data, loading } = useQuery(LINEUP_REWARDS_DISTANCES_QUERY, {
    variables: { so5RankingId },
    fetchPolicy: 'cache-first',
  });

  const { so5Ranking: mySo5Ranking } = data?.so5 || {};
  const { rewardsConfig, so5LeaderboardGroup } =
    mySo5Ranking?.so5Leaderboard || {};
  const { percentageOfGamesPlayed } = so5LeaderboardGroup || {};

  const so5RewardConfigs = useMemo(() => {
    if (!data || !mySo5Ranking) {
      return [];
    }

    return [
      ...(rewardsConfig?.ranking || []),
      ...(rewardsConfig?.conditional || []),
    ]
      .map(r => {
        const isRankingRewardConfig = isRankingConfig(r);
        const requiredScore = isRankingRewardConfig
          ? r.toSo5Ranking?.score || 0
          : r.score || 0;
        const distance = mySo5Ranking.score - requiredScore;
        const eligible =
          isRankingRewardConfig &&
          (r.fromRank || 0) <= (mySo5Ranking.ranking || 0) &&
          (mySo5Ranking.ranking || 0) <= (r.toRank || 0);

        let distanceLabel: ReactNode;
        let label: ReactNode;

        if (isRankingRewardConfig) {
          label =
            r.fromRank !== r.toRank
              ? `${r.fromRank} - ${r.toRank}`
              : r.fromRank;
        } else {
          label = formatMessage(fantasy.points, { score: r.score });
        }

        if (distance === 0 && requiredScore !== 0) {
          distanceLabel = (
            <FormattedMessage
              id="LineupRewardsDistances.zeroScoreDelta"
              defaultMessage="just enough"
            />
          );
        } else if (distance > 0) {
          distanceLabel = (
            <ColoredDistance delta={distance}>
              <FormattedMessage
                id="LineupRewardsDistances.positiveScoreDelta"
                defaultMessage="{scoreDelta}pts ahead"
                values={{
                  scoreDelta: (
                    <FormattedNumber
                      value={Math.floor(distance)}
                      maximumFractionDigits={0}
                    />
                  ),
                }}
              />
            </ColoredDistance>
          );
        } else if (distance < 0) {
          distanceLabel = (
            <ColoredDistance delta={distance}>
              <FormattedMessage
                id="LineupRewardsDistances.negativeScoreDelta"
                defaultMessage="{scoreDelta}pts away from"
                values={{
                  scoreDelta: (
                    <FormattedNumber
                      value={-Math.floor(distance)}
                      maximumFractionDigits={2}
                    />
                  ),
                }}
              />
            </ColoredDistance>
          );
        }

        return {
          ...r,
          distance,
          eligible,
          distanceLabel,
          label,
        };
      })
      .sort((a, b) => a.distance - b.distance);
  }, [
    data,
    formatMessage,
    mySo5Ranking,
    rewardsConfig?.conditional,
    rewardsConfig?.ranking,
  ]);

  if (loading || !data) {
    return (
      <Root>
        <Loader>
          <LoadingIndicator small />
        </Loader>
      </Root>
    );
  }

  return (
    <Root>
      <StyledSBHorizontal>
        {percentageOfGamesPlayed !== undefined && (
          <PercentageOfGamesPlayed>
            <FormattedMessage
              id="LineupRewardsDistances.percentageOfGamesPlayed2"
              defaultMessage="{percentage, number, percent} of games played"
              values={{
                percentage: percentageOfGamesPlayed,
              }}
            />
          </PercentageOfGamesPlayed>
        )}
        <StyledIconButton
          small
          color="transparent"
          icon={faAngleDown}
          onClick={onClose}
        />
      </StyledSBHorizontal>
      {so5RewardConfigs.map((so5RewardConfig, i) => {
        return (
          <RewardConfig
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className={classnames({ eligible: so5RewardConfig.eligible })}
          >
            <DistanceAndRank>
              {percentageOfGamesPlayed !== undefined &&
                so5RewardConfig.distanceLabel}
              <LabelL as="div" bold>
                {so5RewardConfig.label}
              </LabelL>
            </DistanceAndRank>
            <Horizontal gap={1}>
              {so5RewardConfig.rewardConfigs.map((rewardConfig, index) => (
                <ClickableAnyRewardConfig
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  anyRewardConfig={rewardConfig}
                  variant={Variant.INLINE}
                  so5League={mySo5Ranking?.so5Leaderboard.so5League}
                />
              ))}
            </Horizontal>
          </RewardConfig>
        );
      })}
    </Root>
  );
};
