import { TypedDocumentNode, gql, useSubscription } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Drawer } from '@sorare/core/src/atoms/layout/Drawer';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { HeadlineS } from '@sorare/core/src/atoms/typography';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import { useAnySportEvents } from 'lib/events';

import { FootballBench } from './FootballBench';
import FootballField from './FootballField';
import Overview from './Overview';
import PlayerDetails from './PlayerDetails';
import {
  MatchViewFormationsQuery,
  MatchViewFormationsQueryVariables,
  MatchView_game,
  onMatchViewDataUpdated,
  onMatchViewDataUpdatedVariables,
} from './__generated__/index.graphql';

const LoaderContainer = styled.div`
  color: var(--c-white);
  padding: var(--quadruple-unit) 0;
`;
const MatchViewContainer = styled(Vertical).attrs({ gap: 0 })`
  width: 100%;
  flex-grow: 1;
  border-radius: var(--double-unit);
`;
const Substitutes = styled(Vertical)`
  padding-top: var(--unit);
`;
const SubstitutesTitleWrapper = styled(Horizontal)`
  padding: 0 var(--double-unit);
`;
const TeamLogo = styled.img`
  width: var(--quadruple-unit);
`;
const CenteredHeadlineS = styled(HeadlineS)`
  margin: auto;
`;
const Benchs = styled.div`
  display: grid;
  align-items: start;
  column-gap: var(--unit);
  grid-template-columns: repeat(2, 1fr);
`;

const gameFragment = gql`
  fragment MatchView_game on Game {
    id
    homeFormation {
      bench {
        slug
        id
        ...FootballBench_anyPlayer
      }
    }
    awayFormation {
      bench {
        slug
        id
        ...FootballBench_anyPlayer
      }
    }
    ...Overview_game
    ...FootballField_game
  }
  ${Overview.fragments.game}
  ${FootballField.fragments.game}
  ${FootballBench.fragments.anyPlayer}
` as TypedDocumentNode<MatchView_game>;

const subscription = gql`
  subscription onMatchViewDataUpdated($id: ID!) {
    aGameWasUpdated(id: $id) {
      id
      ...MatchView_game
    }
  }
  ${gameFragment}
` as TypedDocumentNode<onMatchViewDataUpdated, onMatchViewDataUpdatedVariables>;

const MATCH_VIEW_FORMATIONS_QUERY = gql`
  query MatchViewFormationsQuery($id: ID!) {
    football {
      game(id: $id) {
        id
        status: statusTyped
        ...MatchView_game
      }
    }
  }
  ${gameFragment}
` as TypedDocumentNode<
  MatchViewFormationsQuery,
  MatchViewFormationsQueryVariables
>;

type Props = {
  id: string;
  desktop?: boolean;
  hideScoresAndGameWeek?: boolean;
  hideGameOverview?: boolean;
  onPlayerSelect?: (playerSlug: string, scoreId?: string) => void;
  highlightedPlayerSlugs?: string[];
  onClose?: () => void;
};
const MatchView = ({
  id,
  desktop,
  hideScoresAndGameWeek,
  hideGameOverview,
  onPlayerSelect,
  highlightedPlayerSlugs,
  onClose,
}: Props) => {
  useSubscription(subscription, { variables: { id: idFromObject(id) } });
  const track = useAnySportEvents();
  const matchViewRef = useRef<HTMLDivElement>(null);
  const [selectedPlayerSlug, setSelectedPlayerSlug] = useState<
    string | undefined
  >(undefined);
  const [isPlayerDetailsOpen, setIsPlayerDetailsOpen] =
    useState<boolean>(false);

  const gameId = idFromObject(id);
  const { data: formationData, loading: formationLoading } = useQuery(
    MATCH_VIEW_FORMATIONS_QUERY,
    {
      variables: {
        id: gameId!,
      },
      skip: !gameId,
    }
  );

  useEffect(() => {
    if (formationData) {
      track('Open Match View', {
        gameId,
        gameStatus: formationData.football.game.status,
        deviceType: `web_${desktop ? 'desktop' : 'mobile'}`,
        interactionContext: 'match_view',
      });
    }
  }, [desktop, formationData, gameId, track]);

  if (formationLoading || !formationData) {
    return (
      <LoaderContainer>
        <LoadingIndicator small />
      </LoaderContainer>
    );
  }
  const { game } = formationData.football;

  const onPlayerDetailsClick = (playerSlug?: string, scoreId?: string) => {
    if (onPlayerSelect && playerSlug) {
      onPlayerSelect(playerSlug, scoreId);
    } else if (isPlayerDetailsOpen && selectedPlayerSlug === playerSlug) {
      setIsPlayerDetailsOpen(false);
    } else {
      setSelectedPlayerSlug(playerSlug);
      setIsPlayerDetailsOpen(true);
      if (playerSlug) {
        track('Open Match View Player Details', {
          playerSlug,
        });
      }
    }
  };
  const onPlayerDetailsClose = () => setIsPlayerDetailsOpen(false);

  return (
    <>
      {selectedPlayerSlug && (
        <Drawer open={isPlayerDetailsOpen}>
          <PlayerDetails
            playerSlug={selectedPlayerSlug}
            gameId={id}
            onClose={onPlayerDetailsClose}
          />
        </Drawer>
      )}
      <Vertical gap={0.5}>
        <MatchViewContainer ref={matchViewRef}>
          {!hideGameOverview && (
            <Overview
              game={game}
              desktop={desktop}
              hideScoresAndGameWeek={hideScoresAndGameWeek}
              onClose={onClose}
            />
          )}
          <FootballField
            game={game}
            highlightedPlayerSlugs={highlightedPlayerSlugs}
            onPlayerDetailsClick={onPlayerDetailsClick}
            desktop={desktop}
          />
        </MatchViewContainer>

        <Substitutes>
          <SubstitutesTitleWrapper>
            {game.homeTeam?.pictureUrl && (
              <TeamLogo src={game.homeTeam.pictureUrl} alt="" />
            )}
            <CenteredHeadlineS as="h3">
              <FormattedMessage
                id="MatchView.substitutes.title"
                defaultMessage="Substitutes"
              />
            </CenteredHeadlineS>
            {game.awayTeam?.pictureUrl && (
              <TeamLogo src={game.awayTeam.pictureUrl} alt="" />
            )}
          </SubstitutesTitleWrapper>

          <Benchs>
            <FootballBench
              benchPlayers={game.homeFormation.bench}
              highlightedPlayerSlugs={highlightedPlayerSlugs}
              onClick={onPlayerDetailsClick}
              desktop={desktop}
            />
            <FootballBench
              benchPlayers={game.awayFormation.bench}
              highlightedPlayerSlugs={highlightedPlayerSlugs}
              onClick={onPlayerDetailsClick}
              desktop={desktop}
            />
          </Benchs>
        </Substitutes>
      </Vertical>
    </>
  );
};

export default MatchView;
