import { TypedDocumentNode, gql } from '@apollo/client';

import { BlueprintRevealStatus } from '__generated__/globalTypes';
import { dateProgress } from 'lib/dates';
import { withFragments } from 'lib/gql';

import type { earlyAccessOverlay_AnyCardInterface } from './__generated__/earlyAccess.graphql';
import delayedUrl from './assets/delayed.png';
import lockedUrl from './assets/locked.png';
import { drawImage } from './utils/drawImage';
import { drawProgressBar } from './utils/drawProgressBar';

export const earlyAccessOverlay = withFragments(
  (card: earlyAccessOverlay_AnyCardInterface) => {
    const { blueprint } = card;
    if (!blueprint) {
      return undefined;
    }
    return async (image: ImageBitmap) => {
      const canvas = document.createElement('canvas');
      const WIDTH = image.width;
      const HEIGHT = image.height;

      canvas.width = WIDTH;
      canvas.height = HEIGHT;

      const state = blueprint.revealStatus;
      const progress = blueprint.revealDate
        ? dateProgress(
            blueprint.launchDate ?? new Date(2024, 6, 15),
            blueprint.revealDate
          ) / 100
        : 0;

      const ctx = canvas.getContext('2d')!;
      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      // draw texture
      ctx.drawImage(image, 0, 0);

      const promises: Promise<void>[] = [];

      const STEPS = 20;
      const revealable = [
        BlueprintRevealStatus.REVEALABLE,
        BlueprintRevealStatus.REVEALED,
      ].includes(state);

      const stepsFilled = revealable
        ? STEPS
        : Math.min(STEPS - 1, Math.max(1, Math.floor(progress * STEPS)));

      promises.push(
        drawProgressBar({
          ctx,
          stepsFilled,
          boundingBox: {
            left: 0.21,
            top: 0.64,
            width: 0.58,
            height: 0.035,
          },
        })
      );

      if (state === BlueprintRevealStatus.DELAYED) {
        promises.push(
          drawImage({
            ctx,
            imageUrl: delayedUrl,
            boundingBox: {
              left: 0,
              top: 0,
              width: 1,
              height: 1,
            },
          })
        );
      }

      if (state === BlueprintRevealStatus.UNREVEALABLE) {
        promises.push(
          drawImage({
            ctx,
            imageUrl: lockedUrl,
            boundingBox: {
              left: 0,
              top: 0,
              width: 1,
              height: 1,
            },
          })
        );
      }

      await Promise.all(promises);

      return createImageBitmap(canvas);
    };
  },
  {
    AnyCardInterface: gql`
      fragment earlyAccessOverlay_AnyCardInterface on AnyCardInterface {
        slug
        blueprint {
          id
          launchDate
          revealDate
          revealStatus
        }
      }
    ` as TypedDocumentNode<earlyAccessOverlay_AnyCardInterface>,
  }
);
