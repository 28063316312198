import { ConsentManagerBuilder } from '@segment/consent-manager';
import { matchPath, useLocation } from 'react-router-dom';

import { Popup } from 'atoms/layout/Popup';
import {
  COOKIE_POLICY,
  FOOTBALL_MINIGAMES_PVE_LEAGUE,
  NBA_MINIGAMES_PVE_CONFERENCE,
} from 'constants/__generated__/routes';
import { LANDING_PREVIEW_ROOT } from 'constants/routes';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import useLocalStorage, { STORAGE } from 'hooks/useLocalStorage';
import useToggle from 'hooks/useToggle';

import { IS_PRERENDER, SEGMENT_API_KEY } from '../../../config';
import { DetailedConsent, SimpleConsent } from './Consent';

declare global {
  interface Window {
    segmentApiKey: string;
  }
}

const DISABLED_LOCATIONS = [
  // this pages needs to be accessible without cookies/consent modal
  COOKIE_POLICY,
  // used within Contentful preview
  LANDING_PREVIEW_ROOT,
  FOOTBALL_MINIGAMES_PVE_LEAGUE,
  NBA_MINIGAMES_PVE_CONFERENCE,
];

const ALLOWED_MOBILE_APP_DESTINATION_IDS = [
  'Amplitude',
  'Appboy', // Braze
  'Braze Web Mode (Actions)',
  'Visual Tagger', // Segment
];

export const CookieConsentBanner = () => {
  const [detailsOpened, toggleDetails] = useToggle(false);
  const { isMobileApp, consentTracking } = useIsMobileApp();
  const [, setConsent] = useLocalStorage<false | true>(
    STORAGE.trackingConsent,
    false
  );

  const location = useLocation();

  if (!SEGMENT_API_KEY) {
    return null;
  }

  if (IS_PRERENDER) {
    // Do not load ads/trackers for prerender
    return null;
  }

  if (DISABLED_LOCATIONS.find(path => matchPath(path, location.pathname))) {
    // no banner on this page
    return null;
  }

  return (
    <ConsentManagerBuilder
      writeKey={SEGMENT_API_KEY}
      // 6 month (but safari limits it to 7 days)
      cookieExpires={183}
    >
      {args => {
        const {
          isConsentRequired,
          destinations,
          newDestinations,
          preferences,
          setPreferences,
          saveConsent,
        } = args;

        if (isMobileApp) {
          // cherry-pick the allowed destinations within mobile apps
          // and do not show the cookie consent banner (this is done natively)
          if (
            destinations.length > 0 &&
            Object.entries(preferences).length === 0
          ) {
            setTimeout(() => {
              // wait for the next tick to avoid a warning
              setPreferences(
                Object.fromEntries(
                  destinations.map(d => [
                    d.id,
                    consentTracking &&
                      ALLOWED_MOBILE_APP_DESTINATION_IDS.includes(d.id),
                  ])
                )
              );
              saveConsent();
            }, 1);
          }
          return <div />;
        }

        if (newDestinations.length === 0 || !isConsentRequired) {
          // ConsentManagerBuilder requires a non null return value :/
          return <div />;
        }
        const setAll = (v: boolean) => {
          setPreferences(Object.fromEntries(destinations.map(d => [d.id, v])));
        };

        // Fix issue with default save
        //  https://github.com/segmentio/consent-manager/issues/92
        const FixedSaveConsent = (value?: boolean) => {
          if (value !== undefined) {
            setAll(value);
          }
          setConsent(value !== false);
          if (value !== false) {
            // TODO(su): if this is working, expose within the details modal
            window.analytics.ready(() => {
              if (window.gtag) {
                window.gtag('consent', 'update', {
                  ad_storage: 'granted',
                  ad_user_data: 'granted',
                  ad_personalization: 'granted',
                  analytics_storage: 'granted',
                });
              }
            });
          }
          saveConsent();
        };
        return (
          <Popup>
            {detailsOpened ? (
              <DetailedConsent
                {...{
                  destinations,
                  preferences,
                  setPreferences,
                  saveConsent: FixedSaveConsent,
                }}
              />
            ) : (
              <SimpleConsent
                saveConsent={FixedSaveConsent}
                seeDetails={() => {
                  setAll(true);
                  toggleDetails();
                }}
              />
            )}
          </Popup>
        );
      }}
    </ConsentManagerBuilder>
  );
};
