import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { Breadcrumb } from '@sorare/core/src/components/breadcrumb';
import { DrukWide24 } from '@sorare/core/src/components/marketing/typography';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import { Category, useCategories } from 'hooks/zendesk';
import { categoryPath, sectionPath } from 'lib/path';

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--triple-unit);
  @media (${laptopAndAbove}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const CategoryImg = styled.img`
  max-width: 100%;
`;

const Card = styled(Vertical).attrs({ gap: 2 })`
  border: 1px solid var(--c-nd-100);
  border-radius: var(--unit);
  overflow: hidden;
`;

const Content = styled.div`
  padding: var(--unit);

  & ul {
    padding-left: var(--triple-unit);

    & li {
      list-style: disc;
      font-size: var(--t-20);
      line-height: 140%;
    }
  }
`;

const CategoryCard = ({ category }: { category: Category }) => {
  return (
    <Card>
      <CategoryImg
        src={`https://frontend-assets.sorare.com/hc/${category.id}.png`}
        alt={category.name}
      />
      <Content>
        <Link to={categoryPath(category)}>
          <DrukWide24>{category.name}</DrukWide24>
        </Link>
        <ul>
          {category.sections.map(section => (
            <li key={section.id}>
              <Link to={sectionPath(section)}>{section.name}</Link>
            </li>
          ))}
        </ul>
      </Content>
    </Card>
  );
};

const Home = () => {
  const categories = useCategories();

  if (categories.length === 0) {
    return <LoadingIndicator fullHeight />;
  }

  return (
    <>
      <Breadcrumb />
      <Grid>
        {categories.map(category => (
          <CategoryCard key={category.id} category={category} />
        ))}
      </Grid>
    </>
  );
};

export default Home;
