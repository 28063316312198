import { stringify } from 'qs';
import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { OnBackForgotPassword } from '@sorare/wallet-shared';
import { Sport, TermsAndConditionsStatus } from '__generated__/globalTypes';
import { GoogleReCAPTCHA, ReCAPTCHA } from 'components/recaptcha';
import { TERMS_AND_CONDITIONS } from 'constants/__generated__/routes';
import {
  OAUTH_LOCKED_ACCOUNT_ERROR_CODE,
  OAUTH_MISSING_EMAIL_ERROR_CODE,
} from 'constants/errors';
import { useCurrentUserContext } from 'contexts/currentUser';
import { Level, useSnackNotificationContext } from 'contexts/snackNotification';
import { useMessagingContext, useWalletContext } from 'contexts/wallet';
import { useEvents } from 'lib/events/useEvents';

import ConnectionContextProvider, {
  AcceptTermsInfo,
  Mode,
  PopMode,
  Prompt2faCallback,
  PromptTermsCallback,
  PromptTermsOptions,
  isConnectionMode,
} from '.';
import ConnectionDialog, { isConnectionDialogMode } from './ConnectionDialog';
import NewDeviceDialog from './NewDeviceDialog';
import PasswordForgottenDialog from './PasswordForgottenDialog';
import PromptTermsDialog from './PromptTermsDialog';
import PromptTwoFactAuthDialog from './PromptTwoFactAuthDialog';
import ResetPasswordDialog from './ResetPasswordDialog';

interface Props {
  children: ReactNode;
}

const INITIAL_TERMS_STATUS = TermsAndConditionsStatus.INITIAL.toLowerCase();

const ConnectionProvider = ({ children }: Props) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const action = searchParams.get('action');
  const code = searchParams.get('code');
  const id = searchParams.get('id') || undefined;
  const tcuToken = searchParams.get('tcuToken');
  const termStatus = searchParams.get('termsStatus');
  const challengeFromQuery = searchParams.get('otp_session_challenge');
  const [mode, setModeState] = useState<Mode | null>(null);
  const [defaultSport, setDefaultSport] = useState<Sport>();
  const [popMode, setPopMode] = useState<PopMode | null>(null);
  const [isMobileWebviewSignUp, setIsMobileWebviewSignUp] =
    useState<boolean>(false);
  const [otpSessionChallenge, setOtpSessionChallenge] =
    useState(challengeFromQuery);
  const { showNotification } = useSnackNotificationContext();

  const { currentUser } = useCurrentUserContext();
  const [passwordForgotten, setPasswordForgotten] = useState(false);
  const [prompt2faCallback, setPrompt2faCallback] =
    useState<Prompt2faCallback | null>(null);
  const [promptTermsCallback, setPromptTermsCallback] =
    useState<PromptTermsCallback | null>(null);
  const [reasonFor2Fa, setReasonFor2FA] = useState<string | undefined>();
  const [promptTermsOptions, setPromptTermsOptions] =
    useState<PromptTermsOptions>({});
  const [acceptedTerms, setAcceptedTerms] = useState<AcceptTermsInfo | null>(
    null
  );
  const { passwordForgotten: promptPasswordForgotten } = useWalletContext();
  const recaptchaRef = useRef<GoogleReCAPTCHA>(null);
  // store the initial value of mustAcceptTcus to
  // avoid the redirection to `TERMS` once accepted
  const mustAcceptTCU = useRef<boolean>(!!currentUser?.mustAcceptTcus);
  const initialTCUAcceptance = useRef<boolean>(
    termStatus === INITIAL_TERMS_STATUS
  );
  const shouldRedirectToTerms = mode === 'showTerms' && !mustAcceptTCU.current;

  const { registerHandler } = useMessagingContext();
  const track = useEvents();

  const setMode = useCallback(
    (newMode: Mode | null) => {
      if (newMode && action) {
        searchParams.set('action', newMode);
      } else {
        searchParams.delete('action');
      }
      setModeState(newMode);
      setSearchParams(searchParams, { state: location.state });
    },
    /** We should not change location.state to location here or it will need to infinite loops whenever we use setMode in a dependency array */
    [searchParams, action, setSearchParams, location.state]
  );
  const signIn = useCallback(() => {
    track('Click Sign In');
    setAcceptedTerms(null);
    return setMode('signin');
  }, [track, setMode]);

  const signUp = useCallback(
    (e?: SyntheticEvent, sport?: Sport) => {
      setDefaultSport(sport);
      track('Click Sign Up', {
        variation: '',
      });
      setAcceptedTerms(null);
      return setMode('signup');
    },
    [track, setMode]
  );

  const showMobileWebviewSignUpFlow = useCallback(() => {
    setAcceptedTerms(null);
    setIsMobileWebviewSignUp(true);
    setMode('signup');
  }, [setMode]);
  const showMobilePasswordForgotten = useCallback(() => {
    setAcceptedTerms(null);
    setIsMobileWebviewSignUp(true);
    setMode('forgotPassword');
  }, [setMode]);
  const toggleResetPassword = useCallback(() => {
    setMode('signin');
  }, [setMode]);

  const handleClose = useCallback(() => setMode(null), [setMode]);
  const handleTermsClose = useCallback(() => setPopMode(null), [setPopMode]);

  const closeConnectionDialog = useCallback(() => {
    setMode(null);
    setPopMode(null);
  }, [setMode]);

  const prompt2fa = useCallback(
    (cb: Prompt2faCallback, challenge: string, reason?: string) => {
      setMode('2fa');
      setPrompt2faCallback(cb);
      setOtpSessionChallenge(challenge);
      setReasonFor2FA(reason);
    },
    [setOtpSessionChallenge, setMode]
  );

  const promptTerms = useCallback(
    (options: PromptTermsOptions, cb?: PromptTermsCallback) => {
      setPopMode('mustAcceptTerms');
      setPromptTermsOptions(options);
      if (cb) setPromptTermsCallback(cb);
    },
    []
  );

  const promptNewDeviceConfirmation = useCallback(
    () => setMode('newDevice'),
    [setMode]
  );

  useEffect(() => {
    if (passwordForgotten) promptPasswordForgotten();
    registerHandler<OnBackForgotPassword>('onBackForgotPassword', async () => {
      setPasswordForgotten(false);
      return {};
    });
  }, [passwordForgotten, promptPasswordForgotten, registerHandler]);

  useEffect(() => {
    if (mode === 'signup') {
      if (code === OAUTH_MISSING_EMAIL_ERROR_CODE) {
        showNotification('oauthMissingEmail', {}, { level: Level.ERROR });
      } else if (code === OAUTH_LOCKED_ACCOUNT_ERROR_CODE) {
        showNotification('oauthLockedAccount', {}, { level: Level.ERROR });
      }
    }
  }, [code, mode, showNotification]);

  /** necessary useEffect because setSearchParams cannot be called in the render function */
  useEffect(() => {
    /** Match mode with the action query param only if there is already an action param*/
    if (mode !== action && action && isConnectionMode(action)) {
      setMode(action);
    }
    /** Reset mode once the navigation to the terms page removed the action */
    if (shouldRedirectToTerms && !action) {
      setMode(null);
    }
  });

  const value = useMemo(
    () => ({
      signIn,
      signUp,
      showMobileWebviewSignUpFlow,
      showMobilePasswordForgotten,
      setPasswordForgotten,
      toggleResetPassword,
      prompt2fa,
      promptTerms,
      passwordForgotten,
      promptNewDeviceConfirmation,
      acceptedTerms,
      closeConnectionDialog,
      recaptchaRef,
    }),
    [
      signIn,
      signUp,
      showMobileWebviewSignUpFlow,
      showMobilePasswordForgotten,
      setPasswordForgotten,
      toggleResetPassword,
      prompt2fa,
      promptTerms,
      passwordForgotten,
      promptNewDeviceConfirmation,
      acceptedTerms,
      closeConnectionDialog,
    ]
  );

  if (mode === 'forgotPassword' && !passwordForgotten && !currentUser) {
    setPasswordForgotten(true);
  }

  if (
    mode &&
    [
      'recoverKey',
      'restoreWallet',
      'addFundsEth',
      'addFunds',
      'kyc',
      'verifyPhone',
    ].includes(mode) &&
    !currentUser
  ) {
    const afterLoggedInTarget = `?${stringify({ action: mode, id })}`;
    return (
      <Navigate
        to="?action=signin"
        state={{
          afterLoggedInTarget,
        }}
        replace
      />
    );
  }

  if (shouldRedirectToTerms) {
    return <Navigate to={TERMS_AND_CONDITIONS} replace />;
  }

  return (
    <ConnectionContextProvider value={value}>
      {isConnectionDialogMode(mode) && !passwordForgotten && !currentUser && (
        <ConnectionDialog
          open
          isMobileWebviewSignUp={isMobileWebviewSignUp}
          mode={mode}
          popMode={popMode}
          setMode={setMode}
          onClose={handleClose}
          defaultSport={defaultSport}
        />
      )}
      {passwordForgotten && (
        <PasswordForgottenDialog
          open
          onBack={() => setPasswordForgotten(false)}
        />
      )}
      {mode === 'resetPassword' && (
        <ResetPasswordDialog open onClose={handleClose} />
      )}
      {mode === 'newDevice' && <NewDeviceDialog onClose={handleClose} />}
      {mode === '2fa' && otpSessionChallenge && (
        <PromptTwoFactAuthDialog
          open
          otpSessionChallenge={otpSessionChallenge}
          onSignedIn={prompt2faCallback}
          onClose={handleClose}
          reason={reasonFor2Fa}
        />
      )}
      {/* OAUTH GRACE PERIOD */}
      {mode === 'showTerms' && mustAcceptTCU.current && (
        <PromptTermsDialog
          open
          onClose={handleClose}
          options={{
            closable: true,
            initialTermsDisplay: initialTCUAcceptance.current,
          }}
          onAccept={null}
          isMobileWebviewSignUp={isMobileWebviewSignUp}
        />
      )}

      {/* OAUTH AFTER GRACE PERIOD */}
      {mode === 'acceptTerms' && tcuToken && (
        <PromptTermsDialog
          open
          onClose={handleClose}
          options={{
            closable: false,
            tcuToken,
            initialTermsDisplay: initialTCUAcceptance.current,
          }}
          onAccept={promptTermsCallback}
          isMobileWebviewSignUp={isMobileWebviewSignUp}
        />
      )}
      {/* ALL THE REST  */}
      {popMode === 'mustAcceptTerms' && (
        <PromptTermsDialog
          open
          onAccept={promptTermsCallback}
          onClose={handleTermsClose}
          options={promptTermsOptions}
          isMobileWebviewSignUp={isMobileWebviewSignUp}
        />
      )}
      {(mode === 'signup' || mode === 'signin') && (
        <ReCAPTCHA ref={recaptchaRef} />
      )}
      {children}
    </ConnectionContextProvider>
  );
};

export default ConnectionProvider;
