import { type TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import styled, { CSSProperties } from 'styled-components';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelS } from '@sorare/core/src/atoms/typography';
import { getAnyRewardConfigRarity } from '@sorare/core/src/lib/rewards';
import { unitMapping } from '@sorare/core/src/lib/style';
import { borderGradientStyle } from '@sorare/core/src/style/utils';

import { Variant } from '../RewardOverview/types';
import { AnyRewardConfigImage } from './AnyRewardConfigImage';
import { AnyRewardConfigText } from './AnyRewardConfigText';
import type { AnyRewardConfig_anyRewardConfigInterface } from './__generated__/index.graphql';

const DEFAULT_COLOR = '173, 173, 173';

const rgbColorPerRarity = {
  [Rarity.limited]: '255, 184, 0',
  [Rarity.rare]: '191, 3, 14',
  [Rarity.super_rare]: '3, 42, 185',
  [Rarity.unique]: '80, 41, 107',
};

const Root = styled.div`
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  position: relative;

  &.classic {
    --text-height: var(--lh-14);
  }

  &.imageOnly {
    --text-height: 0;
  }

  &.classic,
  &.imageOnly {
    display: flex;
    flex-direction: column;
    align-items: center;
    aspect-ratio: unset;
    width: unset;
    height: calc(var(--size) + var(--text-height) / 2);
  }
`;
const Content = styled(Horizontal)`
  width: 100%;
  height: 100%;
  border-radius: var(--unit);

  --border-size: 1px;
  --border-gradient: linear-gradient(
    rgb(var(--color)),
    rgba(var(--color), 0.2)
  );
  ${borderGradientStyle}

  background:
    radial-gradient(
        circle at 50%,
        rgba(var(--color), 0.3),
        rgba(var(--color), 0) 60%
      ), var(--c-nl-400);

  .classic &,
  .imageOnly & {
    width: var(--size);
    height: var(--size);
  }
`;
const ImageContainer = styled(Horizontal).attrs({ center: true })`
  height: 70%;
  aspect-ratio: 1/1;
  margin: auto;
`;
const Chip = styled(Horizontal).attrs({ gap: 1 })`
  width: max-content;
  height: var(--quadruple-unit);
  gap: var(--unit);
  padding: 0 var(--unit);

  border-radius: var(--unit);
  border: 0.5px solid transparent;
  background:
    radial-gradient(
        circle var(--double-unit) at var(--double-unit) 50%,
        rgba(var(--color), 0.3),
        rgba(var(--color), 0)
      )
      padding-box,
    linear-gradient(#262626, #262626) padding-box,
    linear-gradient(rgb(var(--color)), rgba(var(--color), 0.2)) border-box;
  background-repeat: no-repeat;
`;
const TextContainer = styled.div`
  position: relative;
  margin-top: calc(-1 * var(--text-height) / 2);
`;

type Props = {
  anyRewardConfig: AnyRewardConfig_anyRewardConfigInterface;
  rarity?: Rarity;
  variant: Variant;
  size?: keyof typeof unitMapping;
};

export const AnyRewardConfig = ({
  anyRewardConfig,
  rarity: rarityProp,
  variant,
  size = 4,
}: Props) => {
  const rarity = getAnyRewardConfigRarity(anyRewardConfig) || rarityProp;

  const color =
    rarity && rarity in rgbColorPerRarity
      ? rgbColorPerRarity[rarity as keyof typeof rgbColorPerRarity]
      : DEFAULT_COLOR;

  if (variant === Variant.INLINE) {
    return (
      <Chip style={{ '--color': color } as CSSProperties}>
        <ImageContainer>
          <AnyRewardConfigImage
            anyRewardConfig={anyRewardConfig}
            rarity={rarity}
          />
        </ImageContainer>
        <LabelS as="p" bold>
          <AnyRewardConfigText
            variant={variant}
            anyRewardConfig={anyRewardConfig}
          />
        </LabelS>
      </Chip>
    );
  }

  return (
    <Root
      className={classNames(variant)}
      style={
        {
          '--color': color,
          '--size': unitMapping[size],
        } as CSSProperties
      }
    >
      <Content>
        <ImageContainer>
          <AnyRewardConfigImage
            anyRewardConfig={anyRewardConfig}
            rarity={rarity}
          />
        </ImageContainer>
      </Content>
      {variant !== Variant.IMAGE_ONLY && (
        <TextContainer>
          <LabelS as="p" bold className="text-center">
            <AnyRewardConfigText
              variant={variant}
              anyRewardConfig={anyRewardConfig}
            />
          </LabelS>
        </TextContainer>
      )}
    </Root>
  );
};

AnyRewardConfig.fragments = {
  anyRewardConfigInterface: gql`
    fragment AnyRewardConfig_anyRewardConfigInterface on AnyRewardConfigInterface {
      id
      ...getAnyRewardConfigRarity_anyRewardConfigInterface
      ...AnyRewardConfigImage_anyRewardConfigInterface
      ...AnyRewardConfigText_anyRewardConfigInterface
    }
    ${getAnyRewardConfigRarity.fragments.anyRewardConfigInterface}
    ${AnyRewardConfigImage.fragments.anyRewardConfigInterface}
    ${AnyRewardConfigText.fragments.anyRewardConfigInterface}
  ` as TypedDocumentNode<AnyRewardConfig_anyRewardConfigInterface>,
};
