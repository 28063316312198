import classNames from 'classnames';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { CustomRewardExperience } from '__generated__/globalTypes';
import jerseys from 'assets/icons/jerseys.png';
import experiences from 'assets/rewards/experiences.png';
import merch from 'assets/rewards/merch.png';
import tickets from 'assets/rewards/tickets.png';
import { ExperienceType } from 'lib/rewards';

import { messages } from './messages';

const Img = styled.img`
  height: 28px;
  &.sm {
    height: 20px;
  }
`;

type Props = {
  type: ExperienceType | null;
  pictureUrl?: string | null;
  sm?: boolean;
  className?: string;
};

const ICON_URLS: { [key in ExperienceType]?: string } = {
  [CustomRewardExperience.JERSEY]: jerseys,
  [CustomRewardExperience.TICKET]: tickets,
  [CustomRewardExperience.EVENT]: experiences,
  [CustomRewardExperience.MERCH]: merch,
};

export const ExperienceIcon = ({ type, pictureUrl, sm, className }: Props) => {
  const { formatMessage } = useIntl();

  const imgUrl =
    ICON_URLS[type || CustomRewardExperience.EVENT] ||
    ICON_URLS[CustomRewardExperience.EVENT];

  return (
    <Img
      className={classNames({ sm }, className)}
      src={pictureUrl || imgUrl}
      alt={type ? formatMessage(messages[type]) : ''}
    />
  );
};
