import { unitMapping } from 'lib/style';

type Props = {
  size?: keyof typeof unitMapping;
  className?: string;
  hollow?: boolean;
};

export const Glove = ({ size = 2, className, hollow }: Props) => {
  return (
    <svg
      className={className}
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 17"
      fill="none"
    >
      <rect
        x="3.20117"
        y="14.3999"
        width="8.66667"
        height="2"
        fill={hollow ? 'url(#glove-hollow-gradient)' : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5375 0H8.67083V0.8H8.67148V5.86685H9.87148V0.8H11.7375V5.26652L11.7371 5.26672V8.26672L12.2704 7.86672V6.70395L13.6021 4.2668L16.0021 5.4668L12.9354 11.3335L11.7365 11.649V12.3999H3.20312V9.3332H3.20417V3.53333L3.87083 0.8H6.5375V0ZM5.33711 0.800049H6.53711V5.86672H5.33711V0.800049Z"
        fill={hollow ? 'url(#glove-hollow-gradient)' : 'currentColor'}
      />
      <defs>
        <linearGradient
          id="glove-hollow-gradient"
          x1="0.5"
          y1="7.75"
          x2="9.22771"
          y2="6.81853"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8D3DA" />
          <stop offset="0.283178" stopColor="#B3A9F4" />
          <stop offset="0.5401" stopColor="#FBE9FB" />
          <stop offset="1" stopColor="#4F94FD" />
        </linearGradient>
      </defs>
    </svg>
  );
};
