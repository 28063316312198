import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import styled, { CSSProperties } from 'styled-components';

import { PlayerFieldStatus } from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Score } from '@sorare/core/src/components/scoring/Score';
import { StatIcon } from '@sorare/core/src/components/stats/StatIcon';
import { PLAYER_PLACEHOLDER } from '@sorare/core/src/constants/assets';
import { isType } from '@sorare/core/src/gql';
import { range } from '@sorare/core/src/lib/arrays';
import {
  negativeDecisiveStatKeys,
  positiveDecisiveStatKeys,
} from '@sorare/core/src/lib/scoring';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { SubIcon } from 'components/stats/SubIcon';
import { getDecisiveActions } from 'lib/so5';

import { Player_anyPlayer } from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 0, center: true })`
  position: relative;
`;
const PlayerImage = styled.div`
  --border-color: var(--c-nd-800-opaque);

  position: relative;
  width: var(--quadruple-unit);
  height: var(--quadruple-unit);
  border-radius: 100%;
  border: 3px solid var(--border-color);
  background-position: center center;
  background-size: contain;
  background-color: var(--c-white);
  background-repeat: no-repeat;
  @media ${tabletAndAbove} {
    width: 48px;
    height: 48px;
  }
`;
const PlayerScoreWrapper = styled.div`
  z-index: 1;
  margin: calc(-1 * var(--unit)) auto var(--half-unit) auto;
`;
const PlayerName = styled.div`
  background: rgba(var(--c-rgb-black), 0.3);
  border-radius: var(--unit);
  color: var(--c-white);
  padding: var(--half-unit) var(--unit);
  text-align: center;
  font-size: 12px;

  &.highlighted {
    background: var(--c-rivals-win);
    color: var(--c-black);
  }
`;
const BaseIconWrapper = styled(Vertical).attrs({ gap: 0, center: true })``;
const SubWrapper = styled(Horizontal)`
  position: absolute;
  gap: var(--half-unit);
  font-weight: var(--t-bold);
  right: 80%;
  top: -5px;
  font-size: 10px;
  color: var(--c-white);
`;
const DecisiveAction = styled(BaseIconWrapper)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
const NegativeDecisiveActions = styled(DecisiveAction)`
  right: calc(100% - var(--half-unit));
`;
const PositiveDecisiveActions = styled(DecisiveAction)`
  left: calc(100% - var(--half-unit));
`;
const StyledStatIcon = styled(StatIcon)`
  &:not(:first-child) {
    margin-top: -6px;
  }
`;
const MinPlayedWrapper = styled.div`
  opacity: 0.6;
`;
const CardWrapper = styled(BaseIconWrapper)`
  position: absolute;
  right: calc(100% - var(--half-unit));
  top: calc(50% - var(--unit));
  transform: rotateZ(4deg);

  > *:not(:last-child) {
    margin-right: -4px;
  }
`;

type Props = {
  player: Player_anyPlayer;
  highlighted?: boolean;
  startedBench?: boolean;
};
const Player = ({ player, highlighted, startedBench }: Props) => {
  const { playerGameScore } = player;

  if (!playerGameScore || !isType(playerGameScore, 'PlayerGameScore')) {
    return null;
  }

  const { anyPlayerGameStats } = playerGameScore;

  const decisiveActions = getDecisiveActions(playerGameScore);

  const playerMinutesPlayed =
    (isType(anyPlayerGameStats, 'PlayerGameStats') &&
      anyPlayerGameStats.minsPlayed) ||
    0;
  const substituted =
    isType(anyPlayerGameStats, 'PlayerGameStats') &&
    anyPlayerGameStats.fieldStatus === PlayerFieldStatus.SUBSTITUTED;
  const substitutee =
    isType(anyPlayerGameStats, 'PlayerGameStats') &&
    anyPlayerGameStats.fieldStatus === PlayerFieldStatus.ON_FIELD;

  return (
    <Root>
      <PlayerImage
        style={
          {
            '--border-color': highlighted ? 'var(--c-rivals-win)' : undefined,
            backgroundImage: `url(${
              player.avatarPictureUrl || PLAYER_PLACEHOLDER
            })`,
          } as CSSProperties
        }
      >
        {startedBench && substitutee && (
          <SubWrapper>
            <MinPlayedWrapper>{playerMinutesPlayed}&apos;</MinPlayedWrapper>
            <SubIcon type="sub_in" />
          </SubWrapper>
        )}
        {substituted && (
          <SubWrapper>
            <MinPlayedWrapper>{playerMinutesPlayed}&apos;</MinPlayedWrapper>
            <SubIcon type="sub_out" />
          </SubWrapper>
        )}
        {decisiveActions.yellow_card > 0 && (
          <CardWrapper>
            <StatIcon statKey="yellow_card" />
          </CardWrapper>
        )}
        {decisiveActions.red_card > 0 && (
          <CardWrapper>
            <StatIcon statKey="red_card" />
          </CardWrapper>
        )}
        <NegativeDecisiveActions>
          {negativeDecisiveStatKeys.map(statKey =>
            range(decisiveActions[statKey] || 0).map(i => (
              <StyledStatIcon key={`${statKey}-${i}`} statKey={statKey} />
            ))
          )}
        </NegativeDecisiveActions>
        <PositiveDecisiveActions>
          {positiveDecisiveStatKeys.map(statKey =>
            range(decisiveActions[statKey] || 0).map(i => (
              <StyledStatIcon key={`${statKey}-${i}`} statKey={statKey} />
            ))
          )}
        </PositiveDecisiveActions>
      </PlayerImage>
      <PlayerScoreWrapper>
        <Score
          hideReviewing
          shape="HEXAGON"
          score={playerGameScore.score || 0}
          scoreStatus={playerGameScore.scoreStatus}
          scoreType="PLAYER_SCORE"
        />
      </PlayerScoreWrapper>
      <PlayerName className={classNames({ highlighted })}>
        <strong>{player.matchName}</strong>
      </PlayerName>
    </Root>
  );
};

Player.fragments = {
  anyPlayer: gql`
    fragment Player_anyPlayer on AnyPlayerInterface {
      slug
      matchName
      avatarPictureUrl
      playerGameScore(gameId: $id) {
        id
        score
        scoreStatus
        anyPlayerGameStats {
          id
          ... on PlayerGameStats {
            id
            minsPlayed
            fieldStatus
          }
        }
        ...getDecisiveActions_playerGameScoreInterface
      }
    }
    ${getDecisiveActions.fragments.playerGameScoreInterface}
  ` as TypedDocumentNode<Player_anyPlayer>,
};

export default Player;
