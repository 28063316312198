import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';

import { Rarity } from '__generated__/globalTypes';
import { ConditionalWrapper } from 'atoms/layout/ConditionalWrapper';
import { ValidWidths, proxyUrl } from 'atoms/ui/ResponsiveImg';
import { Video } from 'atoms/ui/Video';
import { CardImg, CardImgLoadingWrapper } from 'components/card/CardImg';
import { CardMeta } from 'components/card/CardMeta';
import { isType } from 'gql';
import { useIsVisibleInViewport } from 'hooks/ui/useIsVisibleInViewport';
import {
  CARD_BORDER_RADIUS_2024,
  getAnimatedCardVideoSources,
} from 'lib/cards';

import LoanWrapper from '../Loan';
import {
  Card_anyCard,
  Card_composeTeamBenchObjectInterface,
  Card_composeTeamBenchObjectInterface_ComposeTeamBenchCommonPlayer_,
  Card_so5Appearance,
} from './__generated__/index.graphql';

const RoundedVideo = styled(Video)`
  border-radius: ${CARD_BORDER_RADIUS_2024};
  overflow: clip;
  aspect-ratio: var(--card-aspect-ratio);
`;

interface ClickHandlerProps {
  onClick?: () => void;
}

interface Loan {
  gwLeft: number;
}
interface IProps {
  card: (
    | Card_anyCard
    | Card_so5Appearance
    | (Card_composeTeamBenchObjectInterface & {
        __typename: 'ComposeTeamBenchCommonPlayer';
      })
  ) & {
    rarityTyped?: Rarity;
    loan?: Loan;
  };
  width?: ValidWidths;
}

const castCommonPlayerToVideoSourcesArgs = (
  card: Card_composeTeamBenchObjectInterface_ComposeTeamBenchCommonPlayer_
) => ({
  __typename: 'Card' as const,
  slug: card.anyPlayer.slug,
  webmVideoUrl: null,
  webmLowResVideoUrl: null,
  movVideoUrl: null,
  movLowResVideoUrl: null,
});

export const Card = (props: IProps & ClickHandlerProps) => {
  const [loaded, setLoaded] = useState(false);
  const { card, width = 320, onClick } = props;
  const { pictureUrl, anyPlayer, rarityTyped } = card;

  const videoSourceArg = isType(card, 'ComposeTeamBenchCommonPlayer')
    ? castCommonPlayerToVideoSourcesArgs(card)
    : card;
  const videoSources = getAnimatedCardVideoSources(videoSourceArg, width);

  const { handleRef: videoRef, isVisible: videoIsVisible } =
    useIsVisibleInViewport<HTMLDivElement>();
  const withMeta = !(
    isType(card, 'So5Appearance') || isType(card, 'MissedAppearance')
  );

  return (
    <ConditionalWrapper
      wrap={!!card.loan}
      Wrapper={LoanWrapper}
      props={{
        loan: card.loan!,
      }}
    >
      <CardImgLoadingWrapper loaded={loaded}>
        {!loaded && withMeta && <CardMeta card={card} />}
        <div ref={videoRef}>
          {videoSources.length > 0 && videoIsVisible && (
            <RoundedVideo
              sources={videoSources}
              autoPlay
              loop
              muted
              onLoadedData={() => setLoaded(true)}
              poster={
                pictureUrl
                  ? proxyUrl(pictureUrl, { cropWidth: width })
                  : undefined
              }
              playsInline
              hideMutedIcon
            />
          )}
        </div>
        {videoSources.length === 0 && (
          <CardImg
            loading="lazy"
            src={pictureUrl || undefined}
            alt={`${anyPlayer.displayName}${rarityTyped ? ` - ${rarityTyped}` : ''}`}
            draggable={false}
            onLoad={() => {
              setLoaded(true);
            }}
            onClick={onClick}
            width={width}
          />
        )}
      </CardImgLoadingWrapper>
    </ConditionalWrapper>
  );
};
Card.displayName = 'Card';

Card.fragments = {
  anyCard: gql`
    fragment Card_anyCard on AnyCardInterface {
      slug
      pictureUrl
      rarityTyped
      anyPlayer {
        slug
        displayName
      }
      ...CardMeta_AnyCardInterface
      ...getAnimatedCardVideoSources_anyCardInterface
    }
    ${CardMeta.fragments.AnyCardInterface}
    ${getAnimatedCardVideoSources.fragments.anyCardInterface}
  ` as TypedDocumentNode<Card_anyCard>,
  so5Appearance: gql`
    fragment Card_so5Appearance on So5AppearanceInterface {
      id
      pictureUrl
      rarityTyped: rarity
      anyPlayer {
        slug
        displayName
      }
      ...getAnimatedCardVideoSources_so5Appearance
    }
    ${getAnimatedCardVideoSources.fragments.so5Appearance}
  ` as TypedDocumentNode<Card_so5Appearance>,
  composeTeamBenchObjectInterface: gql`
    fragment Card_composeTeamBenchObjectInterface on ComposeTeamBenchObjectInterface {
      id
      pictureUrl
      anyPlayer {
        slug
        displayName
        ...CardMeta_anyPlayerInterface
      }
    }
    ${CardMeta.fragments.anyPlayerInterface}
  ` as TypedDocumentNode<Card_composeTeamBenchObjectInterface>,
};
