import { type TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { RankingRatioRange } from '@sorare/core/src/__generated__/globalTypes';
import { isType } from '@sorare/core/src/gql';
import { glossary } from '@sorare/core/src/lib/glossary';
import { Color } from '@sorare/core/src/style/types';

import type {
  DomesticLeagueRanking_anyGameInterface,
  DomesticLeagueRanking_teamInterface,
} from './__generated__/index.graphql';

const DEFAULT_COLOR: Color = 'var(--c-nd-600)';

const getTeamRankingColor = (
  team: DomesticLeagueRanking_teamInterface
): Color => {
  let color: Color = DEFAULT_COLOR;
  if (!isType(team, 'Club')) {
    return color;
  }

  const contestantRangeRatio = team.domesticLeagueRankingRatioRange;
  if (contestantRangeRatio === RankingRatioRange.TOP) {
    color = 'var(--c-red-600)';
  } else if (contestantRangeRatio === RankingRatioRange.MEDIUM) {
    color = 'var(--c-score-mediumLow)';
  } else if (contestantRangeRatio === RankingRatioRange.LOW) {
    color = 'var(--c-score-high)';
  }

  return color;
};

const isGameFromDomesticCompetition = (
  game: DomesticLeagueRanking_anyGameInterface,
  team: DomesticLeagueRanking_teamInterface
): boolean =>
  isType(team, 'Club') && game.competition.slug === team.domesticLeague?.slug;

type Props = {
  game: DomesticLeagueRanking_anyGameInterface;
  team: DomesticLeagueRanking_teamInterface;
  colored?: boolean;
};

export const DomesticLeagueRanking = ({ game, team, colored }: Props) => {
  if (!isType(team, 'Club') || !isGameFromDomesticCompetition(game, team)) {
    return null;
  }

  return (
    <span
      style={{ color: colored ? getTeamRankingColor(team) : DEFAULT_COLOR }}
    >
      <FormattedMessage
        {...glossary.ordinal}
        values={{ ordinal: team.domesticLeagueRanking }}
      />
    </span>
  );
};

DomesticLeagueRanking.fragments = {
  anyGameInterface: gql`
    fragment DomesticLeagueRanking_anyGameInterface on AnyGameInterface {
      id
      competition {
        slug
      }
    }
  ` as TypedDocumentNode<DomesticLeagueRanking_anyGameInterface>,
  teamInterface: gql`
    fragment DomesticLeagueRanking_teamInterface on TeamInterface {
      slug
      ... on Club {
        slug
        domesticLeague {
          slug
        }
        domesticLeagueRanking
        domesticLeagueRankingRatioRange
      }
    }
  ` as TypedDocumentNode<DomesticLeagueRanking_teamInterface>,
};
