import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import {
  BodyM,
  HeadlineL,
  HeadlineXS,
} from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';

import { BoxOdds } from '../BoxOdds';
import {
  BoxOverviewDialog_probabilisticBundleConfig,
  BoxOverviewDialog_so5League,
} from './__generated__/index.graphql';

const Wrapper = styled(Vertical)`
  padding: var(--double-unit);
`;
const Header = styled(Vertical)`
  align-items: center;
  text-align: center;
`;

type Props = {
  boxConfig: BoxOverviewDialog_probabilisticBundleConfig;
  so5League?: BoxOverviewDialog_so5League;
  onClose: () => void;
  open: boolean;
};

export const BoxOverviewDialog = ({
  boxConfig,
  so5League,
  open,
  onClose,
}: Props) => {
  const { name, description, pictureUrl } = boxConfig;

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <Wrapper gap={2}>
        <Header as="header" gap={2}>
          <img src={pictureUrl} alt="" width="120" />
          <HeadlineL as="h1">{name}</HeadlineL>
          <BodyM as="p" color="var(--c-nd-600)">
            {description}
          </BodyM>
        </Header>
        <HeadlineXS as="h2">
          <FormattedMessage id="BoxRewardDialog.odd" defaultMessage="Odds" />
        </HeadlineXS>
        <BoxOdds so5League={so5League} boxConfig={boxConfig} />
      </Wrapper>
    </Dialog>
  );
};

BoxOverviewDialog.fragments = {
  probabilisticBundleConfig: gql`
    fragment BoxOverviewDialog_probabilisticBundleConfig on ProbabilisticBundleConfig {
      id
      name
      description
      pictureUrl
      ...BoxOdds_probabilisticBundleConfig
    }
    ${BoxOdds.fragments.probabilisticBundleConfig}
  ` as TypedDocumentNode<BoxOverviewDialog_probabilisticBundleConfig>,
  so5League: gql`
    fragment BoxOverviewDialog_so5League on So5League {
      slug
      ...BoxOdds_so5League
    }
    ${BoxOdds.fragments.so5League}
  ` as TypedDocumentNode<BoxOverviewDialog_so5League>,
};
