import { Navigate, generatePath } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { So5FixtureEvent } from '@sorare/core/src/__generated__/globalTypes';
import {
  NBA_GAMES_GAMEID_EVENTTYPE,
  NBA_PLAY,
} from '@sorare/core/src/constants/__generated__/routes';

import { PageParams } from './$eventType/__generated__/routeParams';

const GamePage = () => {
  const { gameId, eventType } = useTypedParams<PageParams>();
  if (!gameId) {
    return <Navigate to={NBA_PLAY} replace />;
  }
  return (
    <Navigate
      to={generatePath(NBA_GAMES_GAMEID_EVENTTYPE, {
        gameId,
        eventType: eventType || So5FixtureEvent.CLASSIC,
      })}
      replace
    />
  );
};

export default GamePage;
