import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Bold } from '@sorare/core/src/atoms/typography/Bold';
import { EssenceRewardCount } from '@sorare/core/src/components/rewards/RewardCount';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { isType } from '@sorare/core/src/gql';
import { getName } from '@sorare/core/src/lib/cards';

import {
  Rewards_anyRewardInterface,
  Rewards_anyRewardInterface_CardShardsReward_,
} from './__generated__/index.graphql';

const Root = styled.span`
  margin-top: var(--half-unit);
  display: block;
`;
type Props = {
  rewards: Nullable<Rewards_anyRewardInterface[]>;
};

export const Rewards = ({ rewards }: Props) => {
  const { formatList } = useIntlContext();
  const cardRewards =
    rewards
      ?.map(r => isType(r, 'AnyCardReward') && getName(r.card))
      .filter(Boolean) || [];

  const rewardBoxRewards =
    rewards
      ?.map(
        r =>
          isType(r, 'ProbabilisticBundleReward') &&
          r.probabilisticBundle.config.name
      )
      .filter(Boolean) || [];

  const mergedEssenceRewards =
    rewards
      ?.filter(r => isType(r, 'CardShardsReward'))
      .filter(Boolean)
      .reduce<Rewards_anyRewardInterface_CardShardsReward_[]>((acc, r) => {
        const scarcityIndex = acc.findIndex(e => e.rarity === r.rarity);
        if (scarcityIndex === -1) {
          acc.push(r);
        } else {
          acc[scarcityIndex] = {
            ...acc[scarcityIndex],
            quantity: acc[scarcityIndex].quantity + r.quantity,
          };
        }
        return acc;
      }, [])
      .map(r => (
        <EssenceRewardCount
          key={`${r.rarity}-${r.quantity}`}
          quantity={r.quantity}
          rarity={r.rarity}
        />
      )) || [];

  const rewardsText = formatList([
    ...cardRewards,
    ...rewardBoxRewards,
    ...mergedEssenceRewards,
  ]);

  if (!rewardsText) return null;
  return (
    <Root>
      <FormattedMessage
        id="Activity.Notifications.rewards"
        defaultMessage="<b>Special Offer:</b> you also won {rewardsText}"
        values={{ rewardsText, b: Bold }}
      />
    </Root>
  );
};

Rewards.fragments = {
  anyReward: gql`
    fragment Rewards_anyRewardInterface on AnyRewardInterface {
      id
      ... on AnyCardReward {
        id
        card {
          slug
          ...getName_anyCard
        }
      }
      ... on CardShardsReward {
        id
        quantity
        rarity
      }
      ... on ProbabilisticBundleReward {
        id
        probabilisticBundle {
          id
          config {
            id
            name
          }
        }
      }
    }
    ${getName.fragments.anyCard}
  ` as TypedDocumentNode<Rewards_anyRewardInterface>,
};
