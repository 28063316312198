import { Navigate } from 'react-router-dom';

import { LANDING } from 'constants/__generated__/routes';
import useTruncatedLocation from 'hooks/useTruncatedLocation';

const useRedirectToLandingWithAction = (
  action: 'signup' | 'signin',
  to?: string
) => {
  const location = useTruncatedLocation();

  return function RedirectToLanding() {
    return (
      <Navigate
        replace
        to={{
          pathname: to || LANDING,
          search: `action=${action}${action === 'signin' ? `&redirectUrl=${location}` : ''}`,
        }}
      />
    );
  };
};

export const useRedirectToSignup = (to?: string) =>
  useRedirectToLandingWithAction('signup', to);
export const useRedirectToSignin = (to?: string) =>
  useRedirectToLandingWithAction('signin', to);
