import { TypedDocumentNode, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  CommonDraftCampaignType,
  Sport,
  SupportedCurrency,
} from '__generated__/globalTypes';
import { namedOperations } from '__generated__/operations';
import {
  FootballComposeFilters,
  NBAComposeFilters,
} from 'components/compose/types';
import {
  CreditCardPaymentMethod,
  PaymentMethod as DepositMethod,
} from 'components/wallet/BankEthAccounting/AddFundsToFiatWallet/types';
import { AlgoliaCardIndexesName, useConfigContext } from 'contexts/config';
import {
  PaymentMethod,
  PaymentProvider_paymentMethod,
} from 'lib/paymentMethod';
import { PlayerSearchView } from 'lib/playerSearchView';

import {
  CurrentUserLifecycleQuery,
  CurrentUserLifecycleQueryVariables,
  UpdateLifecycleMutation,
  UpdateLifecycleMutationVariables,
} from './__generated__/useLifecycle.graphql';

export enum LIFECYCLE {
  sawCaptainTuto = 'sawCaptainTuto',
  sawCollectionCardTuto = 'sawCollectionCardTuto',
  sawMarketplaceOnboarding = 'sawMarketplaceOnboarding',
  sawManagersSalesOnboarding = 'sawManagersSalesOnboarding',
  sawStarterPacksOnboarding = 'sawStarterPacksOnboarding',
  sawDraftTuto = 'sawDraftTuto',
  transferMarket = 'transferMarket',
  newSignings = 'newSignings',
  nextTimeUserNeedsCheckSecurity = 'nextTimeUserNeedsCheckSecurity',
  nextTimeUserShouldSeeActivateFiatWalletOnListing = 'nextTimeUserShouldSeeActivateFiatWalletOnListing',
  nextTimeUserShouldSeeAcceptedCurrenciesOnListing = 'nextTimeUserShouldSeeAcceptedCurrenciesOnListing',
  userHasSetupWallet = 'userHasSetupWallet',
  nextTimeUserShouldSetupWallet = 'nextTimeUserShouldSetupWallet',
  sawUsSportsCollectionsDialog = 'sawUsSportsCollectionsDialog',
  lastSaleDuration = 'lastSaleDuration',
  sawMlbDraftErrorPrompt = 'sawMlbDraftErrorPrompt',
  sawMlbComposeTeamOnboarding = 'sawMlbComposeTeamOnboarding',
  inSeasonFilterEnabled = 'inSeasonFilterEnabled',
  latestSearchedItems = 'latestSearchedItems',
  sawMlbOnboarding = 'sawMlbOnboarding',
  sawMlbDraftWelcome = 'sawMlbDraftWelcome',
  sawNbaOnboarding = 'sawNbaOnboarding',
  marketSorts = 'marketSorts',
  sawMlbDraftEducationDialog = 'sawMlbDraftEducationDialog',
  saw100PersonLeaderboardDialog = 'saw100PersonLeaderboardDialog',
  hideActivity = 'hideActivity',
  disable3D = 'disable3D',
  hideOnlineStatus = 'hideOnlineStatus',
  preferredPaymentMethod = 'preferredPaymentMethod',
  preferredDepositMethod = 'preferredDepositMethod',
  sawMinorsUnlockedDialog = 'sawMinorsUnlockedDialog',
  sawCommonLevel1UnlockedDialog = 'sawCommonLevel1UnlockedDialog',
  sawCommonLevel2UnlockedDialog = 'sawCommonLevel2UnlockedDialog',
  sawCommonLevel3UnlockedDialog = 'sawCommonLevel3UnlockedDialog',
  sawCommonLevel4UnlockedDialog = 'sawCommonLevel4UnlockedDialog',
  sawCommonLevel5UnlockedDialog = 'sawCommonLevel5UnlockedDialog',
  sawRewardUnlockedDialog = 'sawRewardsUnlockedDialog',
  referenceCurrencyForSale = 'referenceCurrencyForSale',
  sawMlbClubShopIntro = 'sawMlbClubShopIntro',
  hideReferralBanner = 'hideReferralBanner',
  sawKickoffWelcomeToKickoff = 'sawKickoffWelcomeToKickoff',
  showTrainingLineups = 'showTrainingLineups',
  showOnlyCompetitionsEntered = 'showOnlyCompetitionsEntered',
  sawChallengesOnboarding = 'sawChallengesOnboarding',
  sawTacticsOnboarding = 'sawTacticsOnboarding',
  doNotShowRivalsDownloadApp = 'doNotShowRivalsDownloadApp',
  doNotShowAddAuctionReminderDownloadApp = 'doNotShowAddAuctionReminderDownloadApp',
  sawForYouFirstTime = 'sawForYouFirstTime',
  unselectConversionCreditByDefaultFromSettings = 'unselectConversionCreditByDefaultFromSettings',
  sawWelcomeToSeasonsArena = 'sawWelcomeToSeasonsArena',
  sawSocialWallDialog = 'sawSocialWallDialog',
  hideConversionCreditBanner = 'hideConversionCreditBanner',
  sawEuropeanChampionshipTooltip = 'sawEuropeanChampionshipTooltip',
  sawRivalsLeagueOnboarding = 'sawRivalsLeagueOnboarding',
  playRivalsLeagueReminderLastDate = 'playRivalsLeagueReminderLastDate',
  sawRivalsLeagueMatchSlugs = 'sawRivalsLeagueMatchSlugs',
  sawRivalsPlayButtonOnboarding = 'sawRivalsPlayButtonOnboarding',
  sawBoxOnboarding = 'sawBoxOnboarding',
  sawRivalsLeagueCrossMode = 'sawRivalsLeagueCrossMode',
  sawRivalsPlayPageHighlight = 'sawRivalsPlayPageHighlight',
  sawRivalsLeagueEndingStory = 'sawRivalsLeagueEndingStory',
  sawEarlyAccessAnnouncementDialog = 'sawEarlyAccessAnnouncementDialog',
  sawRevealAnnouncementDialog = 'sawRevealAnnouncementDialog',
  sawStartedEarlyAccessAnnouncementDialog = 'sawStartedEarlyAccessAnnouncementDialog',
  sawChallengerEarlyAccessAnnouncementDialog = 'sawChallengerEarlyAccessAnnouncementDialog',
  sawChampionEarlyAccessAnnouncementDialog = 'sawChampionEarlyAccessAnnouncementDialog',
  sawSubstitutesFTUE = 'sawSubstitutesFTUE',
  sawChaseHelpDialog = 'sawChaseHelpDialog',
  sawComposeTeamOnboarding = 'sawComposeTeamOnboarding',
  sawComposeTeamHighlights = 'sawComposeTeamHighlights',
  sawMySquadOnboarding = 'sawMySquadOnboarding',
  sawWarmUpAnnouncement = 'sawWarmUpAnnouncement',
  sawNBAS3LobbyOnboarding = 'sawNBAS3LobbyOnboarding',
  sawPushNotificationsPromptInWebview = 'sawNotificationsPromptInWebview',
  sawGalleryOnboarding = 'sawGalleryOnboarding',
  sawCommonMarketOnboarding = 'sawCommonMarketOnboarding',
  unlockedDialogSeenSo5LeagueNames = 'unlockedDialogSeenSo5LeagueNames',
  sawMVPOnboarding = 'sawMVPOnboarding',
  sawMyCardsOnboarding = 'sawMyCardsOnboarding',
  sawPlayProFixturePageOnboarding = 'sawPlayProFixturePageOnboarding',
  sawDailyHoopsOnboarding = 'sawDailyHoopsOnboarding',
  sawNBAProUnlockScreen = 'sawNBAProUnlockScreen',
  sawNBADailyHoopsUnlockScreen = 'sawNBADailyHoopsUnlockScreen',
  sawDailyClaimButton = 'sawDailyClaimButton',
  sawNeonChristmasDialog = 'sawNeonChristmasDialog',
  sawNeonChristmasStartedDialog = 'sawNeonChristmasStartedDialog',
  DEPRECATEDneonChristmasCalendar = 'neonChristmasCalendar',
  neonChristmasCalendarProgress = 'neonChristmasCalendarProgress',
  lastVisitedMyCardsTab = 'lastVisitedMyCardsTab',
  sawComposeTeamTutorial = 'sawComposeTeamTutorial',
  statsManager = 'statsManager',
  sawFirstTimeStatsManager = 'sawFirstTimeStatsManager',
  hasDismissedExpiredPromoDepositBanner = 'hasDismissedExpiredPromoDepositBanner',
  composeLastViewUsed = 'composeLastViewUsed',
  composeFilters = 'composeFilters',
  sawTiersOnboarding = 'sawTiersOnboarding',
  sawNBATutorialVideos = 'sawNBATutorialVideos',
  sawNBAIntroductionTutorialVideo = 'sawNBAIntroductionTutorialVideo',
  sawNBATypesOfCardsTutorialVideo = 'sawNBATypesOfCardsTutorialVideo',
  sawNBAMarketplaceTutorialVideo = 'sawNBAMarketplaceTutorialVideo',
  sawNBACompetitionsTutorialVideo = 'sawNBACompetitionsTutorialVideo',
}

export type LifecycleEntries = {
  [LIFECYCLE.sawCaptainTuto]?: boolean;
  [LIFECYCLE.sawCollectionCardTuto]?: boolean;
  [LIFECYCLE.sawMarketplaceOnboarding]?: boolean;
  [LIFECYCLE.sawManagersSalesOnboarding]?: boolean;
  [LIFECYCLE.sawStarterPacksOnboarding]?: boolean;
  [LIFECYCLE.sawDraftTuto]?: CommonDraftCampaignType;
  [LIFECYCLE.sawChaseHelpDialog]?: boolean;
  [LIFECYCLE.transferMarket]?: string;
  [LIFECYCLE.newSignings]?: string;
  [LIFECYCLE.nextTimeUserNeedsCheckSecurity]?: string;
  [LIFECYCLE.nextTimeUserShouldSeeActivateFiatWalletOnListing]?: string;
  [LIFECYCLE.nextTimeUserShouldSeeAcceptedCurrenciesOnListing]?: string;
  [LIFECYCLE.userHasSetupWallet]?: boolean;
  [LIFECYCLE.nextTimeUserShouldSetupWallet]?: string;
  [LIFECYCLE.sawUsSportsCollectionsDialog]?: boolean;
  [LIFECYCLE.lastSaleDuration]?: number;
  [LIFECYCLE.sawMlbDraftErrorPrompt]?: boolean;
  [LIFECYCLE.sawMlbComposeTeamOnboarding]?: boolean;
  [LIFECYCLE.saw100PersonLeaderboardDialog]?: boolean;
  [LIFECYCLE.inSeasonFilterEnabled]?: boolean;
  [LIFECYCLE.latestSearchedItems]?: {
    [sport: string]: {
      objectID: string;
      index: string;
      sport: Sport;
    }[];
  };
  [LIFECYCLE.sawMlbOnboarding]?: boolean;
  [LIFECYCLE.sawMlbDraftWelcome]?: boolean;
  [LIFECYCLE.sawNbaOnboarding]?: boolean;
  [LIFECYCLE.marketSorts]?: Record<string, AlgoliaCardIndexesName>;
  [LIFECYCLE.sawMlbDraftEducationDialog]?: boolean;
  [LIFECYCLE.hideActivity]?: boolean;
  [LIFECYCLE.disable3D]?: boolean;
  [LIFECYCLE.hideOnlineStatus]?: boolean;
  [LIFECYCLE.referenceCurrencyForSale]: SupportedCurrency;
  [LIFECYCLE.sawMinorsUnlockedDialog]?: boolean;
  [LIFECYCLE.sawCommonLevel1UnlockedDialog]?: boolean;
  [LIFECYCLE.sawCommonLevel2UnlockedDialog]?: boolean;
  [LIFECYCLE.sawCommonLevel3UnlockedDialog]?: boolean;
  [LIFECYCLE.sawCommonLevel4UnlockedDialog]?: boolean;
  [LIFECYCLE.sawCommonLevel5UnlockedDialog]?: boolean;
  [LIFECYCLE.sawRewardUnlockedDialog]?: boolean;
  [LIFECYCLE.preferredPaymentMethod]?:
    | PaymentProvider_paymentMethod
    | PaymentMethod.ETH_WALLET
    | PaymentMethod.FIAT_WALLET;
  [LIFECYCLE.preferredDepositMethod]?: DepositMethod | CreditCardPaymentMethod;
  [LIFECYCLE.sawMlbClubShopIntro]?: boolean;
  [LIFECYCLE.hideReferralBanner]?: boolean;
  [LIFECYCLE.sawKickoffWelcomeToKickoff]?: boolean;
  [LIFECYCLE.showTrainingLineups]?: boolean;
  [LIFECYCLE.showOnlyCompetitionsEntered]?: boolean;
  [LIFECYCLE.sawChallengesOnboarding]?: boolean;
  [LIFECYCLE.sawTacticsOnboarding]?: boolean;
  [LIFECYCLE.doNotShowRivalsDownloadApp]?: boolean;
  [LIFECYCLE.doNotShowAddAuctionReminderDownloadApp]?: boolean;

  [LIFECYCLE.sawForYouFirstTime]?: boolean;
  [LIFECYCLE.unselectConversionCreditByDefaultFromSettings]?: boolean;
  [LIFECYCLE.sawWelcomeToSeasonsArena]?: boolean;
  [LIFECYCLE.sawSocialWallDialog]?: boolean;
  [LIFECYCLE.hideConversionCreditBanner]?: boolean;
  [LIFECYCLE.sawEuropeanChampionshipTooltip]?: boolean;
  [LIFECYCLE.sawRivalsLeagueOnboarding]?: boolean;
  [LIFECYCLE.playRivalsLeagueReminderLastDate]?: string;
  [LIFECYCLE.sawRivalsLeagueMatchSlugs]?: [string];
  [LIFECYCLE.sawRivalsPlayButtonOnboarding]?: boolean;
  [LIFECYCLE.sawBoxOnboarding]?: {
    limitedCard?: boolean;
    essence?: boolean;
    arenaTickets?: boolean;
  };
  [LIFECYCLE.sawRivalsLeagueCrossMode]?: boolean;
  [LIFECYCLE.sawRivalsPlayPageHighlight]?: boolean;
  [LIFECYCLE.sawRivalsLeagueEndingStory]?: boolean;
  [LIFECYCLE.sawRevealAnnouncementDialog]?: boolean;
  [LIFECYCLE.sawEarlyAccessAnnouncementDialog]?: boolean;
  [LIFECYCLE.sawStartedEarlyAccessAnnouncementDialog]?: boolean;
  [LIFECYCLE.sawSubstitutesFTUE]?: boolean;
  [LIFECYCLE.sawChallengerEarlyAccessAnnouncementDialog]?: boolean;
  [LIFECYCLE.sawChampionEarlyAccessAnnouncementDialog]?: boolean;
  [LIFECYCLE.sawComposeTeamOnboarding]?: boolean;
  [LIFECYCLE.sawComposeTeamHighlights]?: boolean;
  [LIFECYCLE.sawMySquadOnboarding]?: boolean;
  [LIFECYCLE.sawWarmUpAnnouncement]?: boolean;
  [LIFECYCLE.sawPushNotificationsPromptInWebview]?: boolean;
  [LIFECYCLE.sawNBAS3LobbyOnboarding]?: boolean;
  [LIFECYCLE.sawGalleryOnboarding]?: boolean;
  [LIFECYCLE.sawCommonMarketOnboarding]?: boolean;
  [LIFECYCLE.unlockedDialogSeenSo5LeagueNames]?: string[];
  [LIFECYCLE.sawMVPOnboarding]?: boolean;
  [LIFECYCLE.sawMyCardsOnboarding]?: boolean;
  [LIFECYCLE.sawPlayProFixturePageOnboarding]?: boolean;
  [LIFECYCLE.sawDailyHoopsOnboarding]?: boolean;
  [LIFECYCLE.sawNBAProUnlockScreen]?: boolean;
  [LIFECYCLE.sawNBADailyHoopsUnlockScreen]?: boolean;
  [LIFECYCLE.sawDailyClaimButton]?: boolean;
  [LIFECYCLE.sawNeonChristmasDialog]?: boolean;
  [LIFECYCLE.sawNeonChristmasStartedDialog]?: boolean;
  [LIFECYCLE.DEPRECATEDneonChristmasCalendar]?: number[];
  [LIFECYCLE.neonChristmasCalendarProgress]?: number[];
  [LIFECYCLE.lastVisitedMyCardsTab]?: {
    [sport: string]: string;
  };
  [LIFECYCLE.sawComposeTeamTutorial]?: boolean;
  [LIFECYCLE.statsManager]?: Record<Sport, PlayerSearchView>;
  [LIFECYCLE.sawFirstTimeStatsManager]?: Record<Sport, boolean>;
  [LIFECYCLE.hasDismissedExpiredPromoDepositBanner]?: boolean;
  [LIFECYCLE.composeLastViewUsed]?: string;
  [LIFECYCLE.composeFilters]?: {
    [Sport.FOOTBALL]?: Pick<
      FootballComposeFilters,
      'includeNoGame' | 'includeUnavailablePlayers' | 'includeUsed'
    >;
    [Sport.NBA]?: Pick<
      NBAComposeFilters,
      'includeNoGame' | 'includeUnavailablePlayers' | 'includeUsed'
    >;
    [Sport.BASEBALL]?: null;
  };
  [LIFECYCLE.sawTiersOnboarding]?: boolean;
  [LIFECYCLE.sawNBATutorialVideos]?: boolean;
  [LIFECYCLE.sawNBAIntroductionTutorialVideo]?: boolean;
  [LIFECYCLE.sawNBATypesOfCardsTutorialVideo]?: boolean;
  [LIFECYCLE.sawNBAMarketplaceTutorialVideo]?: boolean;
  [LIFECYCLE.sawNBACompetitionsTutorialVideo]?: boolean;
};

export type Lifecycle = LifecycleEntries | undefined;
export type LifecycleValue = LifecycleEntries[LIFECYCLE];
export type LifecycleGetter = LifecycleValue;

const UPDATE_LIFECYCLE_MUTATION = gql`
  mutation UpdateLifecycleMutation($input: updateUserSettingsInput!) {
    updateUserSettings(input: $input) {
      userSettings {
        id
        lifecycle
      }
      errors {
        path
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  UpdateLifecycleMutation,
  UpdateLifecycleMutationVariables
>;

// The lifecycle within useCurrentUserContext is not always up-to-date:
// using that won't explicitly trigger another fetch upon rendering
// + caching mechanism in apollo make state changes on the backend having
// a lag to be reflected on the client.
export const CURRENT_USER_LIFECYCLE_QUERY = gql`
  query CurrentUserLifecycleQuery {
    currentUser {
      slug
      userSettings {
        id
        lifecycle
      }
    }
  }
` as TypedDocumentNode<
  CurrentUserLifecycleQuery,
  CurrentUserLifecycleQueryVariables
>;

/**
 * We have a parameter here to refetch the configQuery as we do not want to load
 * currentUser in this mutation, as optimisticResponse can cause stale data in
 * Apollo Cache, which breaks field policies (and results in Date objects from
 * GraphQL being returned as strings)
 * MR: https://gitlab.com/sorare/frontend/-/merge_requests/19283
 * Works around: https://github.com/apollographql/apollo-client/issues/9293
 */
export default function useLifecycle({ refetchConfigQuery = false } = {}) {
  const { currentUser } = useConfigContext();
  const [updateLifecycle, { loading }] = useMutation(
    UPDATE_LIFECYCLE_MUTATION,
    {
      refetchQueries: refetchConfigQuery
        ? [namedOperations.Query.ConfigQuery]
        : undefined,
    }
  );

  const update = useCallback(
    async <K extends keyof LifecycleEntries>(
      name: K,
      value: Omit<LifecycleEntries[K], 'undefined'>
    ) => {
      if (!currentUser?.slug) {
        return;
      }
      await updateLifecycle({
        variables: {
          input: {
            lifecycle: {
              name,
              value,
            },
          },
        },
        optimisticResponse: {
          updateUserSettings: {
            __typename: 'updateUserSettingsPayload',
            userSettings: {
              __typename: 'UserSettings',
              id: currentUser.userSettings.id,
              lifecycle: {
                ...(currentUser.userSettings.lifecycle as Lifecycle),
                ...{ [name]: value },
              },
            },
            errors: [],
          },
        },
      });
    },
    [updateLifecycle, currentUser]
  );

  const lifecycle = currentUser?.userSettings.lifecycle as Lifecycle;

  return {
    lifecycle,
    update,
    loading,
  };
}
