import { getRoutesFromFiles } from '@sorare/routing';

/*
 * Prefer dynamic over static import into this file
 * because static ones will end up in the main bundle
 */
export const routeObjects = [
  {
    path: 'help',
    children: getRoutesFromFiles(
      import.meta.glob('./**/(page|layout).tsx'),
      '../../packages/help/src/pages'
    ),
  },
];
