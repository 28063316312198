// Generated by the protocol buffer compiler.
/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import {
  Sport,
  sportFromJSON,
  sportToJSON,
} from '../../../events/shared/events';

export const protobufPackage = 'events.so5.web';

export interface CardInfo {
  cardSlug: string;
  scarcity: string;
  sport: Sport;
}

export interface BundledCardsInfo {
  cardSlugs: string[];
  cardCount: number;
  limited: number;
  rare: number;
  superRare: number;
  unique: number;
  sport: Sport;
}

export interface ClickCard {
  /** CardInfo */
  cardSlug: string;
  scarcity: string;
  secondary: boolean;
  interactionContext: string;
  sport: Sport;
}

export interface ClickBundle {
  auctionId: string;
  /** BundledCardsInfo */
  cardSlugs: string[];
  cardCount: number;
  limited: number;
  rare: number;
  superRare: number;
  unique: number;
  secondary: boolean;
  interactionContext: string;
  sport: Sport;
}

export interface ClickBid {
  auctionId: string;
  /** CardInfo */
  cardSlug: string;
  scarcity: string;
  count: number;
  ethAmount: number;
  eurAmount: number;
  secondary: boolean;
  interactionContext: string;
  sport: Sport;
  isV2?: boolean | undefined;
}

export interface ClickBundledBid {
  auctionId: string;
  /** BundledCardsInfo */
  cardSlugs: string[];
  cardCount: number;
  limited: number;
  rare: number;
  superRare: number;
  unique: number;
  count: number;
  ethAmount: number;
  eurAmount: number;
  secondary: boolean;
  interactionContext: string;
  sport: Sport;
}

export interface ClickBuy {
  offerId: string;
  /** CardInfo */
  cardSlug: string;
  scarcity: string;
  ethAmount: number;
  eurAmount: number;
  secondary: boolean;
  interactionContext: string;
  sport: Sport;
}

export interface ClosePaymentBox {}

export interface CloseWallet {}

export interface ClickBackWallet {}

export interface ClickBanner {
  bannerId: string;
  tileIndex: number;
  bannerTitle: string;
  bannerType: string;
  bannerSlotName: string;
  sport: Sport;
}

export interface UseMarketFilter {
  filterName: string;
  filterValue: string;
  interactionContext: string;
  sport: Sport;
}

export interface FavoriteCard {
  /** CardInfo */
  cardSlug: string;
  scarcity: string;
  interactionContext: string;
  sport: Sport;
}

export interface FollowPlayer {
  playerSlug: string;
  position: string;
  positions: string[];
  teamSlug: string;
  alertLimited: boolean;
  alertRare: boolean;
  alertSuperRare: boolean;
  alertUnique: boolean;
  interactionContext: string;
  sport: Sport;
}

export interface PlaceBid {
  auctionId: string;
  /** CardInfo */
  cardSlug: string;
  scarcity: string;
  count: number;
  ethAmount: number;
  eurAmount: number;
  fiatPayment: boolean;
  secondary: boolean;
  sport: Sport;
}

export interface PlaceBundledBid {
  auctionId: string;
  /** BundledCardsInfo */
  cardSlugs: string[];
  cardCount: number;
  limited: number;
  rare: number;
  superRare: number;
  unique: number;
  count: number;
  ethAmount: number;
  eurAmount: number;
  fiatPayment: boolean;
  secondary: boolean;
  sport: Sport;
}

export interface ToggleHideBalance {
  hidden: boolean;
}

export interface SeeTCUModal {
  mobile: boolean;
}

export interface AcceptTCU {
  source: string;
}

export interface AttemptToSignupFromSanctionedCountry {
  countryCode: string;
}

export interface SignUpForProductUpdates {
  email: string;
  topic: string;
}

export interface UseThirdParty {
  name: string;
}

export interface ClickInviteFriends {
  source: string;
}

export interface DisplayWarningHighBidAmount {
  auctionId: string;
  cardSlugs: string[];
  count: number;
  ethAmount: number;
  eurAmount: number;
  fiatPayment: boolean;
  multiplier: number;
  sport: Sport;
}

export interface DisplayWarningVeryHighBidAmount {
  auctionId: string;
  cardSlugs: string[];
  count: number;
  ethAmount: number;
  eurAmount: number;
  fiatPayment: boolean;
  multiplier: number;
  sport: Sport;
}

export interface PlaceBidAfterDisplayWarningHighBidAmount {
  auctionId: string;
  cardSlugs: string[];
  count: number;
  ethAmount: number;
  eurAmount: number;
  fiatPayment: boolean;
  multiplier: number;
  sport: Sport;
}

export interface PlaceBidAfterDisplayWarningVeryHighBidAmount {
  auctionId: string;
  cardSlugs: string[];
  count: number;
  ethAmount: number;
  eurAmount: number;
  fiatPayment: boolean;
  multiplier: number;
  sport: Sport;
}

export interface ClickFilterInSearch {
  sport: Sport;
  context: Sport;
  searchTerm: string;
}

export interface ClickSearchResult {
  sport: Sport;
  context: Sport;
  searchTerm: string;
  resultCategory: string;
  resultDestination: string;
}

export interface ExitSearchWithoutClickingResult {
  sport: Sport;
  context: Sport;
  searchTerm: string;
}

export interface WalletOpened {
  interactionContext: string;
  sport: Sport;
}

export interface ListCard {
  /** CardInfo */
  cardSlug: string;
  scarcity: string;
  ethAmount: number;
  eurAmount: number;
  duration: number;
  sport: Sport;
}

export interface UnlistCard {
  /** CardInfo */
  cardSlug: string;
  scarcity: string;
  unlistingType: string;
  listingTime: number;
  cancelledBy: string;
  sport: Sport;
}

export interface ClickPlayNow {
  interactionContext: string;
  sport: Sport;
}

export interface ClickSignUp {
  interactionContext: string;
  variation: string;
  forceEmailConfirmation?: boolean | undefined;
}

export interface ViewSignUpModal {
  interactionContext: string;
  variation: string;
}

export interface ClickOauthSignUp {
  method: ClickOauthSignUp_Method;
}

export enum ClickOauthSignUp_Method {
  GOOGLE = 0,
  FACEBOOK = 1,
  APPLE = 2,
  UNRECOGNIZED = -1,
}

export function clickOauthSignUp_MethodFromJSON(
  object: any
): ClickOauthSignUp_Method {
  switch (object) {
    case 0:
    case 'GOOGLE':
      return ClickOauthSignUp_Method.GOOGLE;
    case 1:
    case 'FACEBOOK':
      return ClickOauthSignUp_Method.FACEBOOK;
    case 2:
    case 'APPLE':
      return ClickOauthSignUp_Method.APPLE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ClickOauthSignUp_Method.UNRECOGNIZED;
  }
}

export function clickOauthSignUp_MethodToJSON(
  object: ClickOauthSignUp_Method
): string {
  switch (object) {
    case ClickOauthSignUp_Method.GOOGLE:
      return 'GOOGLE';
    case ClickOauthSignUp_Method.FACEBOOK:
      return 'FACEBOOK';
    case ClickOauthSignUp_Method.APPLE:
      return 'APPLE';
    default:
      return 'UNKNOWN';
  }
}

export interface ClickPriceHistory {
  interactionContext: string;
  cardSlug: string;
  clickedCardSlug: string;
}

export interface ExpandPriceHistory {
  interactionContext: string;
  cardSlug: string;
}

export interface ClickBundledBuy {
  offerId: string;
  cardSlugs: string[];
  cardCount: number;
  limited: number;
  rare: number;
  superRare: number;
  unique: number;
  ethAmount: number;
  eurAmount: number;
  secondary: boolean;
  interactionContext: string;
  sport: Sport;
}

export interface StartOnboardingVersion2 {}

export interface ClickOnboardingNext {
  interactionContext: string;
  to: string;
}

export interface ClickOnboardingInfo {
  interactionContext: string;
}

export interface ChooseOnboardingCompetition {
  interactionContext: string;
  competition: string;
}

export interface ClickEuropeanChampionshipPlayPageBlock {}

export interface ClickJoinRivalsLeague {}

export interface ClickJoinRivalsLeagueInvite {
  userSlug: string;
  code: string;
}

export interface ClickRivalsLeaguesActivity {
  cta: ClickRivalsLeaguesActivity_Cta;
  leagueSlug: string;
  gameSlug?: string | undefined;
}

export enum ClickRivalsLeaguesActivity_Cta {
  ADD_FRIENDS = 0,
  SET_LINEUP = 1,
  VIEW_MATCH = 2,
  CELEBRATE = 3,
  CLAIM_REWARD_BOX = 4,
  UNRECOGNIZED = -1,
}

export function clickRivalsLeaguesActivity_CtaFromJSON(
  object: any
): ClickRivalsLeaguesActivity_Cta {
  switch (object) {
    case 0:
    case 'ADD_FRIENDS':
      return ClickRivalsLeaguesActivity_Cta.ADD_FRIENDS;
    case 1:
    case 'SET_LINEUP':
      return ClickRivalsLeaguesActivity_Cta.SET_LINEUP;
    case 2:
    case 'VIEW_MATCH':
      return ClickRivalsLeaguesActivity_Cta.VIEW_MATCH;
    case 3:
    case 'CELEBRATE':
      return ClickRivalsLeaguesActivity_Cta.CELEBRATE;
    case 4:
    case 'CLAIM_REWARD_BOX':
      return ClickRivalsLeaguesActivity_Cta.CLAIM_REWARD_BOX;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ClickRivalsLeaguesActivity_Cta.UNRECOGNIZED;
  }
}

export function clickRivalsLeaguesActivity_CtaToJSON(
  object: ClickRivalsLeaguesActivity_Cta
): string {
  switch (object) {
    case ClickRivalsLeaguesActivity_Cta.ADD_FRIENDS:
      return 'ADD_FRIENDS';
    case ClickRivalsLeaguesActivity_Cta.SET_LINEUP:
      return 'SET_LINEUP';
    case ClickRivalsLeaguesActivity_Cta.VIEW_MATCH:
      return 'VIEW_MATCH';
    case ClickRivalsLeaguesActivity_Cta.CELEBRATE:
      return 'CELEBRATE';
    case ClickRivalsLeaguesActivity_Cta.CLAIM_REWARD_BOX:
      return 'CLAIM_REWARD_BOX';
    default:
      return 'UNKNOWN';
  }
}

export interface ClickSwitchToRivals {
  interactionContext: string;
  sport: Sport;
  so5LeaderboardSlug: string;
  so5LeaderboardRarity: string;
  so5LeaderboardSeasonality: string;
  so5LeagueName: string;
  so5FixtureSlug: string;
}

export interface ClickSetLineup {
  so5LeaderboardSlug: string;
  trigger: string;
  interactionContext: string;
  sport: Sport;
}

export interface View3dNotSupported {
  interactionContext: string;
}

export interface OpenFixtureOverview {
  so5FixtureSlug: string;
  so5FixtureState: string;
  interactionContext: string;
  sport: Sport;
}

function createBaseCardInfo(): CardInfo {
  return { cardSlug: '', scarcity: '', sport: 0 };
}

export const CardInfo = {
  fromJSON(object: any): CardInfo {
    return {
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      scarcity: isSet(object.scarcity) ? String(object.scarcity) : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: CardInfo): unknown {
    const obj: any = {};
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.scarcity !== undefined && (obj.scarcity = message.scarcity);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseBundledCardsInfo(): BundledCardsInfo {
  return {
    cardSlugs: [],
    cardCount: 0,
    limited: 0,
    rare: 0,
    superRare: 0,
    unique: 0,
    sport: 0,
  };
}

export const BundledCardsInfo = {
  fromJSON(object: any): BundledCardsInfo {
    return {
      cardSlugs: Array.isArray(object?.card_slugs)
        ? object.card_slugs.map((e: any) => String(e))
        : [],
      cardCount: isSet(object.card_count) ? Number(object.card_count) : 0,
      limited: isSet(object.limited) ? Number(object.limited) : 0,
      rare: isSet(object.rare) ? Number(object.rare) : 0,
      superRare: isSet(object.super_rare) ? Number(object.super_rare) : 0,
      unique: isSet(object.unique) ? Number(object.unique) : 0,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: BundledCardsInfo): unknown {
    const obj: any = {};
    if (message.cardSlugs) {
      obj.card_slugs = message.cardSlugs.map(e => e);
    } else {
      obj.card_slugs = [];
    }
    message.cardCount !== undefined &&
      (obj.card_count = Math.round(message.cardCount));
    message.limited !== undefined &&
      (obj.limited = Math.round(message.limited));
    message.rare !== undefined && (obj.rare = Math.round(message.rare));
    message.superRare !== undefined &&
      (obj.super_rare = Math.round(message.superRare));
    message.unique !== undefined && (obj.unique = Math.round(message.unique));
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickCard(): ClickCard {
  return {
    cardSlug: '',
    scarcity: '',
    secondary: false,
    interactionContext: '',
    sport: 0,
  };
}

export const ClickCard = {
  fromJSON(object: any): ClickCard {
    return {
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      scarcity: isSet(object.scarcity) ? String(object.scarcity) : '',
      secondary: isSet(object.secondary) ? Boolean(object.secondary) : false,
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickCard): unknown {
    const obj: any = {};
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.scarcity !== undefined && (obj.scarcity = message.scarcity);
    message.secondary !== undefined && (obj.secondary = message.secondary);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickBundle(): ClickBundle {
  return {
    auctionId: '',
    cardSlugs: [],
    cardCount: 0,
    limited: 0,
    rare: 0,
    superRare: 0,
    unique: 0,
    secondary: false,
    interactionContext: '',
    sport: 0,
  };
}

export const ClickBundle = {
  fromJSON(object: any): ClickBundle {
    return {
      auctionId: isSet(object.auction_id) ? String(object.auction_id) : '',
      cardSlugs: Array.isArray(object?.card_slugs)
        ? object.card_slugs.map((e: any) => String(e))
        : [],
      cardCount: isSet(object.card_count) ? Number(object.card_count) : 0,
      limited: isSet(object.limited) ? Number(object.limited) : 0,
      rare: isSet(object.rare) ? Number(object.rare) : 0,
      superRare: isSet(object.super_rare) ? Number(object.super_rare) : 0,
      unique: isSet(object.unique) ? Number(object.unique) : 0,
      secondary: isSet(object.secondary) ? Boolean(object.secondary) : false,
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickBundle): unknown {
    const obj: any = {};
    message.auctionId !== undefined && (obj.auction_id = message.auctionId);
    if (message.cardSlugs) {
      obj.card_slugs = message.cardSlugs.map(e => e);
    } else {
      obj.card_slugs = [];
    }
    message.cardCount !== undefined &&
      (obj.card_count = Math.round(message.cardCount));
    message.limited !== undefined &&
      (obj.limited = Math.round(message.limited));
    message.rare !== undefined && (obj.rare = Math.round(message.rare));
    message.superRare !== undefined &&
      (obj.super_rare = Math.round(message.superRare));
    message.unique !== undefined && (obj.unique = Math.round(message.unique));
    message.secondary !== undefined && (obj.secondary = message.secondary);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickBid(): ClickBid {
  return {
    auctionId: '',
    cardSlug: '',
    scarcity: '',
    count: 0,
    ethAmount: 0,
    eurAmount: 0,
    secondary: false,
    interactionContext: '',
    sport: 0,
    isV2: undefined,
  };
}

export const ClickBid = {
  fromJSON(object: any): ClickBid {
    return {
      auctionId: isSet(object.auction_id) ? String(object.auction_id) : '',
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      scarcity: isSet(object.scarcity) ? String(object.scarcity) : '',
      count: isSet(object.count) ? Number(object.count) : 0,
      ethAmount: isSet(object.eth_amount) ? Number(object.eth_amount) : 0,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      secondary: isSet(object.secondary) ? Boolean(object.secondary) : false,
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      isV2: isSet(object.is_v2) ? Boolean(object.is_v2) : undefined,
    };
  },

  toJSON(message: ClickBid): unknown {
    const obj: any = {};
    message.auctionId !== undefined && (obj.auction_id = message.auctionId);
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.scarcity !== undefined && (obj.scarcity = message.scarcity);
    message.count !== undefined && (obj.count = Math.round(message.count));
    message.ethAmount !== undefined && (obj.eth_amount = message.ethAmount);
    message.eurAmount !== undefined && (obj.eur_amount = message.eurAmount);
    message.secondary !== undefined && (obj.secondary = message.secondary);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.isV2 !== undefined && (obj.is_v2 = message.isV2);
    return obj;
  },
};

function createBaseClickBundledBid(): ClickBundledBid {
  return {
    auctionId: '',
    cardSlugs: [],
    cardCount: 0,
    limited: 0,
    rare: 0,
    superRare: 0,
    unique: 0,
    count: 0,
    ethAmount: 0,
    eurAmount: 0,
    secondary: false,
    interactionContext: '',
    sport: 0,
  };
}

export const ClickBundledBid = {
  fromJSON(object: any): ClickBundledBid {
    return {
      auctionId: isSet(object.auction_id) ? String(object.auction_id) : '',
      cardSlugs: Array.isArray(object?.card_slugs)
        ? object.card_slugs.map((e: any) => String(e))
        : [],
      cardCount: isSet(object.card_count) ? Number(object.card_count) : 0,
      limited: isSet(object.limited) ? Number(object.limited) : 0,
      rare: isSet(object.rare) ? Number(object.rare) : 0,
      superRare: isSet(object.super_rare) ? Number(object.super_rare) : 0,
      unique: isSet(object.unique) ? Number(object.unique) : 0,
      count: isSet(object.count) ? Number(object.count) : 0,
      ethAmount: isSet(object.eth_amount) ? Number(object.eth_amount) : 0,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      secondary: isSet(object.secondary) ? Boolean(object.secondary) : false,
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickBundledBid): unknown {
    const obj: any = {};
    message.auctionId !== undefined && (obj.auction_id = message.auctionId);
    if (message.cardSlugs) {
      obj.card_slugs = message.cardSlugs.map(e => e);
    } else {
      obj.card_slugs = [];
    }
    message.cardCount !== undefined &&
      (obj.card_count = Math.round(message.cardCount));
    message.limited !== undefined &&
      (obj.limited = Math.round(message.limited));
    message.rare !== undefined && (obj.rare = Math.round(message.rare));
    message.superRare !== undefined &&
      (obj.super_rare = Math.round(message.superRare));
    message.unique !== undefined && (obj.unique = Math.round(message.unique));
    message.count !== undefined && (obj.count = Math.round(message.count));
    message.ethAmount !== undefined && (obj.eth_amount = message.ethAmount);
    message.eurAmount !== undefined && (obj.eur_amount = message.eurAmount);
    message.secondary !== undefined && (obj.secondary = message.secondary);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickBuy(): ClickBuy {
  return {
    offerId: '',
    cardSlug: '',
    scarcity: '',
    ethAmount: 0,
    eurAmount: 0,
    secondary: false,
    interactionContext: '',
    sport: 0,
  };
}

export const ClickBuy = {
  fromJSON(object: any): ClickBuy {
    return {
      offerId: isSet(object.offer_id) ? String(object.offer_id) : '',
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      scarcity: isSet(object.scarcity) ? String(object.scarcity) : '',
      ethAmount: isSet(object.eth_amount) ? Number(object.eth_amount) : 0,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      secondary: isSet(object.secondary) ? Boolean(object.secondary) : false,
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickBuy): unknown {
    const obj: any = {};
    message.offerId !== undefined && (obj.offer_id = message.offerId);
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.scarcity !== undefined && (obj.scarcity = message.scarcity);
    message.ethAmount !== undefined && (obj.eth_amount = message.ethAmount);
    message.eurAmount !== undefined && (obj.eur_amount = message.eurAmount);
    message.secondary !== undefined && (obj.secondary = message.secondary);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClosePaymentBox(): ClosePaymentBox {
  return {};
}

export const ClosePaymentBox = {
  fromJSON(_: any): ClosePaymentBox {
    return {};
  },

  toJSON(_: ClosePaymentBox): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseCloseWallet(): CloseWallet {
  return {};
}

export const CloseWallet = {
  fromJSON(_: any): CloseWallet {
    return {};
  },

  toJSON(_: CloseWallet): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickBackWallet(): ClickBackWallet {
  return {};
}

export const ClickBackWallet = {
  fromJSON(_: any): ClickBackWallet {
    return {};
  },

  toJSON(_: ClickBackWallet): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickBanner(): ClickBanner {
  return {
    bannerId: '',
    tileIndex: 0,
    bannerTitle: '',
    bannerType: '',
    bannerSlotName: '',
    sport: 0,
  };
}

export const ClickBanner = {
  fromJSON(object: any): ClickBanner {
    return {
      bannerId: isSet(object.banner_id) ? String(object.banner_id) : '',
      tileIndex: isSet(object.tile_index) ? Number(object.tile_index) : 0,
      bannerTitle: isSet(object.banner_title)
        ? String(object.banner_title)
        : '',
      bannerType: isSet(object.banner_type) ? String(object.banner_type) : '',
      bannerSlotName: isSet(object.banner_slot_name)
        ? String(object.banner_slot_name)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickBanner): unknown {
    const obj: any = {};
    message.bannerId !== undefined && (obj.banner_id = message.bannerId);
    message.tileIndex !== undefined &&
      (obj.tile_index = Math.round(message.tileIndex));
    message.bannerTitle !== undefined &&
      (obj.banner_title = message.bannerTitle);
    message.bannerType !== undefined && (obj.banner_type = message.bannerType);
    message.bannerSlotName !== undefined &&
      (obj.banner_slot_name = message.bannerSlotName);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseUseMarketFilter(): UseMarketFilter {
  return { filterName: '', filterValue: '', interactionContext: '', sport: 0 };
}

export const UseMarketFilter = {
  fromJSON(object: any): UseMarketFilter {
    return {
      filterName: isSet(object.filter_name) ? String(object.filter_name) : '',
      filterValue: isSet(object.filter_value)
        ? String(object.filter_value)
        : '',
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: UseMarketFilter): unknown {
    const obj: any = {};
    message.filterName !== undefined && (obj.filter_name = message.filterName);
    message.filterValue !== undefined &&
      (obj.filter_value = message.filterValue);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseFavoriteCard(): FavoriteCard {
  return { cardSlug: '', scarcity: '', interactionContext: '', sport: 0 };
}

export const FavoriteCard = {
  fromJSON(object: any): FavoriteCard {
    return {
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      scarcity: isSet(object.scarcity) ? String(object.scarcity) : '',
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: FavoriteCard): unknown {
    const obj: any = {};
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.scarcity !== undefined && (obj.scarcity = message.scarcity);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseFollowPlayer(): FollowPlayer {
  return {
    playerSlug: '',
    position: '',
    positions: [],
    teamSlug: '',
    alertLimited: false,
    alertRare: false,
    alertSuperRare: false,
    alertUnique: false,
    interactionContext: '',
    sport: 0,
  };
}

export const FollowPlayer = {
  fromJSON(object: any): FollowPlayer {
    return {
      playerSlug: isSet(object.player_slug) ? String(object.player_slug) : '',
      position: isSet(object.position) ? String(object.position) : '',
      positions: Array.isArray(object?.positions)
        ? object.positions.map((e: any) => String(e))
        : [],
      teamSlug: isSet(object.team_slug) ? String(object.team_slug) : '',
      alertLimited: isSet(object.alert_limited)
        ? Boolean(object.alert_limited)
        : false,
      alertRare: isSet(object.alert_rare) ? Boolean(object.alert_rare) : false,
      alertSuperRare: isSet(object.alert_super_rare)
        ? Boolean(object.alert_super_rare)
        : false,
      alertUnique: isSet(object.alert_unique)
        ? Boolean(object.alert_unique)
        : false,
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: FollowPlayer): unknown {
    const obj: any = {};
    message.playerSlug !== undefined && (obj.player_slug = message.playerSlug);
    message.position !== undefined && (obj.position = message.position);
    if (message.positions) {
      obj.positions = message.positions.map(e => e);
    } else {
      obj.positions = [];
    }
    message.teamSlug !== undefined && (obj.team_slug = message.teamSlug);
    message.alertLimited !== undefined &&
      (obj.alert_limited = message.alertLimited);
    message.alertRare !== undefined && (obj.alert_rare = message.alertRare);
    message.alertSuperRare !== undefined &&
      (obj.alert_super_rare = message.alertSuperRare);
    message.alertUnique !== undefined &&
      (obj.alert_unique = message.alertUnique);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBasePlaceBid(): PlaceBid {
  return {
    auctionId: '',
    cardSlug: '',
    scarcity: '',
    count: 0,
    ethAmount: 0,
    eurAmount: 0,
    fiatPayment: false,
    secondary: false,
    sport: 0,
  };
}

export const PlaceBid = {
  fromJSON(object: any): PlaceBid {
    return {
      auctionId: isSet(object.auction_id) ? String(object.auction_id) : '',
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      scarcity: isSet(object.scarcity) ? String(object.scarcity) : '',
      count: isSet(object.count) ? Number(object.count) : 0,
      ethAmount: isSet(object.eth_amount) ? Number(object.eth_amount) : 0,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      fiatPayment: isSet(object.fiat_payment)
        ? Boolean(object.fiat_payment)
        : false,
      secondary: isSet(object.secondary) ? Boolean(object.secondary) : false,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: PlaceBid): unknown {
    const obj: any = {};
    message.auctionId !== undefined && (obj.auction_id = message.auctionId);
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.scarcity !== undefined && (obj.scarcity = message.scarcity);
    message.count !== undefined && (obj.count = Math.round(message.count));
    message.ethAmount !== undefined && (obj.eth_amount = message.ethAmount);
    message.eurAmount !== undefined && (obj.eur_amount = message.eurAmount);
    message.fiatPayment !== undefined &&
      (obj.fiat_payment = message.fiatPayment);
    message.secondary !== undefined && (obj.secondary = message.secondary);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBasePlaceBundledBid(): PlaceBundledBid {
  return {
    auctionId: '',
    cardSlugs: [],
    cardCount: 0,
    limited: 0,
    rare: 0,
    superRare: 0,
    unique: 0,
    count: 0,
    ethAmount: 0,
    eurAmount: 0,
    fiatPayment: false,
    secondary: false,
    sport: 0,
  };
}

export const PlaceBundledBid = {
  fromJSON(object: any): PlaceBundledBid {
    return {
      auctionId: isSet(object.auction_id) ? String(object.auction_id) : '',
      cardSlugs: Array.isArray(object?.card_slugs)
        ? object.card_slugs.map((e: any) => String(e))
        : [],
      cardCount: isSet(object.card_count) ? Number(object.card_count) : 0,
      limited: isSet(object.limited) ? Number(object.limited) : 0,
      rare: isSet(object.rare) ? Number(object.rare) : 0,
      superRare: isSet(object.super_rare) ? Number(object.super_rare) : 0,
      unique: isSet(object.unique) ? Number(object.unique) : 0,
      count: isSet(object.count) ? Number(object.count) : 0,
      ethAmount: isSet(object.eth_amount) ? Number(object.eth_amount) : 0,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      fiatPayment: isSet(object.fiat_payment)
        ? Boolean(object.fiat_payment)
        : false,
      secondary: isSet(object.secondary) ? Boolean(object.secondary) : false,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: PlaceBundledBid): unknown {
    const obj: any = {};
    message.auctionId !== undefined && (obj.auction_id = message.auctionId);
    if (message.cardSlugs) {
      obj.card_slugs = message.cardSlugs.map(e => e);
    } else {
      obj.card_slugs = [];
    }
    message.cardCount !== undefined &&
      (obj.card_count = Math.round(message.cardCount));
    message.limited !== undefined &&
      (obj.limited = Math.round(message.limited));
    message.rare !== undefined && (obj.rare = Math.round(message.rare));
    message.superRare !== undefined &&
      (obj.super_rare = Math.round(message.superRare));
    message.unique !== undefined && (obj.unique = Math.round(message.unique));
    message.count !== undefined && (obj.count = Math.round(message.count));
    message.ethAmount !== undefined && (obj.eth_amount = message.ethAmount);
    message.eurAmount !== undefined && (obj.eur_amount = message.eurAmount);
    message.fiatPayment !== undefined &&
      (obj.fiat_payment = message.fiatPayment);
    message.secondary !== undefined && (obj.secondary = message.secondary);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseToggleHideBalance(): ToggleHideBalance {
  return { hidden: false };
}

export const ToggleHideBalance = {
  fromJSON(object: any): ToggleHideBalance {
    return {
      hidden: isSet(object.hidden) ? Boolean(object.hidden) : false,
    };
  },

  toJSON(message: ToggleHideBalance): unknown {
    const obj: any = {};
    message.hidden !== undefined && (obj.hidden = message.hidden);
    return obj;
  },
};

function createBaseSeeTCUModal(): SeeTCUModal {
  return { mobile: false };
}

export const SeeTCUModal = {
  fromJSON(object: any): SeeTCUModal {
    return {
      mobile: isSet(object.mobile) ? Boolean(object.mobile) : false,
    };
  },

  toJSON(message: SeeTCUModal): unknown {
    const obj: any = {};
    message.mobile !== undefined && (obj.mobile = message.mobile);
    return obj;
  },
};

function createBaseAcceptTCU(): AcceptTCU {
  return { source: '' };
}

export const AcceptTCU = {
  fromJSON(object: any): AcceptTCU {
    return {
      source: isSet(object.source) ? String(object.source) : '',
    };
  },

  toJSON(message: AcceptTCU): unknown {
    const obj: any = {};
    message.source !== undefined && (obj.source = message.source);
    return obj;
  },
};

function createBaseAttemptToSignupFromSanctionedCountry(): AttemptToSignupFromSanctionedCountry {
  return { countryCode: '' };
}

export const AttemptToSignupFromSanctionedCountry = {
  fromJSON(object: any): AttemptToSignupFromSanctionedCountry {
    return {
      countryCode: isSet(object.country_code)
        ? String(object.country_code)
        : '',
    };
  },

  toJSON(message: AttemptToSignupFromSanctionedCountry): unknown {
    const obj: any = {};
    message.countryCode !== undefined &&
      (obj.country_code = message.countryCode);
    return obj;
  },
};

function createBaseSignUpForProductUpdates(): SignUpForProductUpdates {
  return { email: '', topic: '' };
}

export const SignUpForProductUpdates = {
  fromJSON(object: any): SignUpForProductUpdates {
    return {
      email: isSet(object.email) ? String(object.email) : '',
      topic: isSet(object.topic) ? String(object.topic) : '',
    };
  },

  toJSON(message: SignUpForProductUpdates): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.topic !== undefined && (obj.topic = message.topic);
    return obj;
  },
};

function createBaseUseThirdParty(): UseThirdParty {
  return { name: '' };
}

export const UseThirdParty = {
  fromJSON(object: any): UseThirdParty {
    return {
      name: isSet(object.name) ? String(object.name) : '',
    };
  },

  toJSON(message: UseThirdParty): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },
};

function createBaseClickInviteFriends(): ClickInviteFriends {
  return { source: '' };
}

export const ClickInviteFriends = {
  fromJSON(object: any): ClickInviteFriends {
    return {
      source: isSet(object.source) ? String(object.source) : '',
    };
  },

  toJSON(message: ClickInviteFriends): unknown {
    const obj: any = {};
    message.source !== undefined && (obj.source = message.source);
    return obj;
  },
};

function createBaseDisplayWarningHighBidAmount(): DisplayWarningHighBidAmount {
  return {
    auctionId: '',
    cardSlugs: [],
    count: 0,
    ethAmount: 0,
    eurAmount: 0,
    fiatPayment: false,
    multiplier: 0,
    sport: 0,
  };
}

export const DisplayWarningHighBidAmount = {
  fromJSON(object: any): DisplayWarningHighBidAmount {
    return {
      auctionId: isSet(object.auction_id) ? String(object.auction_id) : '',
      cardSlugs: Array.isArray(object?.card_slugs)
        ? object.card_slugs.map((e: any) => String(e))
        : [],
      count: isSet(object.count) ? Number(object.count) : 0,
      ethAmount: isSet(object.eth_amount) ? Number(object.eth_amount) : 0,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      fiatPayment: isSet(object.fiat_payment)
        ? Boolean(object.fiat_payment)
        : false,
      multiplier: isSet(object.multiplier) ? Number(object.multiplier) : 0,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: DisplayWarningHighBidAmount): unknown {
    const obj: any = {};
    message.auctionId !== undefined && (obj.auction_id = message.auctionId);
    if (message.cardSlugs) {
      obj.card_slugs = message.cardSlugs.map(e => e);
    } else {
      obj.card_slugs = [];
    }
    message.count !== undefined && (obj.count = Math.round(message.count));
    message.ethAmount !== undefined && (obj.eth_amount = message.ethAmount);
    message.eurAmount !== undefined && (obj.eur_amount = message.eurAmount);
    message.fiatPayment !== undefined &&
      (obj.fiat_payment = message.fiatPayment);
    message.multiplier !== undefined && (obj.multiplier = message.multiplier);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseDisplayWarningVeryHighBidAmount(): DisplayWarningVeryHighBidAmount {
  return {
    auctionId: '',
    cardSlugs: [],
    count: 0,
    ethAmount: 0,
    eurAmount: 0,
    fiatPayment: false,
    multiplier: 0,
    sport: 0,
  };
}

export const DisplayWarningVeryHighBidAmount = {
  fromJSON(object: any): DisplayWarningVeryHighBidAmount {
    return {
      auctionId: isSet(object.auction_id) ? String(object.auction_id) : '',
      cardSlugs: Array.isArray(object?.card_slugs)
        ? object.card_slugs.map((e: any) => String(e))
        : [],
      count: isSet(object.count) ? Number(object.count) : 0,
      ethAmount: isSet(object.eth_amount) ? Number(object.eth_amount) : 0,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      fiatPayment: isSet(object.fiat_payment)
        ? Boolean(object.fiat_payment)
        : false,
      multiplier: isSet(object.multiplier) ? Number(object.multiplier) : 0,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: DisplayWarningVeryHighBidAmount): unknown {
    const obj: any = {};
    message.auctionId !== undefined && (obj.auction_id = message.auctionId);
    if (message.cardSlugs) {
      obj.card_slugs = message.cardSlugs.map(e => e);
    } else {
      obj.card_slugs = [];
    }
    message.count !== undefined && (obj.count = Math.round(message.count));
    message.ethAmount !== undefined && (obj.eth_amount = message.ethAmount);
    message.eurAmount !== undefined && (obj.eur_amount = message.eurAmount);
    message.fiatPayment !== undefined &&
      (obj.fiat_payment = message.fiatPayment);
    message.multiplier !== undefined && (obj.multiplier = message.multiplier);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBasePlaceBidAfterDisplayWarningHighBidAmount(): PlaceBidAfterDisplayWarningHighBidAmount {
  return {
    auctionId: '',
    cardSlugs: [],
    count: 0,
    ethAmount: 0,
    eurAmount: 0,
    fiatPayment: false,
    multiplier: 0,
    sport: 0,
  };
}

export const PlaceBidAfterDisplayWarningHighBidAmount = {
  fromJSON(object: any): PlaceBidAfterDisplayWarningHighBidAmount {
    return {
      auctionId: isSet(object.auction_id) ? String(object.auction_id) : '',
      cardSlugs: Array.isArray(object?.card_slugs)
        ? object.card_slugs.map((e: any) => String(e))
        : [],
      count: isSet(object.count) ? Number(object.count) : 0,
      ethAmount: isSet(object.eth_amount) ? Number(object.eth_amount) : 0,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      fiatPayment: isSet(object.fiat_payment)
        ? Boolean(object.fiat_payment)
        : false,
      multiplier: isSet(object.multiplier) ? Number(object.multiplier) : 0,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: PlaceBidAfterDisplayWarningHighBidAmount): unknown {
    const obj: any = {};
    message.auctionId !== undefined && (obj.auction_id = message.auctionId);
    if (message.cardSlugs) {
      obj.card_slugs = message.cardSlugs.map(e => e);
    } else {
      obj.card_slugs = [];
    }
    message.count !== undefined && (obj.count = Math.round(message.count));
    message.ethAmount !== undefined && (obj.eth_amount = message.ethAmount);
    message.eurAmount !== undefined && (obj.eur_amount = message.eurAmount);
    message.fiatPayment !== undefined &&
      (obj.fiat_payment = message.fiatPayment);
    message.multiplier !== undefined && (obj.multiplier = message.multiplier);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBasePlaceBidAfterDisplayWarningVeryHighBidAmount(): PlaceBidAfterDisplayWarningVeryHighBidAmount {
  return {
    auctionId: '',
    cardSlugs: [],
    count: 0,
    ethAmount: 0,
    eurAmount: 0,
    fiatPayment: false,
    multiplier: 0,
    sport: 0,
  };
}

export const PlaceBidAfterDisplayWarningVeryHighBidAmount = {
  fromJSON(object: any): PlaceBidAfterDisplayWarningVeryHighBidAmount {
    return {
      auctionId: isSet(object.auction_id) ? String(object.auction_id) : '',
      cardSlugs: Array.isArray(object?.card_slugs)
        ? object.card_slugs.map((e: any) => String(e))
        : [],
      count: isSet(object.count) ? Number(object.count) : 0,
      ethAmount: isSet(object.eth_amount) ? Number(object.eth_amount) : 0,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      fiatPayment: isSet(object.fiat_payment)
        ? Boolean(object.fiat_payment)
        : false,
      multiplier: isSet(object.multiplier) ? Number(object.multiplier) : 0,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: PlaceBidAfterDisplayWarningVeryHighBidAmount): unknown {
    const obj: any = {};
    message.auctionId !== undefined && (obj.auction_id = message.auctionId);
    if (message.cardSlugs) {
      obj.card_slugs = message.cardSlugs.map(e => e);
    } else {
      obj.card_slugs = [];
    }
    message.count !== undefined && (obj.count = Math.round(message.count));
    message.ethAmount !== undefined && (obj.eth_amount = message.ethAmount);
    message.eurAmount !== undefined && (obj.eur_amount = message.eurAmount);
    message.fiatPayment !== undefined &&
      (obj.fiat_payment = message.fiatPayment);
    message.multiplier !== undefined && (obj.multiplier = message.multiplier);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickFilterInSearch(): ClickFilterInSearch {
  return { sport: 0, context: 0, searchTerm: '' };
}

export const ClickFilterInSearch = {
  fromJSON(object: any): ClickFilterInSearch {
    return {
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      context: isSet(object.context) ? sportFromJSON(object.context) : 0,
      searchTerm: isSet(object.search_term) ? String(object.search_term) : '',
    };
  },

  toJSON(message: ClickFilterInSearch): unknown {
    const obj: any = {};
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.context !== undefined &&
      (obj.context = sportToJSON(message.context));
    message.searchTerm !== undefined && (obj.search_term = message.searchTerm);
    return obj;
  },
};

function createBaseClickSearchResult(): ClickSearchResult {
  return {
    sport: 0,
    context: 0,
    searchTerm: '',
    resultCategory: '',
    resultDestination: '',
  };
}

export const ClickSearchResult = {
  fromJSON(object: any): ClickSearchResult {
    return {
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      context: isSet(object.context) ? sportFromJSON(object.context) : 0,
      searchTerm: isSet(object.search_term) ? String(object.search_term) : '',
      resultCategory: isSet(object.result_category)
        ? String(object.result_category)
        : '',
      resultDestination: isSet(object.result_destination)
        ? String(object.result_destination)
        : '',
    };
  },

  toJSON(message: ClickSearchResult): unknown {
    const obj: any = {};
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.context !== undefined &&
      (obj.context = sportToJSON(message.context));
    message.searchTerm !== undefined && (obj.search_term = message.searchTerm);
    message.resultCategory !== undefined &&
      (obj.result_category = message.resultCategory);
    message.resultDestination !== undefined &&
      (obj.result_destination = message.resultDestination);
    return obj;
  },
};

function createBaseExitSearchWithoutClickingResult(): ExitSearchWithoutClickingResult {
  return { sport: 0, context: 0, searchTerm: '' };
}

export const ExitSearchWithoutClickingResult = {
  fromJSON(object: any): ExitSearchWithoutClickingResult {
    return {
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      context: isSet(object.context) ? sportFromJSON(object.context) : 0,
      searchTerm: isSet(object.search_term) ? String(object.search_term) : '',
    };
  },

  toJSON(message: ExitSearchWithoutClickingResult): unknown {
    const obj: any = {};
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.context !== undefined &&
      (obj.context = sportToJSON(message.context));
    message.searchTerm !== undefined && (obj.search_term = message.searchTerm);
    return obj;
  },
};

function createBaseWalletOpened(): WalletOpened {
  return { interactionContext: '', sport: 0 };
}

export const WalletOpened = {
  fromJSON(object: any): WalletOpened {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: WalletOpened): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseListCard(): ListCard {
  return {
    cardSlug: '',
    scarcity: '',
    ethAmount: 0,
    eurAmount: 0,
    duration: 0,
    sport: 0,
  };
}

export const ListCard = {
  fromJSON(object: any): ListCard {
    return {
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      scarcity: isSet(object.scarcity) ? String(object.scarcity) : '',
      ethAmount: isSet(object.eth_amount) ? Number(object.eth_amount) : 0,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      duration: isSet(object.duration) ? Number(object.duration) : 0,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ListCard): unknown {
    const obj: any = {};
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.scarcity !== undefined && (obj.scarcity = message.scarcity);
    message.ethAmount !== undefined && (obj.eth_amount = message.ethAmount);
    message.eurAmount !== undefined && (obj.eur_amount = message.eurAmount);
    message.duration !== undefined &&
      (obj.duration = Math.round(message.duration));
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseUnlistCard(): UnlistCard {
  return {
    cardSlug: '',
    scarcity: '',
    unlistingType: '',
    listingTime: 0,
    cancelledBy: '',
    sport: 0,
  };
}

export const UnlistCard = {
  fromJSON(object: any): UnlistCard {
    return {
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      scarcity: isSet(object.scarcity) ? String(object.scarcity) : '',
      unlistingType: isSet(object.unlisting_type)
        ? String(object.unlisting_type)
        : '',
      listingTime: isSet(object.listing_time) ? Number(object.listing_time) : 0,
      cancelledBy: isSet(object.cancelled_by)
        ? String(object.cancelled_by)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: UnlistCard): unknown {
    const obj: any = {};
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.scarcity !== undefined && (obj.scarcity = message.scarcity);
    message.unlistingType !== undefined &&
      (obj.unlisting_type = message.unlistingType);
    message.listingTime !== undefined &&
      (obj.listing_time = Math.round(message.listingTime));
    message.cancelledBy !== undefined &&
      (obj.cancelled_by = message.cancelledBy);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickPlayNow(): ClickPlayNow {
  return { interactionContext: '', sport: 0 };
}

export const ClickPlayNow = {
  fromJSON(object: any): ClickPlayNow {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickPlayNow): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickSignUp(): ClickSignUp {
  return {
    interactionContext: '',
    variation: '',
    forceEmailConfirmation: undefined,
  };
}

export const ClickSignUp = {
  fromJSON(object: any): ClickSignUp {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      variation: isSet(object.variation) ? String(object.variation) : '',
      forceEmailConfirmation: isSet(object.force_email_confirmation)
        ? Boolean(object.force_email_confirmation)
        : undefined,
    };
  },

  toJSON(message: ClickSignUp): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.variation !== undefined && (obj.variation = message.variation);
    message.forceEmailConfirmation !== undefined &&
      (obj.force_email_confirmation = message.forceEmailConfirmation);
    return obj;
  },
};

function createBaseViewSignUpModal(): ViewSignUpModal {
  return { interactionContext: '', variation: '' };
}

export const ViewSignUpModal = {
  fromJSON(object: any): ViewSignUpModal {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      variation: isSet(object.variation) ? String(object.variation) : '',
    };
  },

  toJSON(message: ViewSignUpModal): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.variation !== undefined && (obj.variation = message.variation);
    return obj;
  },
};

function createBaseClickOauthSignUp(): ClickOauthSignUp {
  return { method: 0 };
}

export const ClickOauthSignUp = {
  fromJSON(object: any): ClickOauthSignUp {
    return {
      method: isSet(object.method)
        ? clickOauthSignUp_MethodFromJSON(object.method)
        : 0,
    };
  },

  toJSON(message: ClickOauthSignUp): unknown {
    const obj: any = {};
    message.method !== undefined &&
      (obj.method = clickOauthSignUp_MethodToJSON(message.method));
    return obj;
  },
};

function createBaseClickPriceHistory(): ClickPriceHistory {
  return { interactionContext: '', cardSlug: '', clickedCardSlug: '' };
}

export const ClickPriceHistory = {
  fromJSON(object: any): ClickPriceHistory {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      clickedCardSlug: isSet(object.clicked_card_slug)
        ? String(object.clicked_card_slug)
        : '',
    };
  },

  toJSON(message: ClickPriceHistory): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.clickedCardSlug !== undefined &&
      (obj.clicked_card_slug = message.clickedCardSlug);
    return obj;
  },
};

function createBaseExpandPriceHistory(): ExpandPriceHistory {
  return { interactionContext: '', cardSlug: '' };
}

export const ExpandPriceHistory = {
  fromJSON(object: any): ExpandPriceHistory {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
    };
  },

  toJSON(message: ExpandPriceHistory): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    return obj;
  },
};

function createBaseClickBundledBuy(): ClickBundledBuy {
  return {
    offerId: '',
    cardSlugs: [],
    cardCount: 0,
    limited: 0,
    rare: 0,
    superRare: 0,
    unique: 0,
    ethAmount: 0,
    eurAmount: 0,
    secondary: false,
    interactionContext: '',
    sport: 0,
  };
}

export const ClickBundledBuy = {
  fromJSON(object: any): ClickBundledBuy {
    return {
      offerId: isSet(object.offer_id) ? String(object.offer_id) : '',
      cardSlugs: Array.isArray(object?.card_slugs)
        ? object.card_slugs.map((e: any) => String(e))
        : [],
      cardCount: isSet(object.card_count) ? Number(object.card_count) : 0,
      limited: isSet(object.limited) ? Number(object.limited) : 0,
      rare: isSet(object.rare) ? Number(object.rare) : 0,
      superRare: isSet(object.super_rare) ? Number(object.super_rare) : 0,
      unique: isSet(object.unique) ? Number(object.unique) : 0,
      ethAmount: isSet(object.eth_amount) ? Number(object.eth_amount) : 0,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      secondary: isSet(object.secondary) ? Boolean(object.secondary) : false,
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickBundledBuy): unknown {
    const obj: any = {};
    message.offerId !== undefined && (obj.offer_id = message.offerId);
    if (message.cardSlugs) {
      obj.card_slugs = message.cardSlugs.map(e => e);
    } else {
      obj.card_slugs = [];
    }
    message.cardCount !== undefined &&
      (obj.card_count = Math.round(message.cardCount));
    message.limited !== undefined &&
      (obj.limited = Math.round(message.limited));
    message.rare !== undefined && (obj.rare = Math.round(message.rare));
    message.superRare !== undefined &&
      (obj.super_rare = Math.round(message.superRare));
    message.unique !== undefined && (obj.unique = Math.round(message.unique));
    message.ethAmount !== undefined &&
      (obj.eth_amount = Math.round(message.ethAmount));
    message.eurAmount !== undefined &&
      (obj.eur_amount = Math.round(message.eurAmount));
    message.secondary !== undefined && (obj.secondary = message.secondary);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseStartOnboardingVersion2(): StartOnboardingVersion2 {
  return {};
}

export const StartOnboardingVersion2 = {
  fromJSON(_: any): StartOnboardingVersion2 {
    return {};
  },

  toJSON(_: StartOnboardingVersion2): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickOnboardingNext(): ClickOnboardingNext {
  return { interactionContext: '', to: '' };
}

export const ClickOnboardingNext = {
  fromJSON(object: any): ClickOnboardingNext {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      to: isSet(object.to) ? String(object.to) : '',
    };
  },

  toJSON(message: ClickOnboardingNext): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.to !== undefined && (obj.to = message.to);
    return obj;
  },
};

function createBaseClickOnboardingInfo(): ClickOnboardingInfo {
  return { interactionContext: '' };
}

export const ClickOnboardingInfo = {
  fromJSON(object: any): ClickOnboardingInfo {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
    };
  },

  toJSON(message: ClickOnboardingInfo): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    return obj;
  },
};

function createBaseChooseOnboardingCompetition(): ChooseOnboardingCompetition {
  return { interactionContext: '', competition: '' };
}

export const ChooseOnboardingCompetition = {
  fromJSON(object: any): ChooseOnboardingCompetition {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      competition: isSet(object.competition) ? String(object.competition) : '',
    };
  },

  toJSON(message: ChooseOnboardingCompetition): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.competition !== undefined &&
      (obj.competition = message.competition);
    return obj;
  },
};

function createBaseClickEuropeanChampionshipPlayPageBlock(): ClickEuropeanChampionshipPlayPageBlock {
  return {};
}

export const ClickEuropeanChampionshipPlayPageBlock = {
  fromJSON(_: any): ClickEuropeanChampionshipPlayPageBlock {
    return {};
  },

  toJSON(_: ClickEuropeanChampionshipPlayPageBlock): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickJoinRivalsLeague(): ClickJoinRivalsLeague {
  return {};
}

export const ClickJoinRivalsLeague = {
  fromJSON(_: any): ClickJoinRivalsLeague {
    return {};
  },

  toJSON(_: ClickJoinRivalsLeague): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickJoinRivalsLeagueInvite(): ClickJoinRivalsLeagueInvite {
  return { userSlug: '', code: '' };
}

export const ClickJoinRivalsLeagueInvite = {
  fromJSON(object: any): ClickJoinRivalsLeagueInvite {
    return {
      userSlug: isSet(object.user_slug) ? String(object.user_slug) : '',
      code: isSet(object.code) ? String(object.code) : '',
    };
  },

  toJSON(message: ClickJoinRivalsLeagueInvite): unknown {
    const obj: any = {};
    message.userSlug !== undefined && (obj.user_slug = message.userSlug);
    message.code !== undefined && (obj.code = message.code);
    return obj;
  },
};

function createBaseClickRivalsLeaguesActivity(): ClickRivalsLeaguesActivity {
  return { cta: 0, leagueSlug: '', gameSlug: undefined };
}

export const ClickRivalsLeaguesActivity = {
  fromJSON(object: any): ClickRivalsLeaguesActivity {
    return {
      cta: isSet(object.cta)
        ? clickRivalsLeaguesActivity_CtaFromJSON(object.cta)
        : 0,
      leagueSlug: isSet(object.league_slug) ? String(object.league_slug) : '',
      gameSlug: isSet(object.game_slug) ? String(object.game_slug) : undefined,
    };
  },

  toJSON(message: ClickRivalsLeaguesActivity): unknown {
    const obj: any = {};
    message.cta !== undefined &&
      (obj.cta = clickRivalsLeaguesActivity_CtaToJSON(message.cta));
    message.leagueSlug !== undefined && (obj.league_slug = message.leagueSlug);
    message.gameSlug !== undefined && (obj.game_slug = message.gameSlug);
    return obj;
  },
};

function createBaseClickSwitchToRivals(): ClickSwitchToRivals {
  return {
    interactionContext: '',
    sport: 0,
    so5LeaderboardSlug: '',
    so5LeaderboardRarity: '',
    so5LeaderboardSeasonality: '',
    so5LeagueName: '',
    so5FixtureSlug: '',
  };
}

export const ClickSwitchToRivals = {
  fromJSON(object: any): ClickSwitchToRivals {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      so5LeaderboardSlug: isSet(object.so5_leaderboard_slug)
        ? String(object.so5_leaderboard_slug)
        : '',
      so5LeaderboardRarity: isSet(object.so5_leaderboard_rarity)
        ? String(object.so5_leaderboard_rarity)
        : '',
      so5LeaderboardSeasonality: isSet(object.so5_leaderboard_seasonality)
        ? String(object.so5_leaderboard_seasonality)
        : '',
      so5LeagueName: isSet(object.so5_league_name)
        ? String(object.so5_league_name)
        : '',
      so5FixtureSlug: isSet(object.so5_fixture_slug)
        ? String(object.so5_fixture_slug)
        : '',
    };
  },

  toJSON(message: ClickSwitchToRivals): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.so5LeaderboardSlug !== undefined &&
      (obj.so5_leaderboard_slug = message.so5LeaderboardSlug);
    message.so5LeaderboardRarity !== undefined &&
      (obj.so5_leaderboard_rarity = message.so5LeaderboardRarity);
    message.so5LeaderboardSeasonality !== undefined &&
      (obj.so5_leaderboard_seasonality = message.so5LeaderboardSeasonality);
    message.so5LeagueName !== undefined &&
      (obj.so5_league_name = message.so5LeagueName);
    message.so5FixtureSlug !== undefined &&
      (obj.so5_fixture_slug = message.so5FixtureSlug);
    return obj;
  },
};

function createBaseClickSetLineup(): ClickSetLineup {
  return {
    so5LeaderboardSlug: '',
    trigger: '',
    interactionContext: '',
    sport: 0,
  };
}

export const ClickSetLineup = {
  fromJSON(object: any): ClickSetLineup {
    return {
      so5LeaderboardSlug: isSet(object.so5_leaderboard_slug)
        ? String(object.so5_leaderboard_slug)
        : '',
      trigger: isSet(object.trigger) ? String(object.trigger) : '',
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickSetLineup): unknown {
    const obj: any = {};
    message.so5LeaderboardSlug !== undefined &&
      (obj.so5_leaderboard_slug = message.so5LeaderboardSlug);
    message.trigger !== undefined && (obj.trigger = message.trigger);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseView3dNotSupported(): View3dNotSupported {
  return { interactionContext: '' };
}

export const View3dNotSupported = {
  fromJSON(object: any): View3dNotSupported {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
    };
  },

  toJSON(message: View3dNotSupported): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    return obj;
  },
};

function createBaseOpenFixtureOverview(): OpenFixtureOverview {
  return {
    so5FixtureSlug: '',
    so5FixtureState: '',
    interactionContext: '',
    sport: 0,
  };
}

export const OpenFixtureOverview = {
  fromJSON(object: any): OpenFixtureOverview {
    return {
      so5FixtureSlug: isSet(object.so5_fixture_slug)
        ? String(object.so5_fixture_slug)
        : '',
      so5FixtureState: isSet(object.so5_fixture_state)
        ? String(object.so5_fixture_state)
        : '',
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: OpenFixtureOverview): unknown {
    const obj: any = {};
    message.so5FixtureSlug !== undefined &&
      (obj.so5_fixture_slug = message.so5FixtureSlug);
    message.so5FixtureState !== undefined &&
      (obj.so5_fixture_state = message.so5FixtureState);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
