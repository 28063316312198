import { Sport } from '__generated__/globalTypes';

import {
  THRESHOLD_COLORS as FOOTBALL_THRESHOLD_COLORS,
  ScoreType,
  findThreshold as findFootballThreshold,
} from './footballThresholds';
import {
  THRESHOLD_COLORS as US_THRESHOLD_COLORS,
  findThreshold as findUsThreshold,
} from './usSportsThresholds';

const usSports = [Sport.NBA, Sport.BASEBALL];

export const findThreshold = (
  score: number,
  sport: Sport = Sport.FOOTBALL,
  type?: ScoreType
) => {
  const flooredScore = Math.floor(score);
  if (usSports.includes(sport)) {
    return findUsThreshold(flooredScore);
  }
  return findFootballThreshold(flooredScore, type);
};

export const findThresholdColor = (
  score: number,
  sport: Sport,
  type?: ScoreType
) => {
  const threshold = findThreshold(score, sport, type);
  if (usSports.includes(sport)) return US_THRESHOLD_COLORS[threshold];
  return FOOTBALL_THRESHOLD_COLORS[threshold];
};
