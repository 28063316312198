import { TypedDocumentNode, gql } from '@apollo/client';
import { faChevronLeft, faTimes } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { ComponentProps } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { ScoreStatus, Sport } from '@sorare/core/src/__generated__/globalTypes';
import defaultShield from '@sorare/core/src/assets/club/shield_none.png';
import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { Drawer } from '@sorare/core/src/atoms/layout/Drawer';
import { FullWidthContainer } from '@sorare/core/src/atoms/layout/FullWidthContainer';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { Scrollable } from '@sorare/core/src/atoms/navigation/Scrollable';
import { HeadlineM, LabelL, LabelS } from '@sorare/core/src/atoms/typography';
import { PlayerGameScoreScore } from '@sorare/core/src/components/scoring/PlayerGameScoreScore';
import { ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_COMPETITION_CONTENDER_CONTENDER } from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { isType } from '@sorare/core/src/gql';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { fantasy } from '@sorare/core/src/lib/glossary';
import { teamPictureUrl } from '@sorare/core/src/lib/so5';
import { findThresholdColor } from '@sorare/core/src/lib/thresholds';
import {
  mediumDesktopAndAbove,
  tabletAndAbove,
} from '@sorare/core/src/style/mediaQuery';

import { CompactContender } from 'components/contender/CompactContender';
import { PlayerProperties } from 'components/player/PlayerDetails/PlayerProperties';
import { BriefGame } from 'components/so5/BriefGame';
import { isGameStarted } from 'lib/so5';

import { LazyFixtureChart } from '../LastScores/LazyFixtureChart';
import { PlayerGameScoreStats } from '../PlayerGameScoreStats';
import {
  PlayerGameScoreDrawerQuery,
  PlayerGameScoreDrawerQueryVariables,
} from './__generated__/index.graphql';

const PLAYER_GAME_SCORE_DRAWER_QUERY = gql`
  query PlayerGameScoreDrawerQuery(
    $id: ID!
    $so5FixtureSlug: String!
    $withFixtureLineups: Boolean!
  ) {
    so5 {
      playerGameScore(id: $id) {
        id
        score
        scoreStatus
        ... on PlayerGameScore {
          id
          allAroundStats {
            totalScore
          }
          decisiveScore {
            totalScore
          }
        }
        anyPlayer {
          slug
          mySo5LeaderboardContendersForFixture(so5FixtureSlug: $so5FixtureSlug)
            @include(if: $withFixtureLineups) {
            slug
            so5Leaderboard {
              slug
              so5Fixture {
                slug
                type
              }
              so5LeaderboardGroup {
                slug
              }
            }
            ...CompactContender_so5LeaderboardContender
          }
          ...PlayerProperties_anyPlayer
        }
        anyPlayerGameStats {
          id
          anyTeam {
            slug
            ...BriefGame_teamInterface
          }
        }
        anyGame {
          id
          homeTeam {
            slug
            code
            name
            ...teamPictureUrl_teamInterface
          }
          awayTeam {
            slug
            code
            name
            ...teamPictureUrl_teamInterface
          }
          ...BriefGame_anyGameInterface
        }
        ...PlayerGameScoreStats_playerGameScoreInterface
        ...PlayerGameScoreScore_playerGameScoreInterface
      }
    }
  }
  ${PlayerProperties.fragments.anyPlayer}
  ${BriefGame.fragments.anyGameInterface}
  ${BriefGame.fragments.teamInterface}
  ${PlayerGameScoreStats.fragments.playerGameScoreInterface}
  ${PlayerGameScoreScore.fragments.playerGameScoreInterface}
  ${CompactContender.fragments.so5LeaderboardContender}
  ${teamPictureUrl.fragments.teamInterface}
` as TypedDocumentNode<
  PlayerGameScoreDrawerQuery,
  PlayerGameScoreDrawerQueryVariables
>;

const StyledDrawer = styled(Drawer)`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: var(--c-black);

  @media ${tabletAndAbove} {
    width: 420px;
    border-left: 1px solid var(--c-nd-150);

    &.left {
      border-left: none;
      border-right: 1px solid var(--c-nd-150);
    }
    &.COMPOSE {
      @media ${mediumDesktopAndAbove} {
        left: 420px;
      }
    }
  }
`;
const Header = styled(SBHorizontal)`
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--c-black);
  padding: var(--unit) var(--unit) var(--unit) var(--double-unit);
  border-bottom: 1px solid var(--c-nd-150);

  &.COMPOSE {
    padding: var(--double-unit) var(--unit) var(--double-unit)
      var(--double-unit);
  }

  > button:only-child {
    margin-left: auto;
  }
`;
const DrawerContent = styled(FullWidthContainer)`
  flex-grow: 1;
  overflow-x: clip;
  padding-top: var(--container-padding);
  padding-bottom: var(--container-padding);
`;
const Content = styled(Vertical)`
  > *:not(:last-child) {
    padding-bottom: var(--triple-unit);
    border-bottom: 1px solid var(--c-nd-150);
  }
`;
const StyledImg = styled.img`
  object-fit: contain;
`;

type Props = {
  open: boolean;
  onClose: () => void;
  playerGameScoreId?: string;
  so5FixtureSlug?: string;
  variant?: 'CLASSIC' | 'COMPOSE';
  side?: 'left' | 'right';
} & Pick<ComponentProps<typeof Drawer>, 'animated'>;

export const PlayerGameScoreDrawer = ({
  open,
  onClose,
  playerGameScoreId,
  so5FixtureSlug = '',
  variant = 'CLASSIC',
  side,
  animated,
}: Props) => {
  const { generateSportContextPath } = useSportContext();
  const { data, loading } = useQuery(PLAYER_GAME_SCORE_DRAWER_QUERY, {
    variables: {
      id: idFromObject(playerGameScoreId) || '',
      so5FixtureSlug,
      withFixtureLineups: !!so5FixtureSlug,
    },
    skip: !open || !playerGameScoreId,
  });

  const { playerGameScore } = data?.so5 || {};
  const { mySo5LeaderboardContendersForFixture = [] } =
    playerGameScore?.anyPlayer || {};

  const otherTeam =
    playerGameScore?.anyGame.homeTeam?.slug ===
    playerGameScore?.anyPlayerGameStats.anyTeam.slug
      ? playerGameScore?.anyGame.awayTeam
      : playerGameScore?.anyGame.homeTeam;
  const variantSideOrSide = variant === 'COMPOSE' ? 'left' : side;

  return (
    <StyledDrawer
      animated={animated}
      open={open}
      side={variantSideOrSide}
      className={classNames(variant, variantSideOrSide)}
    >
      <Header className={classNames(variant)}>
        {variant === 'COMPOSE' && otherTeam && (
          <Horizontal>
            <PlayerGameScoreScore playerGameScore={playerGameScore} />
            <LabelL bold>
              <FormattedMessage
                id="PlayerGameScoreDrawer.vsTeam"
                defaultMessage="vs {team}"
                values={{ team: otherTeam.code || '' }}
              />
            </LabelL>
            <StyledImg
              src={teamPictureUrl(otherTeam) || defaultShield}
              alt={otherTeam.name}
              width={24}
              height={24}
            />
          </Horizontal>
        )}
        <IconButton
          color="transparent"
          icon={variant === 'COMPOSE' ? faChevronLeft : faTimes}
          onClick={onClose}
        />
      </Header>
      <DrawerContent>
        {loading && <LoadingIndicator />}
        {playerGameScore && (
          <Content gap={3}>
            {variant !== 'COMPOSE' && (
              <Vertical gap={2}>
                <PlayerProperties player={playerGameScore.anyPlayer} />
                {playerGameScore.scoreStatus !== ScoreStatus.DID_NOT_PLAY &&
                  isType(playerGameScore, 'PlayerGameScore') &&
                  playerGameScore.decisiveScore !== null && (
                    <SBHorizontal>
                      <Vertical gap={0.5}>
                        <LabelS bold color="var(--c-nd-600)">
                          <FormattedMessage {...fantasy.decisiveScore} />
                        </LabelS>
                        <HeadlineM>
                          <FormattedNumber
                            value={playerGameScore.decisiveScore.totalScore}
                          />
                        </HeadlineM>
                      </Vertical>
                      <Vertical gap={0.5}>
                        <LabelS bold color="var(--c-nd-600)">
                          <FormattedMessage {...fantasy.allAroundScore} />
                        </LabelS>
                        <HeadlineM>
                          <FormattedNumber
                            value={playerGameScore.allAroundStats.reduce(
                              (acc, value) => acc + value.totalScore,
                              0
                            )}
                          />
                        </HeadlineM>
                      </Vertical>
                      <Vertical gap={0.5}>
                        <LabelS bold color="var(--c-nd-600)">
                          <FormattedMessage {...fantasy.total} />
                        </LabelS>
                        <HeadlineM
                          color={findThresholdColor(
                            playerGameScore.score,
                            Sport.FOOTBALL,
                            'PLAYER_SCORE'
                          )}
                        >
                          <FormattedNumber
                            value={Math.ceil(playerGameScore.score)}
                          />
                        </HeadlineM>
                      </Vertical>
                    </SBHorizontal>
                  )}
              </Vertical>
            )}
            {mySo5LeaderboardContendersForFixture.length > 0 && (
              <Vertical gap={2}>
                <LabelL bold>
                  <FormattedMessage
                    id="PlayerGameScoreDrawer.alignedIn"
                    defaultMessage="Aligned In"
                  />
                </LabelL>
                <Scrollable itemToDisplay={1}>
                  {mySo5LeaderboardContendersForFixture.map(
                    so5LeaderboardContender => (
                      <CompactContender
                        key={so5LeaderboardContender.slug}
                        so5LeaderboardContender={so5LeaderboardContender}
                        to={generateSportContextPath(
                          ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_COMPETITION_CONTENDER_CONTENDER,
                          {
                            params: {
                              eventType:
                                so5LeaderboardContender.so5Leaderboard.so5Fixture.type.toLowerCase(),
                              fixture:
                                so5LeaderboardContender.so5Leaderboard
                                  .so5Fixture.slug,
                              competition:
                                so5LeaderboardContender.so5Leaderboard
                                  ?.so5LeaderboardGroup.slug || '',
                              contender: so5LeaderboardContender.slug,
                            },
                          }
                        )}
                      />
                    )
                  )}
                </Scrollable>
              </Vertical>
            )}
            <div>
              <BriefGame
                game={playerGameScore.anyGame}
                team={playerGameScore.anyPlayerGameStats.anyTeam}
              />
            </div>
            {playerGameScore.scoreStatus !== ScoreStatus.DID_NOT_PLAY &&
              isGameStarted(playerGameScore.anyGame.status) && (
                <PlayerGameScoreStats
                  key={playerGameScore.id}
                  playerGameScores={[playerGameScore]}
                />
              )}
            {variant !== 'COMPOSE' && (
              <LazyFixtureChart
                playerSlug={playerGameScore.anyPlayer.slug}
                disableScoreSelect
              />
            )}
          </Content>
        )}
      </DrawerContent>
    </StyledDrawer>
  );
};
