import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Skeleton } from '@sorare/core/src/atoms/loader/Skeleton';
import { BodyS } from '@sorare/core/src/atoms/typography';
import { Chip } from '@sorare/core/src/atoms/ui/Chip';

import { Cards } from 'assets/icons/Cards';

const Card = styled(Vertical).attrs({ center: true })`
  position: relative;
  isolation: isolate;
  aspect-ratio: var(--card-aspect-ratio);
  overflow: hidden;
  border: 1px solid rgba(var(--c-rgb-white), 0.17);
  border-radius: var(--unit);
  color: rgba(var(--c-rgb-white), 0.6);
  background: var(--c-nd-100);

  &.highlighted {
    color: var(--c-white);
    background: var(--c-nl-500);
  }

  &::after {
    position: absolute;
    content: '';
    inset: 0;
    background-image: radial-gradient(
      154.46% 96.82% at 50% 3.18%,
      transparent 70%,
      var(--c-nd-200) 100%
    );
    opacity: 0;
  }

  &:hover {
    color: var(--c-white);
    &::after {
      opacity: 1;
    }
  }
`;

type Props = {
  icon: ReactNode;
  label: string;
  count?: number;
  loading: boolean;
  Footer?: FC<{ children?: ReactNode }>;
  highlighted?: boolean;
};

export const SimpleCardPlaceholder = ({
  icon,
  label,
  count,
  loading,
  Footer,
  highlighted,
}: Props) => {
  const hasCards = count === undefined || count > 0;

  return (
    <Vertical>
      <Skeleton loading={loading}>
        <Card
          className={classNames({
            loading,
            hasCards,
            highlighted,
          })}
        >
          {icon}
          <BodyS as="p" bold>
            {label}
          </BodyS>
        </Card>
      </Skeleton>
      {Footer && (
        <Footer>
          {!loading && typeof count === 'number' && (
            <Chip color={hasCards ? 'white' : 'transparent'} size="medium">
              <Horizontal as="span" gap={0.5}>
                <Cards size={1.5} />
                <BodyS as="p" bold>
                  {count}
                </BodyS>
              </Horizontal>
            </Chip>
          )}
        </Footer>
      )}
    </Vertical>
  );
};
