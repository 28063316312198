import { TypedDocumentNode, gql } from '@apollo/client';
import { faMemo } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  Rarity,
  SeasonEligibility,
} from '@sorare/core/src/__generated__/globalTypes';
import { Skeleton } from '@sorare/core/src/atoms/animations/Skeleton';
import {
  ButtonBase,
  ButtonProps,
} from '@sorare/core/src/atoms/buttons/ButtonBase';
import { Dropdown } from '@sorare/core/src/atoms/dropdowns/Dropdown';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { AmountWithConversion } from '@sorare/core/src/components/buyActions/AmountWithConversion';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { range } from '@sorare/core/src/lib/arrays';
import { scarcityMessages } from '@sorare/core/src/lib/scarcity';

import useGetPriceHistory from '../../../hooks/useGetPriceHistory';
import PriceHistoryDate from '../PriceHistoryDate';
import { TransactionLabel } from '../TransactionLabel';
import { PriceHistoryTooltip_anyCard } from './__generated__/index.graphql';

const PriceHistoryList = styled(Vertical).attrs({ center: true })`
  overflow: auto;
  flex-wrap: wrap;
  justify-content: stretch;
`;

const Row = styled(SBHorizontal).attrs({ gap: 2 })`
  min-width: 120px;
  width: 100%;
`;

const messages = defineMessages({
  title: {
    id: 'PriceHistoryTooltip.title',
    defaultMessage: 'Last {rarity} sales',
  },
});

export const Title = styled(Horizontal).attrs({ gap: 0 })`
  width: 100%;
  justify-content: flex-start;
`;

const TitlePart = styled(LabelM)`
  text-align: left;
  font-weight: bold;
`;

export const TooltipTitle = styled(TitlePart)`
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const TextGrey = styled.span`
  color: var(--c-nd-600);
`;

const PriceHistoryValueContainer = styled(LabelM)<{ rarity: Rarity }>`
  font-weight: bold;
  color: var(--c-white);
  line-height: 19px;
`;

const TextSkeleton = styled(Skeleton)`
  height: 14px;
  width: 40px;
  background-color: #3a3a3a;
  background-image: linear-gradient(90deg, #212020, #3a3a3a, #212020);
`;

interface PriceHistoryProps {
  card: PriceHistoryTooltip_anyCard;
  displayTransactionType?: boolean;
}

const Loader = () => {
  return (
    <>
      {range(5).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Row key={i}>
          <TextGrey>
            <TextSkeleton />
          </TextGrey>
          <TextGrey>
            <TextSkeleton />
          </TextGrey>
        </Row>
      ))}
    </>
  );
};

const PriceHistoryTooltipContent = ({
  card,
  displayTransactionType,
  ...rest
}: PriceHistoryProps) => {
  const { formatMessage } = useIntl();
  const { up: isTablet } = useScreenSize('tablet');
  const { rarityTyped, anyPlayer, inSeasonEligible } = card;
  const { data, loading } = useGetPriceHistory({
    rarity: rarityTyped,
    playerSlug: anyPlayer.slug,
    seasonEligibility: inSeasonEligible
      ? SeasonEligibility.IN_SEASON
      : SeasonEligibility.CLASSIC,
  });

  if (data && data?.tokens.tokenPrices.length === 0) {
    return (
      <PriceHistoryList {...rest}>
        <FormattedMessage
          id="PriceHistoryTooltipContent.noRecentSales"
          defaultMessage="No recent sales"
        />
      </PriceHistoryList>
    );
  }

  return (
    <PriceHistoryList {...rest}>
      <Title>
        <TooltipTitle>
          <FormattedMessage
            {...messages.title}
            values={{
              rarity: formatMessage(scarcityMessages[rarityTyped]),
            }}
          />
        </TooltipTitle>
        {!isTablet && <TitlePart>&nbsp;- {anyPlayer.displayName}</TitlePart>}
      </Title>
      {loading || !data?.tokens ? (
        <Loader />
      ) : (
        data.tokens.tokenPrices.map(priceHistorySample => (
          <Row key={priceHistorySample.date.toISOString()}>
            <Vertical gap={0}>
              <TextGrey>
                <PriceHistoryDate date={priceHistorySample.date} />
                {displayTransactionType && (
                  <>
                    {' '}
                    - <TransactionLabel deal={priceHistorySample.deal} />
                  </>
                )}
              </TextGrey>
            </Vertical>
            <PriceHistoryValueContainer as="span" rarity={rarityTyped}>
              <AmountWithConversion
                monetaryAmount={priceHistorySample.amounts}
                column
                hideExponent
              />
            </PriceHistoryValueContainer>
          </Row>
        ))
      )}
    </PriceHistoryList>
  );
};

const ToolipContainer = styled(ButtonBase).attrs({
  disableDebounce: true,
})`
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: var(--half-unit);
  height: var(--quadruple-unit);

  svg {
    color: var(--c-white);
  }
`;

const StyledPriceHistoryList = styled(PriceHistoryTooltipContent)`
  padding: var(--triple-unit);
  color: var(--c-white);
  & ${PriceHistoryValueContainer} {
    color: var(--c-white);
  }
  & ${TextGrey} {
    color: var(--c-nd-600);
    font-size: 12px;
  }
`;

const DefaultLabel = (
  props?: Pick<ButtonProps, 'onClick' | 'onMouseEnter' | 'onMouseLeave'>
) => {
  return (
    <ToolipContainer {...props}>
      <FontAwesomeIcon icon={faMemo} size="lg" />
    </ToolipContainer>
  );
};

const PriceHistoryTooltip = ({
  card,
  Label,
  displayTransactionType,
  ...rest
}: {
  card: PriceHistoryTooltip_anyCard;
  displayTransactionType?: boolean;
  Label?: (
    props?: Pick<ButtonProps, 'onClick' | 'onMouseEnter' | 'onMouseLeave'>
  ) => React.JSX.Element;
}) => {
  const { up: isTablet } = useScreenSize('tablet');
  const [dropdownOpened, setDropdownOpened] = useState(false);
  if (isTablet)
    return (
      <Tooltip
        placement="top"
        onClick={e => e.preventDefault()}
        title={
          <PriceHistoryTooltipContent
            displayTransactionType={displayTransactionType}
            card={card}
          />
        }
        {...rest}
      >
        {Label ? <Label /> : <DefaultLabel />}
      </Tooltip>
    );
  return (
    <Dropdown
      onChange={() => {}}
      onOpen={() => {
        setDropdownOpened(true);
      }}
      label={Label || DefaultLabel}
    >
      {dropdownOpened ? (
        <StyledPriceHistoryList
          card={card}
          displayTransactionType={displayTransactionType}
        />
      ) : (
        <div />
      )}
    </Dropdown>
  );
};

PriceHistoryTooltip.fragments = {
  anyCard: gql`
    fragment PriceHistoryTooltip_anyCard on AnyCardInterface {
      slug
      collection
      rarityTyped
      inSeasonEligible
      anyPlayer {
        slug
        displayName
      }
    }
  ` as TypedDocumentNode<PriceHistoryTooltip_anyCard>,
};

export default PriceHistoryTooltip;
