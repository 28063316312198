import type { TypedDocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';
import {
  faArrowRight,
  faCardsBlank,
  faCheck,
  faClose,
  faDollar,
  faUpRightFromSquare,
} from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import {
  BodyM,
  HeadlineXL,
  LabelM,
  LabelS,
} from '@sorare/core/src/atoms/typography';
import { Card } from '@sorare/core/src/components/card/Card';
import Dialog from '@sorare/core/src/components/dialog';
import { FOOTBALL_LOAN } from '@sorare/core/src/constants/__generated__/routes';
import { useIsMobileAndAbove } from '@sorare/core/src/hooks/device/useIsMobileAndAbove';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';

import type {
  LoanWelcomeScreenQuery,
  LoanWelcomeScreenQueryVariables,
} from './__generated__/index.graphql';
import bg from './assets/bg.jpg';
import loanIcon from './assets/loanIcon.svg';

const withLoan = <T extends object>(card: T) => {
  return { ...card, ...{ loan: { gwLeft: 3 } } };
};

const Content = styled.div`
  padding: var(--double-unit);
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px 1fr;
  gap: var(--unit);
  place-items: center;
  text-align: center;
`;

const BrandM = styled(HeadlineXL).attrs({ brand: true })`
  font-family: var(--rigid-square);
`;

const CardRow = styled(Horizontal).attrs({ gap: 2 })`
  width: 100%;
  grid-column: 1 / 4;
`;

const Block = styled(Vertical)`
  width: 100%;
  background-color: var(--c-nd-50);
  padding: var(--unit);
  border-radius: var(--intermediate-unit);
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--unit);
  padding: var(--unit) var(--intermediate-unit);
`;

const ListItem = styled(BodyM).attrs({ as: 'li' })`
  display: flex;
  align-items: center;
  gap: var(--unit);

  & > svg {
    width: 30px;
  }
`;

const Rules = styled(Block)`
  padding: var(--double-unit);
`;

const StyledDialog = styled(Dialog)`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${bg});
`;

const Header = styled.div`
  padding: var(--double-unit);
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  place-items: center;
`;

const LOAN_WELCOME_SCREEN_QUERY = gql`
  query LoanWelcomeScreenQuery {
    currentUser {
      slug
      loanableConfig {
        loanableRosters {
          id
          primaryOffers {
            id
            anyCards {
              slug
              ...Card_anyCard
            }
          }
        }
      }
    }
  }
  ${Card.fragments.anyCard}
` as TypedDocumentNode<LoanWelcomeScreenQuery, LoanWelcomeScreenQueryVariables>;

const WelcomeScreen = () => {
  const isMobile = useIsMobileAndAbove();
  const {
    flags: { useLoanFlow },
  } = useFeatureFlags();
  const { data } = useQuery(LOAN_WELCOME_SCREEN_QUERY);

  const cards =
    data?.currentUser?.loanableConfig?.loanableRosters[0]?.primaryOffers.map(
      o => o.anyCards[0]
    );

  if (!useLoanFlow || !data?.currentUser || !cards || (cards || []).length < 1)
    return null;

  return (
    <StyledDialog open maxWidth="xs" fullScreen={!isMobile}>
      <Header>
        <IconButton color="quaternary" icon={faClose} />
        <Vertical center gap={0}>
          <LabelM bold>
            <FormattedMessage
              id="loan.WelcomeScreen.title"
              defaultMessage="Welcome gift"
            />
          </LabelM>
          <LabelM color="var(--c-nd-600-opaque)">
            {/* TODO PAY-316: handle variables with BE data */}
            <FormattedMessage
              id="loan.WelcomeScreen.header.expire"
              defaultMessage="Expire in 2 days"
            />
          </LabelM>
        </Vertical>
      </Header>
      <Content>
        <Vertical gap={3}>
          <Grid>
            <BrandM>
              <FormattedMessage
                id="loan.WelcomeScreen.buy1"
                defaultMessage="Buy 1 card"
              />
            </BrandM>
            <FontAwesomeIcon icon={faArrowRight} />
            <BrandM>
              <FormattedMessage
                id="loan.WelcomeScreen.get4"
                defaultMessage="Get 4 loans"
              />
            </BrandM>
            <CardRow>
              <Block>
                <Card card={cards[0]} />
              </Block>
              <Block>
                <CardsWrapper>
                  <Card card={withLoan(cards[1])} />
                  <Card card={withLoan(cards[2])} />
                  <Card card={withLoan(cards[3])} />
                  <Card card={withLoan(cards[4])} />
                </CardsWrapper>
                <Horizontal center>
                  <FontAwesomeIcon
                    color="var(--c-nd-600)"
                    icon={faCardsBlank}
                  />
                  <LabelS bold>
                    <FormattedMessage
                      id="loan.WelcomeScreen.bonusCard"
                      defaultMessage="+1 mystery loan"
                    />
                  </LabelS>
                </Horizontal>
              </Block>
            </CardRow>
          </Grid>
          <Vertical center>
            <Rules>
              <Vertical>
                <ListItem>
                  <FontAwesomeIcon icon={faCheck} />
                  <FormattedMessage
                    id="loan.WelcomeScreen.OwnTheCard"
                    defaultMessage="You own the card you buy"
                  />
                </ListItem>
                <ListItem>
                  <img src={loanIcon} width="30px" height="20px" alt="" />
                  <FormattedMessage
                    id="loan.WelcomeScreen.releaseAfter3"
                    defaultMessage="Loans finish after 3 played Game Weeks"
                  />
                </ListItem>
                <ListItem>
                  <FontAwesomeIcon icon={faDollar} />
                  <FormattedMessage
                    id="loan.WelcomeScreen.WinCash"
                    defaultMessage="Win cash, cards & more"
                  />
                </ListItem>
              </Vertical>
            </Rules>
            <Button fullWidth size="large" to={FOOTBALL_LOAN}>
              <FormattedMessage
                id="loan.WelcomeScreen.pickLineup"
                defaultMessage="Pick my lineup"
              />
            </Button>
            {/* TODO PAY-316: add link to T&C's */}
            <LabelM bold color="var(--c-nd-600-opaque)">
              <FontAwesomeIcon icon={faUpRightFromSquare} />
              {` `}
              T&amp;C&apos;s
            </LabelM>
          </Vertical>
        </Vertical>
      </Content>
    </StyledDialog>
  );
};

export default WelcomeScreen;
