/** Alternative implementation of https://github.com/MichalZalecki/storage-factory
 * This avoid blocking the main thread and sending a storage event every time we retrieve an item.
 * Instead, check the support once and move on.
 */
const storageFactory = (getStorage: () => Storage): Storage => {
  let inMemoryStorage: { [key: string]: string } = {};
  let isSupported = true;

  try {
    const testKey = '__some_random_key_you_are_not_going_to_use__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    isSupported = true;
  } catch {
    isSupported = false;
  }

  const clear = () => {
    if (isSupported) {
      getStorage().clear();
    } else {
      inMemoryStorage = {};
    }
  };

  const getItem = (name: string) => {
    if (isSupported) {
      return getStorage().getItem(name);
    }
    if (inMemoryStorage[name]) {
      return inMemoryStorage[name];
    }
    return null;
  };

  const key = (index: number) => {
    if (isSupported) {
      return getStorage().key(index);
    }
    return Object.keys(inMemoryStorage)[index] || null;
  };

  const removeItem = (name: string) => {
    if (isSupported) {
      getStorage().removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  };

  const setItem = (name: string, value: string) => {
    if (isSupported) {
      getStorage().setItem(name, value);
    } else {
      inMemoryStorage[name] = String(value); // not everyone uses TypeScript
    }
  };

  const length = () => {
    if (isSupported) {
      return getStorage().length;
    }
    return Object.keys(inMemoryStorage).length;
  };

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    get length() {
      return length();
    },
  };
};

export const local = storageFactory(() => localStorage);
export const session = storageFactory(() => sessionStorage);
