import classnames from 'classnames';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';

const Root = styled.section`
  color: var(--c-white);
  background:
    no-repeat 100% 100vh,
    var(--scarcity-bg, var(--c-gradient-common));
  &.common,
  &.money {
    --scarcity-bg: var(--c-gradient-common);
    --scarcity-rgb: var(--c-rgb-common);
    color: var(--c-black);
  }
  &.limited {
    --scarcity-bg: var(--c-gradient-limited);
    --scarcity-rgb: var(--c-rgb-limited);
    color: var(--c-white);

    &.BASEBALL {
      box-shadow: inset 1px 1px 2px #fed867;
      background: linear-gradient(336.6deg, #db7e1e -0.12%, #fbd962 99.62%);
    }
  }
  &.rare,
  &.rare_pro {
    --scarcity-bg: var(--c-gradient-rare);
    --scarcity-rgb: var(--c-rgb-rare);
    color: var(--c-white);
  }
  &.super_rare {
    --scarcity-bg: var(--c-gradient-superRare);
    --scarcity-rgb: var(--c-rgb-superRare);
    color: var(--c-white);
  }
  &.unique {
    --scarcity-bg: var(--c-gradient-unique);
    --scarcity-rgb: var(--c-rgb-unique);
    color: var(--c-white);
  }
  &.custom_series {
    --scarcity-bg: var(--c-gradient-customSeries);
    --scarcity-rgb: 233, 233, 233;
    color: var(--c-white);
  }
  &.mix {
    --scarcity-bg: var(--c-gradient-mix);
    --scarcity-rgb: var(--c-rgb-pink-600);
    color: var(--c-white);
  }
`;

type Props = {
  className?: string;
} & HTMLAttributes<HTMLElement>;

export const ScarcityBackground: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className = '',
  ...elementProps
}) => {
  return (
    <Root className={classnames(className)} {...elementProps}>
      {children}
    </Root>
  );
};
