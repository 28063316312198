import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { generateUseEvents } from '@sorare/core/src/lib/events/generateUseEvents';

import { TokenTransferValidatorProps } from 'components/token/TokenTransferValidator/types';

type MarketplaceEventTypes = {
  'Click List Card': {
    cardSlug: string;
    hasWarnings: boolean;
  };
  'Starter Bundles Payment successful': void;
  'Starter Bundles Dialog Open': {
    sport: Sport;
  };
  'Starter Bundles Click Use Your Cards': {
    sport: Sport;
  };
  'Toggle Transfer Consent Message': {
    value: boolean;
    transferContext: TokenTransferValidatorProps['transferContext'];
  };
};

export const useMarketplaceEvents = generateUseEvents<MarketplaceEventTypes>();

export const CELEBRATION_2024_BLOG_POST_URL =
  'https://sorare.com/football/blog/celebration-unlock-the-magic-of-footballs-defining-moments';
