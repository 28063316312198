import { ComponentProps } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled, { css } from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { Text16 } from 'atoms/typography';

type Variant = 'red' | 'blue' | 'yellow' | 'grey';

const backgroundColors = {
  red: 'rgba(var(--c-rgb-red-600), 0.25)',
  blue: 'rgba(var(--c-rgb-brand-600), 0.25)',
  yellow: 'rgba(240, 206, 29, 0.25)',
  grey: 'var(--c-nd-50)',
};

const titleColors = {
  red: 'var(--c-red-600)',
  blue: 'var(--c-brand-600)',
  yellow: 'var(--c-yellow-400)',
  grey: 'var(--c-nd-600)',
};

const Root = styled(Vertical)`
  padding: var(--double-unit) var(--triple-unit);
  justify-content: center;
  border-radius: var(--unit);
  width: 100%;
`;
const Title = styled(Text16)<{ variant: Variant }>`
  font-weight: var(--t-bold);

  color: ${({ variant }) => titleColors[variant]};
`;
const Message = styled(Text16)<{ variant: Variant }>`
  ${({ variant }) =>
    variant === 'yellow' &&
    css`
      color: var(--c-nd-800);
    `}
`;

type Props = {
  title?: MessageDescriptor;
  titleValues?: ComponentProps<typeof FormattedMessage>['values'];
  message?: MessageDescriptor;
  values?: ComponentProps<typeof FormattedMessage>['values'];
  children?: React.ReactNode;
  variant?: Variant;
  className?: string;
};

export const Warning = (props: Props) => {
  const {
    message,
    titleValues,
    title,
    values,
    children,
    variant = 'red',
    className,
  } = props;

  return (
    <Root
      className={className}
      style={{ backgroundColor: backgroundColors[variant] }}
    >
      {title && (
        <Title variant={variant}>
          <FormattedMessage {...title} values={titleValues} />
        </Title>
      )}
      {message && (
        <Message variant={variant}>
          <FormattedMessage {...message} values={values} />
        </Message>
      )}
      {children}
    </Root>
  );
};

export default Warning;
