import { CardCount, Rarity } from '__generated__/globalTypes';

type CardCounts = void | Omit<CardCount, 'total' | '__typename'>;

export const getBlockchainCardsCount = (cardCounts: CardCounts) => {
  if (!cardCounts) {
    return 0;
  }
  return (
    cardCounts.limited +
    cardCounts.rare +
    cardCounts.superRare +
    cardCounts.unique
  );
};

export const getRarities = (cardCounts: CardCounts) => {
  const blockchainCardsCount = getBlockchainCardsCount(cardCounts);

  return [
    cardCounts?.limited && Rarity.limited,
    cardCounts?.rare && Rarity.rare,
    cardCounts?.superRare && Rarity.super_rare,
    cardCounts?.unique && Rarity.unique,
    cardCounts?.customSeries && Rarity.custom_series,
    !blockchainCardsCount && cardCounts?.common && Rarity.common,
  ].filter(Boolean);
};
