import type { TypedDocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage, FormattedNumber, defineMessages } from 'react-intl';

import { SBHorizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { LabelS } from '@sorare/core/src/atoms/typography';
import { Chip } from '@sorare/core/src/atoms/ui/Chip';

import type { BonusBreakdownTooltip_powerBreakdown } from './__generated__/index.graphql';

const messages = defineMessages({
  captain: {
    id: 'CardBonus.captain',
    defaultMessage: 'Captain bonus',
  },
  levelBonus: {
    id: 'BonusChip.levelBonus',
    defaultMessage: 'XP bonus',
  },
  scarcity: {
    id: 'CardBonus.scarcity',
    defaultMessage: 'Scarcity bonus',
  },
  transferMalus: {
    id: 'CardBonus.transferMalus',
    defaultMessage: 'Non transferrable XP',
  },
  collection: {
    id: 'CardBonus.collection',
    defaultMessage: 'Collection bonus',
  },
  season: {
    id: 'BonusChip.seasonBonus',
    defaultMessage: 'Season bonus',
  },
  club: {
    id: 'BonusChip.clubBonus',
    defaultMessage: 'Club bonus',
  },
  nationality: {
    id: 'BonusChip.nationalityBonus',
    defaultMessage: 'Nationality bonus',
  },
  position: {
    id: 'BonusChip.positionBonus',
    defaultMessage: 'Position bonus',
  },
  specialEdition: {
    id: 'BonusChip.specialEdition',
    defaultMessage: 'Special Edition bonus',
  },
});

type Props = {
  powerBreakdown: BonusBreakdownTooltip_powerBreakdown;
  captainBonus?: number | null;
  transferMalus?: number | null;
  children: ReactNode;
};

export const BonusBreakdownTooltip = ({
  powerBreakdown,
  captainBonus,
  transferMalus,
  children,
}: Props) => {
  const normalizedBreakdownList = [
    {
      message: messages.captain,
      value: captainBonus || 0,
    },
    {
      message: messages.levelBonus,
      value: powerBreakdown.xpBasisPoints / 10000,
    },
    {
      message: messages.season,
      value: powerBreakdown.seasonBasisPoints / 10000,
    },
    {
      message: messages.scarcity,
      value: powerBreakdown.scarcityBasisPoints / 10000,
    },
    {
      message: messages.collection,
      value: transferMalus ? 0 : powerBreakdown.collectionBasisPoints / 10000,
    },
    {
      message: messages.club,
      value: powerBreakdown.activeClubsBasisPoints / 10000,
    },
    {
      message: messages.nationality,
      value: powerBreakdown.nationalityBasisPoints / 10000,
    },
    {
      message: messages.position,
      value: powerBreakdown.positionsBasisPoints / 10000,
    },
    {
      message: messages.specialEdition,
      value: powerBreakdown.specialEditionCardBasisPoints / 10000,
    },
    { message: messages.transferMalus, value: transferMalus || 0, malus: true },
  ].filter(Boolean);

  return (
    <Tooltip
      title={
        <Vertical>
          {normalizedBreakdownList
            .filter(({ value }) => value)
            .map(({ message, value, malus }, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <SBHorizontal key={i}>
                <LabelS bold color="var(--c-nd-500)">
                  <FormattedMessage {...message} />
                </LabelS>
                <Chip color={malus ? 'red' : 'green'}>
                  <FormattedNumber
                    value={value}
                    signDisplay="always"
                    style="percent"
                    maximumFractionDigits={1}
                  />
                </Chip>
              </SBHorizontal>
            ))}
        </Vertical>
      }
    >
      {children}
    </Tooltip>
  );
};

BonusBreakdownTooltip.fragments = {
  powerBreakdown: gql`
    fragment BonusBreakdownTooltip_powerBreakdown on PowerBreakdown {
      activeClubsBasisPoints
      captainBasisPoints
      collectionBasisPoints
      nationalityBasisPoints
      positionsBasisPoints
      scarcityBasisPoints
      seasonBasisPoints
      specialEditionCardBasisPoints
      xpBasisPoints
    }
  ` as TypedDocumentNode<BonusBreakdownTooltip_powerBreakdown>,
};
