import { TypedDocumentNode, gql } from '@apollo/client';

import { useQuery } from 'hooks/graphql/useQuery';

import {
  GetCurrentUserWithdrawal,
  GetCurrentUserWithdrawalVariables,
} from './__generated__/useBankWithdrawal.graphql';

const GET_CURRENT_USER_WITHDRAWAL_QUERY = gql`
  query GetCurrentUserWithdrawal {
    currentUser {
      slug
      availableBalance
      availableBalanceForWithdrawal
      bankBalance
      bankMappedEthereumAddress
      ethMigration {
        id
      }
    }
  }
` as TypedDocumentNode<
  GetCurrentUserWithdrawal,
  GetCurrentUserWithdrawalVariables
>;

export default () => {
  const { data, loading } = useQuery(GET_CURRENT_USER_WITHDRAWAL_QUERY);

  if (!data?.currentUser) return { loading };

  const { currentUser } = data;
  const {
    availableBalanceForWithdrawal,
    bankBalance,
    availableBalance,
    ethMigration,
    bankMappedEthereumAddress,
  } = currentUser;

  return {
    withdrawableAmount:
      availableBalanceForWithdrawal < bankBalance
        ? availableBalanceForWithdrawal
        : bankBalance,
    availableBalanceForWithdrawal,
    availableBalance,
    bankBalance,
    ethMigration,
    isFastWithdrawal: !!ethMigration || bankBalance === 0n,
    bankMappedEthereumAddress,
    loading,
  };
};
