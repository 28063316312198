import { BoundingBox } from './types';

export const drawProgressBar = async ({
  ctx,
  stepsFilled,
  boundingBox,
  options = {
    steps: 20,
    gap: 4,
    border: 4,
    color: '#fff',
  },
}: {
  ctx: CanvasRenderingContext2D;
  stepsFilled: number;
  boundingBox: BoundingBox;
  options?: {
    steps: number;
    gap: number;
    border: number;
    color: string;
  };
}) => {
  const WIDTH = ctx.canvas.width;
  const HEIGHT = ctx.canvas.height;

  const width = boundingBox.width * WIDTH;
  const height = boundingBox.height * HEIGHT;

  const x = boundingBox.left * WIDTH;
  const y = boundingBox.top * HEIGHT;

  const cellWidth =
    (width -
      2 * (options.border / 2 + options.gap) -
      (options.steps - 1) * options.gap) /
    options.steps;

  ctx.strokeStyle = options.color;
  ctx.lineWidth = options.border;
  ctx.strokeRect(x, y, width, height);

  ctx.fillStyle = options.color;
  const padding = options.gap + options.border / 2;
  const nbSteps = Math.max(0, Math.min(options.steps, stepsFilled));

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < nbSteps; i++) {
    ctx.fillRect(
      x + padding + (cellWidth + options.gap) * i,
      y + padding,
      cellWidth,
      height - padding * 2
    );
  }
};
