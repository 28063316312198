import { Navigate, generatePath, useParams } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL } from '@sorare/core/src/constants/__generated__/routes';

import { PageParams } from './__generated__/routeParams';

const LeaderboardPage = () => {
  const { leaderboard = '' } = useParams();
  const { eventType = '' } = useTypedParams<PageParams>();
  return (
    <Navigate
      to={generatePath(MLB_PLAY_EVENTTYPE_LEADERBOARD_LEADERBOARD_PRIZE_POOL, {
        eventType,
        leaderboard,
      })}
      replace
    />
  );
};

export default LeaderboardPage;
