import {
  SESSION_STORAGE,
  SessionStorageEntries,
  useSessionStorage,
} from '@sorare/core/src/hooks/useSessionStorage';

const useComposeTeamAutosave = (
  so5LeaderboardSlug: string,
  composeSessionId: string
) => {
  const [composeTeam, setComposeTeam] = useSessionStorage(
    SESSION_STORAGE.composeTeamSelection
  );
  const getSessionData = () => {
    if (!composeTeam || composeTeam.so5LeaderboardSlug !== so5LeaderboardSlug) {
      return {
        so5LeaderboardSlug,
        composeSessionId,
        benchObjectIds: [],
      };
    }
    return {
      ...composeTeam,
      benchObjectIds: [...(composeTeam?.benchObjectIds || [])],
    };
  };

  const addItemToAutosave = (position: number, benchObjectId: string) => {
    const newData = getSessionData();
    newData.benchObjectIds[position] = benchObjectId;
    setComposeTeam(newData);
  };
  const removeItemFromAutosave = (position: number) => {
    const newData = getSessionData();
    newData.benchObjectIds[position] = null;
    setComposeTeam(newData);
  };
  const addItemsToAutoSave = (
    items: { position: number; benchObjectId: string }[]
  ) => {
    const newData = getSessionData();
    items.forEach(({ position, benchObjectId }) => {
      newData.benchObjectIds[position] = benchObjectId;
    });

    setComposeTeam(newData);
  };

  const clearAutosave = () => {
    setComposeTeam(null);
  };

  return {
    addItemToAutosave,
    addItemsToAutoSave,
    removeItemFromAutosave,
    clearAutosave,
  };
};

const isValid = (
  composeTeamSelection: Nullable<
    SessionStorageEntries[SESSION_STORAGE.composeTeamSelection]
  >
) => {
  if (!composeTeamSelection) {
    return true;
  }
  if (
    !composeTeamSelection.so5LeaderboardSlug ||
    !composeTeamSelection.benchObjectIds.every(
      id =>
        !id ||
        id.startsWith('ComposeTeamBenchCard') ||
        id.startsWith('ComposeTeamBenchCommonPlayer')
    )
  ) {
    return false;
  }
  return true;
};
export const useGetComposeTeamAutosave = (
  so5LeaderboardSlug: Nullable<string>
) => {
  const [composeTeamSelection] = useSessionStorage(
    SESSION_STORAGE.composeTeamSelection
  );
  return (
    // allow for case when so5LeaderboardSlug is not provided, e.g. in onboarding flow
    (!so5LeaderboardSlug ||
      so5LeaderboardSlug === composeTeamSelection?.so5LeaderboardSlug) &&
      isValid(composeTeamSelection)
      ? composeTeamSelection
      : null
  );
};

export default useComposeTeamAutosave;
