import classnames from 'classnames';
import { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';

import { BodyM } from 'atoms/typography';

import { Radio } from '../Radio';

const Root = styled.div<{
  withSpacing: boolean;
  row: boolean;
  flexWrap: boolean;
}>`
  ${({ withSpacing }) =>
    withSpacing &&
    css`
      display: flex;
      flex-direction: column;
      gap: var(--unit);
    `}
  ${({ row }) =>
    row &&
    css`
      display: flex;
      flex-direction: row;
      flex: 1;
      gap: var(--radio-group-gap, var(--double-unit));
      justify-content: space-between;
      & > * {
        flex: 1;
        min-width: calc(var(--radio-width) - var(--radio-group-gap) / 2);
        text-align: center;
      }
    `}
  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: wrap;
      row-gap: var(--unit);
    `}
`;

const RadioWrapper = styled.div<{
  checked: boolean;
  rounded: boolean;
}>`
  background: var(--c-radio-bg, var(--c-nd-50));
  border-radius: ${({ rounded }) => (rounded ? 'var(--unit)' : 0)};
  color: var(--c-nd-400);
  /* Select inner radio to make the padding clickable */
  > * {
    padding: var(--radio-padding, var(--double-unit));
  }
  &:hover {
    &:not(.disabled) {
      background: var(--c-nd-100);
    }
  }
  &.checked {
    background: var(--c-nd-100);
    color: var(--c-nd-900);
  }
  &.ghost {
    color: var(--c-white);
    background-color: var(--c-black);
  }
  &.ghost.checked {
    color: var(--c-white);
    background-color: var(--c-brand-600);
  }

  &.border {
    border: 1px solid var(--c-nd-200);
    border-radius: var(--unit);
  }

  &.modal {
    background: var(--c-nd-50);
    border: 1px solid var(--c-nd-200);
    &.border {
      background: transparent;
    }
    &.checked {
      background: rgba(70, 98, 247, 0.16);
      border-color: var(--c-brand-600);
    }
  }
  &.modalSelect {
    background: var(--c-nd-100);
    &.border {
      background: transparent;
    }
    &.checked {
      background: var(--c-nd-200);
    }
    &:hover {
      background: var(--c-nd-200);
    }
    &.disabled {
      opacity: 0.5;
      background: var(--c-nd-100);
      &:hover {
        background: var(--c-nd-100);
      }
    }
  }
`;

type Props<T> = {
  options: {
    label: string | ReactNode;
    value: T;
    helper?: ReactNode;
    disabled?: boolean;
    optional?: ReactNode;
  }[];
  name: string;
  initiallySelectedValue?: T;
  rounded?: boolean;
  onChange: (value: T) => void;
  modal?: boolean;
  withSpacing?: boolean;
  modalSelect?: boolean;
  hideRadio?: boolean;
  border?: boolean;
  row?: boolean;
  ghost?: boolean;
  preventPreselection?: boolean;
  flexWrap?: boolean;
  allowUnselect?: boolean;
  clean?: () => void;
  center?: boolean;
};

type ManagedProps<T> = Props<T> & {
  value?: T;
};

export const RadioGroup = <T extends string>({
  options,
  initiallySelectedValue,
  name,
  rounded = false,
  modal = false,
  withSpacing = false,
  border = false,
  onChange,
  modalSelect = false,
  hideRadio,
  row = false,
  center,
  ghost,
  preventPreselection,
  flexWrap = false,
  clean,
  ...rest
}: ManagedProps<T> | Props<T>) => {
  const isManagedMode = 'value' in rest;
  const [selectedValue, setSelectedValue] = useState(
    !preventPreselection
      ? initiallySelectedValue || options[0].value
      : undefined
  );

  const value = isManagedMode ? rest.value : selectedValue;

  return (
    <Root withSpacing={withSpacing} row={row} flexWrap={flexWrap}>
      {options.map(option => {
        const checked = value === option.value;
        return (
          <RadioWrapper
            className={classnames({
              checked,
              modal,
              modalSelect,
              border,
              ghost,
              disabled: option?.disabled,
            })}
            key={option.value}
            checked={checked}
            rounded={rounded}
          >
            <Radio
              hideRadio={hideRadio}
              name={name}
              checked={checked}
              center={center}
              value={option.value}
              onChange={() => {
                if (!isManagedMode) setSelectedValue(option.value);
                onChange(option.value);
              }}
              onClick={() => {
                if (rest.allowUnselect && option.value === value) {
                  if (!isManagedMode) setSelectedValue(undefined);
                  if (option.value === value) clean?.();
                }
              }}
              labelContent={
                typeof option.label === 'string' ? (
                  <BodyM as="p" bold>
                    {option.label}
                  </BodyM>
                ) : (
                  option.label
                )
              }
              disabled={option.disabled}
            />
            {option.helper}
            {option.optional}
          </RadioWrapper>
        );
      })}
    </Root>
  );
};
