import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Skeleton as SkeletonDumb } from '@sorare/core/src/atoms/animations/Skeleton';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Caption, LabelM, Text16 } from '@sorare/core/src/atoms/typography';
import { Card } from '@sorare/core/src/components/card/Card';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { zeroMonetaryAmount } from '@sorare/core/src/hooks/useMonetaryAmount';
import { useStoreAmount } from '@sorare/core/src/hooks/useStoreAmount';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';
import { glossary } from '@sorare/core/src/lib/glossary';
import {
  ClickAfterPurchase_Action,
  ClickInstantBuy_Flow,
} from '@sorare/core/src/protos/events/so5/shared/events';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import PrimaryBuyBuyField from '../PrimaryBuyBuyField';
import {
  AvailablePrimaryOfferForAPlayerQuery,
  AvailablePrimaryOfferForAPlayerQueryVariables,
} from './__generated__/index.graphql';

const Wrapper = styled(Horizontal).attrs({ gap: 2 })`
  align-items: flex-start;
`;
const TokenContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  &.playerView {
    width: 110px;
  }
  &.manOfYourMatch {
    width: 70px;
    @media ${tabletAndAbove} {
      width: 90px;
    }
  }
`;
const Bottom = styled(Vertical)`
  flex-grow: 1;
  justify-content: flex-end;
  width: 100%;
`;
const Content = styled(Horizontal).attrs({ gap: 2 })`
  align-items: flex-start;
`;
const TokenBuy = styled(Vertical)`
  align-items: flex-start;
  height: 100%;
`;
const Skeleton = styled(SkeletonDumb)`
  height: 210px;
  width: 100%;
`;

export enum Variant {
  MAN_OF_YOUR_MATCH = 'manOfYourMatch',
  PLAYER_VIEW = 'playerView',
}

type Action = {
  onClick?: () => void;
  to?: string;
  label: ReactNode;
  action: ClickAfterPurchase_Action;
};

type PrimaryBuyActions = {
  primary?: Action;
  secondary?: Action;
};

type Props = {
  playerSlug: string;
  title: ReactNode;
  scoreLine?: ReactNode;
  description: ReactNode;
  className?: string;
  variant: Variant;
  primaryBuyActions: PrimaryBuyActions;
  origin: ClickInstantBuy_Flow;
  gameSlug?: string;
};

const AVAILABLE_PRIMARY_OFFER_FOR_A_PLAYER_QUERY = gql`
  query AvailablePrimaryOfferForAPlayerQuery(
    $playerSlugs: [String!]!
    $googlePlayStoreCountryCode: String
    $isAndroidApp: Boolean = false
    $isIosApp: Boolean = false
  ) {
    players(slugs: $playerSlugs) {
      slug
      anyCardWithLivePrimaryOffer(rarity: limited) {
        slug
        latestPrimaryOffer {
          id
          price {
            ...MonetaryAmountFragment_monetaryAmount
          }
          googlePrice(countryCode: $googlePlayStoreCountryCode)
            @include(if: $isAndroidApp)
          applePrice @include(if: $isIosApp) {
            ...MonetaryAmountFragment_monetaryAmount
          }
        }
        ...PrimaryBuyBuyField_anyCard
        ...Card_anyCard
      }
    }
  }
  ${PrimaryBuyBuyField.fragments.anyCard}
  ${Card.fragments.anyCard}
` as TypedDocumentNode<
  AvailablePrimaryOfferForAPlayerQuery,
  AvailablePrimaryOfferForAPlayerQueryVariables
>;

export const AvailablePrimaryBuyForAPlayer = ({
  playerSlug,
  title,
  scoreLine,
  description,
  className,
  variant,
  primaryBuyActions: primaryBuyActionsFromProps,
  origin,
  gameSlug,
}: Props) => {
  const track = useEvents();
  const { isAndroidApp, isIosApp, googlePlayStoreCountryCode } =
    useIsMobileApp();
  const { up: isTablet } = useScreenSize('tablet');

  const { data, loading } = useQuery(
    AVAILABLE_PRIMARY_OFFER_FOR_A_PLAYER_QUERY,
    {
      variables: {
        playerSlugs: [playerSlug],
        isAndroidApp,
        isIosApp,
        googlePlayStoreCountryCode,
      },
    }
  );

  const card = data?.players?.[0]?.anyCardWithLivePrimaryOffer;

  const { main, exponent } = useAmountWithConversion({
    monetaryAmount: card?.latestPrimaryOffer?.price || zeroMonetaryAmount,
  });
  const { storeMain, storeExponent } = useStoreAmount({
    monetaryAmount: card?.latestPrimaryOffer?.price || zeroMonetaryAmount,
    googlePrice: card?.latestPrimaryOffer?.googlePrice,
    applePrice: card?.latestPrimaryOffer?.applePrice,
  });

  if (loading) {
    return <Skeleton />;
  }

  if (!card?.latestPrimaryOffer?.price) {
    return null;
  }

  const { latestPrimaryOffer } = card;
  const { id } = latestPrimaryOffer;

  const trackBuyPrimaryBuyAction = (action: ClickAfterPurchase_Action) => {
    track('Click After Purchase', {
      offerId: id,
      cardSlug: card.slug,
      gameSlug,
      sport: card.sport,
      action,
    });
  };
  const { primary, secondary } = primaryBuyActionsFromProps;

  const primaryBuyActions = {
    ...(!!primary && {
      primary: {
        label: primary.label,
        onClick: () => {
          primary?.onClick?.();
          trackBuyPrimaryBuyAction(primary.action);
        },
        to: primary.to,
      },
    }),
    ...(secondary && {
      secondary: {
        label: secondary.label,
        onClick: () => {
          secondary?.onClick?.();
          trackBuyPrimaryBuyAction(secondary.action);
        },
        to: secondary.to,
      },
    }),
  };

  return (
    <Wrapper className={className}>
      {variant === Variant.PLAYER_VIEW && (
        <TokenContainer className={Variant.PLAYER_VIEW}>
          <Card card={card} width={320} />
        </TokenContainer>
      )}
      <TokenBuy>
        <Text16 bold>{title}</Text16>
        <Content gap={2}>
          {variant === Variant.MAN_OF_YOUR_MATCH && (
            <TokenContainer className={Variant.MAN_OF_YOUR_MATCH}>
              <Card card={card} width={320} />
            </TokenContainer>
          )}
          <Vertical gap={1}>
            {scoreLine && <LabelM color="var(--c-nd-600)">{scoreLine}</LabelM>}
            <LabelM color="var(--c-nd-600)">{description}</LabelM>
          </Vertical>
        </Content>
        <Bottom>
          <PrimaryBuyBuyField
            origin={origin}
            color="tertiary"
            size="medium"
            fullWidth={variant === Variant.MAN_OF_YOUR_MATCH}
            cta={
              <FormattedMessage
                {...glossary.primaryBuy}
                values={{
                  amount: (
                    <span>
                      {storeMain || main}
                      {(storeExponent || exponent) && isTablet ? (
                        <Caption as="span">
                          {' '}
                          ({storeExponent || exponent})
                        </Caption>
                      ) : (
                        ''
                      )}
                    </span>
                  ),
                }}
              />
            }
            card={card}
            primaryBuyConfirmationOptions={{
              actions: primaryBuyActions,
              variant: 'pro',
            }}
          />
        </Bottom>
      </TokenBuy>
    </Wrapper>
  );
};
