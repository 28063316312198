import { unitMapping } from 'lib/style';

type Props = {
  size?: keyof typeof unitMapping;
};

export const Gift = ({ size = 2 }: Props) => {
  return (
    <svg
      style={{
        width: unitMapping[size],
        height: unitMapping[size],
      }}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1.5V0H2.5C3.32843 0 4 0.671573 4 1.5C4 0.671573 4.67157 0 5.5 0H6.5V1.5H8V3H0V1.5H1.5Z"
        fill="currentColor"
      />
      <path d="M0.5 4H3.5V7.5H0.5V4Z" fill="currentColor" />
      <path d="M7.5 4H4.5V7.5H7.5V4Z" fill="currentColor" />
    </svg>
  );
};
