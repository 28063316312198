import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { isPast, subHours } from 'date-fns';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { DisplayM, LabelM } from '@sorare/core/src/atoms/typography';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { absCenter, absHCenter } from '@sorare/core/src/lib/style';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import MatchView from '@sorare/gameplay/src/components/MatchView';
import { PlayerGameScoreDrawer } from '@sorare/gameplay/src/components/stats/PlayerGameScoreDrawer';

import { RivalsCountdown } from 'components/rivals/RivalsCountdown';

import {
  RivalsLineupsQuery,
  RivalsLineupsQueryVariables,
} from './__generated__/index.graphql';

const Root = styled(Vertical)`
  position: relative;
  padding: var(--double-unit);

  &.displayCountdown {
    overflow: hidden;
  }
`;
const Background = styled.div`
  position: absolute;
  z-index: 1;
  inset: 0;
  background: rgba(var(--c-rgb-black), 0.6);
`;
const CountdownWrapper = styled(Vertical).attrs({ gap: 2, center: true })`
  ${absHCenter}
  top: 25vh;
  z-index: 2;

  @media ${tabletAndAbove} {
    ${absCenter}
  }
`;
const CountdownText = styled(Vertical).attrs({ gap: 2, center: true })`
  &.startingLineupsComingSoon {
    color: var(--c-nd-600);
  }
`;

const RIVALS_LINEUPS_QUERY = gql`
  query RivalsLineupsQuery($slug: String!) {
    football {
      rivals {
        id
        game(slug: $slug) {
          id
          slug
          formationKnown
          game {
            id
            date
            status: statusTyped
          }
        }
      }
    }
  }
` as TypedDocumentNode<RivalsLineupsQuery, RivalsLineupsQueryVariables>;

type Props = { rivalsGameSlug: string };

export const Lineups = ({ rivalsGameSlug }: Props) => {
  const { up: isLaptop } = useScreenSize('laptop');
  const [playerGameScoreId, setPlayerGameScoreId] = useState<string>();
  const { data, loading } = useQuery(RIVALS_LINEUPS_QUERY, {
    variables: { slug: rivalsGameSlug },
  });

  if (loading) {
    return <LoadingIndicator small />;
  }

  const { game: rivalsGame } = data?.football.rivals || {};
  if (!rivalsGame) {
    return null;
  }

  const { game } = rivalsGame;

  const displayCountdown = !rivalsGame.formationKnown;
  const startingLineupsDate = subHours(game.date, 1);
  const startingLineupsComingSoon = isPast(startingLineupsDate);

  return (
    <Root className={classNames({ displayCountdown })}>
      {displayCountdown && (
        <Background>
          <CountdownWrapper>
            <CountdownText
              className={classNames({ startingLineupsComingSoon })}
            >
              <LabelM>
                <FormattedMessage
                  id="Lineups.countdown.text"
                  defaultMessage="Starting Lineups in"
                />
              </LabelM>
              <DisplayM>
                <RivalsCountdown time={startingLineupsDate} />
              </DisplayM>
            </CountdownText>
            {startingLineupsComingSoon && (
              <LabelM color="var(--c-rivals-win)">
                <FormattedMessage
                  id="Lineups.countdown.comingSoon"
                  defaultMessage="Coming soon..."
                />
              </LabelM>
            )}
          </CountdownWrapper>
        </Background>
      )}
      <MatchView
        desktop={isLaptop}
        id={game.id}
        hideScoresAndGameWeek
        hideGameOverview={displayCountdown}
        onPlayerSelect={(_, scoreId) => setPlayerGameScoreId(scoreId)}
      />
      {playerGameScoreId && (
        <PlayerGameScoreDrawer
          open
          onClose={() => setPlayerGameScoreId(undefined)}
          playerGameScoreId={playerGameScoreId}
        />
      )}
    </Root>
  );
};
