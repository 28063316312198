import { TypedDocumentNode, gql } from '@apollo/client';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import {
  So5LeaderboardSeasonality,
  So5LeaderboardType,
} from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelXS } from '@sorare/core/src/atoms/typography';

import { SeasonalityLabel } from 'components/lineup/SeasonalityLabel';

import { LeaderboardInlineInfo_so5Leaderboard } from './__generated__/index.graphql';

const Dot = styled.div`
  width: 5px;
  height: 5px;
  background-color: var(--color);
  border-radius: 100%;
`;

type Props = {
  so5Leaderboard: LeaderboardInlineInfo_so5Leaderboard;
  children?: ReactNode;
};

export const LeaderboardInlineInfo = ({ so5Leaderboard, children }: Props) => {
  return (
    <Horizontal gap={0.5}>
      <Dot
        style={{ '--color': so5Leaderboard.so5League.color } as CSSProperties}
      />
      <LabelXS uppercase bold>
        <SeasonalityLabel
          seasonality={
            so5Leaderboard.seasonality ===
              So5LeaderboardSeasonality.IN_SEASON ||
            so5Leaderboard.so5TournamentType.so5LeaderboardType ===
              So5LeaderboardType.SPECIAL_WEEKLY
              ? So5LeaderboardSeasonality.IN_SEASON
              : So5LeaderboardSeasonality.ALL_SEASONS
          }
          label={so5Leaderboard.so5League.displayName}
        />
      </LabelXS>
      {children}
    </Horizontal>
  );
};

LeaderboardInlineInfo.fragments = {
  so5Leaderboard: gql`
    fragment LeaderboardInlineInfo_so5Leaderboard on So5Leaderboard {
      slug
      seasonality
      so5TournamentType {
        id
        so5LeaderboardType
      }
      so5League {
        slug
        displayName
        color(variant: PRIMARY)
      }
    }
  ` as TypedDocumentNode<LeaderboardInlineInfo_so5Leaderboard>,
};
