import classNames from 'classnames';
import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

import { unitMapping } from 'lib/style';

const Root = styled.div`
  width: 100%;
  margin: auto;
  display: grid;
  justify-content: center;
  gap: var(--gap);
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(24, 1fr);
  grid-template-areas:
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    '. . . F F F F F F . . E E E E E E . . .'
    'D D D D D D . . . . . . . . M M M M M M'
    'D D D D D D . . . . . . . . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    'D D D D D D . G G G G G G . M M M M M M'
    '. . . . . . . G G G G G G . . . . . . .'
    '. . . . . . . G G G G G G . . . . . . .';

  &.slots6 {
    grid-template-rows: repeat(26, 1fr);
    grid-template-areas:
      '. . . . . . . V V V V V V . . . . . . .'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . V V V V V V . E E E E E E'
      'F F F F F F . . . . . . . . E E E E E E'
      '. . . . . . . . . . . . . . . . . . . .'
      '. . . . . . . . . . . . . . . . . . . .'
      'D D D D D D . . . . . . . . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      'D D D D D D . G G G G G G . M M M M M M'
      '. . . . . . . G G G G G G . . . . . . .';
  }

  &.slots7 {
    grid-template-rows: repeat(20, 1fr);
    grid-template-columns: repeat(16, 1fr);
    grid-template-areas:
      '. V V V V . . . . . . U U U U .'
      '. V V V V . . . . . . U U U U .'
      '. V V V V . . . . . . U U U U .'
      '. V V V V . E E E E . U U U U .'
      '. V V V V . E E E E . U U U U .'
      '. V V V V . E E E E . U U U U .'
      '. V V V V . E E E E . U U U U .'
      '. V V V V . E E E E . U U U U .'
      'F F F F . . E E E E . . M M M M'
      'F F F F . . E E E E . . M M M M'
      'F F F F . . E E E E . . M M M M'
      'F F F F . . . . . . . . M M M M'
      'F F F F G G G G D D D D M M M M'
      'F F F F G G G G D D D D M M M M'
      'F F F F G G G G D D D D M M M M'
      'F F F F G G G G D D D D M M M M'
      '. . . . G G G G D D D D . . . .'
      '. . . . G G G G D D D D . . . .'
      '. . . . G G G G D D D D . . . .'
      '. . . . G G G G D D D D . . . .';
  }

  &.inline {
    grid-template-columns: repeat(var(--slots), 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: 'G D M F E';

    &.slots7 {
      grid-template-columns: repeat(var(--slots), 1fr);
      grid-template-rows: 1fr;
      grid-template-areas: 'G D M F E V U';
    }
  }

  & > * {
    &:nth-child(1) {
      grid-area: G;
    }
    &:nth-child(2) {
      grid-area: D;
    }
    &:nth-child(3) {
      grid-area: M;
    }
    &:nth-child(4) {
      grid-area: F;
    }
    &:nth-child(5) {
      grid-area: E;
    }
    &:nth-child(6) {
      grid-area: V;
    }
    &:nth-child(7) {
      grid-area: U;
    }
  }

  .transitioning + &,
  .standalone & {
    view-transition-name: lineup-field-cards;
    > *:nth-child(1) {
      view-transition-name: lineup-field-card-1;
    }
    > *:nth-child(2) {
      view-transition-name: lineup-field-card-2;
    }
    > *:nth-child(3) {
      view-transition-name: lineup-field-card-3;
    }
    > *:nth-child(4) {
      view-transition-name: lineup-field-card-4;
    }
    > *:nth-child(5) {
      view-transition-name: lineup-field-card-5;
    }
  }

  &.smartWidth {
    --card-footer-height: 48px;
    --usable-height: calc(var(--100vh) * var(--height-percentage));
    --card-height: calc(
      ((11 / 24) * var(--usable-height) - var(--card-footer-height))
    );
    --calc-card-width: calc(var(--card-height) * var(--card-aspect-ratio));
    --width: calc(
      3 * var(--calc-card-width) + (2 / 6) * var(--calc-card-width)
    );
    max-width: clamp(300px, var(--width), 100%);
  }
`;

type Props = {
  className?: string;
  fillHeightPercent?: number;
  slots?: number;
  children?: ReactNode;
  gap?: keyof typeof unitMapping;
  inline?: boolean;
};

export const LineupCardsLayout: FC<PropsWithChildren<Props>> = ({
  className,
  fillHeightPercent,
  slots = 5,
  children,
  gap = 1,
  inline,
}) => {
  return (
    <Root
      className={classNames(className, `slots${slots}`, {
        smartWidth: !!fillHeightPercent,
        inline,
      })}
      style={
        {
          '--height-percentage': fillHeightPercent,
          '--gap': unitMapping[gap],
          '--slots': slots,
        } as CSSProperties
      }
    >
      {children}
    </Root>
  );
};
