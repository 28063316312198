import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';

import { ClubLayout } from '@sorare/gameplay/src/components/profile/ClubLayout';

const StyledVertical = styled(Vertical)`
  padding: var(--double-unit) 0;
`;

const HistoryLayout = () => {
  return (
    <ClubLayout home>
      <StyledVertical gap={2}>
        <Outlet />
      </StyledVertical>
    </ClubLayout>
  );
};

export default HistoryLayout;
