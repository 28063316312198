import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from 'react-intl';

import { Switch } from 'atoms/inputs/Switch';
import { Horizontal } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';

const messages = defineMessages({
  saveCreditCard: {
    id: 'PaymentMethodPicker.saveCreditCard',
    defaultMessage: 'Save your credit card for later purchases',
  },
});

export const SaveCreditCard = ({
  saveCreditCard,
  toggleSaveCreditCard,
  label = messages.saveCreditCard,
}: {
  saveCreditCard: boolean;
  toggleSaveCreditCard: () => void;
  label?: MessageDescriptor;
}) => {
  return (
    <Horizontal>
      <Switch checked={saveCreditCard} onChange={toggleSaveCreditCard} />
      <LabelM color="var(--c-white)">
        <FormattedMessage {...label} />
      </LabelM>
    </Horizontal>
  );
};
