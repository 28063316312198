import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { CSSProperties } from 'styled-components';

import { Tooltip } from 'atoms/tooltip/Tooltip';
import { CARD_BORDER_RADIUS_2024 } from 'lib/cards';

import noGwLeft from './noGwLeft.svg';
import oneGwLeft from './oneGwLeft.svg';
import threeGwLeft from './threeGwLeft.svg';
import twoGwLeft from './twoGwLeft.svg';

interface Loan {
  gwLeft: number;
}

const BadgeWrapper = styled(Tooltip)`
  position: absolute;
  top: 0;
  right: 8%;
  width: 20%;
  z-index: 1;
  & > img {
    max-width: 100%;
  }
`;

const getBorderColor = (loan: Loan) => {
  if (loan.gwLeft === 0) return '#FFFFFF';
  if (loan.gwLeft === 1) return '#FF9999';
  if (loan.gwLeft === 2) return '#F0CE1D';
  if (loan.gwLeft === 3) return '#01FFD0';

  return null;
};

const getIcon = (loan: Loan) => {
  if (loan.gwLeft === 0) return noGwLeft;
  if (loan.gwLeft === 1) return oneGwLeft;
  if (loan.gwLeft === 2) return twoGwLeft;
  if (loan.gwLeft === 3) return threeGwLeft;

  return null;
};

const LoanRoot = styled.div`
  position: relative;
  padding: 2px;
  border-radius: ${CARD_BORDER_RADIUS_2024};
  background: linear-gradient(
    200.94deg,
    var(--bgColor) 0.02%,
    rgba(255, 255, 255, 0) 100.01%
  );
`;

const LoanWrapper = ({
  children,
  loan,
}: {
  children?: ReactNode;
  loan: Loan;
}) => {
  return (
    <LoanRoot
      style={
        {
          '--bgColor': getBorderColor(loan),
        } as CSSProperties
      }
    >
      <BadgeWrapper
        placement="top"
        title={
          <FormattedMessage
            id="loan.tooltip"
            // TODO PAY-316: handle variables with BE data
            defaultMessage="{gwLeft} gameweeks remaining to play. Card fully expires 6 gameweeks after loan start, on DD/MM"
            values={{ gwLeft: loan.gwLeft }}
          />
        }
      >
        <img alt="" src={getIcon(loan)} />
      </BadgeWrapper>
      {children}
    </LoanRoot>
  );
};

export default LoanWrapper;
